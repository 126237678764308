import i18n from 'i18next'
import { getBybitPositions } from 'src/@http/position'
import { Position } from 'src/types/models'

const getPositions = async (userId: number, startDate: Date, endDate: Date) => {
  const positions = await getBybitPositions(userId, startDate.toISOString(), endDate.toISOString())
  const data = positions.map((position: Position) => {
    return {
      ...position,
      createdDate: new Date(position.createdDate).toLocaleString(),
      isOpen: position.isOpen ? i18n.t('YES') : i18n.t('NO'),
      side: position.side === 'Sell' ? i18n.t('SALE') : i18n.t('BUY')
    }
  })

  return data
}

export default getPositions
