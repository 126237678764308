// import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar, Box, Grid, Typography } from '@mui/material'
import { DataGrid /*, GridColDef */ } from '@mui/x-data-grid'
// import {getProfitDetail } from 'src/@http/user'
// import Icon from 'src/@core/components/icon'
import { DialogGeneric } from 'src/pages/components/generic/DialogGeneric'
import profileStore, { ProfileStore } from 'src/zustand/profile'
// import { logger } from 'src/utils/Logger'

interface Props {
  showProp: boolean
  closeFunc: () => void
  userEmail: string
  startDate: string
  endDate: string
  total: number
  details: Array<any>
  selectedPlatformId: number
  loading: boolean
}

const ProfitDetailsDialog = ({
  showProp,
  closeFunc,
  userEmail,
  details,
  startDate,
  endDate,
  total,
  selectedPlatformId,
  loading
}: Props) => {
  const { t } = useTranslation()
  const columns = [
    {
      flex: 3,
      field: 'symbol',
      headerName: t('SYMBOL'),
      width: 250,
      editable: false,
      renderCell: (row: any) => {
        return (
          <>
            <Avatar
              variant='square'
              src={`https://raw.githubusercontent.com/spothq/cryptocurrency-icons/master/32/color/${row.row.symbol
                .replace('USDT', '')
                .toLowerCase()}.png`}
              sx={{ width: 30, height: 30, m: 2 }}
            />
            <Typography variant='body2'>{row.row.symbol}</Typography>
          </>
        )
      }
    },
    {
      flex: 3,
      field: 'createdDate',
      headerName: t('CREATE_DATE'),
      width: 250,
      editable: false,
      renderCell: (row: any) => {
        return (
          <>
            <Typography variant='body2'>{new Date(row.row.createdDate).toDateString()}</Typography>
          </>
        )
      }
    },
    {
      flex: 3,
      field: 'side',
      headerName: t('SIDE'),
      width: 200,
      editable: false
    },
    {
      flex: 3,
      field: 'size',
      headerName: t('SIZE'),
      width: 200,
      editable: false
    },

    {
      flex: 3,
      field: 'closedPnl',
      headerName: t('CLOSED_PNL'),
      width: 200,
      editable: false,
      type: 'number',
      renderCell: ({ row }: any) => {
        return (
          <Typography noWrap variant='body2' color={Number(row.closedPnl) < 0 ? '#DC2626' : '#03A66D'}  fontSize={16}>
            $ {row.closedPnl}
          </Typography>
        )
      }
    }
  ]

  const platforms = profileStore((state: ProfileStore) => state.platforms)
  const platform = platforms.find(value => value.platformId === selectedPlatformId)

  return (
    <DialogGeneric
      show={showProp}
      hideActions={true}
      title={t('DETAILS_USER_EMAIL', { userEmail })}
      closeFunc={() => {
        closeFunc()
      }}
      showIconClose
      maxWidth={1200}
    >
      <Box sx={{display:'flex', justifyContent:'center', justifyItems:'center', mb:2}}>
      <Avatar
        src={platform?.vchImage}
        sx={{
          width: { xs: 200, sm: 150 },
          height: { xs: 45, sm: 45 }
        }}
        variant='square'
      />
      </Box>
      
      <Grid container flexDirection={'row'} justifyContent={'center'}>
        <Typography sx={{ m: 1, color: 'secondary.main' }} variant='body2'>
          {t('START_DATE')} :
        </Typography>
        <Typography sx={{ m: 1, color: 'primary.main' }} variant='body2'>
          {new Date(startDate).toDateString()}
        </Typography>
        <Typography sx={{ m: 1, color: 'secondary.main' }} variant='body2'>
          {t('END_DATE')} :
        </Typography>
        <Typography sx={{ m: 1, color: 'primary.main' }} variant='body2'>
          {new Date(endDate).toDateString()}
        </Typography>
        <Typography sx={{ mt:1, mr:1, color: 'secondary.main' }} variant='body2'>
          {t('TOTAL')}
        </Typography>{' '}
        <Typography sx={{ mt:1, color: total >= 0 ? '#03A66D': '#DC2626' }} variant='body2'>
          : $ {total.toFixed(2)}
        </Typography>
      </Grid>

      <Grid container sx={{ justifyContent: 'center' }}>
        <DataGrid
          sx={{
            mt: 4,
            width: '100%'
          }}
          autoHeight
          rows={details}
          // getRowId={r => r.userId}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          // rowsPerPageOptions={[5]}
          loading={loading}
        />
      </Grid>
    </DialogGeneric>
  )
}

ProfitDetailsDialog.defaultProps = {
  showProp: false
}

export default ProfitDetailsDialog
