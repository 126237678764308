import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormControl, Grid, TextField } from '@mui/material'
import { putTelegramBotSettings,TelegramBotSetting } from 'src/@http/telegram-bot'
import { CustomLoadingBtn } from 'src/layouts/components/shared-component/CustomBtn'
import { ModeForm } from 'src/types'
import newsStore from 'src/zustand/news'
import * as yup from 'yup'

interface Props {
  modeForm: ModeForm
  submitLoading: boolean
  valuesDefaultForm?: TelegramBotSetting | null
  closeFunc: () => any
}

const TelegramBotForm = ({ modeForm, closeFunc, valuesDefaultForm }: Props) => {
  const { t } = useTranslation()
  const { getNewsCategory } = newsStore()

  let defaultValuesForm: TelegramBotSetting = {
    apiKey: '',
    secret: '',
    balanceFetchMs: 0,
    balancePercentageChange: 0,
    balancePercentageFetchMs: 0,
    liquidationPriceFecthMs: 0,
    marketOrdersConsumed: 0,
    marketOrdersConsumedFetchMs: 0,
    marketOrdersTimeFetchMs: 0,
    symbolPercentageChange: 0,
    telegramBotConfigurationId: 1,
    traderId: 0,
    userId: 0
  }

  if (modeForm === ModeForm.edit && valuesDefaultForm) {
    defaultValuesForm = {
      ...valuesDefaultForm
    }
  }

  const validations = {
    apiKey: yup.string().required(),
    secret: yup.string().required(),
    userId: yup.number().positive().required(),
    traderId: yup.number().positive().required(),
    symbolPercentageChange: yup.number().positive().required(),
    balanceFetchMs: yup.number().positive().required(),
    balancePercentageChange: yup.number().positive().required(),
    balancePercentageFetchMs: yup.number().positive().required(),
    marketOrdersConsumed: yup.number().positive().required(),
    marketOrdersConsumedFetchMs: yup.number().positive().required(),
    marketOrdersTimeFetchMs: yup.number().positive().required(),
    liquidationPriceFecthMs: yup.number().positive().required()
  }

  const schemaValidations = yup.object().shape(validations)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { control, handleSubmit, formState } = useForm<TelegramBotSetting>({
    resolver: yupResolver(schemaValidations),
    defaultValues: defaultValuesForm
  })

  const onSubmit = async (form: TelegramBotSetting) => {
 
    setIsLoading(true)
    try {
      await putTelegramBotSettings(form)
      toast.success(t('UPDATE_SUCCESS'), { duration: 4000 })
      closeFunc()
    } catch (error: any) {
      toast.error(error.message, { duration: 4000 })
    }
    setIsLoading(false)
  }

  return (
    <>
      <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='apiKey'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                disabled={ModeForm.show === modeForm}
                {...field}
                label={t('API_KEY')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='secret'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                disabled={ModeForm.show === modeForm}
                {...field}
                label={t('SECRET')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='userId'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                type='number'
                disabled={ModeForm.show === modeForm}
                {...field}
                label={t('USER_ID')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='traderId'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                type='number'
                disabled={ModeForm.show === modeForm}
                {...field}
                label={t('TRADER_ID')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='symbolPercentageChange'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                type='number'
                disabled={ModeForm.show === modeForm}
                {...field}
                label={t('SYMBOL_PERCENTAGE_CHANGE')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='balanceFetchMs'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                type='number'
                disabled={ModeForm.show === modeForm}
                {...field}
                label={t('BALANCE_FETCH_MS')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='balancePercentageChange'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                type='number'
                disabled={ModeForm.show === modeForm}
                {...field}
                label={t('BALANCE_PERCENTAGE_CHANGE')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='balancePercentageFetchMs'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                type='number'
                disabled={ModeForm.show === modeForm}
                {...field}
                label={t('BALANCE_PERCENTAGE_FETCH_MS')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='marketOrdersConsumed'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                type='number'
                disabled={ModeForm.show === modeForm}
                {...field}
                label={t('MARKET_ORDERS_CONSUMED')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='marketOrdersConsumedFetchMs'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                type='number'
                disabled={ModeForm.show === modeForm}
                {...field}
                label={t('MARKET_ORDERS_CONSUMED_FETCH_MS')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='marketOrdersTimeFetchMs'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                type='number'
                disabled={ModeForm.show === modeForm}
                {...field}
                label={t('MARKET_ORDERS_TIME_FETCH_MS')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='liquidationPriceFecthMs'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                type='number'
                disabled={ModeForm.show === modeForm}
                {...field}
                label={t('LIQUIDATION_PRICE_FETCH_MS')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>

        <Grid container>
          <Grid sx={{ p: 1 }} item xs={12}>
            <CustomLoadingBtn
              loading={isLoading}
              fullWidth
              size='large'
              type='submit'
              variant='contained'
              sx={{ mt: 3 }}
              disabled={ModeForm.edit === modeForm ? !formState.isDirty : false}
            >
              {t('SAVE')}
            </CustomLoadingBtn>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

TelegramBotForm.defaultProps = {
  editForm: ModeForm.create
}

export default TelegramBotForm
