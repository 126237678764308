import { useEffect } from 'react'
import { Card, CardContent } from '@mui/material'
import ItemsScroller from 'src/layouts/components/ItemScroller'
import adminStore from 'src/zustand/admin'

import CommissionRankItemCard from './CommissionRankItemCard'



const CommissionRankManagerCards = () => {
  const {  getAllCommissionRank, commissionRank} = adminStore()

  useEffect(() => {
    getAllCommissionRank()
  }, [])

  return (
    <Card>
      <CardContent sx={{ px: 0 }}>
        <ItemsScroller list={commissionRank} itemCard={CommissionRankItemCard} increment={6}></ItemsScroller>
      </CardContent>
    </Card>
  )
}

export default CommissionRankManagerCards