import { getAsync, postAsync, putAsync } from 'src/@http'
import { Category, CurrentNews, News } from 'src/zustand/news'

const rootNewsCategory = '/newsCategory'
const rootNews = '/News'
const MODULE = 'AXESKAPITAL'

export const getNewsCategory = (): Promise<Category[]> => {
  return getAsync(rootNewsCategory + '/' + MODULE)
}

export const postNewsCategory = (body: Partial<Category>): Promise<Category> => {
  return postAsync(rootNewsCategory, {
    vchName: body.name,
    vchModule: MODULE
  })
}

export const putNewsCategory = (id: number, body: Partial<Category>): Promise<Category> => {
  return putAsync(rootNewsCategory + `/${id}`, {
    vchName: body.name,
    vchModule: MODULE,
    intStatusId: body.statusId
  })
}

export const postDeleteNewsCategory = (id: number): Promise<Category> => {
  return postAsync(rootNewsCategory + `/${id}/delete`, {})
}

export const getNews = (): Promise<News[]> => {
  return getAsync(rootNews + '/' + MODULE)
}

export const getCurrentNews = (): Promise<CurrentNews[]> => {
  return getAsync(rootNews + '/currentNews/' + MODULE)
}



interface NewsPost {
  title: string
  thumbnail: string
  categories: number[]
  content: string
  statusId: number
  startDate: string
  endDate: string
}

export const postNews = (body: NewsPost): Promise<News> => {
  return postAsync(rootNews, {
    vchTitle: body.title,
    vchContent: body.content,
    vchThumbnail: body.thumbnail,
    vchModule: MODULE,
    dtmStartDate: body.startDate,
    dtmEndDate: body.endDate,
    categoriesId: body.categories
  })
}

export const putNews = (id: number, body: Partial<NewsPost>): Promise<Category> => {

  return putAsync(rootNews + `/${id}`, {
    vchTitle: body.title,
    vchContent: body.content,
    vchThumbnail: body.thumbnail,
    vchModule: MODULE,
    dtmStartDate: body.startDate,
    dtmEndDate: body.endDate,
    categoriesId: body.categories,
    intStatusId: body.statusId
  })
}

export const postDeleteNews = (id: number): Promise<News> => {
  return postAsync(rootNews + `/${id}/delete`, {})
}
