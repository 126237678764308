import { getAsync, postAsync } from 'src/@http'

const root = '/subscriptionCommissionWithdrawal'

interface SubscriptionCommissionWithdrawalPost{
    dcmAmount: number;
    vchNetwork: string;
    vchDestinationWallet: string;
}

export interface SubscriptionCommissionWithdrawal {
  intSubscriptionCommissionWithdrawalId: number;
  intStatusId: number;
  intUserId: number;
  dcmAmount: string;
  vchTxId: string | null;
  vchNetwork: string;
  vchOriginWallet: string | null;
  vchDestinationWallet: string;
  vchIp: string;
  dtmCreatedDate: string;
  user: {
    intUserId: number;
    vchEmail: string;
  };
}

interface SubscriptionCommissionWithdrawalConfirmRequest {
  intSubscriptionCommissionWithdrawalId: number;
  vchTxId: string;
  vchOriginWallet: string;
}

export const getSubscriptionCommissionWithdrawal = (): Promise<SubscriptionCommissionWithdrawal[]> => {
  return getAsync(root)
}


export const postSubscriptionCommissionWithdrawal = (body: SubscriptionCommissionWithdrawalPost): Promise<boolean> => {
  return postAsync(root, body)
}

export const postSubscriptionCommissionWithdrawalConfirm = (body: SubscriptionCommissionWithdrawalConfirmRequest): Promise<boolean> => {
  return postAsync(root+'/confirm', body)
}

