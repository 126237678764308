import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Chip, Typography } from '@mui/material'
import moment from 'moment'
import profileStore, { ProfileStore } from 'src/zustand/profile'

import CustomBtn from './CustomBtn'

interface Props {
  onlyDate: boolean
  modeMobile: boolean
}

const EndDateSubscription = ({ onlyDate, modeMobile }: Props) => {
  const { t } = useTranslation()
  const userDetail = profileStore((state: ProfileStore) => state.userDetail)
  const setShowPaymentFlowDialog = profileStore((state: ProfileStore) => state.setShowPaymentFlowDialog)

  const formattedDate = useMemo(() => {
    if (userDetail.endDateSubscription) {
      const endDateSubscriptionDate = new Date(userDetail.endDateSubscription)
      const monthName = endDateSubscriptionDate.toLocaleString('en-US', { month: 'short' })

      return `${endDateSubscriptionDate.getDate()}-${monthName.toUpperCase()}-${endDateSubscriptionDate.getFullYear()}`
    } else return null
  }, [userDetail.endDateSubscription])

  const dateLeft = useMemo(() => {
    if (userDetail.endDateSubscription) {
      const now = moment(moment.now())
      const endDateSubscriptionDate = moment(userDetail.endDateSubscription)

      /*
      const years = endDateSubscriptionDate.diff(now, 'year')
      now.add(years, 'years')
      const months = endDateSubscriptionDate.diff(now, 'months')
      now.add(months, 'months')
      const days = endDateSubscriptionDate.diff(now, 'days')
      */
      const days = endDateSubscriptionDate.diff(now, 'days')

      let message = ''
      //if (years > 0) message += `${years} ${years > 1 ? t('YEARS') : t('YEAR')} `
      //if (months > 0) message += `${months} ${months > 1 ? t('MONTHS') : t('MONTH')} `
      //if (days > 0 && days <= 15) message += `${days} ${days > 1 ? t('DAYS') : t('DAY')}`
      if (days > 0 && days <= 20)
        message += `${days} ${days > 1 ? t('REMAINING_SUBSCRIPTION_DAYS') : t('REMAINING_SUBSCRIPTION_DAY')}` //

      return message
    } else return ''
  }, [userDetail.endDateSubscription])

  const days = useMemo(() => {
    if (userDetail.endDateSubscription) {
      const now = moment(moment.now())
      const endDateSubscriptionDate = moment(userDetail.endDateSubscription)

      return endDateSubscriptionDate.diff(now, 'days')
    } else return null
  }, [userDetail.endDateSubscription])

  return (
    <>
      {modeMobile && (
        <Typography sx={{ pr: 2 }} color='#9295A6'>
          {t('MY_SUSCRIPTION')}
        </Typography>
      )}

      <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, mb: 1 }}>
        {!modeMobile && (
          <>
            <Typography sx={{ pr: 2 }} color='#9295A6'>
              {t('MY_SUSCRIPTION')}:
            </Typography>
            <Typography sx={{ mr: 1, textAlign: 'center' }} color='white'>
              {userDetail.subscriptionName}
            </Typography>
          </>
        )}
        {modeMobile && (
          <Typography sx={{ mr: 1, textAlign: 'center' }} color='white'>
            {userDetail.subscriptionName}
          </Typography>
        )}

        {formattedDate !== null && <Chip label={formattedDate} color='error' size='small' />}
        {formattedDate === null && !userDetail.blnPendingSubscription && <Typography color='#9295A6'>-</Typography>}
        {userDetail.blnPendingSubscription && (
          <Chip label={t('VALIDATION_PENDING')} color='warning' size='small' variant='outlined' />
        )}
      </Box>
      {!onlyDate && (
        <Typography sx={{fontWeight: 'bold', textAlign: modeMobile ? 'center' : 'left' }} color='#F61414'>
          {dateLeft}
        </Typography>
      )}
      {(days !== null && days <= 5 && !modeMobile) && (
        <CustomBtn variant='contained' onClick={() => { setShowPaymentFlowDialog(true) }} sx={{mt:2, mb:2}}>
          {t('RENEW_SUBSCRIPTION')}
        </CustomBtn>
      )}
    </>
  )
}
EndDateSubscription.defaultProps = {
  onlyDate: false,
  modeMobile: false
}

export default EndDateSubscription
