import { MouseEvent, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Box, IconButton, Menu, MenuItem } from '@mui/material'
import Icon from 'src/@core/components/icon'
import { deleteRank } from 'src/@http/commission'
import { ModeForm } from 'src/types'
import adminStore from 'src/zustand/admin'

import { DialogGeneric } from '../../generic/DialogGeneric'

import CommissionRankForm from './CommissionRankForm'



const userStatusObj: UserStatusType = {
  active: 'success',
  pending: 'warning',
  inactive: 'secondary'
}

interface CellType {
  row: API.Commission.Rank
}

const RowCommissionRankOptions = ({ row }: CellType) => {

  // ** State
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [show, setShow] = useState<boolean>(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [modeForm, setModeForm] = useState<ModeForm>(ModeForm.edit)
  const [valuesDefaultForm, setValuesDefaulForm] = useState<API.Commission.Rank | null>(null)
  const { t } = useTranslation()
  const { getAllCommissionRank } = adminStore()

  const rowOptionsOpen = Boolean(anchorEl)

  const handleRowOptionsClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleRowOptionsClose = () => {
    setAnchorEl(null)
  }

  const handleDelete = () => {
    handleRowOptionsClose()
  }

  const deleteItemSelected = async () => {
    try {
      await deleteRank(row.commissionRankId)
      setShowDeleteDialog(false)
      handleDelete()
      toast.success(t('COMMISSION_RANK_DELETED_SUCCESS'), { duration: 4000 })
      getAllCommissionRank()
    } catch (err: any) {
      toast.error(err.message, { duration: 4000 })
    }
  }

  return (
    <>
      <IconButton size='small' onClick={handleRowOptionsClick}>
        <Icon icon='mdi:dots-vertical' />
      </IconButton>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={rowOptionsOpen}
        onClose={handleRowOptionsClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        PaperProps={{ style: { minWidth: '8rem' } }}
      >
        <MenuItem
          onClick={() => {
            setValuesDefaulForm(row)
            setModeForm(ModeForm.edit)
            setShow(true)
            handleRowOptionsClose()
          }}
          sx={{ '& svg': { mr: 2 } }}
        >
          <Icon icon='mdi:pencil-outline' fontSize={20} />
          {t('EDIT')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setShowDeleteDialog(true)
            handleRowOptionsClose()
           
          }}
          sx={{ '& svg': { mr: 2 } }}
        >
          <Icon icon='mdi:delete-outline' fontSize={20} />

          {t('DELETE')}
        </MenuItem>
      </Menu>
      <DialogGeneric 
      title='DELETE_COMMISSION_RANK' 
      show={showDeleteDialog} 
      confirmText='CONFIRM'
      closeFunc={() => {
        setShowDeleteDialog(false)
      }}
       confirmFunc={deleteItemSelected}>
        {t('CONFIRM_DELETE_COMMISSION_RANK')}
      </DialogGeneric>
      <DialogGeneric
        show={show}
        hideActions={true}
        showIconClose={true}
        closeFunc={() => {
          setShow(false)
        }}
        title={t('COMMISSION_RANK_DETAILS')}
      >
        <Box sx={{ pt: 4 }}>
        <CommissionRankForm
            valuesDefaultForm={valuesDefaultForm}
            modeForm={modeForm}
            submitLoading={submitLoading}
            closeFunc={() => {setShow(false)}}
          />
        </Box>
      </DialogGeneric>
    </>
  )
}

export { RowCommissionRankOptions }
