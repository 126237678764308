import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Avatar, Box, ButtonGroup, Card, CardContent, Grid, IconButton } from '@mui/material'
import Icon from 'src/@core/components/icon'
import { deletedSubscription } from 'src/@http/subscription'
import { DialogGeneric } from 'src/pages/components/generic/DialogGeneric'
// import { logger } from 'src/utils/Logger'

interface Props {
  subscription: API.Subscription.SubscriptionResponse
  editFunction: (percents: any) => any
  deleteFunctionSuccess: () => any
}

const SubscriptionItemCard = ({ subscription, editFunction, deleteFunctionSuccess }: Props) => {
  const { t } = useTranslation()
  const [show, setShow] = useState<boolean>(false)

  const deleteSubscription = async () => {
    try {
      await deletedSubscription(subscription.subscriptionId)
      toast.success(t('SUBSCRIPTION_DELETE_MESSAGE_SUCCESS'))
      deleteFunctionSuccess()
    } catch (err: any) {
      toast.error(err)
    }
  }

  const ActionButtons = () => {
    const buttons = [
      {
        icon: 'carbon:edit',
        action: () => {
          editFunction(subscription)
        }
      },
      {
        icon: 'ph:trash-light',
        action: () => {
          setShow(true)
        }
      }
    ]

    return (
      <Grid container sx={{ flexDirection: 'row', justifyContent: 'end' }}>
        <ButtonGroup>
          {buttons.map(button => (
            <Grid item key={button.icon}>
              <IconButton onClick={() => button.action()} aria-label='capture screenshot' color='secondary'>
                <Icon icon={button.icon} fontSize={16} />
              </IconButton>
            </Grid>
          ))}
        </ButtonGroup>
      </Grid>
    )
  }

  return (
    <Card sx={{ backgroundColor: 'background.default' }}>
      <CardContent sx={{ pr: 0 }}>
        <Grid container sx={{ flexDirection: 'row', width: '100%' }}>
          <Grid item xs={2}>
            <Avatar sx={{ bgcolor: subscription.statusId === 1 ? '#066c00' : 'secondary.main' }} variant='rounded'>
              <Icon icon={subscription.statusId === 1 ? 'solar:dollar-line-duotone' : 'akar-icons:stop'} />
            </Avatar>
          </Grid>
          <Grid item sx={{ p: 1, pl: 1, fontSize: 18 }} xs={8}>
            {subscription.subscriptionName}
          </Grid>
          <Grid item xs={2} sx={{ justifyContent: 'end' }}>
            <ActionButtons />
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', fontSize: 12, mt: 4 }}>
          <Box sx={{ mr: 3 }}> {t('SUBSCRIPTION_VALUE')}:</Box> <span>$ {subscription.subscriptionValue}</span>
        </Box>
        <Box sx={{ display: 'flex', fontSize: 12 }}>
          <Box sx={{ mr: 3 }}> {t('AVAILABLE_DAYS')}:</Box> <span>{subscription.availableDays}</span>
        </Box>
        <DialogGeneric
          show={show}
          title={t('DELETE_SUBSCRIPTION')}
          confirmText={t('CONFIRM') || ''}
          confirmFunc={() => deleteSubscription()}
          closeFunc={() => {
            setShow(false)
          }}
        >
          {t('DELETE_SUBSCRIPTION_MESSAGE')}
        </DialogGeneric>
      </CardContent>
    </Card>
  )
}

export default SubscriptionItemCard
