import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Box,
  Button,
  CircularProgress,
  Grid,
  Typography
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { GridColDef } from '@mui/x-data-grid'
// import {  /*DataGrid,*/ GridColDef } from '@mui/x-data-grid'
import Icon from 'src/@core/components/icon'
import { GridBot } from 'src/@http/gridbot'
import { getAllGridBotRSIByGridbotId, GridBotRSI } from 'src/@http/gridbot-rsi'
import { DialogGeneric } from 'src/pages/components/generic/DialogGeneric'
import { ModeForm } from 'src/types'
import { logger } from 'src/utils/Logger'

import GridBotRSIForm from './GridBotRSIForm'
import { RowGridBotRSIOptions } from './RowGridBotRSIOptions'
// import { logger } from 'src/utils/Logger'

interface Props {
  showProp: boolean
  closeFunc: () => void
  gridBot: GridBot
}

interface CellType {
  row: GridBotRSI
}

const GridBotRSIDialog = ({ showProp, closeFunc, gridBot }: Props) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const [showNewRSI, setShowNewRSI] = useState<boolean>(false)
  const [gridBotRSIList, setGridBotRSIList] = useState<GridBotRSI[]>([])

  const init = async () => {
    setLoading(true)
    try {
      const response = await getAllGridBotRSIByGridbotId(gridBot.intGridBotId)
      setGridBotRSIList(response)
    } catch (error) {
      logger.log('[getAllGridBotRSIByGridbotId] error:', error)
    }
    setLoading(false)
  }

  useEffect(() => {
    if(showProp)
      init()
  }, [showProp])

  const headers: GridColDef[] = [
    {
      field: 'intGridBotRSIId',
      renderHeader: () => <Typography color='#9295A6'>{t('ID')}</Typography>,
      renderCell: ({ row }: CellType) => {
        return (<Typography fontSize={14}>{row.intGridBotRSIId}</Typography>)
      },
      flex: 1
    },
    {
      field: 'RSIValue',
      renderHeader: () => <Typography color='#9295A6'>{t('VALUE')}</Typography>,
      flex: 5
    },
    {
      field: 'RSIPercentageIncrement',
      renderHeader: () => <Typography color='#9295A6'>{t('PERCENTAGE_INCREMENT')}</Typography>,
      renderCell: ({ row }: CellType) => {
        return <Typography fontSize={14}>{row.RSIPercentageIncrement}</Typography>
      },
      flex: 4.5,
    },
    {
        flex: 1.5,
        field: 'actions',
        align: 'right',
        renderHeader: () => <Typography color='#9295A6'>{t('ACTIONS')}</Typography>,
        renderCell: ({ row }: CellType) => <RowGridBotRSIOptions row={row} refreshList={init} />
      }
  ]

  return (
    <>
    <DialogGeneric
      show={showProp}
      hideActions={true}
      title={`${t('RSI_VALUES')} (Grid Bot: ${gridBot.intGridBotId} ID)`}
      closeFunc={() => {
        closeFunc()
      }}
      showIconClose
      maxWidth={1200}
    >
        <>
        <Box sx={{ display: 'flex', alignContent: 'center', justifyContent:'end' }}>
            <Button onClick={() => {setShowNewRSI(true)}} startIcon={<Icon icon='mdi:plus-box-outline' color='#9295A6' />}>
              <Typography color='#9295A6' sx={{ textTransform: 'capitalize' }}>
                {t('ADD')}
              </Typography>
            </Button>
          </Box>
          <Grid container sx={{ justifyContent: 'center' }}>
        {loading ? (
          <CircularProgress />
        ) : gridBotRSIList.length !== 0 ? (
          <DataGrid
            autoHeight
            rows={gridBotRSIList}
            getRowId={r => r.intGridBotRSIId}
            columns={headers}
            pageSize={10}
            disableSelectionOnClick
            hideFooterPagination={false}
          />
        ) : (
          <Grid item sx={{ mt: 3 }}>
            <Icon icon='iconoir:info-empty' fontSize={60} color='red' />
            <Typography fontSize={16} sx={{ mt: 4 }}>
              {t('DONT_HAVE_RSI_MESSAGE')}
            </Typography>
          </Grid>
        )}
      </Grid>
        </>
     
    </DialogGeneric>
    
    <DialogGeneric
        show={showNewRSI}
        hideActions={true}
        showIconClose={true}
        closeFunc={() => {
            setShowNewRSI(false)
        }}
        title={t('GRID_BOT_RSI_DETAILS')}
      >
        <Box sx={{ pt: 4 }}>
          <GridBotRSIForm
            modeForm={ModeForm.create}
            closeFunc={() => {setShowNewRSI(false)}}
            intGridBotId={gridBot.intGridBotId}
            refreshList={init}
          />
        </Box>
      </DialogGeneric>
    </>
    
  )
}

GridBotRSIDialog.defaultProps = {
  showProp: false
}

export default GridBotRSIDialog
