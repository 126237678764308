import { ReferredUserTree } from 'src/@http/user'
import profileStore from 'src/zustand/profile'

const emailFormated = (email: string) => {
  if (email.includes('@') && email.includes('.')){
    const aux = email.split('@')
    const aux2 = aux[1].split('.')
  
    if (aux2.length !== 2) return '***ff@***ff.com'
  
    return `***${aux[0].slice(aux[0].length - 2, aux[0].length)}@***${aux2[1].slice(
      aux2[1].length - 2,
      aux2[1].length
    )}.${aux2[1]}`
  }
  
  return '***ff@***ff.com'
}

const emailMaxLengthFormated = (node: ReferredUserTree) => {
  const max = 30
  const label = `${node.intUserId}-${node.vchEmail}`
  if (label.length > max) {
    const aux = label.slice(`${node.intUserId}-`.length, max-3)

    return aux + '...'
  }

  return node.vchEmail
}

const transformNode = (node: ReferredUserTree) => {
  if (node.children && node.children.length !== 0) if (!(Number(node.level) < 1)) node.collapsed = true

  if (Number(node.level) > 1)
    node.vchEmail = profileStore.getState().isAdmin() ? emailMaxLengthFormated(node) : emailFormated(node.vchEmail)
  else node.vchEmail = emailMaxLengthFormated(node)
  if (node.dtmCreatedDate) node.dtmCreatedDate = node.dtmCreatedDate.slice(0, 10)
  else node.dtmCreatedDate = '-'

  return node
}

export default transformNode
