import { postAsync } from 'src/@http'

export interface apiCredentials {
  vchApiKey: string
  vchApiSecret: string
}

const root = '/2fa'

export const generateCode = (email : string) => {
  return postAsync(root + '/generate-code', {email})
}

export const validateCode = (email : string, code: string) => {
  return postAsync(root + '/validate', {
    email,
    code
  })
}
