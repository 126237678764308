import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@emotion/react'
import { Avatar, Box, Card, CardContent, Collapse, Typography } from '@mui/material'
import useOuterClick from 'src/hooks/useOuterClick'
import { hexToRgba } from 'src/utils/hexToRgba'

import RankInfoDetails from './RankInfoDetails/RankInfoDetails'
import RankSlider from './RankSlider'

const RankInfo = () => {
  const { t, i18n } = useTranslation()
  const actualRank = 1
  const [rankSelected, setRankSelected] = useState<number>(actualRank)
  const [showInfo, setShowInfo] = useState<boolean>(false)

  const theme: any = useTheme()

  const fontStyle = { fontSize: 18, fontWeight: 600 }

  const changeRankInfo = (rankId: number) => {
    if (rankId === actualRank) {
      setShowInfo(false)
    } else {
      if (showInfo) {
        if (rankId === rankSelected) setShowInfo(false)
      } else {
        setShowInfo(true)
      }
    }

    setRankSelected(rankId)
  }
  const innerRef = useOuterClick((ev: any) => {
    setShowInfo(false)
  })

  const now = new Date()
  const monthName = now.toLocaleString(i18n.language === 'es' ? 'es-ES' : 'en-GB', { month: 'long' })

  const data = {
    ranks: [
      {
        activeUsers: 0,
        minInWallet: 1000,
        nextRankName: 'AX1',
        requirements: ''
      },
      {
        activeUsers: 7,
        minInWallet: 1250,
        nextRankName: 'AX2',
        requirements: '1 AX1, 1 AX1, 1 AX1'
      },
      {
        activeUsers: 20,
        minInWallet: 1500,
        nextRankName: 'AX3',
        requirements: '1 AX2, 1 AX2, 1 AX1'
      },
      {
        activeUsers: 50,
        minInWallet: 2000,
        nextRankName: 'AX4',
        requirements: '1 AX3, 1 AX2, 1 AX1'
      },
      {
        activeUsers: 120,
        minInWallet: 2500,
        nextRankName: 'AX5',
        requirements: '1 AX4, 1 AX3, 2 AX2'
      },
      {
        activeUsers: 350,
        minInWallet: 5000,
        nextRankName: 'AX6',
        requirements: '1 AX5, 1 AX4, 1 AX3 , 1 AX2'
      }
    ]
  }

  return (
    <>
      <Card ref={innerRef} sx={{ overflow: 'visible', position: 'relative' }}>
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 4, mb: 4 }}>
            <Typography sx={{ ...fontStyle }}>
              {monthName[0].toUpperCase() + monthName.slice(1)} {now.getFullYear()}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                transform: 'translateX(-27px)'
              }}
            >
              <Typography sx={{ ...fontStyle, mr: 2 }}>{t('TO_BE')} </Typography>
              <Avatar sx={{ mr: 2 }} src='/images/icons/Estrategia Axes ACTIVA-01.png' variant='square' />
              <Typography sx={{ ...fontStyle, mr: 2 }} color='primary'>
                AXES {rankSelected === actualRank ? rankSelected + 1 : rankSelected}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 6, mb: 4 }}>
            <Typography sx={{ ...fontStyle, mr: 4 }} color='white'>
              {t('LEVEL')}
            </Typography>
            <RankSlider setRankSelected={changeRankInfo} actualRank={actualRank}></RankSlider>
          </Box>
        </CardContent>
        <Collapse id='rank-collapse' in={showInfo}>
          <Box
            sx={{
              width: '100%',
              zIndex: 1,
              background: `linear-gradient(to top, ${hexToRgba(theme.palette.primary.main, 0.97)} 55%, ${hexToRgba(
                theme.palette.primary.light,
                0.97
              )})`,
              position: 'absolute',
              borderRadius: '0px 0px 10px 10px'
            }}
          >
            <RankInfoDetails rank={data.ranks[rankSelected - 1]}></RankInfoDetails>
          </Box>
        </Collapse>
      </Card>
    </>
  )
}

export default RankInfo
