import { MouseEvent, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Box, IconButton, Menu, MenuItem } from '@mui/material'
import Icon from 'src/@core/components/icon'
import { apiCredentials, putPlatform } from 'src/@http/platform/'
import { deleteUser, updateUserDetail,UpdateUserRequest } from 'src/@http/user'
import { ModeForm } from 'src/types'
import adminStore from 'src/zustand/admin'

import { DialogGeneric } from '../../generic/DialogGeneric'
import CustomerDetailsForm, { CustomerForm, ValuesDefaultCustomerForm } from '../customer-manager/CustomerDetailsForm'

import TransactionsDialog from './TransactionsDialog'

const userStatusObj: UserStatusType = {
  active: 'success',
  pending: 'warning',
  inactive: 'secondary'
}

interface CellType {
  row: API.User.AllUserResponse
}

const RowOptions = ({ row }: CellType) => {
  // ** State
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [show, setShow] = useState<boolean>(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [modeForm, setModeForm] = useState<ModeForm>(ModeForm.edit)
  const [showTransactions, setShowTransactions] = useState<boolean>(false)
  const [valuesDefaultCustomerForm, setValuesDefaultCustomerForm] = useState<ValuesDefaultCustomerForm>({
    email: '',
    id: '',
    registerDate: '',
    status: false,
    endDateSubscription: ''
  })
  const { t } = useTranslation()
  const { getAllUser, setperformanceUserDetail } = adminStore()

  const rowOptionsOpen = Boolean(anchorEl)

  const handleRowOptionsClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleRowOptionsClose = () => {
    setAnchorEl(null)
  }

  const handleDelete = () => {
    handleRowOptionsClose()
  }

  const editUser = async (form: CustomerForm) => {
    const bodyCredentials: apiCredentials = {
      vchApiKey: form.apiKey,
      vchApiSecret: form.secretKey
    }
    setSubmitLoading(true)
    try {
      const body: UpdateUserRequest = {
        vchLastName: '',
        vchName: '',
        intStatusId: form.status ? 1 : 0,
      }
      if(form.endDateSubscription !== null)
        body.dtmEndDateSubscription = form.endDateSubscription.toISOString()
      if (form.password !== '') body['vchPassword'] = form.password
      await updateUserDetail(parseInt(form.id), body)
      if (form.secretKey !== '') await putPlatform(Number(form.id), Number(form.platformId), bodyCredentials)
      setShow(false)

      toast.success(t('USER_UPDATED_SUCCESS'), { duration: 4000 })
    } catch (error: any) {
      toast.error(error.message, { duration: 4000 })
    }
    setSubmitLoading(false)

    getAllUser()
  }

  const deleteUserSelected = async () => {
    try {
      await deleteUser(`${row.userId}`)
      toast.success(t('USER_DELETED_SUCCESS'), { duration: 4000 })
      getAllUser()
    } catch (err: any) {
      toast.error(err.message, { duration: 4000 })
    }
  }

  return (
    <>
      <IconButton size='small' onClick={handleRowOptionsClick}>
        <Icon icon='mdi:dots-vertical' />
      </IconButton>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={rowOptionsOpen}
        onClose={handleRowOptionsClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        PaperProps={{ style: { minWidth: '8rem' } }}
      >
        <MenuItem
          onClick={() => {
            setValuesDefaultCustomerForm({
              id: `${row.userId}`,
              email: row.email,
              registerDate: '',
              status: row.status === 1,
              endDateSubscription: row.endDateSubscription
            })
            setModeForm(ModeForm.show)
            setShow(true)
            handleRowOptionsClose()
          }}
          sx={{ '& svg': { mr: 2 } }}
        >
          <Icon icon='mdi:eye-outline' fontSize={20} />
          {t('VIEW')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setValuesDefaultCustomerForm({
              id: `${row.userId}`,
              email: row.email,
              registerDate: '',
              status: row.status === 1,
              endDateSubscription: row.endDateSubscription
            })
            setModeForm(ModeForm.edit)
            setShow(true)
            handleRowOptionsClose()
          }}
          sx={{ '& svg': { mr: 2 } }}
        >
          <Icon icon='mdi:pencil-outline' fontSize={20} />
          {t('EDIT')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setShowDeleteDialog(true)
            handleRowOptionsClose()
          }}
          sx={{ '& svg': { mr: 2 } }}
        >
          <Icon icon='mdi:delete-outline' fontSize={20} />

          {t('DELETE')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleRowOptionsClose()
            const endDate = new Date() // Now
            const startDate = new Date()
            startDate.setDate(startDate.getDate() - 30)
            setperformanceUserDetail(row.userId, startDate, endDate)
            setShowTransactions(true)
          }}
          sx={{ '& svg': { mr: 2 } }}
        >
          <Icon icon='mdi:list-box-outline' fontSize={20} />
          {t('SHOW_TRANSACTIONS')}
        </MenuItem>
      </Menu>
      <DialogGeneric title='DELETE_USER' show={showDeleteDialog} confirmText='CONFIRM' confirmFunc={deleteUserSelected}>
        {t('CONFIRM_DELETE_USER')}
      </DialogGeneric>
      <DialogGeneric
        show={show}
        hideActions={true}
        showIconClose={true}
        closeFunc={() => {
          setShow(false)
        }}
        title={t('CUSTOMER_DETAILS')}
      >
        <Box sx={{ pt: 4 }}>
          <CustomerDetailsForm
            modeForm={modeForm}
            submitFinishFunction={form => {
              editUser(form)
            }}
            submitLoading={submitLoading}
            valuesDefaultForm={valuesDefaultCustomerForm}
          />
        </Box>
      </DialogGeneric>
      <TransactionsDialog
        showProp={showTransactions}
        closeFunc={() => {
          setShowTransactions(false)
        }}
      ></TransactionsDialog>
    </>
  )
}

export { RowOptions }
