import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import Icon from 'src/@core/components/icon'
import CustomAvatar from 'src/@core/components/mui/avatar'

import { RowCommissionRankOptions } from './RowCommissionRankOptions'


interface Props {
  item: API.Commission.Rank
}

const userStatusObj: UserStatusType = {
  active: 'success',
  pending: 'warning',
  inactive: 'secondary'
}

const CommissionRankItemCard = ({ item }: Props) => {
  // const { bgColor } = adminStore()
  const { t } = useTranslation()
  const [show, setShow] = useState<boolean>(false)


  const { commissionPercentage, commissionRankId, commissionRankName, costPerMonth, maxBalance, minBalance, statusId } = item


  const strategies: any = {
    1: {
      color: '#1AF772',
      name: t('LOW')
    },
    2: {
      color: '#FF8E0A',
      name: t('MEDIUM')
    },
    3: {
      color: '#F10707',
      name: t('HIGH')
    }
  }

  return (
    <Card variant='outlined' sx={{ backgroundColor: '' }}>
      <CardContent sx={{ px: 2 }}>
        <Grid container sx={{}}>
          <Grid item xs={2}>
            <CustomAvatar color={statusId === 1 ? 'primary' : 'secondary'} variant='rounded'>
              <Icon icon={statusId === 1 ? 'mdi:currency-usd' : 'akar-icons:stop'} color='white' />
            </CustomAvatar>
          </Grid>
          <Grid item sx={{ p: 1, pl: 1, fontSize: 18, display:'flex', alignItems:'center' }} xs={8}>
          <Typography sx={{pl:2}}>{commissionRankName}</Typography>
          </Grid>
          <Grid item xs={2} sx={{ justifyContent: 'end', display: 'flex' }}>
            <RowCommissionRankOptions row={item} />
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', fontSize: 12, mt: 2 }}>
          <Box sx={{ mr: 3 }}> {t('MIN_BALANCE')}:</Box>{' '}
          <span> {minBalance}</span>
        </Box>
        <Box sx={{ display: 'flex', fontSize: 12, mt: 2 }}>
          <Box sx={{ mr: 3 }}> {t('MAX_BALANCE')}:</Box>{' '}
          <span> {maxBalance}</span>
        </Box>
        <Box sx={{ display: 'flex', fontSize: 12, mt: 2 }}>
          <Box sx={{ mr: 3 }}> {t('COMMISSION_PORCENTAGE')}:</Box>{' '}
          <span> {parseFloat(commissionPercentage)}%</span>
        </Box>
        <Box sx={{ display: 'flex', fontSize: 12, mt: 2 }}>
          <Box sx={{ mr: 3 }}> {t('COST_PER_MONTH')}:</Box>{' '}
          <span> {costPerMonth}</span>
        </Box>
      </CardContent>
    </Card>
  )
}

export default CommissionRankItemCard