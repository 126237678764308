import { Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import i18n from 'i18next'
import Icon from 'src/@core/components/icon'
import { putNews } from 'src/@http/news'
import { News } from 'src/zustand/news'
import newsStore from 'src/zustand/news'

import ChangeStatus from '../../actions-form/ChangeStatus'

import { RowNewsOptions } from './RowNewsOptions'

interface CellType {
  row: News
}

/**
 * 
 id: number
  title: string
  thumbnail: string
  categoryId: number
  content: string
  statusId: number
  createdDate: string
  visualizationDate: string
  hideDate: string
 * 
 * 
 */

const columns: GridColDef[] = [
  {
    width: 350,
    field: 'title',
    headerAlign: 'left',
    align: 'left',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('TITLE')}</Typography>,
    renderCell: ({ row }: CellType) => {
      const size = 30

      return (
        <>
          {row.title.length > size && (
            <Grid container>
              <Grid item sx={{ display: 'flex', alignItems: 'center' }} xs={10}>
                <Typography color={'white'}>{row.title.slice(0, size) + '...'}</Typography>
              </Grid>
              <Grid item xs={2} sx={{ justifyContent: 'end', display: 'flex', alignItems: 'center' }}>
                <Tooltip disableFocusListener disableTouchListener title={row.title}>
                  <IconButton>
                    <Icon icon='mdi:information-outline' />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          )}
          {row.title.length < size && <Typography color={'white'}>{row.title}</Typography>}
        </>
      )
    }
  },
  {
    width: 100,
    field: 'minBalance',
    headerAlign: 'left',
    align: 'left',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('STATUS')}</Typography>,
    renderCell: ({ row }: CellType) => {
      const change = async (status: boolean) => {
        await putNews(row.id, {statusId: status?1:0})
        await newsStore.getState().getNews()
      }

      return <ChangeStatus change={change} status={row.statusId}></ChangeStatus>
    }
  },
  {
    width: 150,
    field: 'visualizationDate',
    headerAlign: 'left',
    align: 'left',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('START_DATE')}</Typography>,
    renderCell: ({ row }: CellType) => {
      const { startDate } = row

      return <Typography color={'white'}>{startDate ? row.startDate.slice(0, 10) : '-'}</Typography>
    }
  },
  {
    width: 150,
    field: 'hideDate',
    headerAlign: 'left',
    align: 'left',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('END_DATE')}</Typography>,
    renderCell: ({ row }: CellType) => {
      const { endDate } = row

      return <Typography color={'white'}>{endDate ? row.endDate.slice(0, 10) : '-'}</Typography>
    }
  },
  {
    width: 150,
    field: 'createdDate',
    headerAlign: 'left',
    align: 'left',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('CREATED_DATE')}</Typography>,
    renderCell: ({ row }: CellType) => {
      const { createdDate } = row

      return <Typography color={'white'}>{createdDate ? row.createdDate.slice(0, 10) : '-'}</Typography>
    }
  },
  {
    width: 100,
    minWidth: 90,
    sortable: false,
    field: 'actions',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('ACTIONS')}</Typography>,
    renderCell: ({ row }: CellType) => <RowNewsOptions row={row} />
  }
]

export default columns
