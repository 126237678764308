import { getAsync, postAsync, putAsync } from 'src/@http'
import { PlatformModel } from 'src/types/models/platformModel'

/* Endpoints definition */

export interface apiCredentials {
  vchApiKey: string
  vchApiSecret: string
  vchPassPhrase?: string
}

export interface Platform {
  platformId: number
  platformName: string
  apiCode: string
  vchImage: string
  intPassPhrase: number
  intStatusId: number
  vchServiceDomain: string
}


interface PlatformUpdate {
  intPlatformId: number;
  vchName: string;
  vchApiCode: string;
  vchServiceDomain: string;
  vchImage: string;
  intStatusId: number;
  intPassPhrase: boolean;
}

const rootPath = '/platform'
const PostPlatform = (userId: number, platformId: number) => `users/${userId}/Platform/${platformId}`
const VerifyUserCredentials = (platformId: number) => `${rootPath}/verify/${platformId}`

interface UserCredentials {
  vchApiKey: string
  vchApiSecret: string
}

export const verifyUserCredentials = (platformId: number, apiKey: string, secretkey: string): Promise<any> => {
  const body: UserCredentials = {
    vchApiKey: apiKey,
    vchApiSecret: secretkey
  }

  return postAsync(VerifyUserCredentials(platformId), body)
}

export const getPlatforms = (): Promise<Platform[]> => {
  return getAsync(rootPath)
}



export const putExchangePlatform = (platformUpdate: PlatformUpdate): Promise<Platform> => {
  return putAsync(rootPath, platformUpdate)
}


export const postPlatform = (userId: number, platformId: number, body: apiCredentials): Promise<PlatformModel[]> => {
  return postAsync(PostPlatform(userId, platformId), body)
}

export const putPlatform = (userId: number, platformId: number, body: apiCredentials): Promise<PlatformModel[]> => {
  return putAsync(PostPlatform(userId, platformId), body)
}
