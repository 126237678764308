import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import CustomBtn from 'src/layouts/components/shared-component/CustomBtn'

import { IDate } from './ButtonsFilter'
import DateFilter from './DateFilter'

interface PropsFilterPanel {
  handleDates: (dates: IDate) => any
  initDate: IDate
  maxDate: Date
}

const RangeDateFilter = ({ handleDates, initDate, maxDate }: PropsFilterPanel) => {
  const { t } = useTranslation()

  const [visible, setVisible] = useState<boolean>(false)
  const [isCustom, setIsCustom] = useState<boolean>(false)

  //** Style Configuration
  const theme = useTheme()


  function setDateSelected(dates: IDate) {
    handleDates(dates)
  }

  return (
    <Grid container spacing={2} alignItems="center" sx={{mt: 2}}>
      <Grid item xs={12} md={2}>
        <CustomBtn
          sx={{ height: 50, mr: 2,mt: {xs:2, md:0} }}
          variant={!isCustom?'contained': 'outlined'}
          fullWidth
          onClick={() => {
            setIsCustom(false)
            setVisible(false)
            setDateSelected(initDate)
          }}
        >
          {t('LAST_7_DAYS')}
        </CustomBtn>
      </Grid>
      <Grid item xs={12} md={2}>
        <CustomBtn
          variant={isCustom?'contained': 'outlined'}
          fullWidth
          sx={{ height: 50,mt: {xs:2, md:0} }}
          onClick={() => {
            setVisible(true)
            setIsCustom(true)
          }}
        >
           {t('CUSTOM_TIME_FRAME')}
        </CustomBtn>
      </Grid>
      <Grid item xs={12} sx={{mt: {xs:4, md:0}}} md={6}>
        {visible && (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateFilter onData={setDateSelected} width={'100%'} initDate={initDate} maxDate={maxDate}/>
            </LocalizationProvider>
        )}
      </Grid>
    </Grid>
  )
}

export default RangeDateFilter
