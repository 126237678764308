import { getAsync, postAsync } from 'src/@http'
import { Position } from 'src/types/models'

interface BybitPosition {
  symbol: string;
  leverage: string;
  autoAddMargin: number;
  avgPrice: string;
  liqPrice: string;
  riskLimitValue: string;
  takeProfit: string;
  positionValue: string;
  isReduceOnly: boolean;
  tpslMode: string;
  riskId: number;
  trailingStop: string;
  unrealisedPnl: string;
  markPrice: string;
  adlRankIndicator: number;
  cumRealisedPnl: string;
  positionMM: string;
  createdTime: string;
  positionIdx: number;
  positionIM: string;
  seq: number;
  updatedTime: string;
  side: string;
  bustPrice: string;
  positionBalance: string;
  leverageSysUpdatedTime: string;
  size: string;
  positionStatus: string;
  mmrSysUpdatedTime: string;
  stopLoss: string;
  tradeMode: number;
}


const path = '/Positions'
const GetBybitPositions = (userId: number, startDate: string, endDate: string) =>
  `/Positions/${userId}/${startDate}/${endDate}`

export const getBybitPositions = (userId: number, startDate: string, endDate: string): Promise<Position[]> => {
  return getAsync(GetBybitPositions(userId, startDate, endDate))
}

export const getAllProvider = (userId: number): Promise<any[]> => {
  return getAsync(path+'/'+userId+'/AllProvider')
}

export const postClosePositions = (userId: number): Promise<any[]> => {
  return postAsync(path+'/'+userId+'/close', {})
}
