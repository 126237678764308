import { Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import i18n from 'i18next'
import CustomChip from 'src/@core/components/mui/chip'
import { ThemeColor } from 'src/@core/layouts/types'
import { SubscriptionTransaction } from 'src/@http/subscriptionTransaction'
import adminStore from 'src/zustand/admin'
import profileStore from 'src/zustand/profile'

import CopyText from './column/CopyText'
import SubscriptionTransactionStatus from './column/SubscriptionTransactionStatus'

interface CellType {
  row: SubscriptionTransaction
}

const statusObj: { [key: number]: ThemeColor } = {
  1: 'success',
  0: 'warning',
  2: 'error'
}

const columns: GridColDef[] = [
  {
    width: 180,
    field: 'dtmCreatedDate',
    headerAlign: 'center',
    align: 'center',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('DATE')}</Typography>,
    renderCell: ({ row }: CellType) => {
      return (
        <Typography sx={{ px: 4 }} color={'white'} fontSize={14}>
          {row.dtmCreatedDate ? row.dtmCreatedDate.slice(0, 10) : '-'}
        </Typography>
      )
    }
  },
  {
    width: 150,
    field: 'vchPaymentType',
    headerAlign: 'center',
    align: 'center',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('PAYMENT_TYPE')}</Typography>,
    renderCell: ({ row }: CellType) => {
      return <Typography color={'white'}>{row.vchPaymentType === '' ? '-' : row.vchPaymentType}</Typography>
    }
  },
  {
    width: 200,
    field: 'intStatusId',
    headerAlign: 'center',
    align: 'center',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('STATUS')}</Typography>,
    renderCell: ({ row }: CellType) => {
      return (
        <>
          {false && (
            <CustomChip
              skin='light'
              size='small'
              label={row.intStatusId === 1 ? i18n.t('COMPLETED') : i18n.t('PENDING')}
              color={statusObj[row.intStatusId] || 'success'}
              sx={{ textTransform: 'capitalize' }}
            />
          )}
          <SubscriptionTransactionStatus item={row}></SubscriptionTransactionStatus>
        </>
      )
    }
  },
  {
    width: 350,
    field: 'intUserId',
    headerAlign: 'center',
    align: 'center',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('USER')}</Typography>,
    renderCell: ({ row }: CellType) => {
      return (
        <Typography color={'white'}>
          {row.user.intUserId}-{row.user.vchEmail}
        </Typography>
      )
    }
  },
  {
    width: 230,
    field: 'dcmAmountTransaction',
    headerAlign: 'center',
    align: 'center',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('AMOUNT_TRANSACTION')}</Typography>,
    renderCell: ({ row }: CellType) => {
      return (
        <Typography color={'white'}>
          {row.dcmAmountTransaction !== null ? `$${row.dcmAmountTransaction}` : '-'}
        </Typography>
      )
    }
  },
  {
    width: 400,
    field: 'vchOriginWallet',
    headerAlign: 'center',
    align: 'center',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('ORIGIN_WALLET')}</Typography>,
    renderCell: ({ row }: CellType) => {
      return (
        <>
          {row.vchOriginWallet !== null && <CopyText text={row.vchOriginWallet}></CopyText>}
          {row.vchOriginWallet === null && (
            <Typography color={'white'}>-</Typography>
          )}
        </>
      )
    }
  },
  {
    width: 400,
    field: 'vchDestinationWallet',
    headerAlign: 'center',
    align: 'center',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('DESTINATION_WALLET')}</Typography>,
    renderCell: ({ row }: CellType) => {
      return (
        <>
          {row.vchDestinationWallet !== null && <CopyText text={row.vchDestinationWallet}></CopyText>}
          {row.vchDestinationWallet === null && (
            <Typography color={'white'}>
              {row.vchDestinationWallet !== null ? `${row.vchDestinationWallet}` : '-'}
            </Typography>
          )}
        </>
      )
    }
  },
  {
    width: 150,
    field: 'intPlatformId',
    headerAlign: 'center',
    align: 'center',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('PLATFORM')}</Typography>,
    renderCell: ({ row }: CellType) => {
      const platform = profileStore.getState().platforms.find(value => value.platformId === row.intPlatformId)

      return <Typography color={'white'}>{platform ? platform.platformName : row.intPlatformId}</Typography>
    }
  },
  {
    width: 250,
    field: 'intSubscriptionId',
    headerAlign: 'center',
    align: 'center',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('SUBSCRIPTION_NAME')}</Typography>,
    renderCell: ({ row }: CellType) => {
      const subscription = adminStore
        .getState()
        .subscriptions.find(value => value.subscriptionId === row.intSubscriptionId)

      return (
        <Typography color={'white'}>{subscription ? subscription.subscriptionName : row.intSubscriptionId}</Typography>
      )
    }
  }
]

export default columns
