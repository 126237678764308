import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Avatar, Box, Card, CardContent, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Icon from 'src/@core/components/icon'
import { getWalletBalance } from 'src/@http/user'
import { FontWeight } from 'src/layouts/TypographyTheme'
import profileStore, { ProfileStore, UserPlatform, WalletBalance } from 'src/zustand/profile'

import { priceColor } from '../PriceText'

const WalletVisualization = () => {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('sm'))

  const user = profileStore((state: ProfileStore) => state.userDetail)
  const setWalletBalance = profileStore((state: ProfileStore) => state.setWalletBalance)
  const [loading, setLoading] = useState<boolean>(false)
  const { t } = useTranslation()

  const walletBalance: WalletBalance = profileStore((state: ProfileStore) => state.walletBalance)

  const getPlatform = profileStore((state: ProfileStore) => state.getPlatform)

  const userPlatform: UserPlatform = profileStore((state: ProfileStore) => state.userPlatform)

  const callWalletBalance = async () => {
    if (user.userId !== '') {
      setLoading(true)
      try {
        const data = await getWalletBalance(user.userId)
        setWalletBalance(data)
      } catch (error: any) {
        toast.error(error.message)
      }
      setLoading(false)
    }
  }

  const reloadPage = () => {
    window.location.reload()
  }

  useEffect(() => {
    callWalletBalance()
    const interval = setInterval(callWalletBalance, 120000)

    return () => clearInterval(interval)
  }, [])

  const imageUrl = useMemo(() => {
    return getPlatform(userPlatform.platformId) !== null
      ? getPlatform(userPlatform.platformId)?.vchImage
      : '/images/platforms/notfound.png'
  }, [userPlatform.platformId])

  if (mobile)
    return (
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='center'
        sx={{ backgroundColor: 'background.default' }}
        style={{ padding: 7, borderRadius: 6 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ px: 2, pt: 1 }} fontWeight={FontWeight.Bold} fontSize={10} color='white'>
            {t('TOTAL_EQUITY')}:
          </Typography>
          <Typography sx={{ px: 2, pt: 1 }} fontWeight={FontWeight.Bold} fontSize={12} color='#1BE817'>
            {Number(walletBalance.totalBalance).toFixed(2)}
          </Typography>
          {walletBalance.totalPerpUPL !== 0 && (
            <>
              <Typography sx={{ px: 2, pt: 1 }} fontWeight={FontWeight.Bold} fontSize={10} color='white'>
                PnL:
              </Typography>
              <Typography sx={{ px: 2, pt: 1 }} fontWeight={FontWeight.Bold} fontSize={10} color='#1BE817'>
                {Number(walletBalance.totalPerpUPL).toFixed(2)}
              </Typography>
            </>
          )}
        </Box>
      </Box>
    )

  return (
    <Card sx={{ backgroundColor: 'background.default' }}>
      <CardContent sx={{ alignItems: 'center', alignContent: 'center' }} style={{ padding: 6 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Avatar
            src={imageUrl}
            sx={{
              px: 2,
              width: { xs: 25, sm: 75 },
              height: { xs: 25, sm: 25 }
            }}
            variant='rounded'
          />
          {loading ? (
            <Box>
              <CircularProgress size={15} color='primary' />
            </Box>
          ) : (
            <Box>
              <IconButton
                aria-label='capture screenshot'
                onClick={() => {
                  reloadPage()
                }}
                color='secondary'
                size='small'
              >
                <Icon icon='solar:refresh-broken' fontSize='inherit' />
              </IconButton>
            </Box>
          )}
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography sx={{ px: 2, pt: 1 }} fontWeight={FontWeight.Bold} fontSize={12} color='white'>
            {t('TOTAL_EQUITY')}:
          </Typography>
          <Typography sx={{ px: 2, pt: 1 }} fontWeight={600} fontSize={14} color={priceColor(Number(walletBalance.totalBalance))}>
            {Number(walletBalance.totalBalance).toFixed(2)} USDT
          </Typography>
          {walletBalance.totalPerpUPL !== 0 && (
            <>
              <Typography sx={{ px: 2, pt: 1 }} fontWeight={FontWeight.Bold} fontSize={12} color='white'>
                PnL:
              </Typography>
              <Typography sx={{ px: 2, pt: 1 }} fontWeight={600} fontSize={12} color={priceColor(Number(walletBalance.totalPerpUPL))}>
                {Number(walletBalance.totalPerpUPL).toFixed(2)} USDT
              </Typography>
            </>
          )}
        </Box>
      </CardContent>
    </Card>
  )
}

export default WalletVisualization
