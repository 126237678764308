import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Avatar,
  Box,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
// import { styled } from '@mui/material/styles'
import Icon from 'src/@core/components/icon'
import { postPercentageStrategiesByTraderId, postTrader, updateTrader } from 'src/@http/trader/'
import { CustomBtn, CustomLoadingBtn } from 'src/layouts/components/shared-component/CustomBtn'
import { ModeForm } from 'src/types'
import type { Platform2 } from 'src/types/models/platformModel'
import { PercentageTraderPost, Trader, TraderStrategies } from 'src/types/models/traderModel'
import { logger } from 'src/utils/Logger'
import * as yup from 'yup'

interface Props {
  strategies?: TraderStrategies[] | null
  trader?: Trader | null
  modeForm: ModeForm
  submitFinishFunction: () => any
  closeFunc: () => any
}
interface FormDataRegisterTrader {
  name: string
  apiKey: string
  apiSecret: string
  percentStrategyLow: number | string
  percentStrategyMedium: number | string
  percentStrategyHigh: number | string
  traderPassPhrase: string
}

const TraderRegisterForm = (props: Props) => {
  const { closeFunc, submitFinishFunction, modeForm, trader, strategies } = props

  //const { platforms } = profileStore()
  const platforms = [{
      id: 1,
      image: "/images/platforms/bybit.png",
      platformName: "bybit",
      status: true
  }]
  const getPercents = (id: number) => {
    if (!strategies || strategies.length === 0) return 0
    const strategy = strategies.find(strategy => strategy.id === id)
    if (!strategy) return 0

    return strategy.percentage
  }

  const defaultValuesForm: FormDataRegisterTrader = {
    name: trader ? trader.traderName : '',
    apiKey: trader ? '*'.repeat(8) + trader.traderApiKey : '',
    apiSecret: trader ? '*'.repeat(8) + trader.traderApiSecret : '',
    percentStrategyLow: getPercents(1),
    percentStrategyMedium: getPercents(2),
    percentStrategyHigh: getPercents(3),
    traderPassPhrase: trader ? trader.traderPassPhrase : ''
  }

  const initStatus = trader ? trader.statusId === 1 : true

  const schema = yup.object().shape({
    name: yup.string().required(),
    apiKey: modeForm === ModeForm.create ? yup.string().min(5).required() : yup.string(),
    apiSecret: modeForm === ModeForm.create ? yup.string().min(5).required() : yup.string(),
    traderPassPhrase: modeForm === ModeForm.create ? yup.string().min(5) : yup.string(),
    percentStrategyLow: yup.number().min(1).max(200).required(),
    percentStrategyMedium: yup.number().min(1).max(200).required(),
    percentStrategyHigh: yup.number().min(1).max(200).required()
  })
  const theme = useTheme()
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [platformSelected, setPlatformSelected] = useState<number>(trader ? trader.platformId : platforms[0].id)
  const { t } = useTranslation()
  const [showApiKey, setShowApiKey] = useState<boolean>(false)
  const [showApiSecret, setShowApiSecret] = useState<boolean>(false)
  const [checked, setChecked] = useState<boolean>(initStatus)

  const onSubmit = async (data: FormDataRegisterTrader) => {
    setIsLoading(true)
    const { name, apiKey, apiSecret, percentStrategyLow, percentStrategyMedium, percentStrategyHigh,traderPassPhrase } = data
    const body = {
      intStatusId: checked,
      vchTraderName: name,
      vchTraderApiKey: apiKey,
      vchTraderApiSecret: apiSecret,
      intPlatformId: platformSelected,
      // vchTraderPassPhrase: platforms[platformSelected-1].platformName === 'okx' ? traderPassPhrase : ''
    }

    const bodyPecents: PercentageTraderPost[] = [
      {
        intStrategyId: 1,
        decPercent: percentStrategyLow
      },
      {
        intStrategyId: 2,
        decPercent: percentStrategyMedium
      },
      {
        intStrategyId: 3,
        decPercent: percentStrategyHigh
      }
    ]
    try {
      const tradeResponse = await postTrader(body)
      logger.log(tradeResponse)

      toast.success(t('CREATE_TRADER_SUCCESS_MESSAGE'))

      if (tradeResponse) {
        const responsePercents = await postPercentageStrategiesByTraderId(tradeResponse.traderId, bodyPecents)
        logger.log(responsePercents)
        toast.success(t('UPDATE_PERCENTAGE_STRATEGIES_SUCCESS_MESSAGE'))
      }

      submitFinishFunction()
    } catch (error: any) {
      toast.error(error.message)
    }
    setIsLoading(false)
  }

  const onSubmitEdit = async (data: FormDataRegisterTrader) => {
    if (!trader) return logger.error('You dont have trader')
    setIsLoading(true)
    const { name, /* apiKey, apiSecret,*/ percentStrategyLow, percentStrategyMedium, percentStrategyHigh } = data
    const body = {
      intStatusId: checked,
      vchTraderName: name,
      intPlatformId: platformSelected,
      tinTraderId: trader?.traderId,
      // vchTraderPassPhrase: platforms[platformSelected-1].platformName === 'okx' ? trader.traderPassPhrase : ''
    }

    const bodyPecents: PercentageTraderPost[] = [
      {
        intStrategyId: 1,
        decPercent: percentStrategyLow
      },
      {
        intStrategyId: 2,
        decPercent: percentStrategyMedium
      },
      {
        intStrategyId: 3,
        decPercent: percentStrategyHigh
      }
    ]
    try {
      const responseUpdate = await updateTrader(body)
      logger.log('Response update', responseUpdate)
      toast.success(t('UPDATE_TRADER_SUCCESS_MESSAGE'))
      const response = await postPercentageStrategiesByTraderId(trader.traderId, bodyPecents)
      logger.log(response)
      toast.success(t('UPDATE_PERCENTAGE_STRATEGIES_SUCCESS_MESSAGE'))
      submitFinishFunction()
    } catch (error: any) {
      toast.error(error.message)
    }
    setIsLoading(false)
  }

  const handlePlatformIdChange = (event: SelectChangeEvent) => {
    setPlatformSelected(+event.target.value)
    reset()
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<FormDataRegisterTrader>({
    resolver: yupResolver(schema),
    defaultValues: defaultValuesForm
  })

  return (
    <form noValidate autoComplete='off' onSubmit={trader ? handleSubmit(onSubmitEdit) : handleSubmit(onSubmit)}>
      <FormControl fullWidth>
        <InputLabel id='demo-simple-select-label'>{t('PLATFORM')}</InputLabel>
        <Select
          labelId='platform'
          id='platform'
          required
          value={platformSelected.toString()}
          label={t('PLATFORM')}
          onChange={handlePlatformIdChange}
        >
          {platforms.map((platform: Platform2, key: number) => (
            <MenuItem key={key} value={platform.id}>
              <Typography style={{ textTransform: 'capitalize' }}>{platform.platformName}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ mb: 4, mt: 4 }}>
        <Controller
          name='name'
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange, onBlur } }) => (
            <TextField
              autoFocus
              label={t('TRADER_NAME')}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              error={Boolean(errors.name)}
              placeholder='Trader name'
            />
          )}
        />
        {errors.name && <FormHelperText sx={{ color: 'error.main' }}>{errors.name.message}</FormHelperText>}
      </FormControl>

      <FormControl fullWidth sx={{ mb: 4, mt: 4 }}>
        <Controller
          name='percentStrategyLow'
          control={control}
          rules={{ required: false }}
          render={({ field: { value, onChange, onBlur } }) => (
            <TextField
              autoFocus
              label={'% ' + t('STRATEGY_LOW')}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              type='number'
              placeholder=''
            />
          )}
        />
        {errors.percentStrategyLow && (
          <FormHelperText sx={{ color: 'error.main' }}>{errors.percentStrategyLow.message}</FormHelperText>
        )}
      </FormControl>

      <FormControl fullWidth sx={{ mb: 4, mt: 4 }}>
        <Controller
          name='percentStrategyMedium'
          control={control}
          rules={{ required: false }}
          render={({ field: { value, onChange, onBlur } }) => (
            <TextField
              autoFocus
              label={'% ' + t('STRATEGY_MEDIUM')}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              type='number'
              placeholder=''
            />
          )}
        />
        {errors.percentStrategyMedium && (
          <FormHelperText sx={{ color: 'error.main' }}>{errors.percentStrategyMedium.message}</FormHelperText>
        )}
      </FormControl>

      <FormControl fullWidth sx={{ mb: 4, mt: 4 }}>
        <Controller
          name='percentStrategyHigh'
          control={control}
          rules={{ required: false }}
          render={({ field: { value, onChange, onBlur } }) => (
            <TextField
              autoFocus
              label={'% ' + t('STRATEGY_HIGH')}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              type='number'
              placeholder=''
            />
          )}
        />
        {errors.percentStrategyHigh && (
          <FormHelperText sx={{ color: 'error.main' }}>{errors.percentStrategyHigh.message}</FormHelperText>
        )}
      </FormControl>

      <FormControl fullWidth sx={{ mb: 4, mt: 4 }}>
        <InputLabel htmlFor='auth-login-v2-password' error={Boolean(errors.apiKey)}>
          {t('API_KEY')}
        </InputLabel>
        <Controller
          name='apiKey'
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange, onBlur } }) => (
            <OutlinedInput
              value={value}
              disabled={!!trader}
              onBlur={onBlur}
              label={t('API_KEY')}
              onChange={onChange}
              id='auth-login-v2-password'
              error={Boolean(errors.apiKey)}
              type={showApiKey ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    edge='end'
                    onMouseDown={e => e.preventDefault()}
                    onClick={() => setShowApiKey(!showApiKey)}
                  >
                    <Icon icon={showApiKey ? 'mdi:eye-outline' : 'mdi:eye-off-outline'} fontSize={20} />
                  </IconButton>
                </InputAdornment>
              }
            />
          )}
        />
        {errors.apiKey && (
          <FormHelperText sx={{ color: 'error.main' }} id=''>
            {errors.apiKey.message}
          </FormHelperText>
        )}
      </FormControl>

      <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
        <InputLabel htmlFor='auth-login-v2-password' error={Boolean(errors.apiSecret)}>
          {t('API_SECRET_KEY')}
        </InputLabel>
        <Controller
          name='apiSecret'
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange, onBlur } }) => (
            <OutlinedInput
              value={value}
              disabled={!!trader}
              onBlur={onBlur}
              label={t('API_SECRET_KEY')}
              onChange={onChange}
              id='auth-login-v2-password'
              error={Boolean(errors.apiSecret)}
              type={showApiSecret ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    edge='end'
                    onMouseDown={e => e.preventDefault()}
                    onClick={() => setShowApiSecret(!showApiSecret)}
                  >
                    <Icon icon={showApiSecret ? 'mdi:eye-outline' : 'mdi:eye-off-outline'} fontSize={20} />
                  </IconButton>
                </InputAdornment>
              }
            />
          )}
        />
        {errors.apiSecret && <FormHelperText sx={{ color: 'error.main' }}>{errors.apiSecret.message}</FormHelperText>}
      </FormControl>
      {platforms[platformSelected-1].platformName === 'okx' && (
        <FormControl fullWidth sx={{ my: 4 }}>
          <Controller
            name='traderPassPhrase'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                {...field}
                label={t('PASSPHRASE')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>
      )}

      <Grid container alignItems='center'>
        {!lessThanSmall && (
          <Grid item xs={1} sm={1.5}>
            <Avatar sx={{ bgcolor: '#FCA5A5', width: 50, height: 50 }} variant='rounded' />
          </Grid>
        )}

        <Grid item xs={10} sm={8.5}>
          <Box>
            <Typography align='center' fontSize={22}>
              {t('STATUS')}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Switch checked={checked} onChange={() => setChecked(!checked)} color='primary' />
          {/* <FormControlLabel
              sx={{ mr: 4 }}
              control={<Switch checked={checked} onChange={() => setChecked(!checked)} color='success' />}
            /> */}
        </Grid>
      </Grid>

      <Grid container>
        <Grid sx={{ p: 1 }} item xs={6}>
          <CustomLoadingBtn loading={isLoading} fullWidth size='large' type='submit' variant='contained' sx={{ mt: 3 }}>
            {trader ? t('EDIT') : t('PROCEED')}
          </CustomLoadingBtn>
        </Grid>
        <Grid sx={{ p: 1 }} item xs={6}>
          <CustomBtn fullWidth size='large' variant='contained' onClick={() => closeFunc()} sx={{ mt: 3 }}>
            {t('CANCEL')}
          </CustomBtn>
        </Grid>
      </Grid>
    </form>
  )
}

export default TraderRegisterForm
