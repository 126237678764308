import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import {
  Avatar,
  Box,
  ButtonGroup,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Typography
} from '@mui/material'
import Icon from 'src/@core/components/icon'
import { ManualTrader, putStatusManualTrader } from 'src/@http/manual-trader'
import { DialogGeneric } from 'src/pages/components/generic/DialogGeneric'
import { logger } from 'src/utils/Logger'

import ChangeStatus from '../../actions-form/ChangeStatus'

interface Props {
  item: ManualTrader
  editFunction: (data: any) => any
  deleteFunctionSuccess: () => any
  updateFunction: () => any
  setGlobalLoading: (value: boolean) => void
}

const ManualTraderItemCard = ({
  item,
  editFunction,
  deleteFunctionSuccess,
  updateFunction,
  setGlobalLoading
}: Props) => {
  
  const { t } = useTranslation()
  const [show, setShow] = useState<boolean>(false)
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false)

  const details: { label: string; value: string }[] = [
    {
      label: 'ID',
      value: `${item.intManualTraderId}`
    },
    {
      label: t('AMOUNT'),
      value: `$${Number(item.dcmAmount).toFixed(2)}`
    },
    {
      label: t('AMOUNT_LIMIT'),
      value: `$${Number(item.dcmAmountLimit).toFixed(2)}`
    },
    {
      label: t('NUMBER_ORDERS_LIMIT'),
      value: `${item.intNumberOfOrdersLimit}`
    },
    {
      label: t('LEVERAGE'),
      value: `x${item.intLeverage}`
    },
    {
      label: t('MIN_MAX_PRICE'),
      value: `$${Number(item.dcmMinPriceLimit).toFixed(2)} / $${Number(item.dcmMaxPriceLimit).toFixed(2)}`
    },
    {
      label: t('PERCENTAGE_STEP_LIMIT'),
      value: `${(Number(item.dcmPercentageStepLimit)*100).toFixed(2)}%`
    },
    {
      label: t('IS_CYCLICAL'),
      value: `${item.intIsCyclical === 1 ? t('YES'): t('NO')}`
    },
    {
      label: t('STOP_LOSS_PRICE'),
      value: `$${Number(item.dcmStopLossPrice).toFixed(2)}`
    }
  ]

  const deleteItem = async () => {
    try {
      await putStatusManualTrader({id:item.intManualTraderId, intStatusId: 2})
      toast.success(t('DELETE_SUCCESS'))
      deleteFunctionSuccess()
    } catch (err: any) {
      toast.error(err.message)
    }
  }

  const change = async (status: boolean) => {
    setLoadingStatus(true)
    try {
      await putStatusManualTrader({id:item.intManualTraderId, intStatusId: status?1:0})
      updateFunction()
    } catch (error) {
      logger.log('[putManualTrader] error: ', error)
    }
    setLoadingStatus(false)
  }

  const ActionButtons = () => {
    const buttons = [
      {
        icon: 'carbon:edit',
        action: () => {
          editFunction(item)
        }
      },
      {
        icon: 'ph:trash-light',
        action: () => {
          setShow(true)
        }
      }
    ]

    return (
      <Grid container sx={{ flexDirection: 'row', justifyContent: 'end' }}>
        <ButtonGroup>
          {buttons.map(button => (
            <Grid item key={button.icon}>
              <IconButton onClick={() => button.action()} aria-label='capture screenshot' color='secondary'>
                <Icon icon={button.icon} fontSize={16} />
              </IconButton>
            </Grid>
          ))}
        </ButtonGroup>
      </Grid>
    )
  }

  return (
    <Card sx={{ backgroundColor: 'background.default' }}>
      <CardContent sx={{ pr: 0 }}>
        <Grid container sx={{ flexDirection: 'row', width: '100%' }} spacing={2}>
          <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              src={`https://raw.githubusercontent.com/spothq/cryptocurrency-icons/master/32/color/${item.vchSymbol
                .replace('USDT', '')
                .toLowerCase()}.png`}
              sx={{ width: 30, height: 30 }}
            >
              <Typography sx={{ fontSize: 18 }}>{item.vchSymbol[0]}</Typography>
            </Avatar>
          </Grid>
          <Grid item sx={{ display: 'flex', alignItems: 'center', justifyContent:'space-between' }} xs={10}>
            <Typography sx={{ fontSize: 18, mr: 2 }}>{item.vchSymbol}</Typography>

            <Chip
              size='small'
              label={item.vchSide === 'Sell' ? t('SELL') : t('BUY')}
              color={item.vchSide === 'Sell' ? 'error' : 'success'}
            />
          </Grid>
        </Grid>
        <ActionButtons />
        
        <Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
          <Typography sx={{ mr: 2 }}> {t('STATUS')}:</Typography>
          {loadingStatus && (
            <Box sx={{ minHeight: 38 }}>
              <CircularProgress size={25} sx={{ mx: 2, mt: 2 }}></CircularProgress>
            </Box>
          )}
          {!loadingStatus && (
            <ChangeStatus
              change={change}
              status={item.intStatusId}
              keyConfirmMessage={
                item.intStatusId === 1 ? '' : ''
              }
            ></ChangeStatus>
          )}
        </Box>

        {details.map((detail, key) => {

          if(detail.value.includes('undefined') || detail.value.includes('null'))
            return <></>

          return (
            <Box key={key} sx={{ display: 'flex', mt: 2 }}>
              <Typography sx={{ mr: 2, fontSize: 14 }}>{detail.label}:</Typography>
              <Typography sx={{ fontWeight: 600, fontSize: 14 }}>{detail.value}</Typography>
            </Box>
          )
        })}

        <DialogGeneric
          show={show}
          title={t('DELETE_ORDER')}
          confirmText={t('CONFIRM') || ''}
          confirmFunc={() => deleteItem()}
          closeFunc={() => {
            setShow(false)
          }}
        >
        </DialogGeneric>
      </CardContent>
    </Card>
  )
}

export default ManualTraderItemCard
