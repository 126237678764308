import { useEffect, useState } from 'react'
import { Grid, Theme, useMediaQuery } from '@mui/material'
import { getGlobalSettingForKey } from 'src/@http/global-settings'
import newsStore, { NewsStore } from 'src/zustand/news'
import profileStore, { ProfileStore } from 'src/zustand/profile'

import ReferredCards from './dashboard/ReferredCards/ReferredCards'
import StrategyCardContainer from './dashboard/StrategyCards/StrategyCardContainer'
import RankInfo from './RankInfo/RankInfo'
import { DetailPerformanceGrid } from './DetailPerformanceGrid'
import { MyPerformance } from './MyPerformance'
import { MyProfitability } from './MyProfitability'
import NewsMarquee from './NewsMarquee'
import { PromotionCarousel } from './PromotionCarousel'
import { ReferredUsers } from './ReferredUsers'
import { StrategyGrid } from './StrategyGrid'

const CryptoDashboard = () => {
  const token: string = profileStore((state: ProfileStore) => state.token)
  const isLogged = token !== ''
  // const { userDetail } = profileStore()
  const [showNews, setShowNews] = useState<boolean>(false)
  const newsInit = newsStore((state: NewsStore) => state.newsInit)
  const getCurrentNews = newsStore((state: NewsStore) => state.getCurrentNews)
  const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))

  useEffect(() => {
    getGlobalSettingForKey('showNews')
      .then(disabled => {
        setShowNews(disabled.value === 'true')
      })
      .catch(err => setShowNews(false))
  }, [])

  useEffect(() => {
    const init = () => {
      getCurrentNews()
    }
    init()
  }, [])

  return (
    <Grid container sx={{}}>
      {showNews && newsInit.length !== 0 && (
        <Grid item xs={12} sx={{ mb: 8 }}>
          <NewsMarquee></NewsMarquee>
        </Grid>
      )}

      <Grid item xs={12} sx={{ mb: 8 }}>
        <PromotionCarousel></PromotionCarousel>
      </Grid>

      <Grid xs={12} sx={{ mb: 8 }}>
        <StrategyCardContainer></StrategyCardContainer>
      </Grid>

      {false && (
        <Grid item xs={12} sx={{ mb: 8 }}>
          <StrategyGrid />
        </Grid>
      )}

      {isLogged && (
        <Grid xs={12} sx={{ mb: 8 }}>
          <ReferredCards></ReferredCards>
        </Grid>
      )}
      
      {(isLogged && !lgDown) && (
        <Grid xs={12} sx={{ mb: 8 }}>
          <RankInfo />
        </Grid>
      )}

      <Grid item xs={12} sx={{ mb: 8 }}>
        <MyPerformance />
      </Grid>

      {false && (
        <Grid item xs={12} sx={{ mb: 8 }}>
          <MyProfitability />
        </Grid>
      )}

      {isLogged && false && (
        <Grid item xs={12}>
          <DetailPerformanceGrid />
        </Grid>
      )}

      

      {isLogged && (
        <Grid item xs={12}>
          <ReferredUsers />
        </Grid>
      )}
    </Grid>
  )
}

export default CryptoDashboard
