import { Typography } from "@mui/material"

const priceColor = (value: number) => {
  return value == 0 ? 'default' : value > 0 ? '#03A66D' : 'error'
}

const PriceText = (price: number | string) => {
    return <Typography fontWeight={600} fontSize={24} color={priceColor(Number(price))}>
    {price}
  </Typography>
}

export default PriceText

export {priceColor}