import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Box } from '@mui/material'
import { Theme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
// import WelcomeAxiosContent from 'src/layouts/components/shared-component/WelcomeAxiosContent'
import { DialogGeneric } from 'src/pages/components/generic/DialogGeneric'
import ForgotPasswordForm from 'src/pages/components/home/ForgotPasswordForm'
import LoginForm from 'src/pages/components/home/LoginForm'
import { RegisterForm } from 'src/pages/components/home/RegisterForm'
import appStore, { AppStore } from 'src/zustand/app'
import profileStore, { ProfileStore } from 'src/zustand/profile'

function useQuery() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

const DialogsApp = () => {
 

  const isLogged = profileStore((state: ProfileStore) => state.isLogged)
  const showLoginDialog = appStore((state: AppStore) => state.showDialog.login)
  const showRegisterDialog = appStore((state: AppStore) => state.showDialog.register)
  const showForgotPasswordDialog = appStore((state: AppStore) => state.showDialog.forgotPassword)
  const setShowDialog = appStore((state: AppStore) => state.setShowDialog)

  const { t } = useTranslation()

  const show = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))
  const token: string = profileStore((state:ProfileStore) => state.token)

  const query = useQuery()
  const referredUserId: string | null = query.get('bienvenido')
  const renewSubscription: string | null = query.get('renewSubscription')

  useEffect(() => {
    if (token === ''){
      if (referredUserId) setShowDialog('register',true)
      if (renewSubscription) setShowDialog('login',true)
    }
      
      //else setShowDialog('login',true)
  }, [token])

  //backdropColor='rgba(0,0,0,0.9)'
  useEffect(() => {
    window.onbeforeunload = function () {
      // Al actualizar la página se ocultan todos los dialogs
      setShowDialog('login',false)
      setShowDialog('register', false)
      setShowDialog('forgotPassword', false)
    }

    return () => {
      window.onbeforeunload = null
    }
  }, [])

  return (
    <>
      {showLoginDialog && (
        <DialogGeneric
          show={showLoginDialog}
          hideActions={true}
          title={t('LOGIN')}
          hideBackdrop={false}
          closeFunc={() => {
            setShowDialog('login',false)
          }}
          showIconClose={true}
          backdropZIndex={99999}
        >
          <Box sx={{ mt: 2 }}>
            <LoginForm
              onSuccess={() => {
                setShowDialog('login',false)
              }}
              closeFunc={() => {
                setShowDialog('login',false)
              }}
              
            />
          </Box>
        </DialogGeneric>
      )}

      {showRegisterDialog && (
        <DialogGeneric
          show={showRegisterDialog && !isLogged}
          hideActions={true}
          title={t('REGISTER')}
          hideBackdrop={false}
          backdropZIndex={99999}
          closeFunc={() => {
            setShowDialog('register',false)
          }}
          showIconClose={true}
        >
          <RegisterForm
            submitFinishFunction={() => setShowDialog('register',false)}
            closeFunc={() => {
              setShowDialog('login',true)
            }}
          />
        </DialogGeneric>
      )}

      <DialogGeneric
        show={showForgotPasswordDialog}
        hideActions={true}
        title={t('FORGOT_PASSWORD')}
        showIconClose={true}
        closeFunc={() => setShowDialog('forgotPassword', false)}
        backdropZIndex={100000}
      >
        {showForgotPasswordDialog && (
          <ForgotPasswordForm submitFinishFunction={() => setShowDialog('forgotPassword', false)} />
        )}
      </DialogGeneric>
      {/* {showWelcome && 
      <DialogGeneric
        show={showWelcome}
        title=''
        cancelText={t('LOGIN') || 'Login'}
        confirmText={t('REGISTER') || 'Register'}
        backgroundColor='rgba(195,171,91,0.60)'
        buttonsColor='black'
        confirmFunc={() => setShowDialog('register',true)}
        cancelFunc={() => setShowDialog('login',true)}
        closeFunc={() => {
          setShowWelcome(false)
        }}
        maxWidth={400}
        hideBackdrop={true}
        backdropZIndex={2}
      >
        <WelcomeAxiosContent />
      </DialogGeneric>
      } */}
    </>
  )
}

export default DialogsApp
