import { Card, CardContent } from '@mui/material'
import ItemsScroller from 'src/layouts/components/ItemScroller'

import PositionRealTimeItemCard from './PositionRealTimeItemCard'

interface Props {
  items: API.User.AllUserResponse[]
}


const UserManagerCards = ({items}: Props) => {
  
  return (
    <Card>
      <CardContent sx={{ px: 0 }}>
        <ItemsScroller list={items} itemCard={PositionRealTimeItemCard} increment={6}></ItemsScroller>
      </CardContent>
    </Card>
  )
}

export default UserManagerCards
