import { getAsync, postAsync, putAsync } from 'src/@http'

const rootPath = '/promoCode'
const DeletePromoCode = (promoCodeId: number) => `${rootPath}/${promoCodeId}/delete`

export interface Promocode {
  promoCodeId: number
  code: string
  startDate: string
  endDate: string
  numberOfSubscriptionDays: number
  statusId: number
  numberOfUsesAvailable: number
  numberOfUsesMade: number
}

export interface CreatePromocode {
  vchCode: string
  dtmStartDate: string
  dtmEndDate: string
  intNumberOfSubscriptionDays: number
  intStatusId: number
  intNumberOfUsesAvailable: number
}

export const getAllPromocodes = (): Promise<Promocode[]> => {
  return getAsync(rootPath)
}

export const postPromocode = (promocode: CreatePromocode): Promise<void> => {
  return postAsync(rootPath, promocode)
}

export const putPromocode = (id: number, promocode: CreatePromocode): Promise<void> => {
  return putAsync(rootPath + `/${id}`, promocode)
}

export const deletePromoCode = (promoCodeId: number): Promise<any> => {
  return postAsync(DeletePromoCode(promoCodeId), {})
}
