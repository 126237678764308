import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Card, CardContent, Divider, Grid, Theme, Typography, useMediaQuery } from '@mui/material'
import CustomBtn from 'src/layouts/components/shared-component/CustomBtn'
import { DialogGeneric } from 'src/pages/components/generic/DialogGeneric'
import profileStore, { ProfileStore } from 'src/zustand/profile'

import CommissionRetiramentForm from './CommissionRetiramentForm'

interface Props {
  amountSubscriptionCommission: number
  totalSixMonths: number
  totalTwelveMonths: number
}

const SubscriptionCommissionCard = ({ amountSubscriptionCommission, totalSixMonths, totalTwelveMonths }: Props) => {
  const { t } = useTranslation()
  const upLg = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))
  const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const user = profileStore((state: ProfileStore) => state.userDetail)
  const getUserDetail = profileStore((state: ProfileStore) => state.getUserDetail)

  useEffect(()=> {
    getUserDetail(user.userId)
  }, [user.userId, getUserDetail])

  return (
    <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 300 }}>
      <CardContent>
        <Typography fontWeight={600} align='center' sx={{}} color='white'>
          {t('SUBSCRIPTION_COMMISSION')}
        </Typography>
        {false && <Grid container xs={12}>
          <Grid item xs={5} sx={{ mr: 2 }}>
            <Typography align='center' fontWeight={600} fontSize={16} sx={{ mt: 4 }} color='white'>
              {` 6 ${t('MONTHS')}`}
            </Typography>
            <Typography align='center' fontWeight={600} fontSize={30} sx={{ mt: 1 }} color='white'>
              {totalSixMonths}
            </Typography>
          </Grid>
          <Divider orientation='vertical' variant='middle' flexItem color='#7c5d16'></Divider>
          <Grid item xs={5} sx={{ ml: 2 }}>
            <Typography align='center' fontWeight={600} fontSize={16} sx={{ mt: 4 }} color='white'>
              {` 12 ${t('MONTHS')}`}
            </Typography>
            <Typography align='center' fontWeight={600} fontSize={30} sx={{ mt: 1 }} color='white'>
              {totalTwelveMonths}
            </Typography>
          </Grid>
        </Grid>}
        
        <Typography align='center' fontWeight={600} fontSize={32} sx={{ mt: 20 }} color='white'>
          ${amountSubscriptionCommission.toFixed(2)}
        </Typography>
        {amountSubscriptionCommission !== 0 && (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CustomBtn
              variant='contained'
              onClick={() => {
                setShowDialog(true)
              }}
              sx={{ mt: 2 }}
              size={downSm || upLg ? 'medium' : 'small'}
              disabled={user.blnPendingSubscriptionCommissionWithdrawal}
            >
              {!user.blnPendingSubscriptionCommissionWithdrawal?t('REQUEST_WITHDRAWAL'):t('PROCESSING_COMMISSION_WITHDRAWAL')}
            </CustomBtn>
          </Box>
        )}
      </CardContent>
      <DialogGeneric
      show={showDialog}
      hideActions={true}
      showIconClose={true}
      closeFunc={() => {setShowDialog(false)}}
      title={t('COMMISSION_RETIRAMENT_DATA')}
    >
        <CommissionRetiramentForm
        closeFunc={() => {setShowDialog(false)}}
        valuesDefaultForm={null}
        amount={amountSubscriptionCommission}
        updateFunc={() => {getUserDetail(user.userId)}}
        ></CommissionRetiramentForm>
    </DialogGeneric>
    </Card>
  )
}

export default SubscriptionCommissionCard
