import { useTranslation } from 'react-i18next'
import { Avatar, Typography, useMediaQuery } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import i18n from 'i18next'
import ItemsScroller from 'src/layouts/components/ItemScroller'

// import {  logger } from 'src/utils/Logger'
import DetailPerformanceCard from './DetailPerformanceCard'

interface Header {
  attr: string
  label: string
  width: number
}

interface Props {
  performanceDetail: Store.PerformanceDetail
}

const DetailPerformance = ({ performanceDetail }: Props) => {
  const { t } = useTranslation()
  const showMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const headers: Header[] = [
    {
      attr: 'symbol',
      label: t('SYMBOL'),
      width: 1
    },
    {
      attr: 'closedPnl',
      label: t('AMOUNT'),
      width: 1
    },
    {
      attr: 'side',
      label: t('TYPE'),
      width: 1
    },
    {
      attr: 'createdDate',
      label: t('DATE'),
      width: 2
    }
  ]

  const columns: GridColDef[] = [
    {
      flex: 3,
      field: 'symbol',
      renderHeader: () => <Typography color='#9295A6'>{i18n.t('SYMBOL')}</Typography>,
      renderCell: (row: any) => {
        return (
          <>
            <Avatar
              variant='square'
              src={`https://raw.githubusercontent.com/spothq/cryptocurrency-icons/master/32/color/${row.row.symbol
                .replace('USDT', '')
                .toLowerCase()}.png`}
              sx={{ width: 30, height: 30, m: 2 }}
            />
            <Typography variant='body2'>{row.row.symbol}</Typography>
          </>
        )
      }
    },
    {
      flex: 3,
      field: 'size',
      renderHeader: () => <Typography color='#9295A6'>{i18n.t('AMOUNT')}</Typography>,
      renderCell: ({ row }: any) => {
        return (
          <Typography noWrap variant='body2' color={Number(row.closedPnl) < 0 ? '#DC2626' : '#03A66D'} fontSize={16}>
            {row.closedPnl}
          </Typography>
        )
      }
      
    },
    {
      flex: 3,
      field: 'side',
      renderHeader: () => <Typography color='#9295A6' >{i18n.t('TYPE')}</Typography>,
    },
    {
      flex: 3,
      field: 'createdDate',
      renderHeader: () => <Typography color='#9295A6'>{i18n.t('DATE')}</Typography>,
      renderCell: (row: any) => {
        return (
          <>
            <Typography variant='body2'>{new Date(row.row.createdDate).toDateString()}</Typography>
          </>
        )
      }
    }
  ]

  return (
    <>
      {!showMobile && (
        <DataGrid
          autoHeight
          rows={performanceDetail.data.map((detail: any, index: number) => ({ ...detail, id: index }))}
          // getRowId={r => r.orderId}
          columns={columns}
          // disableSelectionOnClick
          pageSize={10}
          //rowsPerPageOptions={[5]}
          loading={performanceDetail.loading}
        />
      )}
      {showMobile && (
        <ItemsScroller
          list={performanceDetail.data}
          itemCard={DetailPerformanceCard}
          increment={6}
          loading={performanceDetail.loading}
        ></ItemsScroller>
      )}
    </>
  )
}

export default DetailPerformance
