import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, FormControl, Grid, TextField, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { postSubscriptionCommissionWithdrawalConfirm,SubscriptionCommissionWithdrawal } from 'src/@http/subscriptionCommissionWithdrawal'
import CustomBtn, { CustomLoadingBtn } from 'src/layouts/components/shared-component/CustomBtn'
import { ModeForm } from 'src/types'
import adminStore from 'src/zustand/admin'
import * as yup from 'yup'

import CopyText from './column/CopyText'

interface Props {
  modeForm: ModeForm
  valuesDefaultForm: SubscriptionCommissionWithdrawal
  closeFunc: () => any
}

interface SubscriptionCommissionWithdrawalForm {
  intSubscriptionCommissionWithdrawalId: number
  vchTxId: string
  vchOriginWallet: string
  vchDestinationWallet: string
  dcmAmount: string,
  user: string
  vchNetwork: string
}

const SubscriptionCommissionWithdrawalForm = ({ modeForm, closeFunc, valuesDefaultForm }: Props) => {
  const { t } = useTranslation()
  let defaultValuesForm: SubscriptionCommissionWithdrawalForm = {
    intSubscriptionCommissionWithdrawalId: 0,
    vchTxId: '',
    vchOriginWallet: '',
    vchDestinationWallet: '',
    dcmAmount: '',
    user: '',
    vchNetwork: ''
  }

  const { getsubscriptionTransaction } = adminStore()

  if (modeForm === ModeForm.edit && valuesDefaultForm) {

    defaultValuesForm = {
      ...defaultValuesForm,
      dcmAmount: `$${valuesDefaultForm.dcmAmount}`,
      user: `${valuesDefaultForm.user.intUserId}-${valuesDefaultForm.user.vchEmail}`,
      vchNetwork: valuesDefaultForm.vchNetwork,
      intSubscriptionCommissionWithdrawalId: valuesDefaultForm.intSubscriptionCommissionWithdrawalId
    }
  }

  const validations = {
    vchOriginWallet: yup.string().test('TRC20', t('TRC20_STRING_FORMAT') || '', function (value) {
      if (value === undefined) return false

      const expresionRegular = /^T[1-9A-HJ-NP-Za-km-z]{33}$/

      return expresionRegular.test(value)
    }),
  }
  

  const schemaValidations = yup.object().shape(validations)

  
  const theme = useTheme()
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const activateStrategyByUser = adminStore((state ) => state.activateStrategyByUser)
  const { getSubscriptionCommissionWithdrawal } = adminStore()
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<SubscriptionCommissionWithdrawalForm>({
    resolver: yupResolver(schemaValidations),
    defaultValues: defaultValuesForm
  })

  const success = async () => {
    closeFunc()
    getSubscriptionCommissionWithdrawal()
  }


  const onSubmit = async (form: SubscriptionCommissionWithdrawalForm) => {
    setIsLoading(true)
    
    try {
      await postSubscriptionCommissionWithdrawalConfirm({ 
        intSubscriptionCommissionWithdrawalId: form.intSubscriptionCommissionWithdrawalId,
        vchTxId: form.vchTxId,
        vchOriginWallet: form.vchOriginWallet
      })
      await activateStrategyByUser(valuesDefaultForm.user.intUserId)
      toast.success(t('SUCCESSFULLY_CONFIRMED'), { duration: 4000 })
      await success()
    } catch (error: any) {
      toast.error(error.message, { duration: 4000 })
    }
    
    setIsLoading(false)
  }

 

  return (
    <>
      <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>

        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='user'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                disabled={true}
                {...field}
                label={t('USER')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>

        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='dcmAmount'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                disabled={true}
                {...field}
                label={t('AMOUNT')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>

        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='vchNetwork'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
              disabled={true}
                variant='outlined'
                {...field}
                label={t('NETWORK')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>

        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='vchOriginWallet'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                disabled={false}
                {...field}
                label={t('ORIGIN_WALLET')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>

        <Box sx={{ display: 'flex', mt:2, mb:2 }}>
          <Typography>
            {t('DESTINATION_WALLET')}:
            <CopyText text={valuesDefaultForm.vchDestinationWallet || ''}></CopyText>
          </Typography>
        </Box>

        

        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='vchTxId'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                {...field}
                label={t('TRANSACTION_HASH')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>

        <Grid container>
          <Grid sx={{ p: 1 }} item xs={6}>
            <CustomLoadingBtn
              loading={isLoading}
              fullWidth
              size='large'
              type='submit'
              variant='contained'
              sx={{ mt: 3 }}
            >
              {t('CONFIRM')}
            </CustomLoadingBtn>
          </Grid>
          <Grid sx={{ p: 1 }} item xs={6}>
            <CustomBtn fullWidth size='large' variant='contained' onClick={() => closeFunc()} sx={{ mt: 3 }}>
              {t('CANCEL')}
            </CustomBtn>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

export default SubscriptionCommissionWithdrawalForm
