import { useTranslation } from 'react-i18next'
import { Avatar, Box, Card, CardContent, Grid, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import Icon from 'src/@core/components/icon'
import { StrategyProfile } from 'src/zustand/profile'

import Percentage from '../../Percentage'

import InvestStrategyBtn from './InvestStrategyBtn'
import PauseStrategyBtn from './PauseStrategyBtn'

interface Props {
  isActive: boolean
  percentages: number[]
  strategyProfile: StrategyProfile
  isCenter: boolean
}

const StrategyCard = ({ isActive, percentages, strategyProfile, isCenter }: Props) => {
  const { t } = useTranslation()

  const percentageLabels = [`${t('7_DAYS_MOST_RECENT')}`, `${t('14_DAYS_MOST_RECENT')}`, `${t('LAST_MONTH')}`]
  const theme: any = useTheme()

  return (
    <Card
      sx={{
        pt: isActive ? 4 : 0,
        background: isActive
          ? `linear-gradient(to right bottom, ${theme.palette.primary.main} 1%, rgba(170, 123, 3, 0.15) 85%)`
          : 'default',
        position: 'relative'
      }}
    >
      {!isActive && (
        <Box
          sx={{
            background: `linear-gradient(to right bottom, #000000 1%, transparent 100%)`,
            width: '100%',
            height: '100%',
            position: 'absolute'
          }}
        />
      )}

      <CardContent sx={{}}>
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <Tooltip title={strategyProfile.description} placement="top">
            <IconButton>
              <Icon icon='mdi:information-outline' fontSize={22} />
            </IconButton>
          </Tooltip>
        </Box>
        <Typography textAlign='center' fontSize={22}>
          {strategyProfile.strategyName}
        </Typography>
        <Grid container xs={12} sx={{ mt: 6 }}>
          <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Avatar
              variant='square'
              src={
                isActive
                  ? '/images/new-icons/Estrategia Axes ACTIVA-01.png'
                  : '/images/new-icons/Estrategia Axes Bloqueada o por Activar-01.png'
              }
              sx={{ width: 100, height: 100 }}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ justifyContent: 'center' }}>
            {percentageLabels.map((label: string, key: number) => (
              <Grid container key={key} sx={{ py: 1 }}>
                <Grid item xs={8}>
                  <Typography align='right'>{label} </Typography>
                </Grid>
                <Grid item xs={4} display='flex' >
                  {isActive && <Percentage percentage={percentages[key]}></Percentage>}
                  {!isActive && <Typography sx={{ pl: 3 }}>{`N/A`}</Typography>}
                  {isActive && percentages[key] ===undefined && <Typography sx={{ pl: 3 }}>{`N/A`}</Typography>}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: isActive || isCenter ? 'center' : 'end', mt: 4 }}>
          {isActive && <PauseStrategyBtn strategyId={strategyProfile.strategyId}></PauseStrategyBtn>}
          {!isActive && <InvestStrategyBtn strategyProfile={strategyProfile}></InvestStrategyBtn>}
        </Box>
      </CardContent>
    </Card>
  )
}

StrategyCard.defaultProps = {
  isActive: false
}

export default StrategyCard
