import { useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { Button, ButtonProps, Chip } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Icon from 'src/@core/components/icon'
import CustomAvatar from 'src/@core/components/mui/avatar'
import { ThemeColor } from 'src/@core/layouts/types'
import { getGlobalSettingForKey } from 'src/@http/global-settings'
import EndDateSubscription from 'src/layouts/components/shared-component/EndDateSubscription'
import { FontWeight } from 'src/layouts/TypographyTheme'
import type { Menu } from 'src/pages/profile'
import { logger } from 'src/utils/Logger'
import profileStore, { ProfileStore } from 'src/zustand/profile'

import { AdminContext } from '../admin/adminContext'

import UserSubscriptionDialog from './UserSubscriptionDialog'
import UserSuspendDialog from './UserSuspendDialog'

const emailFormat = (email: string) => {
  if (email && email.includes('@')){
  const emailArr = email.split('@')
  if (emailArr[0].length >= 6) {
    const sub = emailArr[0].substring(3, emailArr[0].length - 2)

    return '***' + sub + '**@' + emailArr[1]
  }
  if (emailArr[0].length >= 4) {
    const sub = emailArr[0].substring(2, emailArr[0].length - 1)

    return '**' + sub + '*@' + emailArr[1]
  }

  return '***@' + emailArr[1]
}

return ''
}

const MenuBtn = styled(Button)<ButtonProps>(({ theme }) => ({
  color: '#6C7080',
  backgroundColor: '',
  '&:hover': {
    color: 'white',
    backgroundColor: 'primary.main'
  },
  textTransform: 'none',
  fontSize: 16,
  fontWeight: FontWeight.Normal
}))

interface Props {
  menus: Menu[]
  isAdmin: boolean
}

const UserProfileAdmin = ({ menus, isAdmin }: Props) => {
  const userEdit = profileStore((state: ProfileStore) => state.userDetail)
  const { t } = useTranslation()
  const [suspendDialogOpen, setSuspendDialogOpen] = useState<boolean>(false)
  const [subscriptionDialogOpen, setSubscriptionDialogOpen] = useState<boolean>(false)
  const { setPath, path } = useContext(AdminContext)
  const userDetail = profileStore((state: ProfileStore) => state.userDetail)

  const [urlSignUpWithReferredId, setUrlSignUpWithReferredId] = useState<string>('')
  const getAll = () =>
  getGlobalSettingForKey('UrlSignUpWithReferredId').then(res => {
    setUrlSignUpWithReferredId(res.value)
    })

  useEffect(() => {
    getAll()
  }, [])

  const copyAddress = async () => {
    try {
      await navigator.clipboard.writeText(urlSignUpWithReferredId.replace('{0}', userEdit.userId))
      toast.success(t('COPIED_SUCCESSFULLY'), { duration: 4000 })
    } catch (error) {
      logger.log('Error', error)
    }
  }

  if (userEdit) {
    return (
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <CardContent sx={{ pt: 15, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <CustomAvatar
                skin='light'
                variant='circular'
                color={'primary' as ThemeColor}
                sx={{ width: 120, height: 120, fontWeight: 600, mb: 4, fontSize: '3rem' }}
              >
                <Icon icon='mdi:incognito' style={{ width: 60, height: 60 }} />
              </CustomAvatar>
              {!isAdmin && (userDetail.activeSubscription || userDetail.blnPendingSubscription) && (
                <Grid container>
                  <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography sx={{  }} color='white'>
                      {t('MY_SUSCRIPTION')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ padding: 0, margin: 0 }}>
                    <EndDateSubscription modeMobile={true}></EndDateSubscription>
                  </Grid>
                </Grid>
              )}
              {isAdmin && (
                <Grid container>
                  <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography sx={{ mb: 1.5 }} color='white'>
                      {t('ADMINISTRATOR')}
                    </Typography>
                  </Grid>
                </Grid>
              )}

              <Typography style={{ fontSize: 16 }} color='#6C7080' fontWeight={FontWeight.Normal} sx={{ mb: 4 }}>
                {emailFormat(userEdit.email)}
              </Typography>

              {urlSignUpWithReferredId!=='' && 
              <Chip
              label={urlSignUpWithReferredId.replace('{0}', userEdit.userId)}
              variant='outlined'
              deleteIcon={<Icon icon='mdi:content-copy' />}
              onClick={copyAddress}
              onDelete={copyAddress}
            />
              }
              
            </CardContent>

            <CardContent sx={{ mx: 4 }}>
              {menus.map((menu: Menu, key: number) => (
                <MenuBtn
                  onClick={() => {setPath(menu.path); console.log(menu.path)}}
                  key={key}
                  fullWidth
                  sx={{
                  textAlign:'left',
                    justifyContent: 'flex-start',
                    '&.MuiButton-text': { color: menu.path === path ? 'primary.main' : '' },
                    '&.MuiButton-root': {
                      backgroundColor: menu.path === path ? '' : '',
                      backdropFilter: menu.path === path ? 'saturate(400%)' : ''
                    }
                  }}
                  startIcon={<Icon icon={menu.icon} style={{ fontSize: 24 }} />}
                  size='large'
                >
                  {menu.label}
                </MenuBtn>
              ))}
            </CardContent>

            <UserSuspendDialog open={suspendDialogOpen} setOpen={setSuspendDialogOpen} />
            <UserSubscriptionDialog open={subscriptionDialogOpen} setOpen={setSubscriptionDialogOpen} />
          </Card>
        </Grid>
      </Grid>
    )
  } else {
    return null
  }
}

UserProfileAdmin.defaultProps = {
  isAdmin: false
}

export default UserProfileAdmin

export { emailFormat }
