import { useTranslation } from 'react-i18next'
import { Avatar, Box, Card, CardContent, Typography } from '@mui/material'
import Icon from 'src/@core/components/icon'
import GenericTab, { Tab } from 'src/layouts/components/GenericTab'
import CompanyManagementCard from 'src/pages/components/admin/global-settings/admin-company/CompanyCard'

import PlatformManager from './admin-platform/PlatformManager'
import SubscriptionManager from './admin-subscription/SubscriptionManager'
import TelegramBotManager from './admin-telegram-bot/TelegramBotManager'
import GlobalSettingsManager from './global-settings/GlobalSettingsManager'

const GlobalManager = () => {
  const { t } = useTranslation()

  const tabs: Tab[] = [
    {
      index: 0,
      label: t('SETTINGS'),
      component: <GlobalSettingsManager isContent />,
      icon: 'material-symbols:settings-outline'
    },
    {
      index: 1,
      label: t('COMPANIES_ADMIN'),
      component: <CompanyManagementCard isContent />,
      icon: 'mdi:domain'
    },
    {
      index: 2,
      label: t('PLATFORMS'),
      component: <PlatformManager isContent />,
      icon: 'mdi:layers'
    },
    {
      index: 3,
      label: t('SUBSCRIPTIONS'),
      component: <SubscriptionManager isContent />,
      icon: 'mdi:dollar'
    },
    {
      index: 4,
      label: t('TELEGRAM_BOT'),
      component: <TelegramBotManager isContent />,
      icon: 'streamline:telegram'
    }
  ]

  return (
    <Card>
      <CardContent sx={{}}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', flexWrap: 'wrap' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar sx={{ width: 40, height: 40, bgcolor: 'primary.main' }} color='error' variant='rounded'>
              <Icon icon='material-symbols:settings-outline' style={{ fontSize: 24 }} color='white' />
            </Avatar>
            <Typography sx={{ ml: 2 }} variant='h5'>
              {t('GLOBAL_SETTINGS')}
            </Typography>
          </Box>
        </Box>
        <GenericTab tabs={tabs}></GenericTab>
      </CardContent>
    </Card>
  )
}

export default GlobalManager
