import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, FormControl, IconButton, InputLabel, MenuItem } from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Icon from 'src/@core/components/icon'
import { changeStrategyById } from 'src/@http/user'
import adminStore from 'src/zustand/admin'
import profileStore, {ProfileStore, StrategyProfile} from 'src/zustand/profile'

import { DialogGeneric } from '../../generic/DialogGeneric'

interface CellType {
  row: API.User.AllUserResponse
}

const ChangeStrategy = ({ row }: CellType) => {
  const [show, setShow] = useState<boolean>(false)
  const { t } = useTranslation()
  const { getAllUser } = adminStore()
  const user = profileStore((state:ProfileStore) => state.userDetail)

  const strategiesProfile = profileStore((state: ProfileStore) => state.strategiesProfile)

  const currentStrategyId = row.currentStrategyId === null ? 1 : row.currentStrategyId
  const [strategySelected, setStrategySelected] = useState<string>(`${currentStrategyId}`)
  const handleChange = (event: SelectChangeEvent) => {
    if (event.target.value) {
      setStrategySelected(event.target.value as string)
    }
  }

  const playStrategy = async () => {
    const userId = `${row.userId}`
    await changeStrategyById(userId, parseInt(strategySelected))
    getAllUser()
    const getUserDetail = profileStore((state:ProfileStore) => state.getUserDetail)
    
    if (user.userId === userId) {
      await getUserDetail(userId)
    }
    
  }

  return (
    <>
      <IconButton
        onClick={() => {
          setShow(true)
        }}
        sx={{ ml: 2 }}
      >
        <Icon icon='mdi:swap-horizontal-circle-outline' fontSize={20} />
      </IconButton>
      <DialogGeneric
        show={show}
        title={t('CHANGE_STRATEGY')}
        confirmText={t('CONFIRM') || ''}
        confirmFunc={() => playStrategy()}
        closeFunc={() => {
          setShow(false)
        }}
      >
        <Box sx={{ mt: 4 }}>
          <FormControl fullWidth>
            <InputLabel id='demo-simple-select-label'>{t('STRATEGY')}</InputLabel>
            <Select value={strategySelected} label={t('STRATEGY')} onChange={handleChange}>
              {strategiesProfile.map((strategy: StrategyProfile, key: number) => (
                <MenuItem key={key} value={strategy.strategyId}>
                  {strategy.strategyName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </DialogGeneric>
    </>
  )
}

export { ChangeStrategy }
