import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar, Box, Card, CardContent, Grid, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { CountReferredUser, getCountReferredUsers, getReferredUsers, ReferredUser } from 'src/@http/user'
import GenericTab from 'src/layouts/components/GenericTab'
import profileStore, { ProfileStore } from 'src/zustand/profile'

import ReferredUserLink from '../profile/ReferredUserLink'

import { ReferredNodeColor, totalizeByColors } from './dashboard/ReferredGraphic/utils/totalizeByColors'
import transformArrayToTree from './dashboard/ReferredGraphic/utils/transformArrayToTree'
import ReferredUsersData from './ReferredUsersTable/ReferredUsersData'
import ReferredUsersFilters from './ReferredUsersTable/ReferredUsersFilters'



const ReferredUsers = () => {
  const { t } = useTranslation()

  const user = profileStore((state: ProfileStore) => state.userDetail)

  const [countReferredUser, setCountReferredUser] = useState<CountReferredUser[]>([])

  const [loading, setLoading] = useState<boolean>(false)
  const [referredUsers, setReferredUsers] = useState<ReferredUser[]>([])
  const [filledReferredUsers, setFilledReferredUsers] = useState<ReferredUser[]>([])

  const nodesColor: ReferredNodeColor[] = useMemo(() => {
    return totalizeByColors(referredUsers)
  }, [referredUsers])

  // const [referredUsersTree, setReferredUsersTree] = useState<ReferredUserTree[]>([])
  const [filledReferredUsersTree, setFilledReferredUsersTree] = useState<ReferredUser[]>([])
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('lg'))

  useEffect(() => {
    const init = async () => {
      try {
        if(Number(user.userId) !== 0){
          const data = await getCountReferredUsers(Number(user.userId))
          setCountReferredUser(data)
        }
      } catch (error) {}
    }
    init()
  }, [user.userId])

  useEffect(() => {
    const init = async () => {
      if(Number(user.userId) !== 0){
        setLoading(true)
        try {
          const data = await getReferredUsers(Number(user.userId))
          if (data.length !== 0) {
            const aux = transformArrayToTree(data, data[0].intReferredUserId)
            //setReferredUsersTree(aux)
            setFilledReferredUsersTree(aux)
            setReferredUsers(data)
            setFilledReferredUsers(data)
          }
        } catch (error) {}
        setLoading(false)
      }
      
    }
    init()
  }, [user.userId])

  const totalReferals = useMemo(() => {
    return countReferredUser.reduce(
      (accumulator: number, currentValue: CountReferredUser) => accumulator + Number(currentValue.users_count),
      0
    )
  }, [countReferredUser])

  const tab1 = () => {
    return (
      <ReferredUsersData
        visualMode='tree'
        loading={loading}
        referredUsers={referredUsers}
        filledReferredUsers={filledReferredUsers}
        filledReferredUsersTree={filledReferredUsersTree}
        treeMode='vertical'
      />
    )
  }
  const tab2 = () => {
    return (
      <ReferredUsersData
        visualMode='tree'
        loading={loading}
        referredUsers={referredUsers}
        filledReferredUsers={filledReferredUsers}
        filledReferredUsersTree={filledReferredUsersTree}
        treeMode='horizontal'
      />
    )
  }

  const tab3 = () => {
    return (
      <ReferredUsersData
        visualMode='table'
        loading={loading}
        referredUsers={referredUsers}
        filledReferredUsers={filledReferredUsers}
        filledReferredUsersTree={filledReferredUsersTree}
      />
    )
  }

  const tabs = [
    {
      index: 0,
      label: t('VERTICAL'),
      component: tab1(),
      icon: 'mdi:family-tree'
    },
    {
      index: 1,
      label: t('HORIZONTAL'),
      component: tab2(),
      icon: 'mdi:file-tree-outline'
    },
    {
      index: 2,
      label: t('TABLE'),
      component: tab3(),
      icon: 'mdi:table'
    }
  ]

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
          <Typography sx={{ mt: 2 }} variant='h5' component='div'>
            {t('REFERALS')}
          </Typography>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'end', flexWrap: 'wrap' }}>
            {countReferredUser.map((value: CountReferredUser, key: number) => {
              return (
                <Box sx={{ display: 'flex', mx: 1 }} key={key}>
                  <Typography>{`${t('TOTAL_LEVEL')} ${value.level}:`}</Typography>
                  <Typography color='#03A66D' fontWeight={600} sx={{ ml: 2 }}>
                    {value.users_count}
                  </Typography>
                </Box>
              )
            })}
            {countReferredUser.length !== 0 && (
              <Box sx={{ display: 'flex', mx: 1 }}>
                <Typography>{`${t('TOTAL_REFERALS')}:`}</Typography>
                <Typography color='#03A66D' fontWeight={600} sx={{ ml: 2 }}>
                  {totalReferals}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2 ,  flexWrap: 'wrap'}}>
          {nodesColor.map((value: ReferredNodeColor, key: number) => {
            return (
              <Box sx={{ display: 'flex', mx: 1, alignItems: 'center' }} key={key}>
                <Avatar sx={{ bgcolor: value.color, width:20, height:20, mr:1 }}> </Avatar>
                <Typography >{`${value.label}:`}</Typography>
                <Typography color={value.color} fontWeight={600} sx={{ml:2}}>
                  {value.total}
                </Typography>
              </Box>
            )
          })}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2 }}>
          <ReferredUserLink></ReferredUserLink>
        </Box>

        <Grid container>
          <Grid item xs={12} sx={{}}>
            <ReferredUsersFilters
              allData={referredUsers}
              setData={data => {
                if (data.length !== 0 && data[0].intReferredUserId !== undefined && data[0].level === '0') {
                  setFilledReferredUsers(data)
                  setFilledReferredUsersTree(transformArrayToTree(data, data[0].intReferredUserId))
                } else {
                  setFilledReferredUsersTree([])
                  setFilledReferredUsers([])
                }
              }}
            ></ReferredUsersFilters>
            {!mobile && <GenericTab tabs={tabs}></GenericTab>}
            {mobile && (
              <ReferredUsersData
                visualMode='tree'
                loading={loading}
                referredUsers={referredUsers}
                filledReferredUsers={filledReferredUsers}
                filledReferredUsersTree={filledReferredUsersTree}
                treeMode='horizontal'
              />
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export { ReferredUsers }
