// ** React Imports
import type { ReactNode } from 'react'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
// ** i18n Imports
import { useTranslation } from 'react-i18next'
import { Box, Divider, IconButton } from '@mui/material'
// ** MUI Imports
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
// ** MUI Imports
import Icon from 'src/@core/components/icon'

interface Props {
  title: string
  cancelText?: string
  confirmText?: string
  children?: ReactNode
  confirmDisabled?: boolean
  confirmFunc?: () => any
  closeFunc?: () => any
  cancelFunc?: () => any
  show?: boolean
  hideActions?: boolean
  showIconClose?: boolean
  leftAline?: boolean
  backgroundColor?: string
  buttonsColor?: string
  backdropColor?: string
  maxWidth?: number
  hideBackdrop?: boolean
  backdropZIndex?: number
}

interface DialogExpose {
  open: () => Promise<boolean>
  close: () => void
}

let resultResolve: (value: boolean | PromiseLike<boolean>) => void

const DialogGeneric = forwardRef((props: Props, ref) => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState<boolean>(false)

  useEffect(() => {
    if (props.show) open()
    else close()
  }, [props.show])

  const open = (): Promise<boolean> => {
    setVisible(true)
    //if (props.closeFunc) props.closeFunc()

    return new Promise<boolean>(resolve => {
      resultResolve = resolve
    })
  }

  const closeForUser = () => {
    if (props.cancelFunc) props.cancelFunc()
    if (props.closeFunc) props.closeFunc()
    if (resultResolve) resultResolve(false)
    setVisible(false)
  }

  const close = () => {
    if (props.closeFunc) props.closeFunc()
    if (resultResolve) resultResolve(false)
    setVisible(false)
  }
  const save = () => {
    if (props.closeFunc) props.closeFunc()
    if (resultResolve) resultResolve(true)

    if (props.confirmFunc) props.confirmFunc()
    setVisible(false)
  }

  useImperativeHandle(
    ref,
    (): DialogExpose => ({
      open,
      close
    })
  )

  interface ObjectDynamic {
    [index: string]: any
  }

  const dynamicStyle: ObjectDynamic = {}
  if (props.backdropZIndex) dynamicStyle['zIndex'] = props.backdropZIndex

  return (
    <Dialog
      disableEscapeKeyDown
      open={visible}
      style={{ ...dynamicStyle, 
       }}
      sx={{
        '& .MuiPaper-root': {
          width: '100%',
          maxWidth: props.maxWidth,
          backgroundColor: props.backgroundColor ? props.backgroundColor : ''
        },
      }}
      hideBackdrop={props.hideBackdrop}
      slotProps={{ backdrop: { style: { backgroundColor: props.backdropColor } } }}
    >
      <DialogTitle
        sx={{
          textAlign: props.leftAline ? 'start' : 'center',
          fontSize: '1.5rem !important',
          pl: 10,
          pb: 3
          // px: theme => [`${theme.spacing(5)} !important`, `${theme.spacing(15)} !important`],
          // pt: theme => [`${theme.spacing(8)} !important`, `${theme.spacing(12.5)} !important`]
        }}
      >
        <Box sx={{ pl: props.leftAline ? 6 : 0 }}>{t(props.title)}</Box>
        {props.showIconClose && props.closeFunc ? (
          <IconButton
            aria-label='close'
            onClick={props.closeFunc}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500]
            }}
          >
            <Icon icon='mdi:close' fontSize={30} color='white' />
          </IconButton>
        ) : null}
      </DialogTitle>

      <DialogContent
        sx={{
          pb: theme => `${theme.spacing(8)} !important`,
          px: theme => [`${theme.spacing(5)} !important`, `${theme.spacing(15)} !important`]
        }}
      >
        {props.leftAline && <Divider />}
        <DialogContentText variant='body2' sx={{ textAlign: 'center', mb: 0 }} component={'div'}>
          {props.children}
        </DialogContentText>
      </DialogContent>
      {!props.hideActions && <Divider sx={{}}></Divider>}
      {!props.hideActions && (
        <DialogActions
          sx={{
            justifyContent: 'space-between',
            py: theme => [`${theme.spacing(0)} !important`, `${theme.spacing(0)} !important`],
            px: theme => [`${theme.spacing(0)} !important`, `${theme.spacing(0)} !important`]
            //pb: theme => [`${theme.spacing(8)} !important`, `${theme.spacing(12.5)} !important`]
          }}
        >
          <Button
            variant='text'
            sx={{
              mr: 2,
              color: props.buttonsColor ? props.buttonsColor : '',
              textTransform: 'capitalize',
              width: '100%'
            }}
            onClick={save}
            disabled={props.confirmDisabled}
          >
            {t(props.confirmText ? props.confirmText : '')}
          </Button>
          <Divider orientation='vertical' flexItem sx={{}}></Divider>
          <Button
            variant='text'
            sx={{ color: props.buttonsColor ? props.buttonsColor : 'grey', textTransform: 'capitalize', width: '100%' }}
            onClick={closeForUser}
          >
            {t(props.cancelText ? props.cancelText : '')}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
})

DialogGeneric.defaultProps = {
  confirmText: 'SAVE',
  cancelText: 'CANCEL',
  confirmDisabled: false,
  show: false,
  showIconClose: false,
  backgroundColor: undefined,
  buttonsColor: undefined,
  backdropColor: '',
  maxWidth: 650,
  hideBackdrop: false
}

export { DialogGeneric }
export type { DialogExpose }
