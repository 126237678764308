import { useTranslation } from 'react-i18next'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { styled } from '@mui/system'

interface KeyColumn {
  label: string
  key: string
}

interface Props {
  sx: any
  headers: KeyColumn[]
  data: any[]
}

const SimpleTable = ({ sx, headers, data }: Props) => {
  const SX = styled('div')({})
  const { t } = useTranslation()

  return (
    <SX sx={{ ...sx }}>
      <TableContainer component={Paper} style={{ maxHeight: 350, overflowX: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((element: KeyColumn, key: number) => {
                {
                  return <TableCell key={key}>{element.label}</TableCell>
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length!==0 && data.map((row: any, index: number) => (
              <TableRow key={index}>
                {headers.map((element: KeyColumn, key: number) => {
                  {
                    return <TableCell key={key}>{row[element.key]}</TableCell>
                  }
                })}
              </TableRow>
            ))}
             {data.length===0 && 
             <TableRow >
             <Typography sx={{ mt: 4, fontStyle: 'italic'}}>
             {t('DATA_NOT_FOUND')}
             </Typography>
           </TableRow>
           }
          </TableBody>
        </Table>
      </TableContainer>
    </SX>
  )
}

SimpleTable.defaultProps = {
  sx: {},
  data: []
}

export default SimpleTable
