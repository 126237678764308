// ** React Imports
import { KeyboardEvent, MouseEvent, TouchEvent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/material'
import Button from '@mui/material/Button'
import ClickAwayListener from '@mui/material/ClickAwayListener'
// ** MUI Imports
import Grow from '@mui/material/Grow'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
// ** Hook Import
import { useSettings } from 'src/@core/hooks/useSettings'

interface Filter {
  text: string
  icon: string
  id: number
}
interface IMenuProps {
  list: Array<{ text: string; icon: string; id: number }>
  onData?: (item: Filter) => void
}

const MenuComposition = ({ list, onData }: IMenuProps) => {
  const { t } = useTranslation()

  // ** States
  const [open, setOpen] = useState<boolean>(false)

  // ** Hook & Var
  const { settings } = useSettings()
  const { skin } = settings

  // ** Ref
  const anchorRef = useRef<HTMLButtonElement | null>(null)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = (event: MouseEvent | TouchEvent, item: Filter): void => {
    if (onData) {
      onData(item)
    }
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }
    setOpen(false)
  }

  const handleListKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === 'Escape') {
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open)

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <Stack direction='row' justifyContent='space-between' spacing={2} width='100%'>
      <Button
        ref={anchorRef}
        variant='outlined'
        aria-haspopup='true'
        onClick={handleToggle}
        id='composition-button'
        aria-expanded={open ? 'true' : undefined}
        aria-controls={open ? 'composition-menu' : undefined}
        sx={{ '& + div': { zIndex: theme => theme.zIndex.modal } }}
      >
        {t('FILTERS')}
      </Button>
      <Popper
        transition
        open={open}
        disablePortal
        role={undefined}
        placement='bottom-start'
        anchorEl={anchorRef.current}
        popperOptions={{
          modifiers: [
            {
              name: 'flip',
              options: {
                enabled: true,
                boundary: 'window'
              }
            }
          ]
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom' }}
          >
            <Paper elevation={skin === 'bordered' ? 0 : 6}>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <MenuList autoFocusItem={open} id='composition-menu' onKeyDown={handleListKeyDown}>
                  {list.map((item, index) => (
                    <MenuItem key={index} onClick={e => handleClose(e, item)}>
                      {item.text}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Stack>
  )
}

export default MenuComposition
