import { Avatar, Box, Typography } from '@mui/material'

interface Props {
    textTop: string
    textLeft: string
    textRight: string
}

const ReferredDirectActives = ({textLeft, textRight, textTop}: Props) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 7, position: 'relative' }}>
      <Typography
        sx={{ color: 'black', fontWeight: 700, position: 'absolute', top: -24 }}
      >
        {textTop}
      </Typography>
      <Typography
        sx={{ color: 'black', fontWeight: 700, position: 'absolute', top: 90, left:75 }}
      >
        {textLeft}
      </Typography>
      <Typography
        sx={{ color: 'black', fontWeight: 700, position: 'absolute', top: 90, right:80 }}
      >
        {textRight}
      </Typography>
      {true && 
      <Box sx={{
        width: 100,
        height: 100,
        border: '8px solid #0A1F29',
        borderRadius: '50%',
        boxSizing: 'border-box',
        position: 'absolute',
        top: 7
      }}></Box>
      }
      
      <Avatar sx={{ width: 100, height: 90 }} src='/images/icons/Asset 3.png' variant='square' />
    </Box>
  )
}

ReferredDirectActives.defaultProps = {
    textTop: '1 AX3',
    textLeft: '1 AX2',
    textRight: '1 AX1'
}

export default ReferredDirectActives
