import { useState } from "react"
import { useTranslation } from "react-i18next"
import Icon from 'src/@core/components/icon'
import { postStopStrategyByUserId } from "src/@http/user"
import { CustomLoadingBtn } from "src/layouts/components/shared-component/CustomBtn"
import { DialogGeneric } from "src/pages/components/generic/DialogGeneric"
import { logger } from "src/utils/Logger"
import profileStore, { ProfileStore } from "src/zustand/profile"

interface Props {
    strategyId: number
}

const PauseStrategyBtn = ({ strategyId }: Props) => {
    const [loading, setLoading] = useState<boolean>(false)
    const getUserDetail = profileStore((state:ProfileStore) => state.getUserDetail)
    const user = profileStore((state:ProfileStore) => state.userDetail)
    const [show, setShow] = useState<boolean>(false)
    const { t } = useTranslation()
    
  
    const stopStrategy = async () => {
      setLoading(true)
      await postStopStrategyByUserId(user.userId)
      logger.log(`[v] Stop Stretegy ${strategyId}`)
      await getUserDetail(user.userId)
      setLoading(false)
    }
  
    return (
      <>
        <CustomLoadingBtn
          loading={loading}
          size='large'
          variant='contained'
          startIcon={<Icon icon={'mdi:pause-circle-outline'} />}
          onClick={() => {
            setShow(true)
          }}
        >
          {t('STOP_STRATEGY')}
        </CustomLoadingBtn>
        <DialogGeneric
          show={show}
          title={t('STOP_STRATEGY')}
          confirmText={t('CONFIRM') || ''}
          confirmFunc={() => stopStrategy()}
          closeFunc={() => {
            setShow(false)
          }}
        >
          {t('CONFIRM_STOP_CURRENT_STRATEGY')}
        </DialogGeneric>
      </>
    )
  }

  export default PauseStrategyBtn