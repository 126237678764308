import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormControl, Grid, Slider, TextField } from '@mui/material'
import pDebounce from 'p-debounce'
import { getGlobalSettingForKey } from 'src/@http/global-settings'
import { postSubscriptionCommissionWithdrawal } from 'src/@http/subscriptionCommissionWithdrawal'
import CustomBtn, { CustomLoadingBtn } from 'src/layouts/components/shared-component/CustomBtn'
import TwoFactorAuthField from 'src/layouts/components/TwoFactorAuthField'
import { ModeForm } from 'src/types'
import { logger } from 'src/utils/Logger'
import * as yup from 'yup'

interface Props {
  modeForm: ModeForm
  valuesDefaultForm?: any | null
  closeFunc: () => any
  updateFunc: () => any
  amount: number
}

interface RetiramentForm {
  amount: number
  network: string
  walletAddress: string
  code: string
}

const reactAppAPIDomanin = process.env.REACT_APP_API_DOMAIN

const CommissionRetiramentForm = ({ modeForm, closeFunc, amount, updateFunc }: Props) => {
  const { t } = useTranslation()
  const [disabledProceed, setDisabledProceed] = useState<boolean>(true)
  const defaultValuesForm: RetiramentForm = {
    amount,
    network: 'TRC20',
    walletAddress: '',
    code: ''
  }

  const getMinAmountWithdrawalSubCommission = pDebounce(async () => {
    try {
      return Number((await getGlobalSettingForKey('MinAmountWithdrawalSubCommission')).value)
    } catch (error) {
      logger.log('[getGlobalSettingForKey (minAmountWithdrawalSubCommission)] error: ', error)

      return 50
    }
  }, 500)

  const validations = {
    amount: yup
      .number()
      .required()
      .test({
        name: 'checkMinValue',
        test: async function (value) {
          let min = 50
          try {
            min = await getMinAmountWithdrawalSubCommission()
          } catch {}
          if (value < min)
            return this.createError({
              message:
                t('VALIDATION.GREATER_THAN_OR_EQUAL', { value: min, labelEn: 'Amount', label: t('AMOUNT') || '' }) || ''
            })

          return min < value
        }
      })
      .max(amount),
    network: yup.string().required(),
    walletAddress: yup.string().test('TRC20', t('TRC20_STRING_FORMAT') || '', function (value) {
      if (value === undefined) return false

      const expresionRegular = /^T[1-9A-HJ-NP-Za-km-z]{33}$/

      return expresionRegular.test(value)
    }),
    code:
      reactAppAPIDomanin === 'stg.axeskapital.xssclients.com/api' || reactAppAPIDomanin === 'axeskapital.com/api'
        ? yup.string().required()
        : yup.string()
  }

  const schemaValidations = yup.object().shape(validations)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {
    control,
    handleSubmit,
    formState: { errors },
    register
  } = useForm<RetiramentForm>({ resolver: yupResolver(schemaValidations), defaultValues: defaultValuesForm })

  const onSubmit = async (form: RetiramentForm) => {
    setIsLoading(true)

    try {
      await postSubscriptionCommissionWithdrawal({
        dcmAmount: form.amount,
        vchDestinationWallet: form.walletAddress,
        vchNetwork: form.network
      })
      toast.success(t('APPLICATION_MADE_SUCCESSFULLY'), { duration: 4000 })
      updateFunc()
      closeFunc()
    } catch (error: any) {
      toast.error(error.message, { duration: 4000 })
    }

    setIsLoading(false)
  }

  return (
    <>
      <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='amount'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                disabled={ModeForm.show === modeForm}
                type='number'
                {...field}
                label={`${t('AMOUNT')} (USDT)`}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name='amount'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <Slider
                {...field}
                step={1.1}
                valueLabelDisplay='auto'
                valueLabelFormat={value => `${((value * 100) / amount).toFixed(0)}%`}
                max={amount}
                disabled={ModeForm.show === modeForm}
              />
            )}
          />
        </FormControl>

        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='network'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                disabled={true}
                {...field}
                label={t('NETWORK')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>

        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='walletAddress'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                disabled={false}
                {...field}
                label={t('DESTINATION_WALLET')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>
        <TwoFactorAuthField
          successFunc={() => {
            setDisabledProceed(false)
          }}
          control={control}
        />

        <Grid container>
          <Grid sx={{ p: 1 }} item xs={6}>
            <CustomLoadingBtn
              disabled={disabledProceed}
              loading={isLoading}
              fullWidth
              size='large'
              type='submit'
              variant='contained'
              sx={{ mt: 3 }}
            >
              {t('CONFIRM')}
            </CustomLoadingBtn>
          </Grid>
          <Grid sx={{ p: 1 }} item xs={6}>
            <CustomBtn fullWidth size='large' variant='contained' onClick={() => closeFunc()} sx={{ mt: 3 }}>
              {t('CANCEL')}
            </CustomBtn>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

CommissionRetiramentForm.defaultProps = {
  modeForm: ModeForm.create
}

export default CommissionRetiramentForm
