import { useEffect, useState } from 'react'
// import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Avatar, Box, Button, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material'
import Icon from 'src/@core/components/icon'
// import { getCompanies } from 'src/@http/company/'
import { DialogGeneric } from 'src/pages/components/generic/DialogGeneric'
import { ModeForm } from 'src/types'
import { Company } from 'src/types/models/companyModel'
import adminStore from 'src/zustand/admin'

// import { logger } from 'src/utils/Logger'
import CompanyItemCard from './CompanyItemCard'
import CompanyRegisterForm from './CompanyRegisterForm'
// import CustomBtn /*, { ActiveBtn }*/ from 'src/layouts/components/shared-component/CustomBtn'

interface Props {
  isContent: boolean
}


const CompanyCard = ({isContent}: Props) => {
  const [showCompanyRegisterDialog, setShowCompanyRegisterDialog] = useState<boolean>(false)
  const [notFound, setNotFound] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  // const [companies, setCompanies] = useState<Company[]>([])
  const [companySelected, setCompanySelected] = useState<Company | null>(null)
  const { getAllCompanies, companies } = adminStore()
  const { t } = useTranslation()

  useEffect(() => {
    const init = async () => {
      setLoading(true)
      const companiesList = await getAllCompanies()
      if(companiesList.length === 0)
        setNotFound(true)
      setLoading(false)
    }
    init()
  }, [])

  const submitGetCompany = () => {
    getAllCompanies()
    setShowCompanyRegisterDialog(false)

    // setCompanies([])
    // getCompanies()
    //   .then(res => {
    //     setCompanies(res)
    //     setShowCompanyRegisterDialog(false)
    //   })
    //   .catch(err => toast.error(err))
  }

  const openDialogEdit = (company: Company) => {
    setCompanySelected(company)
    setShowCompanyRegisterDialog(true)
  }

  const openDialogCreate = () => {
    setCompanySelected(null)
    setShowCompanyRegisterDialog(true)
  }

  return (
    <Card sx={{ ...(isContent && { border: 'none', boxShadow: 'none' }) }}>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {!isContent && <>
              <Avatar sx={{ width: 40, height: 40, bgcolor: 'primary.main' }} color='error' variant='rounded'>
              <Icon icon='mdi:domain' style={{ fontSize: 24 }} color='white' />
            </Avatar>
            <Typography sx={{ m: 3 }} color='white' variant='h5' component='div'>
              {t('COMPANIES_ADMIN')}
            </Typography>
            </>}
          </Box>
          <Button onClick={() => openDialogCreate()} startIcon={<Icon icon='mdi:plus-box-outline' color='#9295A6' />}>
            <Typography color='#9295A6' sx={{ textTransform: 'capitalize' }}>
              {t('ADD')}
            </Typography>
          </Button>
        </Box>
        {companies.length !== 0 && (
          <Grid sx={{ mt: 3 }} container>
            {companies.map(company => (
              <Grid key={company.companyId} item xs={12} sm={6} lg={4} sx={{ p: 1 }}>
                <CompanyItemCard
                  company={company}
                  editFunction={() => openDialogEdit(company)}
                  deleteFunctionSuccess={() => submitGetCompany()}
                />
              </Grid>
            ))}
          </Grid>
        )}
        {(notFound) && (
          <Typography align='center'>
            {t('DATA_NOT_FOUND')}
          </Typography>
        )}
        {(companies.length === 0 && loading) && (
          <Grid sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Grid>
        )}
      </CardContent>
      <DialogGeneric
        show={showCompanyRegisterDialog}
        closeFunc={() => {
          setShowCompanyRegisterDialog(false)
        }}
        hideActions={true}
        title={t('COMPANY_DETAIL')}
        leftAline={true}
        showIconClose={true}
      >
        <Box sx={{ mt: 4 }}>
          <CompanyRegisterForm
            company={companySelected}
            modeForm={ModeForm.edit}
            submitFinishFunction={() => submitGetCompany()}
            closeFunc={() => setShowCompanyRegisterDialog(false)}
          />
        </Box>
      </DialogGeneric>
    </Card>
  )
}

CompanyCard.defaultProps = {
  isContent: false
}

export default CompanyCard
