import { deleteAsync, getAsync, postAsync, putAsync } from 'src/@http'
import { PercentageTraderPost, Trader } from 'src/types/models/traderModel'

// import { Trader } from 'src/types/models/traderModel'

/* Endpoints definition */

const GetTraders = 'trader/'
const GetTradersById = (traderId: string) => `trader/${traderId}`
// const ChangeUserPassword = (userId: number) => `/users/updatePassword/${userId}`
const UpdateTrader = '/trader'
const PostTrader = '/trader'
const DeleteTradersById = (traderId: string) => `trader/${traderId}`
const GetPercentageStrategiesByTraderId = (traderId: string) => `/trader/${traderId}/strategies`
const PostPercentageStrategiesByTraderId = (traderId: number) => `/trader/${traderId}/strategies`
const GetPositionsBybitByTraderId = (traderId: string) => `/trader-position-bybit/${traderId}`
const PutClosePositionByPositionIdAndTraderId = (traderId: number, positionId: number) =>
  `/trader-position-bybit/${traderId}/close/${positionId}`

interface Response {
  statusCode: number
  message: string
  data: any
}

interface ClosePosition {
  intTraderPositionByBitId: number
  vchSize: string
}

// TODO Dejar tipado al trader
export const getTradersById = (traderId: string): Promise<any> => {
  return getAsync(GetTradersById(traderId))
}

export const deleteTradersById = (traderId: string): Promise<any> => {
  return deleteAsync(DeleteTradersById(traderId))
}

export const getTraders = (): Promise<Trader[]> => {
  return getAsync(GetTraders)
}

// TODO Dejar tipado al trader
export const updateTrader = (traderData: any): Promise<any> => {
  return putAsync(UpdateTrader, traderData)
}

export const postTrader = (traderData: any): Promise<any> => {
  return postAsync(PostTrader, traderData)
}

export const getPercentageStrategiesByTraderId = (traderId: string): Promise<any> => {
  return getAsync(GetPercentageStrategiesByTraderId(traderId))
}

export const postPercentageStrategiesByTraderId = (traderId: number, body: PercentageTraderPost[]): Promise<any> => {
  return postAsync(PostPercentageStrategiesByTraderId(traderId), body)
}

export const getPositionsBybitByTraderId = (traderId: string): Promise<API.Trader.PositionsBybit[]> => {
  return getAsync(GetPositionsBybitByTraderId(traderId))
}

export const putClosePositionByPositionIdAndTraderId = (
  traderId: number,
  positionId: number,
  body: ClosePosition
): Promise<any> => {
  return putAsync(PutClosePositionByPositionIdAndTraderId(traderId, positionId), body)
}
