import G6, { ModelConfig } from '@antv/g6'
import i18n from 'i18next'

import { TreeMode } from './types'

interface Props {
  treeMode: TreeMode
}

export const registerNodeG6 = ({ treeMode }: Props) => {
  G6.registerNode(
    'flow-rect',
    {
      draw(cfg: ModelConfig, group) {
        const {
          vchEmail,
          dtmCreatedDate,
          intUserId,
          level,
          vchSubscriptionName,
          balance,
          paymentMethod,
          blnCurrentStrategyIsActive,
          collapsed,
          blnActiveSubscription
        } = cfg
        const rectConfig = {
          width: 300,
          height: 132,
          lineWidth: 3,
          fontSize: 12,
          fill: '#fff',
          radius: 4,
          stroke:
            blnCurrentStrategyIsActive === 'Active'
              ? '#03A66D'
              : blnActiveSubscription === 'Active'
              ? '#FDDA0D'
              : '#DC2626', //'#aa7b03',
          opacity: 1
        }

        const rect = group.addShape('rect', {
          attrs: {
            x: 0,
            y: 0,
            ...rectConfig
          }
        })

        const colapseCirclePosition =
          treeMode === 'horizontal'
            ? {
                x: rectConfig.width,
                y: rectConfig.height / 2
              }
            : {
                x: rectConfig.width / 2,
                y: rectConfig.height
              }

        const textLine = [
          {
            label: `${i18n.t('REGISTRATION_DATE')}: ${dtmCreatedDate}`
          },
          {
            label: `${i18n.t('PAYMENT_METHOD')}: ${paymentMethod}`
          },
          {
            label: `${i18n.t('SUBSCRIPTION')}: ${vchSubscriptionName === null ? '' : vchSubscriptionName + ' - '} ${
              blnActiveSubscription === 'Active' ? i18n.t('ACTIVE') : i18n.t('INACTIVE')
            }`
          },
          {
            label: `${i18n.t('STRATEGY')}: ${
              blnCurrentStrategyIsActive === 'Active' ? i18n.t('ACTIVE') : i18n.t('INACTIVE')
            }`
          }
        ]

        if (balance !== null)
          textLine.push({
            label: `${i18n.t('BALANCE')}: $${Number(balance).toFixed(2)}`
          })

        // label title
        group.addShape('text', {
          attrs: {
            textAlign: 'left',
            textBaseline: 'top',
            x: 12,
            y: 8,
            text: `${intUserId}-${vchEmail}`,
            fontSize: 16,
            fill: '#000',
            fontWeight: 600
          }
        })

        let yInitial = 34
        textLine.forEach(item => {
          group.addShape('text', {
            attrs: {
              textAlign: 'left',
              textBaseline: 'top',
              x: 12,
              y: yInitial,
              text: item.label,
              fontSize: 14,
              fill: '#000'
            }
          })
          yInitial += 20
        })

        // label percentage
        group.addShape('text', {
          attrs: {
            textBaseline: 'top',
            x: 280,
            y: rectConfig.height / 2 - 5,
            text: `${i18n.t('LEVEL')} ${level}`,
            fontSize: 14,
            textAlign: 'right',
            fill: rectConfig.stroke
          }
        })

        // bottom line
        /*
        group.addShape('rect', {
          attrs: {
            x: 0,
            y: 70,
            width: rectConfig.width,
            height: 4,
            radius: [0, 0, rectConfig.radius, rectConfig.radius],
            fill: '#DCDFE5'
          }
        })
        */

        // bottom percent
        /*
        group.addShape('rect', {
          attrs: {
            x: 0,
            y: 70,
            width: rectConfig.width * Number(cfg.finishRate),
            height: 4,
            radius: [0, 0, 0, rectConfig.radius],
            fill: rectConfig.stroke
          }
        })
        */

        if (cfg.children && Array(cfg.children).length) {
          // collapse circle
          group.addShape('circle', {
            attrs: {
              ...colapseCirclePosition,
              r: 8,
              stroke: rectConfig.stroke,
              fill: '#fff'
            },
            name: 'collapse-marker'
          })

          // collpase text
          group.addShape('text', {
            attrs: {
              ...colapseCirclePosition,
              width: 16,
              height: 16,
              textAlign: 'center',
              textBaseline: 'middle',
              text: collapsed ? '+' : '-',
              fontSize: 16,
              fill: rectConfig.stroke,
              cursor: 'pointer'
            },
            name: 'collapse-marker-text'
          })
        }

        return rect
      }
    },
    'rect'
  )
}
