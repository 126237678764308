import { useTranslation } from 'react-i18next'
import { Avatar, Box, Card, CardContent, Chip, Grid, Typography } from '@mui/material'
// import { Position } from 'src/types/models'

interface DetailCardProps {
  item: API.User.ProfitDetailResponse
}

const DetailCard = ({ item }: DetailCardProps) => {
  const colorAttribute = (value: number) => {
    return value >= 0 ? '#03A66D' : '#DC2626'
  }
  const { t } = useTranslation()
  const symbol = item.symbol.replace('USDT', '')

  return (
    <Card variant='outlined' elevation={0}>
      <CardContent sx={{ px: 2 }}>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Avatar
            variant='square'
            src={`https://raw.githubusercontent.com/spothq/cryptocurrency-icons/master/32/color/${symbol.toLowerCase()}.png`}
            sx={{ width: 30, height: 30 }}
          />
          <Typography fontSize={14}>{item.symbol}</Typography>
          <Chip label={item.side} />
        </Box>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Typography color='#6C7080'>{t('DATE')}:</Typography>
              <Typography fontWeight={500} color='white'>
                { new Date(item.createdDate).toDateString() }
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Typography color='#6C7080'>{t('AMOUNT')}:</Typography>
              <Typography fontWeight={500}  color={Number(item.closedPnl) < 0 ? '#DC2626' : '#03A66D'}>
                {item.closedPnl}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Typography color='#6C7080'>{t('SIZE')}:</Typography>
              <Typography fontWeight={500} color={colorAttribute(0)}>
                {Number(item.size).toFixed(3)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default DetailCard
