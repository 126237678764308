import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Avatar, Box, ButtonGroup, Card, CardContent, Grid, IconButton, Typography } from '@mui/material'
// import { logger } from 'src/utils/Logger'
import { styled } from '@mui/material/styles'
import Icon from 'src/@core/components/icon'
import { deleteGlobalSetting, GlobalSetting } from 'src/@http/global-settings'
// import { deletedSubscription } from 'src/@http/subscription'
import { DialogGeneric } from 'src/pages/components/generic/DialogGeneric'

interface Props {
  globalSetting: GlobalSetting
  editFunction: (percents: any) => any
  deleteFunctionSuccess: () => any
}

const CustomCardContent = styled(CardContent)(({ theme }) => ({
  padding: 2,
  '&:last-of-type': {
    paddingBottom: 6
  }
}))

const GlobalSettingsItemCard = ({ globalSetting, editFunction, deleteFunctionSuccess }: Props) => {
  const { t } = useTranslation()
  const [show, setShow] = useState<boolean>(false)

  const deleteSubscription = async () => {
    try {
      await deleteGlobalSetting(globalSetting.key)
      toast.success(t('GLOBAL_SETTING_DELETE_MESSAGE_SUCCESS'))
      deleteFunctionSuccess()
    } catch (err: any) {
      toast.error(err)
    }
  }

  const ActionButtons = () => {
    const buttons = [
      {
        icon: 'carbon:edit',
        action: () => {
          editFunction(globalSetting)
        }
      },
      {
        icon: 'ph:trash-light',
        action: () => {
          setShow(true)
        }
      }
    ]

    return (
      <Grid container sx={{ flexDirection: 'row', justifyContent: 'end' }}>
        <ButtonGroup>
          {buttons.map(button => (
            <Grid item key={button.icon}>
              <IconButton onClick={() => button.action()} aria-label='capture screenshot' color='secondary'>
                <Icon icon={button.icon} fontSize={16} />
              </IconButton>
            </Grid>
          ))}
        </ButtonGroup>
      </Grid>
    )
  }

  return (
    <Card sx={{ backgroundColor: 'background.default', p: 0 }}>
      <CustomCardContent sx={{ p: 2 /*, pt:1, pb:0*/ }}>
        <Grid container sx={{ flexDirection: 'row', width: '100%' }}>
          <Grid item xs={2}>
            <Avatar
              sx={{ bgcolor: globalSetting.value.includes('#') ? globalSetting.value : 'secondary.main' }}
              variant='rounded'
            >
              <Icon icon='ant-design:setting-outlined' />
            </Avatar>
          </Grid>
          <Grid item xs={8} sx={{ flexDirection: 'column', width: '100%' }}>
            <Box sx={{ display: 'flex', fontSize: 12 }}>
              <Typography variant='body1'> {globalSetting.key.length >= 23 ? globalSetting.key.slice(0,23)+'...' : globalSetting.key} </Typography>
              {/* <Box sx={{ mr: 3 }}> {t('KEY_VALUE')}:</Box> <span> {globalSetting.key}</span> */}
            </Box>
            <Box sx={{ display: 'flex', fontSize: 12 }}>
              <Box sx={{ mr: 3 }}> {t('VALUE')}:</Box> <span>{globalSetting.value.length<15?globalSetting.value: globalSetting.value.slice(0,15)+'...'}</span>
            </Box>
          </Grid>
          <Grid item xs={2} sx={{ justifyContent: 'end' }}>
            <ActionButtons />
          </Grid>
        </Grid>
        <DialogGeneric
          show={show}
          title={t('DELETE_GLOBAL_SETTING')}
          confirmText={t('CONFIRM') || ''}
          confirmFunc={() => deleteSubscription()}
          closeFunc={() => {
            setShow(false)
          }}
        >
          {t('DELETE_GLOBAL_SETTING_MESSAGE')}
        </DialogGeneric>
      </CustomCardContent>
    </Card>
  )
}

export default GlobalSettingsItemCard
