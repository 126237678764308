import { Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import i18n from 'i18next'

import { RowCommissionRankOptions } from './RowCommissionRankOptions'



interface CellType {
  row: API.Commission.Rank
}

const columns: GridColDef[] = [
  {
    width: 250,
    field: 'commissionRankName',
    headerAlign: 'left',
    align: 'left',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('NAME')}</Typography>,
    renderCell: ({ row }: CellType) => {
      return (
        <Typography color={'white'}>
          {row.commissionRankName}
        </Typography>
      )
    }
  },
  {
    width: 200,
    field: 'minBalance',
    headerAlign: 'left',
    align: 'left',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('MIN_BALANCE')+' (USDT)'}</Typography>,
    renderCell: ({ row }: CellType) => {
      const { minBalance} = row

      return (
        <Typography color={'white'}>
          {minBalance}
        </Typography>
      )
    }
  },
  {
    width: 200,
    field: 'maxBalance',
    headerAlign: 'left',
    align: 'left',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('MAX_BALANCE')+' (USDT)'}</Typography>,
    renderCell: ({ row }: CellType) => {
      const { maxBalance} = row

      return (
        <Typography color={'white'}>
          {maxBalance}
        </Typography>
      )
    }
  },
  {
    width: 250,
    field: 'commissionPercentage',
    headerAlign: 'left',
    align: 'left',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('COMMISSION_PORCENTAGE')}</Typography>,
    renderCell: ({ row }: CellType) => {
      const { commissionPercentage} = row

      return (
        <Typography color={'white'}>
          {commissionPercentage}
        </Typography>
      )
    }
  },
  {
    width: 200,
    field: 'costPerMonth',
    headerAlign: 'left',
    align: 'left',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('COST_PER_MONTH')}</Typography>,
    renderCell: ({ row }: CellType) => {
      const { costPerMonth} = row

      return (
        <Typography color={'white'}>
          {costPerMonth}
        </Typography>
      )
    }
  },
  {
    width: 100,
    minWidth: 90,
    sortable: false,
    field: 'actions',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('ACTIONS')}</Typography>,
    renderCell: ({ row }: CellType) => <RowCommissionRankOptions row={row} />
  }
]

export default columns
