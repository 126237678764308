import { toast } from 'react-hot-toast'
import axios, { AxiosResponse } from 'axios'
import { logger } from 'src/utils/Logger'

let axiosInstanceHeaders: any = {}

/*  TODO: Tomar el auth token, por ejemplo del localStorage. */
// logger.log(window.localStorage.get('authTokenStorage'))
// const authToken = window.localStorage.getItem('token')
const authToken: string | null = window.localStorage.getItem('token')

if (authToken != null) {
  axiosInstanceHeaders = {
    common: { Authorization: 'Bearer ' + authToken },
    'Content-Type': 'application/json'
  }
}

const apiProtocol = process.env.REACT_APP_API_PROTOCOL
const apiDomain = process.env.REACT_APP_API_DOMAIN
const apiTimeOut = 30000000
// typeof process.env.REACT_APP_API_TIMEOUT === 'string' ? parseInt(process.env.REACT_APP_API_TIMEOUT) : 0

export const axiosInstance = axios.create({
  baseURL: `${apiProtocol}://${apiDomain}/`,
  timeout: apiTimeOut,
  headers: axiosInstanceHeaders
})

const catchAxiosError = (error: any) => {
  if (!error.response) {
    logger.log('Catch error', error)

    return Promise.reject({ error: 'Error no existe', message: 'Ha ocurrido un error' })
  }
  if (error.response.status === 401) {
    window.localStorage.removeItem('token')
  }

  return Promise.reject(error.response?.data)
}

//Esto, hay que cambiarlo. Lo puse asi para que no rompa el resto, pero hay que unificar que todas las cosas
//del back, devuelvan una GenericResponse
const mapResponseBody = (response: AxiosResponse) => {
  try {
    if (response.data.message && response.data.message !== '' && response.data.message !== 'Success') {
      logger.log('response.data', response.data)
      toast.error(response.data.message, { duration: 4000 })
    }
  } catch (err: any) {
    logger.log('Error', err)
  }

  return response.data.hasOwnProperty('data') ? response.data.data : response.data
}

export const getAsync = async (url: string, options?: {}) => axiosInstance.get(url, options).then(mapResponseBody).catch(catchAxiosError)

export const getBlobAsync = async (url: string) =>
  axiosInstance.get(url, { responseType: 'blob' }).then(mapResponseBody).catch(catchAxiosError)

export const getWithQueryParamsAsync = async (url: string, params: {}) =>
  axiosInstance.get(url, { params: params }).then(mapResponseBody).catch(catchAxiosError)

export const postFormAsync = async (url: string, body: {}) => {
  try {
    axiosInstance.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    const response = await axiosInstance.post(url, body)

    axiosInstance.defaults.headers['Content-Type'] = 'application/json'
    logger.log('Res en index Form async', response)
    const data = mapResponseBody(response)

    return data
  } catch (error: any) {
    const data = catchAxiosError(error)

    return data
  }
}

export const postAsync = async (url: string, body: {}) =>
  axiosInstance.post(url, body).then(mapResponseBody).catch(catchAxiosError)

export const putAsync = async (url: string, body: {}) =>
  axiosInstance.put(url, body).then(mapResponseBody).catch(catchAxiosError)

export const deleteAsync = async (url: string) => axiosInstance.delete(url).then(mapResponseBody).catch(catchAxiosError)
