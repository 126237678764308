import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { Avatar, Box, FormControl, Grid, InputAdornment, Switch, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { postPromocode, Promocode, putPromocode } from 'src/@http/promocode'
import { CustomLoadingBtn } from 'src/layouts/components/shared-component/CustomBtn'
import { ModeForm } from 'src/types'
import adminStore from 'src/zustand/admin'
import * as yup from 'yup'

interface CuponForm {
  promoCodeId: number
  code: string
  startDate: Date
  endDate: Date
  numberOfSubscriptionDays: number
  statusId: boolean
  numberOfUsesAvailable: number
}

interface Props {
  modeForm: ModeForm
  valuesDefaultForm?: Promocode
  submitSuccess: () => void
}

const CuponForm = ({ modeForm, valuesDefaultForm, submitSuccess }: Props) => {
  const { getAllPromocodes } = adminStore()
  // --- FORM

  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)
  const tomorrow = new Date()
  tomorrow.setDate(new Date().getDate() + 1)

  const defaultValuesForm: CuponForm = {
    promoCodeId: 0,
    code: '',
    startDate: new Date(),
    endDate: tomorrow,
    numberOfSubscriptionDays: 1,
    statusId: true,
    numberOfUsesAvailable: 1
  }

  if (valuesDefaultForm) {
    defaultValuesForm.promoCodeId = valuesDefaultForm.promoCodeId
    defaultValuesForm.code = valuesDefaultForm.code
    defaultValuesForm.startDate = new Date(valuesDefaultForm.startDate)
    defaultValuesForm.endDate = new Date(valuesDefaultForm.endDate)
    defaultValuesForm.numberOfSubscriptionDays = valuesDefaultForm.numberOfSubscriptionDays
    defaultValuesForm.statusId = valuesDefaultForm.statusId == 1
    defaultValuesForm.numberOfUsesAvailable = valuesDefaultForm.numberOfUsesAvailable
  }

  function formatDate(date: any) {
    return new Date(date).toLocaleDateString()
  }

  const validations = {
    promoCodeId: yup.number().typeError('Invalid id format'),
    code: yup.string(),
    startDate: yup.date().min(yesterday, ({ min }) => `Date needs to be before ${formatDate(min)}.`),
    endDate: yup
      .date()
      .when('startDate', ([startDate], schema) =>
        schema.min(startDate, ({ min }) => `Date needs to be before ${formatDate(min)}.`)
      ), //.min(new Date(), ({ min }) => `Date needs to be before ${formatDate(min)}.`),
    numberOfSubscriptionDays: yup.number(),
    statusId: yup.boolean()
  }

  const disableStartDate = (datee: Date) => {
    return datee <= yesterday
  }

  const disableEndDate = (datee: Date) => {
    return datee <= getValues('startDate')
  }

  if (modeForm === ModeForm.create) {
    validations.code = validations.code.required().min(4)
    validations.startDate = validations.startDate.required()
    validations.endDate = validations.endDate.required()
    validations.numberOfSubscriptionDays = validations.numberOfSubscriptionDays.required().min(1)
    validations.statusId = validations.statusId.required()
  }

  const schemaValidations = yup.object().shape(validations)

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    getValues
  } = useForm<CuponForm>({ resolver: yupResolver(schemaValidations), defaultValues: defaultValuesForm })

  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const onSubmit = async (form: CuponForm) => {
    if (modeForm == ModeForm.create) {
      setSubmitLoading(true)
      try {
        await postPromocode({
          vchCode: form.code,
          dtmStartDate: form.startDate.toISOString(),
          dtmEndDate: form.endDate.toISOString(),
          intNumberOfSubscriptionDays: form.numberOfSubscriptionDays,
          intStatusId: form.statusId ? 1 : 0,
          intNumberOfUsesAvailable:form.numberOfUsesAvailable
        })
        toast.success(t('CUPON_CREATED_SUCCESS'), { duration: 4000 })
        getAllPromocodes()
        submitSuccess()
      } catch (error: any) {
        toast.error(error.message, { duration: 4000 })
      } finally {
        setSubmitLoading(false)
      }
    }

    if (modeForm == ModeForm.edit) {
      setSubmitLoading(true)
      try {
        await putPromocode(form.promoCodeId, {
          vchCode: form.code,
          dtmStartDate: form.startDate.toISOString(),
          dtmEndDate: form.endDate.toISOString(),
          intNumberOfSubscriptionDays: form.numberOfSubscriptionDays,
          intStatusId: form.statusId ? 1 : 0,
          intNumberOfUsesAvailable: form.numberOfUsesAvailable
        })
        toast.success(t('CUPON_UPDATED_SUCCESS'), { duration: 4000 })
        getAllPromocodes()
        submitSuccess()
      } catch (error: any) {
        toast.error(error.message, { duration: 4000 })
      } finally {
        setSubmitLoading(false)
      }
    }
  }

  //--------------------------
  const { t } = useTranslation()
  const theme = useTheme()
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const [disabledProceed, setDisabledProceed] = useState<boolean>(false)

  return (
    <>
      <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
        {ModeForm.create !== modeForm && (
          <FormControl fullWidth sx={{ my: 2 }}>
            <Controller
              name='promoCodeId'
              control={control}
              render={({ field: { ...field }, fieldState }) => (
                <TextField
                  disabled
                  variant='outlined'
                  {...field}
                  label={t('ID')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </FormControl>
        )}

        {
          <FormControl fullWidth sx={{ my: 2 }}>
            <Controller
              name='code'
              control={control}
              render={({ field: { ...field }, fieldState }) => (
                <TextField
                  variant='outlined'
                  {...field}
                  label={t('NAME')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </FormControl>
        }

        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            control={control}
            name='startDate'
            render={({ field: { ref, onBlur, name, ...field }, fieldState }) => (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  {...field}
                  inputRef={ref}
                  label={t('START_DATE')}
                  shouldDisableDate={disableStartDate}
                  renderInput={inputProps => (
                    <TextField
                      {...inputProps}
                      onBlur={onBlur}
                      name={name}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </LocalizationProvider>
            )}
          />
        </FormControl>

        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            control={control}
            name='endDate'
            render={({ field: { ref, onBlur, name, ...field }, fieldState }) => (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  {...field}
                  inputRef={ref}
                  label={t('END_DATE')}
                  shouldDisableDate={disableEndDate}
                  renderInput={inputProps => (
                    <TextField
                      {...inputProps}
                      onBlur={onBlur}
                      name={name}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </LocalizationProvider>
            )}
          />
        </FormControl>

        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='numberOfSubscriptionDays'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                inputProps={{ type: 'number' }}
                disabled={ModeForm.show === modeForm}
                {...field}
                label={t('SUBSCRIPTION_DAYS')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>

        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='numberOfUsesAvailable'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                inputProps={{ type: 'number' }}
                disabled={ModeForm.show === modeForm}
                {...field}
                label={t('NUMBER_OF_USES_AVAILABLE')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {valuesDefaultForm && 
                      <Typography>{valuesDefaultForm.numberOfUsesMade}/{valuesDefaultForm.numberOfUsesAvailable}</Typography>
                      }
                      
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </FormControl>

        

        <Grid container alignItems='center'>
          {!lessThanSmall && (
            <Grid item xs={1} sm={1.5}>
              <Avatar sx={{ bgcolor: '#FCA5A5', width: 50, height: 50 }} variant='rounded' />
            </Grid>
          )}

          <Grid item xs={10} sm={8.5}>
            <Box>
              <Typography align='center' fontSize={22}>
                {t('STATUS')}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Controller
              name='statusId'
              control={control}
              render={({ field: { ...field } }) => (
                <Switch defaultChecked={getValues('statusId')} {...field} disabled={ModeForm.show === modeForm} />
              )}
            />
          </Grid>
        </Grid>
        {ModeForm.show !== modeForm && (
          <CustomLoadingBtn
            disabled={disabledProceed}
            size='large'
            variant='contained'
            type='submit'
            style={{ minHeight: 60, width: '100%' }}
            sx={{ mt: { xs: 4, sm: 4 } }}
            loading={submitLoading}
          >
            {t('PROCEED')}
          </CustomLoadingBtn>
        )}
      </form>
    </>
  )
}

export default CuponForm
