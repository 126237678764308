import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import CustomChip from 'src/@core/components/mui/chip'
import { ThemeColor } from 'src/@core/layouts/types'
import { SubscriptionTransaction } from 'src/@http/subscriptionTransaction'
import { DialogGeneric } from 'src/pages/components/generic/DialogGeneric'
import { ModeForm } from 'src/types'

import SubscriptionTransactionForm from '../SubscriptionTransactionForm'

interface Props {
  item: SubscriptionTransaction
}

const SubscriptionTransactionStatus = ({ item }: Props) => {
  const { t } = useTranslation()

  const [show, setShow] = useState<boolean>(false)

  const statusObj: { [key: number]: ThemeColor } = {
    1: 'success',
    0: 'warning',
    2: 'error'
  }

  return (
    <>
      <CustomChip
        skin='light'
        size='small'
        label={item.intStatusId === 1 ? t('COMPLETED') : t('PENDING')}
        color={statusObj[item.intStatusId] || 'success'}
        sx={{ textTransform: 'capitalize' }}
      />
      {item.intStatusId === 0 && <CustomChip
        skin='light'
        size='small'
        label={t('VALIDATE')}
        color={'success'}
        sx={{ textTransform: 'capitalize', ml:2 }}
        onClick={()=>{ if(item.intStatusId===0) setShow(true) }}
      />}
      
      <DialogGeneric
        show={show}
        hideActions={true}
        showIconClose={true}
        closeFunc={() => {
          setShow(false)
        }}
        title={t('SUBSCRIPTION_TRANSACTION_DETAIL')}
        leftAline={true}
      >
        <Box sx={{ pt: 4 }}>
          <SubscriptionTransactionForm
            modeForm={ModeForm.edit}
            valuesDefaultForm={item}
            closeFunc={() => {
              setShow(false)
            }}
          />
        </Box>
      </DialogGeneric>
    </>
  )
}

export default SubscriptionTransactionStatus
