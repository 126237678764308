import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, FormControl, Grid, Switch, TextField, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { SubscriptionTransaction } from 'src/@http/subscriptionTransaction'
import { verifyDirectPayment } from 'src/@http/user'
import CustomBtn, { CustomLoadingBtn } from 'src/layouts/components/shared-component/CustomBtn'
import { ModeForm } from 'src/types'
import adminStore from 'src/zustand/admin'
import * as yup from 'yup'

import CopyText from './column/CopyText'

interface Props {
  modeForm: ModeForm
  valuesDefaultForm: SubscriptionTransaction
  closeFunc: () => any
}

interface SubscriptionTransactionForm {
  intSubscriptionTransactionId: number
  blnValidPayment: boolean
  intTxId: string
  vchOriginWallet: string
  subscriptionName: string
  intUserId: string
}

const SubscriptionTransactionForm = ({ modeForm, closeFunc, valuesDefaultForm }: Props) => {
  let defaultValuesForm: SubscriptionTransactionForm = {
    intSubscriptionTransactionId: 0,
    blnValidPayment: true,
    intTxId: '',
    vchOriginWallet: '',
    subscriptionName: '',
    intUserId: ''
  }

  const { getsubscriptionTransaction } = adminStore()

  if (modeForm === ModeForm.edit && valuesDefaultForm) {
    const subscription = adminStore
      .getState()
      .subscriptions.find(value => value.subscriptionId === valuesDefaultForm.intSubscriptionId)
    defaultValuesForm = {
      ...defaultValuesForm,
      intSubscriptionTransactionId: valuesDefaultForm.intSubscriptionTransactionId,
      vchOriginWallet: valuesDefaultForm.vchOriginWallet || '',
      subscriptionName: `${subscription?.subscriptionName} $${valuesDefaultForm.dcmAmountTransaction}` || '',
      intUserId: `${valuesDefaultForm.user.intUserId}-${valuesDefaultForm.user.vchEmail}`
    }
  }

  const validations = {
    intTxId: yup.string(),
    vchOriginWallet: yup.string(),
    subscriptionName: yup.string(),
    intSubscriptionTransactionId: yup.number().positive(),
    intUserId: yup.string(),
    blnValidPayment: yup.boolean()
  }

  const schemaValidations = yup.object().shape(validations)

  const { t } = useTranslation()
  const theme = useTheme()
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const activateStrategyByUser = adminStore((state ) => state.activateStrategyByUser)
  
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<SubscriptionTransactionForm>({
    resolver: yupResolver(schemaValidations),
    defaultValues: defaultValuesForm
  })

  const success = async () => {
    closeFunc()
    await getsubscriptionTransaction()
  }


  const onSubmit = async (form: SubscriptionTransactionForm) => {
    setIsLoading(true)
    try {
      await verifyDirectPayment({ 
        blnValidPayment: form.blnValidPayment,
        intTxId: form.intTxId,
        intSubscriptionTransactionId: form.intSubscriptionTransactionId
      })
      await activateStrategyByUser(valuesDefaultForm.user.intUserId)
      toast.success(t('SUCCESSFULLY_CONFIRMED'), { duration: 4000 })
      await success()
    } catch (error: any) {
      toast.error(error.messagge, { duration: 4000 })
    }
    setIsLoading(false)
  }

 

  return (
    <>
      <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='subscriptionName'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                disabled={ModeForm.edit === modeForm}
                {...field}
                label={t('SUBSCRIPTION')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>

        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='intUserId'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                disabled={ModeForm.edit === modeForm}
                {...field}
                label={t('USER')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>

        <Box sx={{ display: 'flex', mt:2, mb:2 }}>
          <Typography>
            {t('ORIGIN_WALLET')}:
            <CopyText text={defaultValuesForm.vchOriginWallet}></CopyText>
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', mt:2, mb:2 }}>
          <Typography>
            {t('DESTINATION_WALLET')}:
            <CopyText text={valuesDefaultForm.vchDestinationWallet || ''}></CopyText>
          </Typography>
        </Box>

        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='intTxId'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                {...field}
                label={t('TRANSACTION_HASH')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>

        <Box sx={{ display: 'flex', mt: 2 }}>
          <Typography align='center' fontSize={22}>
            {t('ELIMINATE_TRANSACTION')}
          </Typography>
          <Controller
            name='blnValidPayment'
            control={control}
            render={({ field: { ...field } }) => (
              <Switch defaultChecked={true} {...field} disabled={ModeForm.show === modeForm} />
            )}
          />
          <Typography align='center' fontSize={22}>
            {t('VALIDATE_PAYMENT')}
          </Typography>
        </Box>

        <Grid container>
          <Grid sx={{ p: 1 }} item xs={6}>
            <CustomLoadingBtn
              loading={isLoading}
              fullWidth
              size='large'
              type='submit'
              variant='contained'
              sx={{ mt: 3 }}
            >
              {t('SAVE')}
            </CustomLoadingBtn>
          </Grid>
          <Grid sx={{ p: 1 }} item xs={6}>
            <CustomBtn fullWidth size='large' variant='contained' onClick={() => closeFunc()} sx={{ mt: 3 }}>
              {t('CANCEL')}
            </CustomBtn>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

export default SubscriptionTransactionForm
