import { Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import i18n from 'i18next'
import Icon from 'src/@core/components/icon'
import { putNewsCategory } from 'src/@http/news'
import { Category } from 'src/zustand/news'
import newsStore from 'src/zustand/news'

import ChangeStatus from '../../actions-form/ChangeStatus'

import { RowNewsCategoriesOptions } from './RowNewsCategoriesOptions'

interface CellType {
  row: Category
}

const columns: GridColDef[] = [
  {
    flex: 9,
    field: 'name',
    headerAlign: 'left',
    align: 'left',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('NAME')}</Typography>,
    renderCell: ({ row }: CellType) => {
      const size = 30

      return (
        <>
          {row.name.length > size && (
            <Grid container>
              <Grid item sx={{ display: 'flex', alignItems: 'center' }} xs={10}>
                <Typography color={'white'}>{row.name.slice(0, size) + '...'}</Typography>
              </Grid>
              <Grid item xs={2} sx={{ justifyContent: 'end', display: 'flex', alignItems: 'center' }}>
                <Tooltip disableFocusListener disableTouchListener title={row.name}>
                  <IconButton>
                    <Icon icon='mdi:information-outline' />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          )}
          {row.name.length < size && <Typography color={'white'}>{row.name}</Typography>}
        </>
      )
    }
  },
  {
    flex: 2,
    field: 'statusId',
    headerAlign: 'center',
    align: 'center',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('STATUS')}</Typography>,
    renderCell: ({ row }: CellType) => {
      const change = async (status: boolean) => {
        await putNewsCategory(row.id, {statusId: status?1:0})
        await newsStore.getState().getNewsCategory()
      }

      return <ChangeStatus change={change} status={row.statusId}></ChangeStatus>
    }
  },
  {
    flex: 1,
    minWidth: 90,
    sortable: false,
    field: 'actions',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('ACTIONS')}</Typography>,
    renderCell: ({ row }: CellType) => <RowNewsCategoriesOptions row={row} />
  }
]

export default columns
