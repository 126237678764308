import { createElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Card, CardContent, Grid, IconButton, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import Icon from 'src/@core/components/icon'

interface Props {
  list: any[]
  loading: boolean
  itemCard: any
  itemProps?: any
  increment: number
}

const ItemsScroller = ({ list, loading, itemCard, increment, itemProps }: Props) => {
  const { t } = useTranslation()

  // State for storing list of details
  const [itemsList, setItemsList] = useState<any[]>([])
  const [totalElements, setTotalElements] = useState<number>(10)

  const loadMorePerformaceDetails = async () => {
    if (totalElements + increment >= list.length) {
      setTotalElements(list.length)
    } else {
      setTotalElements(totalElements + increment)
    }
  }

  useEffect(() => {
    const details = list.slice(0, totalElements)
    setItemsList(details)
  }, [totalElements])

  useEffect(() => {
    setItemsList(list.slice(0, increment))
    setTotalElements(increment)
  }, [list])

  return (
    <Card>
      <CardContent sx={{ px: 0 }}>
        <Grid container spacing={3}>
          {list.length !== 0 && (
            <>
              {itemsList.map((item: any, key: number) => (
                <Grid item xs={12} sm={6} key={key}>
                  {createElement(itemCard, { ...itemProps, item })}
                </Grid>
              ))}
              {totalElements !== list.length && (
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <IconButton color='primary' onClick={loadMorePerformaceDetails}>
                      <Icon icon='mdi:plus-circle' fontSize={30} />
                    </IconButton>
                  </Box>
                </Grid>
              )}
            </>
          )}
          {!loading && list.length === 0 && (
            <Grid item xs={12}>
              <Typography textAlign='center'>{t('EMPTY')}</Typography>
            </Grid>
          )}
          {loading && (
            <Grid item xs={12}>
              <CircularProgress />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}

ItemsScroller.defaultProps = {
  loading: false,
  increment: 5,
  itemProps: {}
}

export default ItemsScroller
