import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import styled from '@emotion/styled'
import { Box, Button, ButtonProps, Card, CardContent, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { MuiMarkdown } from 'mui-markdown'
import { FontWeight } from 'src/layouts/TypographyTheme'
import newsStore, { Category, CurrentNews } from 'src/zustand/news'

import { PromotionCarousel } from '../components/home/PromotionCarousel'

function useQuery() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

const MenuBtn = styled(Button)<ButtonProps>(({ theme }) => ({
  color: '#6C7080',
  backgroundColor: '',
  '&:hover': {
    color: 'white',
    backgroundColor: 'primary.main'
  },
  textTransform: 'none',
  fontSize: 16,
  fontWeight: FontWeight.Normal
}))

const NewsPage = () => {
  const { t } = useTranslation()
  

  const [menuSeleted, setMenuSelected] = useState<Category>({
    id:0,
    name:'',
    statusId: 1
  })
  const query = useQuery()
  const id: string | null = query.get('id')
  const {categories, newsInit, getCurrentNews} = newsStore()
  const originalNews = useRef<CurrentNews[]>(newsInit)
  const [news, setNews] = useState<CurrentNews[]>(newsInit)
  const aux = newsInit.find((news: CurrentNews) => news.id === parseInt(id!==null? id: '0'))
  const [selectedNews, setSelectedNews] = useState<CurrentNews | null>(aux ? aux: null)


  useEffect(() => {
    getCurrentNews()
  }, [])
  
  const handlerReset = () => {
    setMenuSelected({
      id: 0,
      name: 'ALL',
      statusId: 1
    })
    setSelectedNews(null)
    setNews(originalNews.current)
  }

  const handlerSelect = (menu: Category) => {

      const newsFilted = originalNews.current.filter((item: CurrentNews) => {
        return item.categories.some(category => category.id === menu.id)
      })
      setSelectedNews(null)
      setMenuSelected(menu)
      setNews(newsFilted)
    
  }

  return (
    <>
      <Grid item xs={12} sx={{ mb: 8 }}>
        <PromotionCarousel></PromotionCarousel>
      </Grid>
      <Grid container spacing={10}>
        <Grid item sx={{}} xs={12} lg={4}>
          <Card>
            <CardContent sx={{ mx: 0 }}>
              <MenuBtn
                onClick={handlerReset}
                fullWidth
                sx={{
                  my: 1,
                  justifyContent: 'flex-start',
                  '&.MuiButton-text': { color: 0 === menuSeleted.id ? 'primary.main' : '' },
                  '&.MuiButton-root': {
                    backgroundColor: 0 === menuSeleted.id ? '' : '',
                    backdropFilter: 0 === menuSeleted.id ? 'saturate(400%)' : ''
                  }
                }}
                size='large'
              >
                {t('ALL')}
              </MenuBtn>
              {categories.filter((category: Category) => category.statusId === 1).map((menu: Category, key: number) => (
                <MenuBtn
                  onClick={() => handlerSelect(menu)}
                  key={key}
                  fullWidth
                  sx={{
                    my: 1,
                    justifyContent: 'flex-start',
                    '&.MuiButton-text': { color: menu.id === menuSeleted.id ? 'primary.main' : '' },
                    '&.MuiButton-root': {
                      backgroundColor: menu.id === menuSeleted.id ? '' : '',
                      backdropFilter: menu.id === menuSeleted.id ? 'saturate(400%)' : ''
                    }
                  }}
                  size='large'
                >
                  {menu.name}
                </MenuBtn>
              ))}
            </CardContent>
          </Card>
        </Grid>
        <Grid item sx={{}} xs={12} lg={8}>
          <Card>
            <CardContent sx={{ mx: 0, mb:2 }}>
              {selectedNews === null &&
                news.map((menu: CurrentNews, key: number) => (
                  <MenuBtn
                    onClick={() => {
                      setSelectedNews(menu)
                    }}
                    key={key}
                    fullWidth
                    sx={{
                      justifyContent: 'flex-start'
                    }}
                    size='large'
                  >
                    <Box sx={{ textAlign: 'left' }}>
                      <Typography variant='h5' sx={{ fontWeight: 'bold' }} color='white'>
                        {menu.title}
                      </Typography>
                      <Typography sx={{ my: 2 }}>{menu.startDate.slice(0,10)}</Typography>
                    </Box>
                  </MenuBtn>
                ))}
              {selectedNews !== null && (
                <>
                  <Box sx={{ textAlign: 'left' }}>
                    <Typography variant='h5' sx={{ fontWeight: 'bold', mb: 2 }} color='white'>
                      {selectedNews.title}
                    </Typography>
                    <Typography sx={{ my: 2 }}>{selectedNews.startDate.slice(0,10)}</Typography>
                  </Box>
                  <MuiMarkdown>{selectedNews.content}</MuiMarkdown>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

export default NewsPage
