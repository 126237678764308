import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Box, ButtonGroup, Card, CardContent, Grid, IconButton } from '@mui/material'
import Icon from 'src/@core/components/icon'
import CustomAvatar from 'src/@core/components/mui/avatar'
import { deleteAccountType } from 'src/@http/commission'
import { DialogGeneric } from 'src/pages/components/generic/DialogGeneric'

interface Props {
  accountType: API.Commission.AccountType
  editFunction: (percents: API.Commission.AccountType) => any
  deleteFunctionSuccess: () => any
}

const AccountTypeItemCard = ({ accountType, editFunction, deleteFunctionSuccess }: Props) => {
  const { t } = useTranslation()
  const [show, setShow] = useState<boolean>(false)

  const deleteItem = async () => {
    try {
      const res = await deleteAccountType(accountType.accountTypeId)
      toast.success(t('ACCOUNT_TYPE_DELETE_MESSAGE_SUCCESS'))
      deleteFunctionSuccess()
    } catch (err: any) {
      toast.error(err)
    }
  }

  const ActionButtons = () => {
    const buttons = [
      {
        icon: 'carbon:edit',
        action: () => {
          editFunction(accountType)
        }
      },
      {
        icon: 'ph:trash-light',
        action: () => {
          setShow(true)
        }
      }
    ]

    return (
      <Grid container sx={{ flexDirection: 'row', justifyContent: 'end' }}>
        <ButtonGroup>
          {buttons.map(button => (
            <Grid item key={button.icon}>
              <IconButton onClick={() => button.action()} aria-label='capture screenshot' color='secondary'>
                <Icon icon={button.icon} fontSize={16} />
              </IconButton>
            </Grid>
          ))}
        </ButtonGroup>
      </Grid>
    )
  }

  return (
    <Card sx={{ backgroundColor: 'background.default' }}>
      <CardContent sx={{ pr: 0 }}>
        <Grid container sx={{ flexDirection: 'row', width: '100%' }}>
          <Grid item xs={2}>
            <CustomAvatar color={accountType.statusId === 1 ? 'warning' : 'secondary'} variant='rounded'>
              <Icon icon={accountType.statusId === 1 ? 'mdi:account-star' : 'akar-icons:stop'} />
            </CustomAvatar>
          </Grid>
          <Grid item sx={{ p: 1, pl: 1, fontSize: 18 }} xs={8}>
            {accountType.accountTypeName}
          </Grid>
          <Grid item xs={2} sx={{ justifyContent: 'end' }}>
            <ActionButtons />
          </Grid>
        </Grid>

        <Box sx={{ display: 'flex', fontSize: 12, mt:2 }}>
          <Box sx={{ mr: 3 }}> {t('COMMISSION_FRECUENCY_ID_DAYS_DEFAULT')}:</Box> <span>{accountType.commissionFrecuencyInDaysDefault}</span>
        </Box>
        
        <DialogGeneric
          show={show}
          title={t('DELETE_ACCOUNT_TYPE')}
          confirmText={t('CONFIRM') || ''}
          confirmFunc={() => deleteItem()}
          closeFunc={() => {
            setShow(false)
          }}
        >
          {t('DELETE_ACCOUNT_TYPE_MESSAGE')}
        </DialogGeneric>
      </CardContent>
    </Card>
  )
}

export default AccountTypeItemCard
