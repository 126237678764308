// ** React Imports
import { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
// ** MUI Imports
import Box from '@mui/material/Box'
import Fab from '@mui/material/Fab'
// ** Icon Imports
import Icon from 'src/@core/components/icon'
import CustomFilterPanel from 'src/views/Filter/CustomFilterPanel'

import MenuButtonsFilter from './MenuButtonsFilter'

// ** Types
export interface IDate {
  dateFrom: Date 
  dateTo: Date 
}

interface Filter {
  text: string
  icon: string
  id: number
}

export interface ButtonFilterProp {
  onData?: (index: { text: string; icon: string; id: number } | null) => void
  children?: ReactNode
  apply: (startDate: Date, endDate: Date) => void
}

// ** Initialization
export const initialDate = {
  dateFrom: new Date(),
  dateTo: new Date()
}

const ButtonsFilter = ({ onData, children, apply }: ButtonFilterProp) => {
  const { t } = useTranslation()

  const filters: Filter[] = [
    {
      text: t('1_WEEK'),
      icon: 'ep:trend-charts',
      id: 1
    },
    {
      text: t('1_MONTH'),
      icon: 'fluent:convert-range-24-regular',
      id: 2
    },
    {
      text: t('1_YEAR'),
      icon: 'healthicons:i-schedule-school-date-time-outline',
      id: 3
    },
    {
      text: t('CUSTOM'),
      icon: 'bx:customize',
      id: 4
    }
  ]

  const [filterState, setFilterState] = useState<Filter>(filters[0])
  const [showCustom, setShowCustom] = useState<boolean>(false)
  const [dateCustom, setDateCustom] = useState<IDate>({ dateFrom: new Date(), dateTo: new Date() })

  useEffect(() => {
    const now = new Date()
    const lastWeek = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000)
    apply(lastWeek, new Date())
  }, [])

  const handleFilter = (item: Filter) => {
    setFilterState(item)

    // onData(item)

    switch (item.id) {
      case 1:
        const now = new Date()
        const lastWeek = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000)
        apply(lastWeek, new Date())
        setShowCustom(false)
        break
      case 2:
        const lastMonth = new Date()
        lastMonth.setMonth(lastMonth.getMonth() - 1)
        apply(lastMonth, new Date())
        setShowCustom(false)
        break
      case 3:
        const lastYear = new Date()
        lastYear.setFullYear(lastYear.getFullYear() - 1)
        apply(lastYear, new Date())
        setShowCustom(false)
        break
      case 4:
        setShowCustom(true)
        break
    }
  }

  const handleFilterDate = (date: IDate) => {
    setDateCustom(date)
  }

  const handleFilterCustom = () => {
    if (dateCustom.dateFrom && dateCustom.dateTo) apply(dateCustom.dateFrom, dateCustom.dateTo)
    setShowCustom(false)
  }

  const listFilter = filters.map((filter: any) => (
    <Fab
      key={filter.text}
      onClick={() => handleFilter(filter)}
      color={filter.id === filterState.id ? 'primary' : 'secondary'}
      aria-label='edit'
      variant='extended'
      sx={{ '& svg': { mr: 1 } }}
      style={{ width: 'max-content' }}
    >
      <Icon icon={filter.icon} />
      {filter.text}
    </Fab>
  ))

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
          justifyContent: 'space-between'
        }}
      >
        {children}
        <Box
          sx={{
            display: {
              xs: 'flex',
              sm: 'flex',
              md: 'flex',
              lg: 'none'
            },
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <MenuButtonsFilter list={filters} onData={handleFilter} />
        </Box>
        <Box
          sx={{
            // m: 6,
            display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex' },
            // maxWidth: '65%',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          className='demo-space-x'
        >
          {listFilter}
        </Box>
      </Box>
      {showCustom && (
        <CustomFilterPanel sx={{ mt: 1 }} handleDates={handleFilterDate} handleConfirm={handleFilterCustom} />
      )}
    </>
  )
}

export default ButtonsFilter
