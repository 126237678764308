import { useContext, useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Avatar, Box, Button, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material'
import Icon from 'src/@core/components/icon'
import { GridBot, stopAllByTraider } from 'src/@http/gridbot'
import CustomBtn from 'src/layouts/components/shared-component/CustomBtn'
import { DialogGeneric } from 'src/pages/components/generic/DialogGeneric'
import { ModeForm } from 'src/types'
import { Trader } from 'src/types/models/traderModel'
import adminStore from 'src/zustand/admin'

import { AdminContext } from '../../adminContext'

import GridBotForm from './GridBotForm'
// import { logger } from 'src/utils/Logger'
import GridBotItemCard from './GridBotItemCard'
// import CustomBtn /*, { ActiveBtn }*/ from 'src/layouts/components/shared-component/CustomBtn'

const GridBotManager = () => {
  const [showCuponRegisterDialog, setShowCuponRegisterDialog] = useState<boolean>(false)
  const [itemSelected, setItemSelected] = useState<GridBot | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [showStopAllwithOperations, setShowStopAllwithOperations] = useState<boolean>(false)
  const [showStopAllwithoutOperations, setShowStopAllwithoutOperations] = useState<boolean>(false)
  const { t } = useTranslation()

  const { params, setPath } = useContext(AdminContext)

  const { trader } = params as { trader: Trader }

  const openDialogEdit = (promocode: GridBot) => {
    setItemSelected(promocode)
    setShowCuponRegisterDialog(true)
  }

  const openDialogCreate = () => {
    setItemSelected(null)
    setShowCuponRegisterDialog(true)
  }

  const { getAllByTraderGridbots, gridBots, resetGridbots, getBybitSymbols, bybitSymbols } = adminStore()

  useEffect(() => {
    updateGridBot(true)
  }, [])

  const updateGridBot = async (refresh: boolean) => {
    if (refresh) setLoading(true)
    if ((gridBots.length !== 0 && gridBots[0].intTraderId !== trader.traderId) || refresh) resetGridbots()
    await getAllByTraderGridbots(trader.traderId)
    if (refresh) setLoading(false)
  }

  const someStatus = useMemo(() => {
    return gridBots.some((value: GridBot) => value.intStatusId === 1)
  }, [gridBots])

  const someActive = useMemo(() => {
    return gridBots.some((value: GridBot) => value.blnActiveOperations)
  }, [gridBots])

  const stopGridBotAllByTraider = async (stopOperations: boolean) => {
    setLoading(true)
    resetGridbots()
    try {
      await stopAllByTraider({
        intTraderId: trader.traderId,
        blnStopOperations: stopOperations
      })
      await getAllByTraderGridbots(trader.traderId)
      toast.success(stopOperations ? t('ALL_OPERATIONS_WAS_STOPPED_SUCCESSFULLY') : t('ALL_BOTS_STOPPED_SUCCESSFULLY'))
    } catch (err: any) {
      toast.error(err.message)
    }

    setLoading(false)
  }

  useEffect(() => {
    if (bybitSymbols.length === 0) getBybitSymbols()
  }, [])

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar sx={{ bgcolor: 'primary.main', width: 40, height: 40 }} variant='rounded'>
              <Icon icon='mdi:robot-outline' style={{ fontSize: 24 }} color='white' />
            </Avatar>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                sx={{ ml: 4, cursor: 'pointer' }}
                color='white'
                variant='h5'
                component='div'
                onClick={() => {
                  setPath('trader-manager')
                }}
              >
                {trader.traderName}
              </Typography>
              <Icon icon='mdi:chevron-right' />

              <Typography sx={{}} color='white' variant='h5' component='div'>
                {t('GRIDBOT')}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignContent: 'center' }}>
            <Button onClick={() => openDialogCreate()} startIcon={<Icon icon='mdi:plus-box-outline' color='#9295A6' />}>
              <Typography color='#9295A6' sx={{ textTransform: 'capitalize' }}>
                {t('ADD')}
              </Typography>
            </Button>
          </Box>
        </Box>
        {gridBots.length !== 0 && (
          <>
            <Grid sx={{ mt: 3, display: 'flex', justifyContent: 'center' }} container>
              <Grid item xs={5}>
                <CustomBtn
                  onClick={() => setShowStopAllwithoutOperations(true)}
                  color='primary'
                  variant='contained'
                  disabled={!someStatus}
                  startIcon={<Icon icon='mdi:robot-off-outline' />}
                  sx={{ mr: 1 }}
                >
                  <Typography color='white' sx={{ fontWeight: 600 }}>
                    {t('STOP_ALL_GRIDBOTS_WITHOUT_CLOSING_OPERATIONS')}
                  </Typography>
                </CustomBtn>
              </Grid>
              <Grid item xs={5}>
                <CustomBtn
                  onClick={() => setShowStopAllwithOperations(true)}
                  disabled={!someActive}
                  variant='contained'
                  color='error'
                  startIcon={<Icon icon='mdi:robot-off' />}
                  sx={{ ml: 1 }}
                >
                  <Typography color='white' sx={{ fontWeight: 600 }}>
                    {t('STOP_ALL_GRIDBOTS_WITH_CLOSING_OPERATIONS')}
                  </Typography>
                </CustomBtn>
              </Grid>
            </Grid>
          </>
        )}
        {loading && (
          <Grid sx={{ mt: 6, display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Grid>
        )}
        {gridBots.length !== 0 && !loading && (
          <Grid sx={{ mt: 3 }} container>
            {gridBots.map((gridbot: GridBot, key: number) => (
              <Grid key={key} item sx={{ p: 1 }} xs={12} sm={6} lg={4}>
                <GridBotItemCard
                  item={gridbot}
                  setGlobalLoading={(value: boolean) => {
                    setLoading(value)
                  }}
                  editFunction={() => openDialogEdit(gridbot)}
                  deleteFunctionSuccess={() => updateGridBot(true)}
                  updateFunction={() => updateGridBot(false)}
                />
              </Grid>
            ))}
          </Grid>
        )}

        {gridBots.length === 0 && !loading && (
          <Typography align='center' sx={{ mt: 4 }}>
            {t('GRIDBOT_NOT_FOUND')}
          </Typography>
        )}
      </CardContent>
      <DialogGeneric
        show={showCuponRegisterDialog}
        closeFunc={() => {
          setShowCuponRegisterDialog(false)
        }}
        hideActions={true}
        title={itemSelected ? t('EDIT_GRIDBOT') : t('CREATE_GRIDBOT')}
        leftAline={true}
        showIconClose={true}
      >
        <Box sx={{ mt: 4 }}>
          <GridBotForm
            closeFunc={() => setShowCuponRegisterDialog(false)}
            traderId={trader.traderId}
            modeForm={itemSelected ? ModeForm.edit : ModeForm.create}
            {...(itemSelected ? { valuesDefaultForm: itemSelected } : {})}
          ></GridBotForm>
        </Box>
      </DialogGeneric>
      <DialogGeneric
        show={showStopAllwithoutOperations}
        title={t('STOP_GRIDBOT')}
        confirmText={t('CONFIRM') || ''}
        confirmFunc={() => stopGridBotAllByTraider(false)}
        closeFunc={() => {
          setShowStopAllwithoutOperations(false)
        }}
      >
        {t('STOP_ALL_GRIDBOTS_WITHOUT_CLOSING_OPERATIONS')}
      </DialogGeneric>
      <DialogGeneric
        show={showStopAllwithOperations}
        title={t('STOP_GRIDBOT')}
        confirmText={t('CONFIRM') || ''}
        confirmFunc={() => stopGridBotAllByTraider(true)}
        closeFunc={() => {
          setShowStopAllwithOperations(false)
        }}
      >
        {t('STOP_ALL_GRIDBOTS_WITH_CLOSING_LIMIT_OPERATIONS')}
      </DialogGeneric>
    </Card>
  )
}

export default GridBotManager
