import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar, Box, Button, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material'
import Icon from 'src/@core/components/icon'
import { getManualTraders,ManualTrader } from 'src/@http/manual-trader'
import { DialogGeneric } from 'src/pages/components/generic/DialogGeneric'
import { ModeForm } from 'src/types'
import { Trader } from 'src/types/models/traderModel'
import adminStore from 'src/zustand/admin'

import { AdminContext } from '../../adminContext'

import ManualTraderForm from './ManualTraderForm'
import ManualTraderItemCard from './ManualTraderItemCard'



const ManualTraderManager = () => {
  const [showCuponRegisterDialog, setShowCuponRegisterDialog] = useState<boolean>(false)
  const [itemSelected, setItemSelected] = useState<ManualTrader|null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [manualTraders, setManualTraders] = useState<ManualTrader[]>([])
  
  
  const openDialogCreate = () => {
    setItemSelected(null)
    setShowCuponRegisterDialog(true)
  }

  const openDialogEdit = (manualTrader: ManualTrader) => {
    setItemSelected(manualTrader)
    setShowCuponRegisterDialog(true)
  }

  const { t } = useTranslation()

    // Context data
  const { params, setPath } = useContext(AdminContext)
  const { trader } = params as { trader: Trader }

 

  const { getAllByTraderGridbots, gridBots, resetGridbots, getBybitSymbols, bybitSymbols } = adminStore()

  useEffect(() => {
    if (bybitSymbols.length === 0) getBybitSymbols()
  }, [])
  
  const updateManualTraders = async() => {
    setLoading(true)
    try {
      const response = await getManualTraders()
      const filter = response.filter(value => value.intTraderId === trader.traderId)
      setManualTraders(filter)
    } catch (error) {
      
    }
    setLoading(false)
  }

  useEffect(()=> {
    updateManualTraders()
  },[])




 
  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar sx={{ bgcolor: 'primary.main', width: 40, height: 40 }} variant='rounded'>
              <Icon icon='mdi:gesture-tap' style={{ fontSize: 24 }} color='white' />
            </Avatar>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                sx={{ ml: 4, cursor: 'pointer' }}
                color='white'
                variant='h5'
                component='div'
                onClick={() => {
                  setPath('trader-manager')
                }}
              >
                {trader.traderName}
              </Typography>
              <Icon icon='mdi:chevron-right' />

              <Typography sx={{}} color='white' variant='h5' component='div'>
                {t('MANUAL_TRADER')}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignContent: 'center' }}>
            <Button onClick={() => {openDialogCreate() }} startIcon={<Icon icon='mdi:plus-box-outline' color='#9295A6' />}>
              <Typography color='#9295A6' sx={{ textTransform: 'capitalize' }}>
                {t('ADD')}
              </Typography>
            </Button>
          </Box>
        </Box>
        
        {loading && (
          <Grid sx={{ mt: 6, display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Grid>
        )}
        {manualTraders.length !== 0 && !loading && (
          <Grid sx={{ mt: 3 }} container>
            {manualTraders.map((config: ManualTrader, key: number) => (
              <Grid key={key} item sx={{ p: 1 }} xs={12} sm={6} lg={4}>
                <ManualTraderItemCard
                  item={config}
                  setGlobalLoading={(value: boolean) => {
                    setLoading(value)
                  }}
                  editFunction={() => openDialogEdit(config)}
                  deleteFunctionSuccess={() => updateManualTraders()}
                  updateFunction={() => updateManualTraders()}
                />
              </Grid>
            ))}
          </Grid>
        )}

        {manualTraders.length === 0 && !loading && (
          <Typography align='center' sx={{ mt: 4 }}>
            {t('MANUAL_TRADER_NOT_FOUND')}
          </Typography>
        )}
      </CardContent>
      <DialogGeneric
        show={showCuponRegisterDialog}
        closeFunc={() => {
          setShowCuponRegisterDialog(false)
        }}
        hideActions={true}
        title={itemSelected ? t('EDIT_ORDER') : t('CREATE_ORDER')}
        leftAline={true}
        showIconClose={true}
      >
        <Box sx={{ mt: 4 }}>
        <ManualTraderForm
            closeFunc={() => setShowCuponRegisterDialog(false)}
            traderId={trader.traderId}
            updateFunction={() => {updateManualTraders()}}
            modeForm={itemSelected ? ModeForm.edit : ModeForm.create}
            {...(itemSelected ? { valuesDefaultForm: itemSelected } : {})}
          ></ManualTraderForm>
        </Box>
      </DialogGeneric>
    </Card>
  )
}

export default ManualTraderManager