import { useTranslation } from "react-i18next"
import { Avatar, Box, Card, CardContent, Typography } from "@mui/material"
import Icon from 'src/@core/components/icon'
import GenericTab, { Tab } from "src/layouts/components/GenericTab"

import CuponManager from "./admin-cupon/CuponManager"
import NewsManager from "./admin-news/NewsManager"
import NewsCategoriesManager from "./admin-news-categories/NewsCategoriesManager"


const MarketingManager = () => {

    const { t } = useTranslation()

    const tabs: Tab[] = [
      {
        index: 0,
        label: t('CUPON'),
        component: <CuponManager isContent />,
        icon: 'mdi:ticket'
      },
      {
        index: 1,
        label: t('NEWS'),
        component: <NewsManager isContent />,
        icon: 'mdi:newspaper-variant-multiple-outline'
      },
      {
        index: 2,
        label: t('NEWS_CATEGORIES'),
        component: <NewsCategoriesManager isContent />,
        icon: 'mdi:format-list-bulleted-type'
      }
    ]


    return <Card>
    <CardContent sx={{}}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center',  flexWrap:'wrap' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar sx={{ width: 40, height: 40, bgcolor: 'primary.main' }} color='error' variant='rounded'>
            <Icon icon='mdi:newspaper-variant' style={{ fontSize: 24 }} color='white' />
          </Avatar>
          <Typography sx={{ ml: 2 }} variant='h5'>
            { t('COMMUNICATION_AND_MARKETING')}
          </Typography>
        </Box>
      </Box>
      <GenericTab tabs={tabs}></GenericTab>
    </CardContent>
  </Card>
}


export default MarketingManager