import { useTranslation } from 'react-i18next'
import { Avatar, Box, Button, Card, CardContent, Switch, Typography } from '@mui/material'
import Icon from 'src/@core/components/icon'

// import CustomBtn, { ActiveBtn } from 'src/layouts/components/shared-component/CustomBtn'

interface Notification {
  id: number
  label: string
  subtitle: string
  icon: string
  status: boolean
}

const NotificationPreference = () => {
  const { t } = useTranslation()

  const list: Notification[] = [
    {
      id: 1,
      label: 'Price Alerts',
      subtitle: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      icon: 'mdi:currency-usd',
      status: false
    },
    {
      id: 2,
      label: 'Referral Commission Alerts',
      subtitle: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      icon: 'mdi:account-plus',
      status: false
    },
    {
      id: 3,
      label: 'Device Login Alerts',
      subtitle: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      icon: 'mdi:tablet-cellphone',
      status: false
    },
    {
      id: 4,
      label: 'Email Notification',
      subtitle: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      icon: 'mdi:email-alert-outline',
      status: false
    }
  ]

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 6, flexWrap: 'wrap' }}>
          <Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
            <Avatar sx={{ bgcolor: 'primary.main', width: 40, height: 40 }} variant='rounded'>
              <Icon icon='mdi:bell-outline' style={{ fontSize: 24 }} color='white' />
            </Avatar>
            <Typography sx={{ m: 3 }} variant='h5' component='div'>
              {t('NOTIFICATION_PREFERENCES')}
            </Typography>
          </Box>
          <Button
            variant='outlined'
            startIcon={<Icon icon='mdi:pencil-outline' />}
            sx={{
              color: '#9295A6',
              borderColor: '#9295A6',
              textTransform: 'none',
              '&:hover': {
                borderColor: '#9295A6'
              }
            }}
          >
            {t('EDIT')}
          </Button>
        </Box>
        {list.map((item: Notification, key: number) => (
          <Box
            key={key}
            sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: { xs: 'center', md: 'space-between' }, py: 3 }}
          >
            <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: { xs: 'center', md: 'space-between' } }}>
              <Avatar sx={{ width: 40, height: 40 }} variant='rounded'>
                <Icon icon={item.icon} color='#9295A6' />
              </Avatar>
              <Box sx={{ px: 4 }}>
                <Typography
                  style={{ fontSize: 16 }}
                  sx={{
                    textAlign: {
                      xs: 'center',
                      sm: 'left'
                    },
                    py: {
                      xs: 2,
                      sm: 0
                    }
                  }}
                >
                  {item.label}
                </Typography>
                <Typography
                  style={{ fontSize: 12 }}
                  sx={{
                    textAlign: {
                      xs: 'center',
                      sm: 'left'
                    },
                    pb: {
                      xs: 2,
                      sm: 0
                    }
                  }}
                  color='#9295A6'
                >
                  {item.subtitle}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                mt: {
                  xs: 2,
                  sm: 0
                }
              }}
            >
              <Switch defaultChecked={item.status} />
            </Box>
          </Box>
        ))}
      </CardContent>
    </Card>
  )
}

export default NotificationPreference
