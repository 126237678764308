import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormControl, Grid, TextField } from '@mui/material'
import { GridBotRSI, postGribotRSI, putGribotRSI } from 'src/@http/gridbot-rsi'
import CustomBtn, { CustomLoadingBtn } from 'src/layouts/components/shared-component/CustomBtn'
import { ModeForm } from 'src/types'
import * as yup from 'yup'

interface Props {
  modeForm: ModeForm
  submitLoading?: boolean
  valuesDefaultForm?: GridBotRSI | null
  closeFunc: () => any
  intGridBotId: number
  refreshList: () => void
}

interface GridBotRSIForm {
  intGridBotRSIId: number
  RSIValue: number
  RSIPercentageIncrement: number
  intGridBotId: number
}

const GridBotRSIForm = ({ modeForm, closeFunc, valuesDefaultForm, intGridBotId, refreshList }: Props) => {
  const { t } = useTranslation()

  let defaultValuesForm: GridBotRSIForm = {
    intGridBotRSIId: 0,
    RSIValue: 30,
    RSIPercentageIncrement: 0.1,
    intGridBotId: intGridBotId
  }

  if (modeForm === ModeForm.edit && valuesDefaultForm) {
    defaultValuesForm = {
      ...valuesDefaultForm,
      RSIPercentageIncrement: parseFloat(valuesDefaultForm.RSIPercentageIncrement)
    }
  }

  const validations = {
    RSIValue: yup.number().min(0).max(100),
    RSIPercentageIncrement: yup.number().min(0).max(2)
  }

  validations.RSIValue = validations.RSIValue.required()
  validations.RSIPercentageIncrement = validations.RSIPercentageIncrement.required()

  const schemaValidations = yup.object().shape(validations)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {
    control,
    handleSubmit,
    formState,
  } = useForm<GridBotRSIForm>({ resolver: yupResolver(schemaValidations), defaultValues: defaultValuesForm })

  const onSubmit = async (form: GridBotRSIForm) => {
    setIsLoading(true)
    if (modeForm === ModeForm.create) {
      try {
        await postGribotRSI({
          intGridBotId,
          RSIPercentageIncrement: form.RSIPercentageIncrement.toString(),
          RSIValue: form.RSIValue
        })
        toast.success(t('CREATE_SUCCESS'), { duration: 4000 })
        closeFunc()
        refreshList()
      } catch (error: any) {
        toast.error(error.message, { duration: 4000 })
      }
    } else {
      try {
        await putGribotRSI({
          RSIPercentageIncrement: form.RSIPercentageIncrement.toString(),
          RSIValue: form.RSIValue,
          intGridBotRSIId: form.intGridBotRSIId
        })
        toast.success(t('UPDATE_SUCCESS'), { duration: 4000 })
        closeFunc()
        refreshList()
      } catch (error: any) {
        toast.error(error.message, { duration: 4000 })
      }
    }
    setIsLoading(false)
  }

  return (
    <>
      <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='RSIValue'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                disabled={ModeForm.show === modeForm}
                {...field}
                label={t('VALUE')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>

        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='RSIPercentageIncrement'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                disabled={ModeForm.show === modeForm}
                {...field}
                label={t('PERCENTAGE_INCREMENT')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>

        <Grid container>
          <Grid sx={{ p: 1 }} item xs={6}>
            <CustomLoadingBtn
              loading={isLoading}
              fullWidth
              size='large'
              type='submit'
              variant='contained'
              sx={{ mt: 3 }}
              disabled={ModeForm.edit === modeForm? !formState.isDirty: false}
            >
              {valuesDefaultForm ? t('EDIT') : t('SAVE')}
            </CustomLoadingBtn>
          </Grid>
          <Grid sx={{ p: 1 }} item xs={6}>
            <CustomBtn fullWidth size='large' variant='contained' onClick={() => closeFunc()} sx={{ mt: 3 }}>
              {t('CANCEL')}
            </CustomBtn>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

GridBotRSIForm.defaultProps = {
  editForm: ModeForm.create
}

export default GridBotRSIForm
