import { Typography } from '@mui/material'
import Icon from 'src/@core/components/icon'

interface Props {
  percentage: number
}

const Percentage = ({ percentage }: Props) => {
  let percentageText = ''
  try {
    percentageText = Number(percentage).toFixed(2)
  } finally {
  }

return (
    <>
      {Number(percentage) === 0 && (
        <>
          <Typography sx={{ pl: 3 }} fontSize={14}>
            {percentageText}%
          </Typography>
          <Icon icon='mdi:trending-neutral' />
        </>
      )}
      {Number(percentage) > 0 && (
        <>
          <Typography sx={{ pl: 3 }} color='#03A66D' fontSize={14}>
            +{percentageText}%
          </Typography>
          <Icon icon='mdi:trending-up' color='#03A66D' />
        </>
      )}
      {Number(percentage) < 0 && (
        <>
          <Typography sx={{ pl: 3 }} color='#DC2626' fontSize={14}>
            {percentageText}%
          </Typography>
          <Icon icon='mdi:trending-down' color='#DC2626' />
        </>
      )}
    </>
  )
}

export default Percentage
