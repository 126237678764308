import jwtDecode from 'jwt-decode'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import { axiosInstance } from 'src/@http'
import { getPlatforms,Platform } from 'src/@http/platform'
import { changeStrategyById, getProfitDetail, getUserDetailById, InfoReferredUser } from 'src/@http/user'
import { StrategyModel } from 'src/types/models/strategyModel'
import { User } from 'src/types/models/userModels'
import { logger } from 'src/utils/Logger'
import adminStore from 'src/zustand/admin'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

let write = 0

interface Dictionary {
  [Key: string]: Platform
}
export interface UserPlatform {
  platformId: number
  apiKey: string
  apiSecret: string
  passPhrase: string
  status: number
}

export interface WalletBalance {
  coin: string
  totalBalance: number
  totalPerpUPL: number
}

export interface Token {
  id: number
  email: string
  roles: Array<string>
}

export interface StrategyProfile extends StrategyModel{
  isActive: boolean
  profitDemo: API.User.ProfitDemoResponse | undefined
}
interface State {
  token: string
  userDetail: User
  performanceDetail: Store.PerformanceDetail
  userPlatform: UserPlatform
  showPaymentFlowDialog: boolean
  activeStrategy: number
  platforms: Platform[]
  isLogged: boolean
  walletBalance: WalletBalance
  platformsDic: { [Key: number]:  Platform},
  strategiesProfile: StrategyProfile[],
  infoReferredUser: InfoReferredUser
}

interface Actions {
  setToken: (token: string) => void
  logout: () => void
  setIsLogged: () => void
  resetProfileStore: () => void
  setperformanceDetail: (userId: number, startDate: Date, endDate: Date, title: string, id: number) => void
  setperformanceDetailInit: (userId: number, startDate: Date, endDate: Date, title: string) => void
  getUserDetail: (id: string) => void
  setUserPlatform: (userPlatform: UserPlatform) => void
  setShowPaymentFlowDialog: (show: boolean) => void
  setActiveStrategy: (status: number) => void
  activateStrategy: (id: number) => Promise<User>
  setWalletBalance: (walletBalance: WalletBalance) => void
  getPlatform: (id: number) => Platform | null
  setPlatforms: () => Promise<Platform[]>
  isAdmin: () => boolean
  setStrategiesProfile: (strategiesProfile :StrategyProfile[]) => void
  setInfoReferredUser: (infoReferredUser: InfoReferredUser) => void
  setUserDetail: (userDetail: User) => void
}

const initialState: State = {
  token: '',
  userDetail: {
    activeSubscription: false,
    currentStrategyId: 0,
    currentStrategyIsActive: false,
    email: '',
    endDateSubscription: '',
    firstName: '',
    lastName: '',
    status: 0,
    userId: '',
    subscriptionActivedDate: '',
    subscriptionId: 0,
    subscriptionName: '',
    vchUrgentNote: '',
    blnPendingSubscription: false,
    platformId: null,
    blnPendingSubscriptionCommissionWithdrawal: false
  },
  performanceDetail: {
    data: [],
    loading: false,
    title: '',
    id: 1
  },
  userPlatform: {
    platformId: 0,
    apiKey: '',
    apiSecret: '',
    passPhrase: '',
    status: 0
  },
  platforms: [],
  platformsDic: {},
  showPaymentFlowDialog: false,
  activeStrategy: 0,
  isLogged: false,
  walletBalance: {
    coin: 'USDT',
    totalBalance: 0,
    totalPerpUPL: 0
  },
  strategiesProfile: [],
  infoReferredUser: {
    numberDirectReferrals: 0,
    totalReferrals: 0,
    amountSubscriptionCommission: 0,
    totalSixMonths: 0,
    totalTwelveMonths: 0,
    totalNoSubscription: 0,
    totalSubscription: 0,
    amountTotalCommission: 0
  }
}

const isLaterDate = (userDetail: User) => {
  if (!userDetail.endDateSubscription) return false
  const actualDate = new Date()
  const endDate = new Date(userDetail.endDateSubscription)

  return endDate < actualDate
}

const profileStore = create<State & Actions>()(
  persist(
    (set, get) => ({
      ...initialState,
      setToken: async (token: string) => {
        window.localStorage.setItem('token', token)
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`
        const tokenDetail: Token = jwtDecode(token)
        await get().getUserDetail(tokenDetail.id.toString())
        set({ token })
      },
      isAdmin: () => {
        const tokenDetail: Token = jwtDecode(get().token)

        return tokenDetail.roles.includes('ADMIN')
      },
      logout: async () => {
        adminStore.getState().resetAdminStore()
        get().resetProfileStore()
        window.localStorage.removeItem('token')
        delete axiosInstance.defaults.headers.common['Authorization']
      },
      setStrategiesProfile: (strategiesProfile :StrategyProfile[]) => {
        set({ strategiesProfile })
      },
      setIsLogged: () => {
        set({ isLogged: true })
      },
      setWalletBalance: (walletBalance: WalletBalance) => {
        set({ walletBalance })
      },
      setInfoReferredUser: (infoReferredUser: InfoReferredUser) => {
        set({infoReferredUser})
      },
      setPlatforms: async () => {
        const platforms = await getPlatforms()
        const platformsDic: { [Key: number]: Platform } = {}
        platforms.forEach(value => (platformsDic[value.platformId] = value))

        set({ platforms, platformsDic })

        return platforms
      },
      getPlatform: (id: number) => {
        const platform = get().platforms.find((value: Platform) => value.platformId === id)

        return platform === undefined ? null : platform
      },
      resetProfileStore: () => {
        set(initialState)
      },
      getUserDetail: async (id: string) => {
        if (id !== '') {
          console.log('[Change] UserDetail ID:', id)
          const userDetail = await getUserDetailById(id)
          if(userDetail.currentStrategyId && userDetail.currentStrategyId !== 0){
            get().setActiveStrategy(userDetail.currentStrategyId)
          }
           
          set({ userDetail })
          logger.log('[Change] UserDetail:', userDetail)
        }
      },
      setUserDetail: (userDetail: User) => {
        if(userDetail.currentStrategyId && userDetail.currentStrategyId !== 0){
          get().setActiveStrategy(userDetail.currentStrategyId)
        }
        set({userDetail})
      },
      setUserPlatform: (userPlatform: UserPlatform) => {
        set({ userPlatform })
      },
      setShowPaymentFlowDialog(show: boolean) {
        set({ showPaymentFlowDialog: show })
      },
      setActiveStrategy(id: number) {
        set({ activeStrategy: id })
      },
      activateStrategy: async (strategyId: number) => {
        if (
          (get().userDetail.activeSubscription || isLaterDate(get().userDetail)) &&
          get().userPlatform.platformId !== 0
        ) {
          try {
            const sleep = (ms: number) => new Promise(r => setTimeout(r, ms))
            logger.log('Activating strategy....')
            const userId = `${get().userDetail.userId}`
            await changeStrategyById(userId, strategyId)
            await sleep(1000)
            await get().getUserDetail(userId)
            logger.log('[v] Success Activating strategy')
          } catch (error) {
            logger.error('Error:', error)
          }
        } else {
          logger.log('Platforms user id | No active Subscription | isLaterDate')
          console.log(get().userPlatform.platformId, get().userDetail.activeSubscription, isLaterDate(get().userDetail))
          get().setShowPaymentFlowDialog(true)
          get().setActiveStrategy(strategyId)
        }

        return get().userDetail
      },
      setperformanceDetailInit: async (userId: number, startDate: Date, endDate: Date, title: string) => {
        if (write === 0) {
          write = 1
          const data = await getProfitDetail(
            userId.toString(),
            startDate.toISOString().slice(0, -5),
            endDate.toISOString().slice(0, -5)
          )
          set({
            performanceDetail: {
              loading: false,
              data: data,
              title,
              id: 1
            }
          })
        }
      },
      setperformanceDetail: async (userId: number, startDate: Date, endDate: Date, title: string, id: number) => {
        set({
          performanceDetail: {
            loading: true,
            data: [],
            title,
            id
          }
        })
        try {
          set({
            performanceDetail: {
              loading: false,
              data: await getProfitDetail(
                userId.toString(),
                startDate.toISOString().slice(0, -5),
                endDate.toISOString().slice(0, -5)
              ),
              title,
              id
            }
          })
        } catch {
          set({
            performanceDetail: {
              loading: false,
              data: [],
              title: '',
              id
            }
          })
        }
      }
    }),
    {
      name: 'profile-store'
    }
  )
)

if (process.env.NODE_ENV === 'development') mountStoreDevtool('profile-store', profileStore)

export default profileStore

export interface ProfileStore extends State, Actions {}
