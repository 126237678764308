import { Avatar, Box, Card, CardContent, Typography, useTheme } from '@mui/material'

interface Props {
  title: string
  value: number
  imageUrl: string,
  showGradient: boolean
}

const RefDefaultCard = ({ title, value, imageUrl, showGradient }: Props) => {

  const theme: any = useTheme()
    const cardStyle: any = {}
    if (showGradient) {
        cardStyle.background = `linear-gradient(to left bottom, ${theme.palette.primary.main} 1%, rgba(170, 123, 3, 0.15) 60%)`

    }

  return (
    <Card sx={{...cardStyle, minHeight:300, display:'flex', alignItems:'center', justifyContent:'center'}}>
      <CardContent>
        <Typography fontWeight={600} align='center' sx={{minHeight: 72}} color='white' >{title}</Typography>
        <Typography align='center' fontWeight={600} fontSize={30} sx={{mt:1, mb:6}} color='white'>{value}</Typography>
        <Box sx={{display:'flex', justifyContent:'center'}}>
        <Avatar
            src={imageUrl}
            sx={{
              width: 60,
              height: 60
            }}
            variant='square'
          />
        </Box>
        
      </CardContent>
    </Card>
  )
}

RefDefaultCard.defaultProps = {
    showGradient: false
}

export default RefDefaultCard
