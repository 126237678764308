import { useEffect, useMemo, useState } from 'react'
// import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { Avatar, Box, Card, CardContent, Divider, Grid, IconButton, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import jwtDecode from 'jwt-decode'
import Icon from 'src/@core/components/icon'
import { Settings } from 'src/@core/context/settingsContext'
import CurrencyDropdown from 'src/layouts/components/shared-component/CurrencyDropdown'
import CustomLanguageDropdown from 'src/layouts/components/shared-component/CustomLanguageDropdown'
import LoginDialog from 'src/layouts/components/shared-component/LoginDialog'
import { FontWeight } from 'src/layouts/TypographyTheme'
import { Platform } from 'src/types/models/platformModel'
import appStore, { AppStore } from 'src/zustand/app'
import profileStore, { ProfileStore } from 'src/zustand/profile'

import CustomBtn from '../shared-component/CustomBtn'
import UserDropdown from '../shared-component/UserDropdown'
import WalletVisualization from '../shared-component/WalletVisualization'

interface Props {
  hidden: boolean
  settings: Settings
  toggleNavVisibility: () => void
  saveSettings: (values: Settings) => void
}

interface Route {
  label: string
  path: string
  public: boolean
  admin: boolean
}

interface Token {
  id: number
  email: string
  roles: Array<string>
}

const AppBarContent = (props: Props) => {
  // ** Props
  const { hidden, settings, saveSettings, toggleNavVisibility } = props
  const { t } = useTranslation()

  const routes: Route[] = [
    {
      label: 'Dashboard',
      path: '/',
      public: true,
      admin: false
    },
    {
      label: t('HELP'),
      path: '/help',
      public: false,
      admin: false
    },
    {
      label: 'Admin',
      path: '/admin',
      public: false,
      admin: true
    }
  ]

  const routeActual = useLocation()

  const navigate = useNavigate()
  const userPlatform: Platform = profileStore((state: ProfileStore) => state.userPlatform)

  const user = profileStore((state: ProfileStore) => state.userDetail)
  const token: string = profileStore((state: ProfileStore) => state.token)
  const isLogged = token !== ''
  const haveUser = user.userId !== '0'
  const haveCredentials = userPlatform.platformId !== 0
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('lg'))
  const sm = useMediaQuery(theme.breakpoints.down('sm'))
  const upSm = useMediaQuery(theme.breakpoints.up('sm'))
  const showTitle = false //  useMediaQuery(theme.breakpoints.between('sm', 'lg'))
  const [showLogin, setShowLogin] = useState<boolean>(false)
  const setShowDialog = appStore((state: AppStore) => state.setShowDialog)

  useEffect(() => {
    if (token !== '') {
      const dataToken: Token = jwtDecode(token)
      if (dataToken.roles.includes('ADMIN')) routes.push()
    }
  }, [token])

  const isAdmin = useMemo(() => {
    if (token !== '') {
      try {
        const dataToken: Token = jwtDecode(token)

        return dataToken.roles.includes('ADMIN')
      } catch {
        return false
      }
    }
  }, [token])

  if (mobile)
    return (
      <Grid
        container
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mt: 2
        }}
      >
        <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            variant='square'
            alt='ak'
            src='/images/Logo-axes-transp.svg'
            sx={{ width: 130, height: 40, cursor: 'pointer' }}
            onClick={() => {
              navigate('/')
              //window.location.reload()
            }}
          />
        </Grid>

        {isLogged && upSm && (
          <Grid item xs={6} sx={{ mb: 2, mt: 2, display: 'flex', justifyContent: 'end' }}>
            {haveUser && haveCredentials ? (
              <WalletVisualization />
            ) : (
              <Card
                sx={{
                  mx: 2,
                  backgroundColor: 'background.default'
                }}
              >
                <CardContent sx={{ display: 'flex' }}>
                  <Typography sx={{ px: 2 }} fontWeight={FontWeight.Normal} fontSize={12} color='red'>
                    {t('DONT_HAVE_CREDENTIALS_MESSAGE')}
                  </Typography>
                </CardContent>
              </Card>
            )}
          </Grid>
        )}

        {isLogged && sm && !(haveUser && haveCredentials) && (
          <Grid item xs={3}>
            <Card
              sx={{
                backgroundColor: 'background.default'
              }}
            >
              <CardContent sx={{ display: 'flex', justifyContent:'center' }} style={{ padding: 8 }}>
                <Typography sx={{}} fontWeight={FontWeight.Normal} fontSize={8} color='red'>
                  {t('DONT_HAVE_CREDENTIALS_MESSAGE')}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        )}

        <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {isLogged ? (
            <UserDropdown settings={settings}></UserDropdown>
          ) : (
            <IconButton onClick={() => setShowDialog('login', true)}>
              <Icon icon='mdi:account-outline' fontSize={35} color='white' />
            </IconButton>
          )}
        </Grid>

        {isLogged && sm && haveUser && haveCredentials && (
          <Grid item xs={12} sx={{ mb: 2, mt: 2 }}>
            <WalletVisualization />
          </Grid>
        )}
        {showLogin && (
          <LoginDialog
            show={showLogin}
            closeFunc={() => {
              setShowLogin(false)
            }}
            onSuccess={() => {
              setShowLogin(false)
            }}
          />
        )}
      </Grid>
    )

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        pb: 2,
        pt: 4,
        flexWrap: 'wrap'
      }}
    >
      <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
        <Avatar
          variant='square'
          alt='ak'
          src='/images/Logo-axes-transp.svg'
          sx={{ width: 160, height: 50, cursor: 'pointer' }}
          onClick={() => {
            navigate('/')
            //window.location.reload()
          }}
        />
        <Divider orientation='vertical' flexItem sx={{ my: 8, px: 4 }}></Divider>
        {isLogged &&
          routes.map((route: Route, key: number) => {
            if (!isLogged && !route.public) return <Box key={key}></Box>

            if (!isAdmin && route.admin) return <Box key={key}></Box>

            return (
              <Box key={key} onClick={() => navigate(route.path)} style={{ cursor: 'pointer' }} sx={{ py: 2 }}>
                <Typography
                  sx={{ px: 2, mt: routeActual.pathname === route.path ? 8 : 0 }}
                  fontWeight={FontWeight.Normal}
                  fontSize={16}
                  color={routeActual.pathname === route.path ? 'primary' : 'white'}
                >
                  {route.label}
                </Typography>
                {routeActual.pathname === route.path && (
                  <Box style={{ borderBottom: '2px solid primary.main' }} sx={{ mt: 8 }}>
                    {' '}
                  </Box>
                )}
              </Box>
            )
          })}
      </Box>
      <Box className='actions-right' sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
          <CustomLanguageDropdown settings={settings} saveSettings={saveSettings} />
          {isLogged && <Divider orientation='vertical' flexItem sx={{ mr: 1 }} />}
          {false && <CurrencyDropdown settings={settings} saveSettings={saveSettings} />}
          {isLogged && (
            <Typography color='white' sx={{ px: 2 }}>
              USDT
            </Typography>
          )}
        </Box>
        {isLogged &&
          haveUser &&
          (haveCredentials ? (
            <WalletVisualization />
          ) : (
            <Card
              sx={{
                mx: 2,
                backgroundColor: 'background.default'
              }}
            >
              <CardContent sx={{ display: 'flex' }}>
                <Typography sx={{ px: 2 }} fontWeight={FontWeight.Normal} fontSize={16} color='red'>
                  {t('DONT_HAVE_CREDENTIALS_MESSAGE')}
                </Typography>
              </CardContent>
            </Card>
          ))}
        <Box sx={{ pl: 2 }}>
          {isLogged && <UserDropdown settings={settings} />}
          {!isLogged && <CustomBtn onClick={() => setShowDialog('login', true)}>{t('LOGIN/REGISTER')}</CustomBtn>}
        </Box>
      </Box>

      {showLogin && (
        <LoginDialog
          show={showLogin}
          closeFunc={() => {
            setShowLogin(false)
          }}
          onSuccess={() => {
            setShowLogin(false)
          }}
        />
      )}
    </Box>
  )
}

export default AppBarContent
