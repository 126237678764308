import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { /*Avatar,*/ Button, Divider } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Icon from 'src/@core/components/icon'
// import CustomBtn from 'src/layouts/components/shared-component/CustomBtn'
import { FontWeight } from 'src/layouts/TypographyTheme'

interface Link {
  label: string
  url: string
}

const FooterContent = () => {
  const { t } = useTranslation()
  const leftLinks: Link[] = [
    {
      label: t('REQUEST_FORM'),
      url: ''
    },
    {
      label: t('CONTACT_SUPPORT'),
      url: ''
    },
    {
      label: 'FAQ',
      url: ''
    },
    {
      label: t('SECURITY'),
      url: ''
    }
  ]

  const bottomLinks: Link[] = [
    {
      label: '© 2023 AxesKapital.' + t('ALL_RIGHTS_RESERVED'),
      url: ''
    },
    {
      label: t('PRIVACY'),
      url: ''
    },
    {
      label: t('TERMS'),
      url: ''
    },
    {
      label: t('FAQs'),
      url: ''
    }
  ]

  // const social: string[] = ['mdi:facebook-box', 'mdi:instagram', 'mdi:youtube-play', 'mdi:twitter', 'mdi.linkedin-box']
  const social: string[] = []

  return (
    <Box>
      {/* <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'end' }}>
        <Avatar
          variant='square'
          alt='ak'
          src='/images/Logo-axes-transp.svg'
          sx={{ width: 160, height: 50 }}
        /> */}
      {/* <Typography sx={{ pb: 2, pl: 2 }} fontWeight={FontWeight.Normal} fontSize={20}>
          AxesKapital
        </Typography> */}
      {/* </Box> */}
      <Box sx={{ py: 0, display: 'flex', flexWrap: 'wrap', justifyContent: { xs: 'center', md: 'space-between' } }}>
        {/* <Box sx={{ marginRight: 'auto' }}>
          <Typography sx={{}} fontWeight={FontWeight.Bold} fontSize={19}>
            {t('SUPPORT')}
          </Typography>
          {leftLinks.map((link: Link, i: number) => (
            <Typography
              key={i}
              sx={{ pb: 1, pt: i === 0 ? 8 : 1, pr: 2 }}
              fontWeight={FontWeight.Normal}
              fontSize={14}
              color='#CCCED9'
            >
              {link.label}
            </Typography>
          ))}
        </Box> */}
        <Box sx={{ py: 0 }}>
          {/* <Typography fontWeight={FontWeight.Bold} fontSize={19}>
            {t('NEWSLETTER')}
          </Typography>
          <Box sx={{ display: 'flex', mt: 8 }}>
            <TextField
              id='email'
              label={t('ENTER_YOUR_EMAIL')}
              variant='outlined'
              size='small'
              sx={{
                '& .MuiInputBase-root': {
                  height: 50
                }
              }}
            />
            <CustomBtn style={{ height: 50 }} variant='contained' sx={{ ml: 1 }}>
              {t('SEND')}
            </CustomBtn>
          </Box>
          <Typography sx={{ pt: 8 }} fontWeight={FontWeight.Normal} fontSize={18} color='#CCCED9'>
            {t('WE_ACCEPT_FOLLOWING_PAYMENT_SYSTEMS')}
          </Typography> */}
          {/* <Box sx={{ display: 'flex', alignItems: 'center', mt: 4, justifyContent: 'space-between' }}>
            <Avatar variant='square' alt='paypal' src='/images/payments/paypal.png' sx={{ width: 52, height: 16 }} />
            <Avatar
              variant='square'
              alt='mastercard'
              src='/images/payments/mastercard.png'
              sx={{ width: 48, height: 27 }}
            />
            <Avatar variant='square' alt='bitcoin' src='/images/payments/bitcoin.png' sx={{ width: 48, height: 27 }} />
            <Avatar variant='square' alt='visa' src='/images/payments/visa.png' sx={{ width: 48, height: 27 }} />
          </Box> */}
        </Box>
      </Box>
      <Divider sx={{ pt: 0 }} />
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: { xs: 'center', md: 'space-between' },
          pt: 6
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: { xs: 'center', md: 'space-between' },
            mb: 2
          }}
        >
          {bottomLinks.map((link: Link, i: number) => (
            <Box key={i} sx={{ display: 'flex', flexWrap: 'wrap' }}>
              <Typography sx={{ pr: { xs: 3, md: 8 } }} fontWeight={FontWeight.Normal} fontSize={14} color='#CCCED9'>
                {link.label}
              </Typography>
              {<Divider orientation='vertical' flexItem sx={{ mr: 6 }}></Divider>}
            </Box>
          ))}
          <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: { xs: 2, md: 0 } }}>
            <Button
            size='small'
              target='_blank'
              component='a'
              href='https://t.me/axeskapital'
              sx={{ textTransform: 'none' }}
              startIcon={<Icon icon='streamline:telegram' color='white' />}
            >
              <Typography
                sx={{ pr: { xs: 3, md: 8 }, ml: 2 }}
                fontWeight={FontWeight.Normal}
                fontSize={14}
                color='#CCCED9'
              >
                {t('AXES_KAPITAL_NEWS')}
              </Typography>
            </Button>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', pt: { xs: 4, md: 0 } }}>
          {social.map((icon: string, i: number) => (
            <Box key={i} sx={{ pl: i === social.length - 1 ? 0 : 4 }}>
              <Icon icon={icon} color='white' style={{ fontSize: '24px' }} />
            </Box>
          ))}
        </Box>
        {/* <Box sx={{ display: 'flex', alignItems: 'center', mt: 4, justifyContent: 'space-between' }}>
          <Avatar variant='square' alt='paypal' src='/images/payments/paypal.png' sx={{ width: 52, height: 16 }} />
          <Avatar
            variant='square'
            alt='mastercard'
            src='/images/payments/mastercard.png'
            sx={{ width: 48, height: 27 }}
          />
          <Avatar variant='square' alt='bitcoin' src='/images/payments/bitcoin.png' sx={{ width: 48, height: 27 }} />
          <Avatar variant='square' alt='visa' src='/images/payments/visa.png' sx={{ width: 48, height: 27 }} />
        </Box> */}
      </Box>
    </Box>
  )
}

export default FooterContent
