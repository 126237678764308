import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Backdrop, Box, CircularProgress, Typography } from '@mui/material'
import { changeStrategyById, paySubscription } from 'src/@http/user'
import { DialogGeneric } from 'src/pages/components/generic/DialogGeneric'
import { CredentialsForm, type FormDataApiUser } from 'src/pages/components/home/CredentialsForm'
import type { CreditCardData, CryptoData } from 'src/pages/components/home/PaymentForm'
import PaymentForm, { PaymentMethodId } from 'src/pages/components/home/PaymentForm'
import profileStore, {ProfileStore} from 'src/zustand/profile'

interface FlowData {
  subscription: API.Subscription.SubscriptionResponse
  paymentData: CryptoData | CreditCardData
  apiData: FormDataApiUser
}

enum Step {
  Payment,
  Api
}

interface Props {
  show: boolean
  closeFunc: () => void
}
const PaymentFlow = ({ show, closeFunc}: Props) => {
  const activeStrategy = profileStore((state: ProfileStore) => state.activeStrategy)
  const activateStrategy = profileStore((state: ProfileStore) => state.activateStrategy)
  const initFlowData: FlowData = {
    subscription: {
      subscriptionId: 0,
      availableDays: 0,
      subscriptionName: '',
      subscriptionValue: 0,
      statusId: 0
    },
    apiData: {
      apiKey: '',
      apiSecret: '',
      passPhrase: ''
    },
    paymentData: {
      network: { id: 0, label: '' },
      currency: { id: 0, label: '' },
      transactionHash: '',
      externalPayment: true,
      promocode: '',
      originWallet: '',
      paymentMethodId: 0
    }
    // activeStrategy
  }
  const [open, setOpen] = useState<boolean>(false);

  const [flowData, setFlowData] = useState<FlowData>(initFlowData)

  const userDetail = profileStore((state:ProfileStore) => state.userDetail)
  const userPlatform = profileStore((state:ProfileStore) => state.userPlatform)
  const getUserDetail = profileStore((state:ProfileStore) => state.getUserDetail)
  

  const [step, setStep] = useState<Step>(userPlatform.platformId === 0 ? Step.Api : Step.Payment)

 
  useEffect(()=>{
    setStep(userPlatform.platformId === 0 ? Step.Api : Step.Payment)
  }, [userPlatform.platformId])
  

  const { t } = useTranslation()
  const token: string = profileStore((state:ProfileStore) => state.token)
  const isLogged = token !== ''
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const savePaymentStep = async (subscription: API.Subscription.SubscriptionResponse, paymentData: CryptoData) => {
    const copy = { ...flowData }
    copy.subscription = subscription
    copy.paymentData = paymentData

    setFlowData(copy)
    try {
      setIsLoading(true)
      if(paymentData.externalPayment)
        setOpen(true)
       const result = await paySubscription(subscription.subscriptionId, paymentData)
      if (result) {
        await getUserDetail(userDetail.userId)
        let key = 'VERIFIED_PAYMENT'
        switch (paymentData.paymentMethodId) {
          case PaymentMethodId.DIRECT:
            key = 'DATA_SENT_TO_BE_VERIFIED'
            await changeStrategyById(userDetail.userId, activeStrategy)
          break;
        }
        toast.success(t(key), { duration: 4000 })
        await activateStrategy(activeStrategy)
        closeFunc()
      }
    } catch (error: any) {
      if(paymentData.paymentMethodId === PaymentMethodId.DIRECT){
        closeFunc()
      } else {
        toast.error(error.message, { duration: 4000 })
      }
        
    } finally {
      setIsLoading(false)
      setOpen(false)
    }
    
  }

  const nexStep = () => {
    setStep(Step.Payment)
  }

  return (
    <>
    <DialogGeneric
      show={show}
      hideActions={true}
      showIconClose={true}
      closeFunc={closeFunc}
      title={t(
        isLogged
          ? step === Step.Payment
            ? t('DEPOSIT_CURRENCY', { currency: 'USDT' })
            : t('ADD_API_CREDENTIALS')
          : t('LOGIN')
      )}
    >
      {step === Step.Api && (
        <Box sx={{ pt: 4 }}>
          <CredentialsForm
            submitFinishFunction={
              () => nexStep()
            }
            confirmText='NEXT'
          />
        </Box>
      )}
      {/* Agregar una validacion para los promocodes*/}
      {step === Step.Payment && <PaymentForm onSave={savePaymentStep} isLoading={isLoading} />}
    </DialogGeneric>
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999 }}
        open={open}
      >
        <Box sx={{textAlign:'center'}}>
        <CircularProgress color="inherit" size={50}/>
        <Typography sx={{pt: 2, fontWeight:500}}>{t('WAIT_WHILE_WE_VALIDATE_THE_PAYMENT')}</Typography>
        </Box>
       
      </Backdrop>
    </>
  )
}

export default PaymentFlow
