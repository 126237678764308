import Grid from '@mui/material/Grid'

const Portfolio = () => {

  return (
    <Grid container>
      <Grid item xs={12}></Grid>
    </Grid>
  )
}

export default Portfolio
