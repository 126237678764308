import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar, Box, Button, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material'
import Icon from 'src/@core/components/icon'
import { Promocode } from 'src/@http/promocode'
import { DialogGeneric } from 'src/pages/components/generic/DialogGeneric'
import { ModeForm } from 'src/types'
import adminStore from 'src/zustand/admin'

import CuponForm from './CuponForm'
// import { logger } from 'src/utils/Logger'
import CuponItemCard from './CuponItemCard'
// import CustomBtn /*, { ActiveBtn }*/ from 'src/layouts/components/shared-component/CustomBtn'
interface Props {
  isContent: boolean
}

const CuponManager = ({isContent}:Props) => {
  const [showCuponRegisterDialog, setShowCuponRegisterDialog] = useState<boolean>(false)
  const [cuponSelected, setCuponSelected] = useState<Promocode | null>(null)
  const { t } = useTranslation()

  const openDialogEdit = (promocode: Promocode) => {
    setCuponSelected(promocode)
    setShowCuponRegisterDialog(true)
  }

  const openDialogCreate = () => {
    setCuponSelected(null)
    setShowCuponRegisterDialog(true)
  }

  const { promocodes, getAllPromocodes } = adminStore()
  const [notFound, setNotFound] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const init = async () => {
      setLoading(true)
      const promocodesList = await getAllPromocodes()
      if(promocodesList.length === 0)
        setNotFound(true)
      setLoading(false)
    }
    init()
  }, [])

  return (
    <Card sx={{ ...(isContent && { border: 'none', boxShadow: 'none' }) }}>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {!isContent && <>
              <Avatar sx={{ bgcolor: 'primary.main', width: 40, height: 40 }} variant='rounded'>
              <Icon icon='mdi:ticket' style={{ fontSize: 24 }} color='white' />
            </Avatar>
            <Typography sx={{ m: 3 }} color='white' variant='h5' component='div'>
              {t('CUPON')}
            </Typography>
            </>}
          </Box>
          <Button onClick={() => openDialogCreate()} startIcon={<Icon icon='mdi:plus-box-outline' color='#9295A6' />}>
            <Typography color='#9295A6' sx={{ textTransform: 'capitalize' }}>
              {t('ADD')}
            </Typography>
          </Button>
        </Box>

        {promocodes.length !== 0 && <Grid sx={{ mt: 3 }} container>
        {promocodes.map((promocode: Promocode, key: number) => (
            <Grid key={key} item sx={{ p: 1 }} xs={12} sm={6} lg={4}>
              <CuponItemCard
                promocode={promocode}
                editFunction={() => openDialogEdit(promocode)}
                deleteFunctionSuccess={() => getAllPromocodes()}
              />
            </Grid>
          ))}
        </Grid>}
        {(notFound) && (
          <Typography align='center'>
            {t('DATA_NOT_FOUND')}
          </Typography>
        )}
        {(promocodes.length === 0 && loading) && (
          <Grid sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Grid>
        )}


      </CardContent>
      <DialogGeneric
        show={showCuponRegisterDialog}
        closeFunc={() => {
          setShowCuponRegisterDialog(false)
        }}
        hideActions={true}
        title={t('CUPON_DETAIL')}
        leftAline={true}
        showIconClose={true}
      >
        <Box sx={{ mt: 4 }}>
          <CuponForm
            submitSuccess={() => setShowCuponRegisterDialog(false)}
            modeForm={cuponSelected ? ModeForm.edit : ModeForm.create}
            {...(cuponSelected ? { valuesDefaultForm: cuponSelected } : {})}
          ></CuponForm>
        </Box>
      </DialogGeneric>
    </Card>
  )
}


CuponManager.defaultProps = {
  isContent: false
}

export default CuponManager
