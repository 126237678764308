import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Switch } from '@mui/material'
import { changeStrategyById, postStopStrategyByUserId } from 'src/@http/user'
import adminStore from 'src/zustand/admin'
import profileStore, {ProfileStore} from 'src/zustand/profile'

import { DialogGeneric } from '../../generic/DialogGeneric'

interface CellType {
  row: API.User.AllUserResponse
}

const StopStrategy = ({ row }: CellType) => {
  const [event, setEvent] = useState(false)
  const [show, setShow] = useState<boolean>(false)
  const { t } = useTranslation()
  const { getAllUser } = adminStore()

  const user = profileStore((state:ProfileStore) => state.userDetail)
  const getUserDetail = profileStore((state:ProfileStore) => state.getUserDetail)
  const { currentStrategyIsActive } = row
  let active = false
  if (currentStrategyIsActive) active = currentStrategyIsActive
  const [checked, setChecked] = useState(active)

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (row.currentStrategyId !== null) {
      setShow(true)
      setEvent(event.target.checked)
    }
  }

  const confirmChange = async () => {
    if (row.currentStrategyId !== null) {
    const userId= `${row.userId}`
    setChecked(event)
    try {
      if (event) await changeStrategyById(userId, row.currentStrategyId)
      else await postStopStrategyByUserId(userId)
      if (user.userId === userId) {
        await getUserDetail(userId)
      }
      getAllUser()
    } catch (error: any) {
      toast.error(error.message, { duration: 4000 })
    }
  }
  }

  return (
    <>
      <Switch checked={checked} onChange={handleChange} />

      <DialogGeneric
        show={show}
        title={checked ? t('STOP_STRATEGY') : t('PLAY_STRATEGY')}
        confirmText={t('CONFIRM') || ''}
        confirmFunc={() => confirmChange()}
        closeFunc={() => {
          setShow(false)
        }}
      >
        {checked ? t('CONFIRM_STOP_CURRENT_STRATEGY') : t('CONFIRM_PLAY_CURRENT_STRATEGY')}
      </DialogGeneric>
    </>
  )
}

export { StopStrategy }
