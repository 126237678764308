import { Card, CardContent } from '@mui/material'
import ItemsScroller from 'src/layouts/components/ItemScroller'

import UserItemCard from './UserItemCard'

interface Props {
  list: API.User.AllUserResponse[]
}

const UserManagerCards = ({list}: Props) => {
 

  return (
    <Card>
      <CardContent sx={{ px: 0 }}>
        <ItemsScroller list={list} itemCard={UserItemCard} increment={6}></ItemsScroller>
      </CardContent>
    </Card>
  )
}

export default UserManagerCards
