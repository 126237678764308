import {useEffect, useState } from 'react'
// import CustomBtn /*, { ActiveBtn }*/ from 'src/layouts/components/shared-component/CustomBtn'
import * as React from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Avatar, Box, Button, Card, CardContent, CircularProgress, Grid, Tab, Tabs, Typography } from '@mui/material'
import Icon from 'src/@core/components/icon'
import { useSettings } from 'src/@core/hooks/useSettings'
import { getAllGlobalSetting, GlobalSetting } from 'src/@http/global-settings'
import { DialogGeneric } from 'src/pages/components/generic/DialogGeneric'
import { ModeForm } from 'src/types'
import { logger } from 'src/utils/Logger'
import adminStore from 'src/zustand/admin'

// import adminStore from 'src/zustand/admin'
import GlobalSettingsForm from './GlobalSettingsForm'
// import { logger } from 'src/utils/Logger'
import GlobalSettingsItemCard from './GlobalSettingsItemCard'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

interface Props {
  isContent: boolean
}

const GlobalSettingsManager = ({isContent}: Props) => {
  const { settings, saveSettings } = useSettings()
  const [showGlobalSettingRegisterDialog, setShowGlobalSettingRegisterDialog] = useState<boolean>(false)
  const [globalSettingSelected, setGlobalSettingSelected] = useState<GlobalSetting | null>(null)
  const [globalSettings, setGlobalSettings] = useState<GlobalSetting[]>([])
  const {publicGlobalSettings, setPublicGlobalSettings} = adminStore()
  const { t } = useTranslation()
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    logger.log('Click in tab', newValue)
    setValue(newValue)
  }

  const openDialogEdit = (globalSetting: GlobalSetting) => {
    setGlobalSettingSelected(globalSetting)
    setShowGlobalSettingRegisterDialog(true)
  }

  const openDialogCreate = () => {
    setGlobalSettingSelected(null)
    setShowGlobalSettingRegisterDialog(true)
  }

  const a11yProps = (index: number) => {

    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    }
  }

  const getAll = () =>
    getAllGlobalSetting()
      .then(res => {setGlobalSettings(res); setPublicGlobalSettings(res)})
      .catch(err => toast.error(err.message))

  useEffect(() => {
    getAll()
  }, [])

  const submitSuccessGlobalSettings = () => {
    setShowGlobalSettingRegisterDialog(false)

    getAllGlobalSetting()
      .then(res => {
        const bg = res.find(gs => gs.key === 'bgColor')
        const primary = res.find(gs => gs.key === 'primary')
        const paperCardColor = res.find(gs => gs.key === 'paperCardColor')

        const bgLocal = publicGlobalSettings.find(gs => gs.key === 'bgColor')
        const primaryLocal = publicGlobalSettings.find(gs => gs.key === 'primary')
        const paperCardColorLocal = publicGlobalSettings.find(gs => gs.key === 'paperCardColor')

        
        if (bg && primary && paperCardColor && bgLocal && primaryLocal && paperCardColorLocal
          && (bg.value!=bgLocal.value || primary.value !=primaryLocal.value || paperCardColor.value!=paperCardColorLocal.value)){
            saveSettings({
              ...settings,
              backgroundColor: bg.value,
              primary: primary.value,
              paperCardColor: paperCardColor.value
            })
          }
          
        setGlobalSettings(res)
        setPublicGlobalSettings(res)
      })
      .catch(err => toast.error(err.message))
  }

  return (
    <Card sx={{ ...(isContent && { border: 'none', boxShadow: 'none' }) }}>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {!isContent && <>
              <Avatar sx={{ bgcolor: 'primary.main', width: 40, height: 40 }} variant='rounded'>
              <Icon icon='mdi:dollar' style={{ fontSize: 24 }} color='white' />
            </Avatar>
            <Typography sx={{ m: 3 }} color='white' variant='h5' component='div'>
              {t('GLOBAL_SETTINGS')}
            </Typography>
            </>}
          </Box>
          <Button onClick={() => openDialogCreate()} startIcon={<Icon icon='mdi:plus-box-outline' color='#9295A6' />}>
            <Typography color='#9295A6' sx={{ textTransform: 'capitalize' }}>
              {t('ADD')}
            </Typography>
          </Button>
        </Box>
        {
          publicGlobalSettings.length !== 0 && <>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab label={t('PUBLIC')} {...a11yProps(0)} />
            <Tab label={t('PRIVATE')} {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
        <Grid sx={{ mt: 3 }} container>
          {publicGlobalSettings
            .map((globalSetting: any, key: number) => (
              <Grid key={key} item sx={{ p: 1 }} xs={12} sm={12} lg={12} xl={6}>
                <GlobalSettingsItemCard
                  globalSetting={globalSetting}
                  editFunction={() => openDialogEdit(globalSetting)}
                  deleteFunctionSuccess={() => getAll()}
                />
              </Grid>
              
            ))}
            </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
        <Grid sx={{ mt: 3 }} container>
          {globalSettings
            .filter(gg => gg.isPrivate)
            .map((globalSetting: any, key: number) => (
              <Grid key={key} item sx={{ p: 1 }} xs={12} sm={12} lg={12} xl={6}>
                <GlobalSettingsItemCard
                  globalSetting={globalSetting}
                  editFunction={() => openDialogEdit(globalSetting)}
                  deleteFunctionSuccess={() => getAll()}
                />
              </Grid>
            ))}
            </Grid>
        </CustomTabPanel>
          </>
        }
        

        {publicGlobalSettings.length === 0 && <Grid sx={{ mt: 3 ,display:'flex', justifyContent:'center'}} >
        <CircularProgress />
        </Grid>}

        
      </CardContent>
      <DialogGeneric
        show={showGlobalSettingRegisterDialog}
        closeFunc={() => {
          setShowGlobalSettingRegisterDialog(false)
        }}
        hideActions={true}
        title={globalSettingSelected ? t('GLOBAL_SETTING_DETAIL') : t('CREATE_GLOBAL_SETTING')}
        leftAline={true}
        showIconClose={true}
      >
        <Box sx={{ mt: 4 }}>
          <GlobalSettingsForm
            submitSuccess={() => {
              submitSuccessGlobalSettings()
            }}
            modeForm={globalSettingSelected ? ModeForm.edit : ModeForm.create}
            {...(globalSettingSelected ? { valuesDefaultForm: globalSettingSelected } : {})}
          ></GlobalSettingsForm>
        </Box>
      </DialogGeneric>
    </Card>
  )
}

GlobalSettingsManager.defaultProps = {
  isContent: false
}

export default GlobalSettingsManager
