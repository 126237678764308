import { useEffect, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Switch,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material'
import { Theme, useTheme } from '@mui/material/styles'
import Icon from 'src/@core/components/icon'
import { getAllCryptoCurrency, postCryptoCurrency } from 'src/@http/cryptocurrency'
import { ManualTrader, postCreateManualTrader, putCreateManualTrader } from 'src/@http/manual-trader'
import CustomBtn, { CustomLoadingBtn } from 'src/layouts/components/shared-component/CustomBtn'
import { ModeForm } from 'src/types'
import { logger } from 'src/utils/Logger'
import adminStore from 'src/zustand/admin'
import profileStore from 'src/zustand/profile'
import * as yup from 'yup'

import ManualTraderTPForm, { TPForm } from './ManualTraderTPForm'

interface Props {
  modeForm: ModeForm
  valuesDefaultForm?: ManualTrader | null
  closeFunc: () => any
  traderId: number
  updateFunction: () => void
}

interface IManualTraderForm {
  intManualTraderId: number
  vchSymbol: string
  vchSide: string
  side: boolean
  dcmAmount: number | null
  dcmAmountLimit: number | null
  intNumberOfOrdersLimit: number | null
  dcmPercentageStepLimit: number | null
  dcmMaxPriceLimit: number | null
  dcmMinPriceLimit: number | null
  intLeverage: number
  status: boolean
  dcmStopLossPrice: number | null
  intIsCyclical: boolean

  // Edit
  dtmCreatedDate: string
  dtmUpdatedDate: string
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight: personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium
  }
}

const ManualTraderForm = ({ modeForm, closeFunc, valuesDefaultForm, traderId, updateFunction }: Props) => {
  const { t } = useTranslation()
  const { getAllByTraderGridbots, bybitSymbols } = adminStore()
  const { userDetail } = profileStore()

  const [showManualTraderTPForm, setShowManualTraderTPForm] = useState<boolean>(false)
  const [tps, setTps] = useState<TPForm[]>([])

  let defaultValuesForm: IManualTraderForm = {
    intManualTraderId: 0,
    vchSymbol: 'BTCUSDT',
    side: false,
    vchSide: 'Buy',
    dcmAmount: null,
    dcmAmountLimit: null,
    intNumberOfOrdersLimit: null,
    dcmPercentageStepLimit: null,
    dcmMaxPriceLimit: null,
    dcmMinPriceLimit: null,
    intLeverage: 1,
    status: false,
    dcmStopLossPrice: null,
    intIsCyclical: false,
    // edit
    dtmCreatedDate: '',
    dtmUpdatedDate: ''
  }

  if (modeForm === ModeForm.edit && valuesDefaultForm) {
    defaultValuesForm = {
      ...valuesDefaultForm,
      status: valuesDefaultForm.intStatusId === 1,
      side: valuesDefaultForm.vchSide === 'Sell',
      dtmCreatedDate: valuesDefaultForm.dtmCreatedDate ? valuesDefaultForm.dtmCreatedDate.slice(0, 10) : '-',
      dtmUpdatedDate: valuesDefaultForm.dtmUpdatedDate ? valuesDefaultForm.dtmUpdatedDate.slice(0, 10) : '-',
      intIsCyclical: valuesDefaultForm.intIsCyclical === 1 ? true: false
    }
  }

  useEffect(()=>{
    if(modeForm === ModeForm.edit && valuesDefaultForm && valuesDefaultForm.manualTraderTP.length!==0){
      setShowManualTraderTPForm(true)
      setTps(valuesDefaultForm.manualTraderTP)
    }
  }, [valuesDefaultForm])

  const validations = {
    vchSymbol: yup.string(),
    vchSide: yup.string(),
    dcmAmount: yup.number().transform((value) => Number.isNaN(value) ? null : value ).nullable().positive().test({
      name: 'at-least-one',
      test: function (value) {
        const amountValue = this.resolve(yup.ref('dcmAmount'));
        
        return value !== null || amountValue !== null;
      },
      message: t('THE_AMOUNT_OR_LIMIT_AMOUNT_IS_REQUIRED') || 'The amount or amount limit is required',
    }),
    dcmAmountLimit: yup.number().transform((value) => Number.isNaN(value) ? null : value ).nullable().positive(),
    intNumberOfOrdersLimit: yup.number().nullable().positive().min(1).max(10),
    dcmPercentageStepLimit: yup.number().nullable().positive().min(0.01).max(1).test({
      name: 'at-least-one',
      test: function (value) {
        const dcmMinPriceLimit = this.resolve(yup.ref('dcmMinPriceLimit'));
        const dcmMaxPriceLimit = this.resolve(yup.ref('dcmMaxPriceLimit'));
        
        return value !== null || (dcmMinPriceLimit !== null && dcmMaxPriceLimit !== null);
      },
      message: t('PST_MM_PL') || 'The Percentage Step Limit or Minimum and Maximum Price Limit is required',
    }),
    dcmMaxPriceLimit: yup.number().transform((value) => Number.isNaN(value) ? null : value ).nullable().positive().test({
      name: 'great',
      test: function (value) {
        const dcmMinPriceLimit = this.resolve(yup.ref('dcmMinPriceLimit'));
        
        return (dcmMinPriceLimit === null) || (value !== null && value !== undefined && dcmMinPriceLimit !== null && value >= Number(dcmMinPriceLimit));
      },
      message: t('GE_MIN_PRICE') || 'It must be greater than or equal to the minimum price',
    }),
    dcmMinPriceLimit: yup.number().transform((value) => Number.isNaN(value) ? null : value ).nullable().positive().positive(),
    intLeverage: yup.number().positive(),
    status: yup.boolean(),
    intIsCyclical: yup.boolean(),
    dcmStopLossPrice: yup.number().transform((value) => Number.isNaN(value) ? null : value ).nullable().positive(),
    dtmCreatedDate: yup.string(),
    dtmUpdatedDate: yup.string()
  }

  if (modeForm === ModeForm.create) {
    validations.vchSymbol = validations.vchSymbol.required()
  }

  const schemaValidations = yup.object().shape(validations)

  const theme = useTheme()
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { control, handleSubmit, formState, getValues } = useForm<IManualTraderForm>({
    resolver: yupResolver(schemaValidations),
    defaultValues: defaultValuesForm
  })

  const statusWatch = useWatch({ control, name: 'status' })

  const [symbols, setSymbols] = useState<string[]>(['BTCUSDT'])
  const [showAddSymbols, setShowAddSymbols] = useState<boolean>(false)
  const [crytoLoading, setCrytoLoading] = useState<boolean>(false)
  const [inputSymbol, setInputSymbol] = useState<string>('')


 
  const onSubmit = async (form: IManualTraderForm) => {
    logger.log('from a',form)
    logger.log('tps', tps)

    
    setIsLoading(true)
    if (modeForm === ModeForm.create) {
      try {
        await postCreateManualTrader({
          vchSymbol: form.vchSymbol,
          vchSide: form.side ? 'Sell' : 'Buy',
          dcmAmount: form.dcmAmount,
          intLeverage: form.intLeverage,
          intTraderId: traderId,
          intCreatedUserId: parseInt(userDetail.userId),
          dcmPercentageStepLimit: form.dcmPercentageStepLimit,
          intNumberOfOrdersLimit: form.intNumberOfOrdersLimit,
          dcmAmountLimit: form.dcmAmountLimit,
          dcmMaxPriceLimit: form.dcmMaxPriceLimit,
          dcmMinPriceLimit: form.dcmMinPriceLimit,
          dcmStopLossPrice: form.dcmStopLossPrice,
          intIsCyclical:form.intIsCyclical?1:0,
          manualTraderTP: tps
        })
        toast.success(t('CREATE_SUCCESS'), { duration: 4000 })
        updateFunction()
        closeFunc()
        
      } catch (error: any) {
        toast.error(error.message, { duration: 4000 })
      }
    } else {
      try {
        await putCreateManualTrader({
          intManualTraderId: form.intManualTraderId,
          vchSymbol: form.vchSymbol,
          vchSide: form.side ? 'Sell' : 'Buy',
          dcmAmount: form.dcmAmount,
          intLeverage: form.intLeverage,
          intTraderId: traderId,
          intCreatedUserId: parseInt(userDetail.userId),
          dcmPercentageStepLimit: form.dcmPercentageStepLimit,
          intNumberOfOrdersLimit: form.intNumberOfOrdersLimit,
          dcmAmountLimit: form.dcmAmountLimit,
          dcmMaxPriceLimit: form.dcmMaxPriceLimit,
          dcmMinPriceLimit: form.dcmMinPriceLimit,
          dcmStopLossPrice: form.dcmStopLossPrice,
          intIsCyclical:form.intIsCyclical?1:0,
          manualTraderTP: tps
        })
        toast.success(t('UPDATE_SUCCESS'), { duration: 4000 })
        updateFunction()
        closeFunc()
      } catch (error: any) {
        toast.error(error.message, { duration: 4000 })
      }
    }
    setIsLoading(false)
    
  }


  const getLeverages = () => {
    const values = [1, 5, 10, 15, 20]

    return values.map((value: number) => {
      return {
        value: value,
        label: 'x' + value.toString()
      }
    })
  }

  const genericMarks = (
    minValue: number,
    maxValue: number,
    decimalCount: number,
    minAllowed: number
  ): { label: string; value: number }[] => {
    if (minValue >= maxValue) {
      return []
    }

    if (decimalCount < 0) {
      return []
    }

    const values = [
      minAllowed,
      minValue,
      minValue + 0.25 * (maxValue - minValue),
      minValue + 0.5 * (maxValue - minValue),
      minValue + 0.75 * (maxValue - minValue),
      maxValue
    ]

    const formattedObjects = values.map(value => {
      const formattedValue = value.toFixed(decimalCount)

      return { label: formattedValue, value: parseFloat(formattedValue) }
    })

    return formattedObjects
  }

  useEffect(() => {
    fecthGetAllCrytoCurrency()
  }, [])

  const addCrypto = async () => {
    setCrytoLoading(true)
    try {
      await postCryptoCurrency({ vchSymbol: inputSymbol })
      await fecthGetAllCrytoCurrency()
      setInputSymbol('')
      toast.success(t('THE_CRYPTOCURRENCY_WAS_ADDED_SUCCESSFULLY'), { duration: 4000 })
    } catch (error: any) {
      toast.error(error.message, { duration: 4000 })
    }
    setCrytoLoading(false)
  }

  const fecthGetAllCrytoCurrency = async () => {
    try {
      const response = await getAllCryptoCurrency()
      setSymbols(response.map(value => value.vchSymbol))
    } catch (error) {
      logger.log(error)
    }
  }

  return (
    <>
      <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
        {false && (
          <FormControl fullWidth sx={{ my: 2 }}>
            <InputLabel>{t('PAIR')}</InputLabel>
            <Controller
              name='vchSymbol'
              control={control}
              render={({ field: { name, value, onChange }, fieldState }) => (
                <>
                  <Select
                    error={!!fieldState.error}
                    defaultValue={value}
                    label={t('PAIR')}
                    fullWidth
                    name={name}
                    value={value}
                    onChange={onChange}
                    disabled={ModeForm.show === modeForm}
                  >
                    {symbols.map((symbol: any, key: number) => (
                      <MenuItem value={symbol.value} key={key}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Avatar
                            variant='square'
                            src={`https://raw.githubusercontent.com/spothq/cryptocurrency-icons/master/32/color/${symbol.label
                              .replace('USDT', '')
                              .toLowerCase()}.png`}
                            sx={{ width: 30, height: 30 }}
                          />
                          <Typography sx={{ ml: 2 }}>{symbol.label}</Typography>
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    <Typography color='error' sx={{ fontSize: 12 }}>
                      {fieldState.error?.message}
                    </Typography>{' '}
                  </FormHelperText>
                </>
              )}
            />
          </FormControl>
        )}

        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='vchSymbol'
            control={control}
            render={({ field: { name, value, onChange }, fieldState }) => (
              <Autocomplete
                defaultValue={value}
                disableClearable
                options={symbols}
                getOptionLabel={option => option}
                onChange={(event: any, newValue: string) => {
                  onChange(newValue)
                }}
                renderOption={(props, option) => (
                  <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <Avatar
                      src={`https://raw.githubusercontent.com/spothq/cryptocurrency-icons/master/32/color/${option
                        .replace('USDT', '')
                        .toLowerCase()}.png`}
                      sx={{ width: 30, height: 30 }}
                    >
                      <Typography sx={{ fontSize: 18 }}>{option[0]}</Typography>
                    </Avatar>
                    <Typography sx={{ ml: 2 }}>{option}</Typography>
                  </Box>
                )}
                renderInput={params => {
                  return <TextField {...params} label={t('PAIR')} />
                }}
              />
            )}
          />
        </FormControl>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={() => {
              setShowAddSymbols(!showAddSymbols)
            }}
            startIcon={<Icon icon={showAddSymbols ? 'mdi:chevron-up' : 'mdi:chevron-down'} color='#9295A6' />}
          >
            <Typography sx={{ fontSize: 10 }}>{showAddSymbols ? t('HIDE') : t('ADD_CRYPTO')}</Typography>
          </Button>
        </Box>
        {showAddSymbols && (
          <Grid container sx={{ mt: 2, mb: 2 }}>
            <Grid item xs={11}>
              <Autocomplete
                value={inputSymbol}
                disableClearable
                options={bybitSymbols}
                getOptionLabel={option => option}
                onChange={(event: any, newValue: string) => {
                  setInputSymbol(newValue)
                }}
                renderOption={(props, option) => (
                  <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <Avatar
                      src={`https://raw.githubusercontent.com/spothq/cryptocurrency-icons/master/32/color/${option
                        .replace('USDT', '')
                        .toLowerCase()}.png`}
                      sx={{ width: 30, height: 30 }}
                    >
                      <Typography sx={{ fontSize: 18 }}>{option[0]}</Typography>
                    </Avatar>
                    <Typography sx={{ ml: 2 }}>{option}</Typography>
                  </Box>
                )}
                renderInput={params => {
                  return <TextField {...params} label={t('SYMBOL')} />
                }}
              />
            </Grid>
            <Grid item xs={1} sx={{}}>
              <IconButton
                sx={{ mt: 2 }}
                disabled={crytoLoading || inputSymbol.length === 0}
                onClick={() => {
                  addCrypto()
                }}
              >
                <Icon icon='mdi:plus-box-outline' fontSize={30} />
              </IconButton>
            </Grid>
          </Grid>
        )}

        <Box sx={{ display: 'flex' }}>
          <Typography align='center' fontSize={20}>
            {t('BUY')}
          </Typography>
          <Controller
            name='side'
            control={control}
            render={({ field: { ...field } }) => (
              <Switch
                sx={{
                  '&.MuiSwitch-root .MuiSwitch-switchBase': { color: '#56CA00' },
                  '&.MuiSwitch-root .Mui-checked': {
                    color: '#DC2626'
                  },
                  '.MuiSwitch-track': {
                    backgroundColor: '#56CA00'
                  },
                  '.Mui-checked+.MuiSwitch-track': {
                    backgroundColor: '#DC2626'
                  }
                }}
                defaultChecked={field.value}
                {...field}
                disabled={ModeForm.show === modeForm}
              />
            )}
          />
          <Typography align='center' fontSize={20}>
            {t('SELL')}
          </Typography>
        </Box>

        <FormControl fullWidth sx={{ mt: 2, mb: 4 }}>
          <Typography align='left' sx={{}}>
            {t('LEVERAGE')}
          </Typography>
          <Controller
            name='intLeverage'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <Slider
                {...field}
                defaultValue={1}
                getAriaValueText={(value: number) => {
                  return `x${value}`
                }}
                step={1}
                valueLabelDisplay='auto'
                marks={getLeverages()}
                min={1}
                max={20}
                disabled={ModeForm.show === modeForm}
              />
            )}
          />
        </FormControl>

        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='dcmAmount'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                disabled={ModeForm.show === modeForm}
                {...field}
                type='number'
                label={t('AMOUNT')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>

        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='dcmAmountLimit'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                disabled={ModeForm.show === modeForm}
                {...field}
                type='number'
                label={t('AMOUNT_LIMIT')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>

        
        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='dcmPercentageStepLimit'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                disabled={ModeForm.show === modeForm}
                {...field}
                type='number'
                label={t('PERCENTAGE_STEP_LIMIT')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>

        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='dcmMinPriceLimit'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                disabled={ModeForm.show === modeForm}
                {...field}
                type='number'
                label={t('MIN_PRICE')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>

        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='dcmMaxPriceLimit'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                disabled={ModeForm.show === modeForm}
                {...field}
                type='number'
                label={t('MAX_PRICE')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>


        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='intNumberOfOrdersLimit'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                disabled={ModeForm.show === modeForm}
                {...field}
                type='number'
                label={t('NUMBER_OF_ORDERS_LIMIT')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>

        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='dcmStopLossPrice'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                disabled={ModeForm.show === modeForm}
                {...field}
                type='number'
                label={t('STOP_LOSS_PRICE')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>

        {(false && ModeForm.create !== modeForm) && (
          <Grid container sx={{ my: 2 }} spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Controller
                  name='dtmCreatedDate'
                  control={control}
                  render={({ field: { ...field }, fieldState }) => (
                    <TextField
                      variant='outlined'
                      disabled={true}
                      {...field}
                      label={t('CREATED_DATE')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Controller
                  name='dtmUpdatedDate'
                  control={control}
                  render={({ field: { ...field }, fieldState }) => (
                    <TextField
                      variant='outlined'
                      disabled={true}
                      {...field}
                      label={t('UPDATE_DATE')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={() => {
              setShowManualTraderTPForm(!showManualTraderTPForm)
            }}
            startIcon={<Icon icon={showManualTraderTPForm ? 'mdi:chevron-up' : 'mdi:chevron-down'} color='#9295A6' />}
          >
            <Typography sx={{ fontSize: 10 }}>{showManualTraderTPForm ? t('HIDE') : t('ADD_TP')}</Typography>
          </Button>
        </Box>
        {showManualTraderTPForm && (
          <Box sx={{ my: 4 }}>
            <ManualTraderTPForm getTps={(data) => {setTps(data)}} defaultTps={tps}></ManualTraderTPForm>
          </Box>
        )}

        <Box sx={{ display: 'flex', my: 1, mx: 2 }}>
          <Typography align='center' fontSize={22}>
            {t('IS_CYCLICAL')}
          </Typography>
          <Controller
            name='intIsCyclical'
            control={control}
            render={({ field: { ...field } }) => (
              <Switch
                defaultChecked={defaultValuesForm.intIsCyclical}
                {...field}
                disabled={ModeForm.show === modeForm}
              />
            )}
          />
        </Box>

        {(false && ModeForm.edit === modeForm) && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center' }}>
            <Box>
              <Avatar sx={{ bgcolor: 'primary.main', width: 50, height: 50 }} variant='rounded'>
                <Icon icon={statusWatch ? 'mdi:eye-outline' : 'mdi:eye-off-outline'} color='white' fontSize={30} />
              </Avatar>
            </Box>
            <Box>
              <Box>
                <Typography align='center' fontSize={22}>
                  {t('STATUS')}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Controller
                name='status'
                control={control}
                render={({ field: { ...field } }) => (
                  <Switch defaultChecked={defaultValuesForm.status} {...field} />
                )}
              />
            </Box>
          </Box>
        )}

        <Grid container>
          <Grid sx={{ p: 1 }} item xs={6}>
            <CustomLoadingBtn
              loading={isLoading}
              fullWidth
              size='large'
              type='submit'
              variant='contained'
              sx={{ mt: 3 }}
              
            >
              {t('SAVE')}
            </CustomLoadingBtn>
          </Grid>
          <Grid sx={{ p: 1 }} item xs={6}>
            <CustomBtn fullWidth size='large' variant='contained' onClick={() => closeFunc()} sx={{ mt: 3 }}>
              {t('CANCEL')}
            </CustomBtn>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

ManualTraderForm.defaultProps = {
  editForm: ModeForm.create
}

export default ManualTraderForm
