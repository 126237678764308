import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, ButtonGroup, Card, CardContent, Grid, IconButton, Typography } from '@mui/material'
import Icon from 'src/@core/components/icon'
import CustomAvatar from 'src/@core/components/mui/avatar'
import { Platform } from 'src/@http/platform'

interface Props {
  platform: Platform
  editFunction: (percents: any) => any
  deleteFunctionSuccess: () => any
}

const PlatformItemCard = ({ platform, editFunction, deleteFunctionSuccess }: Props) => {
  const { t } = useTranslation()
  const [show, setShow] = useState<boolean>(false)

  /*
  const deleteCupon = async () => {
    try {
      const res = await deletePromoCode(promocode.promoCodeId)
      toast.success(t('PROMOCODE_DELETE_MESSAGE_SUCCESS'))
      deleteFunctionSuccess()
    } catch (err: any) {
      toast.error(err)
    }
  }

  <DialogGeneric
          show={show}
          title={t('DELETE_CUPON')}
          confirmText={t('CONFIRM') || ''}
          confirmFunc={() => deleteCupon()}
          closeFunc={() => {
            setShow(false)
          }}
        >
          {t('DELETE_CUPON_MESSAGE')}
        </DialogGeneric>
  */

  const ActionButtons = () => {
    const buttons = [
      {
        icon: 'carbon:edit',
        action: () => {
          editFunction(platform)
        }
      },
      /*
      {
        icon: 'ph:trash-light',
        action: () => {
          setShow(true)
        }
      }
      */
    ]

    return (
      <Grid container sx={{ flexDirection: 'row', justifyContent: 'end' }}>
        <ButtonGroup>
          {buttons.map(button => (
            <Grid item key={button.icon}>
              <IconButton onClick={() => button.action()} aria-label='capture screenshot' color='secondary'>
                <Icon icon={button.icon} fontSize={16} />
              </IconButton>
            </Grid>
          ))}
        </ButtonGroup>
      </Grid>
    )
  }

  return (
    <Card sx={{ backgroundColor: 'background.default' }}>
      <CardContent sx={{ pr: 0 }}>
        <Grid container sx={{ flexDirection: 'row', width: '100%' }}>
          <Grid item xs={2}>
            <CustomAvatar color={platform.intStatusId === 1 ? 'primary' : 'secondary'} variant='rounded'>
              <Icon icon={platform.intStatusId === 1 ? 'mdi:layers' : 'akar-icons:stop'} color='white' />
            </CustomAvatar>
          </Grid>
          <Grid item sx={{ p: 1, pl: 1, fontSize: 18 }} xs={8}>
            {platform.platformName}
          </Grid>
          <Grid item xs={2} sx={{ justifyContent: 'end' }}>
            <ActionButtons />
          </Grid>
        </Grid>

        <Box sx={{ display: 'flex', mt: 2 }}>
          <Typography sx={{ mr: 2,fontSize: 12  }}> {t('ID')}:</Typography>
          <Typography sx={{ fontWeight: 400, fontSize: 12 }}>{platform.platformId}</Typography>
        </Box>
        <Box sx={{ display: 'flex', mt: 1 }}>
          <Typography sx={{ mr: 2,fontSize: 12  }}> {t('API_CODE')}:</Typography>
          <Typography sx={{ fontWeight: 400, fontSize: 12 }}>{platform.apiCode? platform.apiCode: 'null'}</Typography>
        </Box>
        <Box sx={{ display: 'flex', mt: 1 }}>
          <Typography sx={{ mr: 2,fontSize: 12  }}> {t('SERVICE_DOMAIN')}:</Typography>
          <Typography sx={{ fontWeight: 400, fontSize: 12 }}>{platform.vchServiceDomain}</Typography>
        </Box>
        
        
      </CardContent>
    </Card>
  )
}

export default PlatformItemCard
