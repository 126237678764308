import { useTranslation } from "react-i18next"
import { Avatar, Box, Card, CardContent, Typography } from "@mui/material"
import Icon from 'src/@core/components/icon'
import GenericTab, { Tab } from "src/layouts/components/GenericTab"

import SubscriptionCommissionWithdrawalManager from "./admin-subscription-commission-withdrawal/SubscriptionCommissionWithdrawalManager"
import SubscriptionTransactionManager from "./admin-subscription-transaction/SubscriptionTransactionManager"



const FinanceManager = () => {

    const { t } = useTranslation()

    const tabs: Tab[] = [
      {
        index: 0,
        label:  t('SUBSCRIPTION_PAYMENT'),
        component: <SubscriptionTransactionManager isContent />,
        icon: 'mdi:list-box-outline'
      },
      {
        index: 1,
        label: t('SUBSCRIPTION_COM_RETIRAMENT_REQUEST'),
        component: <SubscriptionCommissionWithdrawalManager isContent />,
        icon: 'mdi:list-box-outline'
      }
    ]


    return <Card >
    <CardContent sx={{}}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center',  flexWrap:'wrap' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar sx={{ width: 40, height: 40, bgcolor: 'primary.main' }} color='error' variant='rounded'>
            <Icon icon='mdi:finance' style={{ fontSize: 24 }} color='white' />
          </Avatar>
          <Typography sx={{ ml: 2 }} variant='h5'>
            { t('FINANCE')}
          </Typography>
        </Box>
      </Box>
      <GenericTab tabs={tabs}></GenericTab>
    </CardContent>
  </Card>
}

export default FinanceManager