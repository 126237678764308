import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar, Box, Button, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material'
import Icon from 'src/@core/components/icon'
import { DialogGeneric } from 'src/pages/components/generic/DialogGeneric'
import { ModeForm } from 'src/types'
import adminStore from 'src/zustand/admin'

import SubscriptionForm from './SubscriptionForm'
// import { logger } from 'src/utils/Logger'
import SubscriptionItemCard from './SubscriptionItemCard'
// import CustomBtn /*, { ActiveBtn }*/ from 'src/layouts/components/shared-component/CustomBtn'

interface Props {
  isContent: boolean
}

const SubscriptionManager = ({isContent}: Props) => {
  const [showSubscriptionRegisterDialog, setShowSubscriptionRegisterDialog] = useState<boolean>(false)
  const [subscriptionSelected, setSubscriptionSelected] = useState<API.Subscription.SubscriptionResponse | null>(null)
  const { t } = useTranslation()

  const openDialogEdit = (subscription: API.Subscription.SubscriptionResponse) => {
    setSubscriptionSelected(subscription)
    setShowSubscriptionRegisterDialog(true)
  }

  const openDialogCreate = () => {
    setSubscriptionSelected(null)
    setShowSubscriptionRegisterDialog(true)
  }

  const { subscriptions, getAllSubscriptions } = adminStore()
  const [notFound, setNotFound] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
      
      const init = async () => {
        setLoading(true)
        const subscriptionsList = await getAllSubscriptions()
        if(subscriptionsList.length === 0)
          setNotFound(true)
        setLoading(false)
      }
      init()
  }, [])

  const submitSuccessSubscriptions = async () => {
    setShowSubscriptionRegisterDialog(false)
    await getAllSubscriptions()
  }

  return (
    <Card  sx={{ ...(isContent && { border: 'none', boxShadow: 'none' }) }}>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {!isContent && <>
              <Avatar sx={{ bgcolor: 'primary.main', width: 40, height: 40 }} variant='rounded'>
              <Icon icon='mdi:dollar' style={{ fontSize: 24 }} color='white' />
            </Avatar>
            <Typography sx={{ m: 3 }} color='white' variant='h5' component='div'>
              {t('SUBSCRIPTIONS')}
            </Typography>
            </>}
          </Box>
          <Button onClick={() => openDialogCreate()} startIcon={<Icon icon='mdi:plus-box-outline' color='#9295A6' />}>
            <Typography color='#9295A6' sx={{ textTransform: 'capitalize' }}>
              {t('ADD')}
            </Typography>
          </Button>
        </Box>

        {subscriptions.length !== 0 && <Grid sx={{ mt: 3 }} container>
        {subscriptions.map((subscription: API.Subscription.SubscriptionResponse, key: number) => (
            <Grid key={key} item sx={{ p: 1 }} xs={12} sm={6} lg={6}>
              <SubscriptionItemCard
                subscription={subscription}
                editFunction={() => openDialogEdit(subscription)}
                deleteFunctionSuccess={() => getAllSubscriptions()}
              />
            </Grid>
          ))}
        </Grid>}
        {(notFound) && (
          <Typography align='center'>
            {t('DATA_NOT_FOUND')}
          </Typography>
        )}
        {(subscriptions.length === 0 && loading) && (
          <Grid sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Grid>
        )}

      </CardContent>
      <DialogGeneric
        show={showSubscriptionRegisterDialog}
        closeFunc={() => {
          setShowSubscriptionRegisterDialog(false)
        }}
        hideActions={true}
        title={subscriptionSelected ? t('SUBSCRIPTION_DETAIL') : t('CREATE_SUBSCRIPTION')}
        leftAline={true}
        showIconClose={true}
      >
        <Box sx={{ mt: 4 }}>
          <SubscriptionForm
            submitSuccess={() => {
              submitSuccessSubscriptions()
            }}
            modeForm={subscriptionSelected ? ModeForm.edit : ModeForm.create}
            {...(subscriptionSelected ? { valuesDefaultForm: subscriptionSelected } : {})}
          ></SubscriptionForm>
        </Box>
      </DialogGeneric>
    </Card>
  )
}

SubscriptionManager.defaultProps = {
  isContent: false
}


export default SubscriptionManager
