import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Divider, Grid, Typography, useTheme } from '@mui/material'
import Icon from 'src/@core/components/icon'
import {
  getDailyProfitGraphicByDates,
  getPercentageProfitGraphicByDates,
  getProfitGraphicByDates,
  PercentajeProfitItem,
  UserProfitItem
} from 'src/@http/user'
import SimpleTable from 'src/layouts/components/SimpleTabla'
import { User } from 'src/types/models/userModels'
import { logger } from 'src/utils/Logger'
import BarChartAxes from 'src/views/charts/BarChartAxes'
import LineChartAxes from 'src/views/charts/LineChartAxes'
import { IDate } from 'src/views/Filter/ButtonsFilter'
import RangeDateFilter from 'src/views/Filter/RangeDateFilter'
import profileStore, { ProfileStore, UserPlatform } from 'src/zustand/profile'

import StatisticsSkeleton from './StatisticsSkeleton'

const Statistics = () => {
  const { t } = useTranslation()
  const KUCOIN_ID = 3
  const theme = useTheme()
  const userDetail: User = profileStore((state: ProfileStore) => state.userDetail)
  const token: string = profileStore((state: ProfileStore) => state.token)
  const setperformanceDetail = profileStore((state: ProfileStore) => state.setperformanceDetail)

  const [profitWeek, setProfitWeek] = useState<UserProfitItem[]>([])
  const [profitByDates, setProfitByDates] = useState<UserProfitItem[]>([])
  const [percentageProfitByDates, setPercentageProfitByDates] = useState<PercentajeProfitItem[]>([])
  const [dailyProfitByDates, setDailyProfitByDates] = useState<UserProfitItem[]>([])
  const [todayProfit, setTodayProfit] = useState<UserProfitItem[]>([])
  const [moreDetails, setMoreDetails] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)

  /* Init Date */
  const today = new Date()
  today.setDate(today.getDate() - 1)
  const weekDate = new Date()
  weekDate.setDate(weekDate.getDate() - 7)
  const [rangeDates, setRangeDates] = useState<IDate>({
    dateFrom: weekDate,
    dateTo: today
  })
  const [rangeDatesWeek] = useState<IDate>({
    dateFrom: weekDate,
    dateTo: today
  })
  const [now, setNow] = useState<Date>(today)
  const userPlatform: UserPlatform = profileStore((state: ProfileStore) => state.userPlatform)

  /*
        const promises = [
        getProfitGraphicByDates(Number(userDetail.userId), rangeDates.dateFrom, rangeDates.dateTo),
        getPercentageProfitGraphicByDates(Number(userDetail.userId), rangeDates.dateFrom, rangeDates.dateTo),
        getDailyProfitGraphicByDates(239, rangeDates.dateFrom, rangeDates.dateTo) //Number(userDetail.userId)
      ]

  */
  useEffect(() => {
    const init = async () => {
      setLoading(true)

      try {
        if(userPlatform.platformId !== 0){
        const data = await getProfitGraphicByDates(Number(userDetail.userId), rangeDates.dateFrom, rangeDates.dateTo)
        setProfitByDates(data)
        }
      } catch (error) {
        logger.log('[getProfitGraphicByDates] Error: ', error)
      }

      try {
        if(userPlatform.platformId !== 0){
          const data = await getPercentageProfitGraphicByDates(
            Number(userDetail.userId),
            rangeDates.dateFrom,
            rangeDates.dateTo
          )
          setPercentageProfitByDates(data)
        }
        
      } catch (error) {
        logger.log('[getPercentageProfitGraphicByDates] Error: ', error)
      }

      try {
        if(userPlatform.platformId !== 0){
        const data = await getDailyProfitGraphicByDates(
          Number(userDetail.userId),
          rangeDates.dateFrom,
          rangeDates.dateTo
        )
        setDailyProfitByDates(data)
        if (profitWeek.length === 0) setProfitWeek(data)
        }
      } catch (error) {
        logger.log('[getDailyProfitGraphicByDates] Error: ', error)
      }

      try {
        if(userPlatform.platformId !== 0){
        const today = new Date()
        const data = await getDailyProfitGraphicByDates(Number(userDetail.userId), today, today)
        setTodayProfit(data)
        }
      } catch (error) {
        logger.log('[getDailyProfitGraphicByDates] Error: ', error)
      }

      setLoading(false)
    }
    if (Number(userDetail.userId) !== 0 && token !== '') init()
  }, [rangeDates, userDetail, token])

  const colorLabel = (value: number) => {
    return value == 0 ? 'default' : value > 0 ? '#03A66D' : 'error'
  }

  const handleDates = async (dates: IDate) => {
    //const userId: number = typeof userDetail.userId === 'string' ? parseInt(userDetail.userId) : userDetail.userId
    if (dates.dateFrom && dates.dateTo) {
      setRangeDates(dates)
      /*
      const title = t('FROM_TO', {
        from: dates.dateFrom.toISOString().slice(0, 10),
        to: dates.dateTo.toISOString().slice(0, 10)
      })
      setperformanceDetail(userId, dates.dateFrom, dates.dateTo, title, 0)
      */
    }
  }

  const todayPL = useMemo(() => {
    if (todayProfit.length !== 0) return parseFloat(todayProfit[0].ProfitAmount)
    else return 0
  }, [todayProfit])

  const cumulativePLWeek = useMemo(() => {
    return profitWeek.length === 0
      ? 0
      : profitWeek.reduce(
          (accumulator: number, currentValue: UserProfitItem) => accumulator + parseFloat(currentValue.ProfitAmount),
          0
        )
  }, [profitWeek])

  const cumulativePL = useMemo(() => {
    return profitByDates.length === 0 ? 0 : parseFloat(profitByDates[profitByDates.length - 1].ProfitAmount)
  }, [profitByDates])

  const cumulativePercentagePL = useMemo(() => {
    return percentageProfitByDates.length === 0
      ? 0
      : parseFloat(percentageProfitByDates[percentageProfitByDates.length - 1].PercentajeProfit)
  }, [percentageProfitByDates])

  /* Inicio Graficos */

  const dailyProfitByDatesList = useMemo(() => {
    return dailyProfitByDates.map(value => {
      return {
        ProfitAmount: '$ ' + value.ProfitAmount,
        CreatedDate: value.CreatedDate.slice(0, 10)
      }
    })
  }, [dailyProfitByDates])

  const dailyProfitByDatesListReverse = useMemo(() => {
    return dailyProfitByDates
      .map(value => {
        return {
          ProfitAmount: '$ ' + value.ProfitAmount,
          CreatedDate: value.CreatedDate.slice(0, 10)
        }
      })
      .reverse()
  }, [dailyProfitByDates])

  const dailyProfitByDatesBar = useMemo(() => {
    return dailyProfitByDates.map(value => {
      return {
        ProfitAmount: value.ProfitAmount,
        CreatedDate: value.CreatedDate.slice(0, 10)
      }
    })
  }, [dailyProfitByDates])

  const profitByDatesLine = useMemo(() => {
    return profitByDates.map(value => {
      return {
        ProfitAmount: value.ProfitAmount,
        CreatedDate: value.CreatedDate.slice(0, 10)
      }
    })
  }, [profitByDates])

  const percentageProfitByDatesLine = useMemo(() => {
    return percentageProfitByDates.map(value => {
      return {
        PercentajeProfit: value.PercentajeProfit,
        CreatedDate: value.CreatedDate.slice(0, 10)
      }
    })
  }, [percentageProfitByDates])

  /* Fin Graficos */

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6}></Grid>
        <Grid item xs={12} md={6}>
          <Typography color='#9295A6' fontSize={14} sx={{ fontStyle: 'italic', mt: 3 }}>
            {t('LAST_UPDATED')}: {now.toISOString().slice(0, 10) + ' 00:00:00'} (UTC)
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 2, mb: 2 }}>
        <Grid item xs={12} md={6}>
          <Typography color='#9295A6' fontSize={14}>
            {t('TODAYS_P&L')}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
            <Typography sx={{ pr: 2 }} fontWeight={600} fontSize={24} color={colorLabel(todayPL)}>
              {todayPL.toFixed(2)}
            </Typography>
            <Typography fontSize={14}>USD</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography color='#9295A6' fontSize={14}>
            {t('7DAYS_P&L')}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
            <Typography sx={{ pr: 2 }} fontWeight={600} fontSize={24} color={colorLabel(cumulativePLWeek)}>
              {cumulativePLWeek.toFixed(2)}
            </Typography>
            <Typography fontSize={14}>USD</Typography>
          </Box>
        </Grid>
      </Grid>

      {false && (
        <Divider>
          <Button
            onClick={() => {
              setMoreDetails(!moreDetails)
            }}
            startIcon={<Icon icon={moreDetails ? 'mdi:chevron-up' : 'mdi:chevron-down'} color='#9295A6' />}
          >
            <Typography sx={{ fontSize: 10 }}>{moreDetails ? t('HIDE') : t('MORE_DETAILS')}</Typography>
          </Button>
        </Divider>
      )}

      {moreDetails && (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <RangeDateFilter initDate={rangeDatesWeek} handleDates={handleDates} maxDate={now}></RangeDateFilter>
          </Box>
          {false && <StatisticsSkeleton></StatisticsSkeleton>}

          <Grid container sx={{ mt: 4 }}>
            <Grid item xs={12} md={6} order={1}>
              <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                <Typography fontSize={18} fontWeight={600} sx={{ pr: 3 }}>
                  {t('CUMULATIVE_P&L')}
                </Typography>
                <Typography sx={{ pr: 2 }} fontWeight={600} fontSize={17} color={colorLabel(cumulativePL)}>
                  {cumulativePL.toFixed(2)}
                </Typography>
                <Typography fontSize={12}>USD</Typography>
              </Box>

              <LineChartAxes
                title={t('CUMULATIVE_P&L')}
                lineColor={theme.palette.primary.main}
                data={profitByDatesLine}
                keyData='ProfitAmount'
                keyLabels='CreatedDate'
              />
            </Grid>
            {userPlatform.platformId !== KUCOIN_ID && (
              <Grid item xs={12} md={6} order={2}>
                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                  <Typography fontSize={18} fontWeight={600} sx={{ pr: 3 }}>
                    {t('CUMULATIVE_P&L')} %
                  </Typography>
                  <Typography sx={{ pr: 2 }} fontWeight={600} fontSize={17} color={colorLabel(cumulativePercentagePL)}>
                    {cumulativePercentagePL.toFixed(2)}%
                  </Typography>
                </Box>
                <LineChartAxes
                  title={t('CUMULATIVE_P&L') + ' %'}
                  lineColor='#B15EFF'
                  data={percentageProfitByDatesLine}
                  keyData='PercentajeProfit'
                  keyLabels='CreatedDate'
                />
              </Grid>
            )}

            <Grid item xs={12} md={6} order={userPlatform.platformId !== KUCOIN_ID ? 3 : 4}>
              <Typography fontSize={18} fontWeight={600} sx={{ pr: 3 }}>
                {t('DAILY_P&L')}
              </Typography>
              <BarChartAxes
                title={t('DAILY_P&L')}
                lineColor='#B15EFF'
                data={dailyProfitByDatesBar}
                keyData='ProfitAmount'
                keyLabels='CreatedDate'
              ></BarChartAxes>
            </Grid>
            <Grid item xs={12} md={6} order={userPlatform.platformId !== KUCOIN_ID ? 4 : 3}>
              <Typography fontSize={18} fontWeight={600} sx={{ pr: 3 }}>
                {t('DAILY_P&L_DETAILS')}
              </Typography>
              <SimpleTable
                sx={{ mx: 4 }}
                headers={[
                  { key: 'CreatedDate', label: t('TIME') },
                  { key: 'ProfitAmount', label: t('DAILY_P&L') }
                ]}
                data={dailyProfitByDatesListReverse}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}

export default Statistics
