import { AlertColor } from '@mui/material'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import { getPromotionBanner,PromotionBanner } from 'src/@http/PromotionBanner'
import { Menu } from 'src/pages/admin'
import { Strategy } from 'src/pages/components/home/ColumnsStrategyGrid'
import { logger } from 'src/utils/Logger'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'



interface Dictionary<T> {
  [Key: string]: T
}

export interface IAlert {
  message: string
  severity: AlertColor
  navigation: string
  persistent: boolean
  visible?: boolean
}

interface Dialogs {
  login: boolean
  register: boolean
  forgotPassword: boolean
}

type State = {
  promotionBanners: PromotionBanner[]
  menuSeletedAdmin: Menu
  showDialog: Dialogs
  alert: IAlert
  strategies: Strategy[]
}

type Actions = {
  setMenuSeletedAdmin: (menuSeletedAdmin: Menu) => void
  setShowDialog: (key: keyof Dialogs, show: boolean) => void
  showAlert: (data: IAlert) => void
  hideAlert: () => void
  setStrategies: (strategies: Strategy[]) => void
  setPromotionBanner: () => void
}

const initialState: State = {
  promotionBanners: [],
  menuSeletedAdmin: {
    label: '',
    path: 'customer-manager',
    icon: 'mdi:account-group'
  },
  showDialog: {
    login: false,
    register: false,
    forgotPassword: false
  },
  alert: {
    visible: false,
    message: '',
    severity: 'warning',
    navigation: '',
    persistent: false
  },
  strategies: []
}

const appStore = create<State & Actions>()(
  persist(
    (set, get) => ({
      ...initialState,
      setMenuSeletedAdmin: (menuSeletedAdmin: Menu) => {
        set({ menuSeletedAdmin })
      },
      setShowDialog: (key: keyof Dialogs, show: boolean) => {
        const showDialog = { ...get().showDialog }
        showDialog[key] = show
        set({ showDialog })
      },
      setPromotionBanner: async () => {
        try {
          const promotionBanners = await getPromotionBanner()
          set({ promotionBanners })
        } catch (error) {
          logger.log('[getPromotionBanner] Error: ', error)
        }
      },
      showAlert: (data: IAlert) => {
        const alert: IAlert = {
          message: data.message,
          severity: data.severity,
          navigation: data.navigation ? data.navigation : '',
          persistent: data.persistent ? data.persistent : false,
          visible: true
        }
        set({ alert })
      },
      hideAlert() {
        const alert: IAlert = { ...get().alert, visible: false, persistent: false }
        set({ alert })
      },
      setStrategies(strategies: Strategy[]){
        set({strategies})
      }
    }),
    {
      name: 'app-store'
    }
  )
)

if (process.env.NODE_ENV === 'development') mountStoreDevtool('app-store', appStore)

export default appStore

export interface AppStore extends State, Actions {}
