import { useEffect } from 'react'
import { Card, CardContent } from '@mui/material'
import ItemsScroller from 'src/layouts/components/ItemScroller'
import newsStore from 'src/zustand/news'

import NewsItemCard from './NewsItemCard'



const NewsManagerCards = () => {
  const {  newsInit } = newsStore()

  useEffect(() => {
    //getAllCommissionRank()
  }, [])

  return (
    <Card>
      <CardContent sx={{ px: 0 }}>
        <ItemsScroller list={newsInit} itemCard={NewsItemCard} increment={6}></ItemsScroller>
      </CardContent>
    </Card>
  )
}

export default NewsManagerCards