// ** React Import
import { useEffect } from 'react'
// ** Third Party Import
import { useTranslation } from 'react-i18next'
// ** Custom Components Imports
// ** Type Import
import { Settings } from 'src/@core/context/settingsContext'
import TextOptionsMenu from 'src/layouts/components/shared-component/TextOptionsMenu'

interface Props {
  settings: Settings
  saveSettings: (values: Settings) => void
}

enum Language {
  SPANISH = 'es',
  ENGLISH = 'en'
  // PORTUGUESE = 'pt'
}

const languages: { key: string; lang: Language }[] = [
  { key: 'INGLES', lang: Language.ENGLISH },
  { key: 'ESPANOL', lang: Language.SPANISH }
  // { key: 'PORTUGUES', lang: Language.PORTUGUESE }
]

const langDic = languages.reduce((result: any, item: any) => {
  result[item.lang] = item.key

  return result
}, {})

const LanguageDropdown = ({ settings, saveSettings }: Props) => {
  // ** Hook
  const { t, i18n } = useTranslation()

  // ** Vars
  const { layout } = settings

  const handleLangItemClick = (lang: Language.SPANISH | Language.ENGLISH) => {
    i18n.changeLanguage(lang).then()
  }

  // ** Change html `lang` attribute when changing locale
  useEffect(() => {
    document.documentElement.setAttribute('lang', i18n.language)
  }, [i18n.language])

  const options = languages.map(language => {
    return {
      text: t(language.key),
      menuItemProps: {
        sx: { py: 2 },
        selected: i18n.language === language.lang,
        onClick: () => {
          handleLangItemClick(language.lang)
          saveSettings({ ...settings, direction: 'ltr' })
        }
      }
    }
  })

  return (
    <TextOptionsMenu
      menuProps={{ sx: { '& .MuiMenu-paper': { mt: 4, minWidth: 130 } } }}
      iconButtonProps={{ color: 'inherit', sx: { ...(layout === 'vertical' ? { mr: 0.75 } : { mx: 0.75 }) } }}
      options={options}
      text={langDic[i18n.language]}
    />
  )
}

export default LanguageDropdown
