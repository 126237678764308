import { MouseEvent, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Box, IconButton, Menu, MenuItem } from '@mui/material'
import Icon from 'src/@core/components/icon'
import { deleteGribotRSI,GridBotRSI } from 'src/@http/gridbot-rsi'
import { ModeForm } from 'src/types'
import newsStore from 'src/zustand/news'

import { DialogGeneric } from '../../../generic/DialogGeneric'

import GridBotRSIForm from './GridBotRSIForm'

const userStatusObj: UserStatusType = {
  active: 'success',
  pending: 'warning',
  inactive: 'secondary'
}

interface Props {
  row: GridBotRSI,
  refreshList: () => void
}

const RowGridBotRSIOptions = ({ row,  refreshList}: Props) => {
  // ** State
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [show, setShow] = useState<boolean>(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [modeForm, setModeForm] = useState<ModeForm>(ModeForm.edit)
  const [valuesDefaultForm, setValuesDefaulForm] = useState<GridBotRSI | null>(null)
  const { t } = useTranslation()
  const { getNewsCategory } = newsStore()

  const rowOptionsOpen = Boolean(anchorEl)

  const handleRowOptionsClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleRowOptionsClose = () => {
    setAnchorEl(null)
  }

  const handleDelete = () => {
    handleRowOptionsClose()
  }

  const deleteItemSelected = async () => {
    try {
      await deleteGribotRSI(row.intGridBotRSIId)
      setShowDeleteDialog(false)
      handleDelete()
      toast.success(t('DELETED_SUCCESS'), { duration: 4000 })
      refreshList()
    } catch (err: any) {
      toast.error(err.message, { duration: 4000 })
    }
  }

  return (
    <>
      <IconButton size='small' onClick={handleRowOptionsClick}>
        <Icon icon='mdi:dots-vertical' />
      </IconButton>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={rowOptionsOpen}
        onClose={handleRowOptionsClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        PaperProps={{ style: { minWidth: '8rem' } }}
      >
        <MenuItem
          onClick={() => {
            setValuesDefaulForm(row)
            setModeForm(ModeForm.edit)
            setShow(true)
            handleRowOptionsClose()
          }}
          sx={{ '& svg': { mr: 2 } }}
        >
          <Icon icon='mdi:pencil-outline' fontSize={20} />
          {t('EDIT')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setShowDeleteDialog(true)
            handleRowOptionsClose()
          }}
          sx={{ '& svg': { mr: 2 } }}
        >
          <Icon icon='mdi:delete-outline' fontSize={20} />

          {t('DELETE')}
        </MenuItem>
      </Menu>
      <DialogGeneric
        title='DELETE_GRID_BOT_RSI'
        show={showDeleteDialog}
        confirmText='CONFIRM'
        closeFunc={() => {
          setShowDeleteDialog(false)
        }}
        confirmFunc={deleteItemSelected}
      >
        {t('CONFIRM_DELETE_GRID_BOT_RSI')}
      </DialogGeneric>
      <DialogGeneric
        show={show}
        hideActions={true}
        showIconClose={true}
        closeFunc={() => {
          setShow(false)
        }}
        title={t('GRID_BOT_RSI_DETAILS')}
      >
        <Box sx={{ pt: 4 }}>
          <GridBotRSIForm
            valuesDefaultForm={valuesDefaultForm}
            modeForm={modeForm}
            submitLoading={submitLoading}
            closeFunc={() => {
              setShow(false)
            }}
            intGridBotId={row.intGridBotId}
            refreshList={refreshList}
          ></GridBotRSIForm>
        </Box>
      </DialogGeneric>
    </>
  )
}

export { RowGridBotRSIOptions }
