import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Typography } from '@mui/material'
import { ReferredUser, ReferredUserTree } from 'src/@http/user'
import CustomBtn from 'src/layouts/components/shared-component/CustomBtn'
import { IDate } from 'src/views/Filter/ButtonsFilter'

import SimpleDateFilter from './Filters/SimpleDateFilter'

interface Props {
  allData: ReferredUserTree[]
  setData: (data: ReferredUser[]) => void
}

interface Criterial {
  nameFilter: string
  props?: { [key: string]: any }
}

const ReferredUsersFilters = ({ allData, setData }: Props) => {
  const { t } = useTranslation()
  const [activesFilters, setActivesFilters] = useState<Criterial[]>([])
  const [onlyActives, setOnlyActives] = useState<boolean>(false)

  useEffect(() => {
    let filtedData: ReferredUser[] = allData
    console.log('filtedData', filtedData)
    activesFilters.forEach((criterial: Criterial) => {
      if (criterial.nameFilter === 'DATE') {
        filtedData = allData.filter((referredUser: ReferredUser) => {
          if (criterial.props) {
            const dateObj = new Date(referredUser.dtmCreatedDate)
            criterial.props.dateFrom.setHours(0, 0, 0, 0)
            criterial.props.dateTo.setHours(0, 0, 0, 0)
            dateObj.setHours(0, 0, 0, 0)

            return dateObj >= criterial?.props.dateFrom && dateObj <= criterial?.props.dateTo
          }
        })
      }

      if (criterial.nameFilter === 'ONLY_ACTIVES') {
        filtedData = filtedData.filter((referredUser: ReferredUser) => {
          return (
            (referredUser.blnCurrentStrategyIsActive === 'Active' &&
              referredUser.blnCurrentStrategyIsActive === 'Active') ||
            Number(referredUser.level) === 0 ||
            allData.some(value => value.intReferredUserId === referredUser.intUserId && value.blnCurrentStrategyIsActive === 'Active' &&
            value.blnCurrentStrategyIsActive === 'Active')
          )
        })
      }
    })

    setData(filtedData)
  }, [activesFilters])

  return (
    <Box>
      <Typography sx={{ mb: 1, mt: 2, fontSize: 18, fontWeight: 500 }}>{t('FILTERS')}:</Typography>
      <Grid container sx={{ display: 'flex' }}>
        <Grid item xs={12} md={6}>
          <SimpleDateFilter
            handleDates={(dates: IDate) => {
              const exist = activesFilters.some(value => value.nameFilter === 'DATE')
              if (exist) setActivesFilters([...activesFilters.filter(value => value.nameFilter !== 'DATE')])
              setActivesFilters([...activesFilters, { nameFilter: 'DATE', props: { ...dates } }])
            }}
            reset={() => {
              setActivesFilters([...activesFilters.filter(value => value.nameFilter !== 'DATE')])
            }}
            maxDate={new Date()}
            initDate={{
              dateFrom: new Date(),
              dateTo: new Date()
            }}
          ></SimpleDateFilter>
          <CustomBtn
            variant={onlyActives ? 'contained' : 'outlined'}
            sx={{ mr: 2 }}
            onClick={() => {
              if (onlyActives) {
                setActivesFilters([...activesFilters.filter(value => value.nameFilter !== 'ONLY_ACTIVES')])
              } else {
                setActivesFilters([...activesFilters, { nameFilter: 'ONLY_ACTIVES', props: {} }])
              }
              setOnlyActives(!onlyActives)
            }}
          >
            {t('ONLY_ACTIVE')}
          </CustomBtn>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ReferredUsersFilters
