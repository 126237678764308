import { ReferredUser, ReferredUserTree } from 'src/@http/user'

import transformNode from './transformNode'

const transformArrayToTree = (inputArray: ReferredUser[], parentId: number) => {
  const result: ReferredUserTree[] = []
  inputArray.forEach(item => {
    const aux: ReferredUserTree = { ...item, id: `${item.intUserId}` }
    if (item.intReferredUserId === parentId) {
      const children = transformArrayToTree(inputArray, item.intUserId)
      if (children.length) {
        aux.children = children
      }
      result.push(transformNode(aux))
    }
  })


  return result
}

export default transformArrayToTree
