import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, FormControl, Grid, IconButton, Slider, TextField, Typography } from '@mui/material'
import Icon from 'src/@core/components/icon'
import { CustomLoadingBtn } from 'src/layouts/components/shared-component/CustomBtn'
import * as yup from 'yup'

interface Props {
    getTps: (data: TPForm[]) => void
    defaultTps: TPForm[]
}

export interface TPForm {
  dcmPercentageSize: number
  dcmPrice: number
}

const ManualTraderTPForm = ({getTps, defaultTps}: Props) => {
  const { t } = useTranslation()
  const [tps, setTPs] = useState<TPForm[]>(defaultTps)

  const defaultValuesForm: TPForm = {
    dcmPercentageSize: 0,
    dcmPrice: 0
  }
  const validations = {
    dcmPercentageSize: yup.number().positive().min(1).max(100).required(),
    dcmPrice: yup.number().positive().required()
  }

  const schemaValidations = yup.object().shape(validations)

  const methods = useForm<TPForm>({ resolver: yupResolver(schemaValidations), defaultValues: defaultValuesForm })
  const {
    control,
    handleSubmit,
    formState: { errors,  },
    trigger,
    watch,
    reset
  } = methods

  const onSubmit = async () => {
     const priceError = await trigger('dcmPrice')
      const percentageError = await trigger('dcmPercentageSize')
      if(priceError && percentageError){
        const form = watch()
        if (!tps.some(value => value.dcmPercentageSize === form.dcmPercentageSize && value.dcmPrice === form.dcmPrice))
        setTPs([...tps, form])
        reset()
      }
  }

  const remove = (tp: TPForm) => {
    const filter = tps.filter(
      value => !(value.dcmPrice === tp.dcmPrice && value.dcmPercentageSize === tp.dcmPercentageSize)
    )
    setTPs(filter)
  }

  useEffect(() => {
    getTps(tps)
  }, [tps])

  const genericMarks = (
    minValue: number,
    maxValue: number,
    decimalCount: number,
    minAllowed: number
  ): { label: string; value: number }[] => {
    if (minValue >= maxValue) {
      return []
    }

    if (decimalCount < 0) {
      return []
    }

    const values = [
      minAllowed,
      minValue,
      minValue + 0.25 * (maxValue - minValue),
      minValue + 0.5 * (maxValue - minValue),
      minValue + 0.75 * (maxValue - minValue),
      maxValue
    ]

    const formattedObjects = values.map(value => {
      const formattedValue = value.toFixed(decimalCount)

      return { label: formattedValue+' %', value: parseFloat(formattedValue) }
    })

    return formattedObjects
  }

  return (
    <>
      <form noValidate autoComplete='off' >
        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='dcmPrice'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                {...field}
                type='number'
                label={t('PRICE')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>

        <FormControl fullWidth sx={{ my: 2 }}>
          <Typography align='left' sx={{}}>
            {t('PERCENTAGE')}
          </Typography>
          <Controller
            name='dcmPercentageSize'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <Slider
                {...field}
                step={1}
                valueLabelDisplay='auto'
                min={1}
                max={100}
                marks={genericMarks(0,100,0,1)}
              />
            )}
          />
        </FormControl>


        <Grid container>
          <Grid sx={{ p: 1 }} item xs={12}>
            <CustomLoadingBtn
              fullWidth
              size='large'
              variant='contained'
              onClick={() => {
                onSubmit()
              }}
              sx={{ mt: 3 }}
            >
              {t('ADD')}
            </CustomLoadingBtn>
          </Grid>
        </Grid>
      </form>
      {tps.map((value, key) => {
        return (
          <Box key={key} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ mr: 2 }}>
              {`${t('PRICE')}: $`}
              {Number(value.dcmPrice).toFixed(2)} - {`${t('PERCENTAGE')}: `}
              {value.dcmPercentageSize}%
            </Typography>
            <IconButton
              onClick={() => {
                remove(value)
              }}
            >
              <Icon icon={'mdi:close-circle-outline'} color='white' fontSize={30} />
            </IconButton>
          </Box>
        )
      })}
    </>
  )
}

export default ManualTraderTPForm
