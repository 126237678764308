import { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { CircularProgress, FormControl, IconButton, InputAdornment, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import { generateCode } from 'src/@http/2fa'
import profileStore, { ProfileStore } from 'src/zustand/profile'

interface Props {
  successFunc: () => void
  control: any
}

const TwoFactorAuthField = ({ successFunc, control }: Props) => {
  const initialSec = 300
  const { t } = useTranslation()
  const [enableCounter, setEnableCounter] = useState<boolean>(false)
  const [counter, setCounter] = useState<number>(initialSec + 1)
  const [isLoadingEmail, setIsLoadingEmail] = useState<boolean>(false)
  const userDetail = profileStore((state: ProfileStore) => state.userDetail)
  const theme = useTheme()
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    if (counter > 0 && enableCounter) {
      const timer = setInterval(() => setCounter(counter - 1), 1000)

      return () => clearInterval(timer)
    } else {
      setEnableCounter(false)
    }
  }, [counter])

  const validateEmail = async () => {
    try {
      setIsLoadingEmail(true)
      await generateCode(userDetail.email)
      toast.success(t('VALIDATION_CODE_SENT_EMAIL'), { duration: 4000 })
      successFunc()
    } catch (error) {
    } finally {
      setIsLoadingEmail(false)
      setEnableCounter(true)
      setCounter(initialSec)
    }
  }

  return (
    <FormControl fullWidth sx={{ my: 2 }}>
      <Controller
        name='code'
        control={control}
        render={({ field: { ...field }, fieldState }) => (
          <TextField
            {...field}
            error={!!fieldState.error}
            variant='outlined'
            label={lessThanSmall? '': t('THE_CODE_WILL_ARRIVE_IN_YOUR_EMAIL')}
            helperText={lessThanSmall? t('THE_CODE_WILL_ARRIVE_IN_YOUR_EMAIL'): fieldState.error?.message}
            sx={{ mb: 2 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    edge='end'
                    style={{ backgroundColor: 'transparent' }}
                    onMouseDown={e => e.preventDefault()}
                    onClick={validateEmail}
                    disabled={enableCounter}
                    color={'primary'}
                  >
                    {enableCounter && <Typography sx={{ fontSize: 15 }}>{counter} seg</Typography>}
                    {!isLoadingEmail && !enableCounter && (
                      <Typography
                        color={enableCounter ? 'default' : 'primary'}
                        style={{ cursor: 'pointer' }}
                        sx={{ fontSize: 15 }}
                      >
                        {t('SEND_VALIDATION')}
                      </Typography>
                    )}
                    {isLoadingEmail && !enableCounter && <CircularProgress size={25} />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        )}
      />
    </FormControl>
  )
}

export default TwoFactorAuthField
