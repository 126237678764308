import i18n from 'i18next'
import { ReferredUser } from "src/@http/user"

export interface ReferredNodeColor {
    color: string
    label: string
    total: number
  }

const totalizeByColors = (referredUsers: ReferredUser[]) : ReferredNodeColor[] => {
    const totals = { activeStrategy: 0, activeSubscription: 0, inactiveSubscription: 0 }

    referredUsers.reduce((accumulator, referreduser) => {
      if (referreduser.level !== '0')
        if (referreduser.blnCurrentStrategyIsActive === 'Active') {
          accumulator.activeStrategy += 1
        } else {
          if (referreduser.blnActiveSubscription === 'Active') {
            accumulator.activeSubscription += 1
          } else {
            accumulator.inactiveSubscription += 1
          }
        }

      return accumulator
    }, totals)

    return [
      {
        color: '#DC2626',
        label: i18n.t('INACTIVE_SUBSCRIPTION'),
        total: totals.inactiveSubscription
      },
      {
        color: '#FDDA0D',
        label: i18n.t('INACTIVE_STRATEGY'),
        total: totals.activeSubscription
      },
      {
        color: '#03A66D',
        label: i18n.t('ACTIVE_STRATEGY'),
        total: totals.activeStrategy
      }
    ]

}

export {totalizeByColors}