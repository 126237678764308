import { getAsync } from 'src/@http'
import { StrategyModel } from 'src/types/models/strategyModel'

/* Endpoints definition */

export interface apiCredentials {
  vchApiKey: string
  vchApiSecret: string
}

const GetStrategy = 'strategy/'

export const getStrategies = (): Promise<StrategyModel[]> => {
  return getAsync(GetStrategy)
}
