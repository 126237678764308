import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar, Box, Card, CardContent, FormControlLabel, Switch, Typography, useMediaQuery } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { DataGrid } from '@mui/x-data-grid'
import Icon from 'src/@core/components/icon'
import adminStore from 'src/zustand/admin'

import CustomToolbarAdminGrid from '../../CustomToolbarAdminGrid'

import columns from './ColumnsSubscriptionTransactionGrid'
import SubscriptionTransactionManagerCards from './SubscriptionTransactionManagerCards'

interface Props {
  isContent: boolean
}


const SubscriptionTransactionManager = ({isContent}: Props) => {
  const { t } = useTranslation()

  const { getsubscriptionTransaction, subscriptionTransaction } = adminStore()

  const [onlyPending, setOnlyPending] = useState<boolean>(true)
  
  const handleOnlyPending = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOnlyPending(event.target.checked)
  }

  useEffect(() => {
    getsubscriptionTransaction()
  }, [])

  const subscriptionTransactionFilted = useMemo(() => {
    if (onlyPending === true) return subscriptionTransaction.filter(value => value.intStatusId === 0)
    else return subscriptionTransaction
  }, [onlyPending, subscriptionTransaction])

  const showMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  return (
    <Card sx={{ ...(isContent && { border: 'none', boxShadow: 'none' }) }}>
      <CardContent sx={{}}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {!isContent && <>
              <Avatar sx={{ width: 40, height: 40, bgcolor: 'primary.main' }} color='error' variant='rounded'>
              <Icon icon='mdi:list-box-outline' style={{ fontSize: 24 }} color='white' />
            </Avatar>
            <Typography sx={{ ml: 2 }} variant='h5'>
              {t('SUBSCRIPTIONS_TRANSACTIONS')}
            </Typography>
            </>}
          </Box>
        </Box>
        <Box>
          <FormControlLabel
            control={<Switch defaultChecked checked={onlyPending} onChange={handleOnlyPending} />}
            label={t('ONLY_PENDING')}
            sx={{ mt: 2 }}
          />
        </Box>
        {!showMobile && (
          <div onClick={event => event.stopPropagation()}>
            <DataGrid
              sx={{
                mt: 4,
                width: '100%'
              }} //overflowX: 'scroll'
              autoHeight
              rows={subscriptionTransactionFilted}
              getRowId={r => r.intSubscriptionTransactionId}
              columns={columns}
              pageSize={10}
              disableSelectionOnClick
              rowsPerPageOptions={[5]}
              loading={subscriptionTransaction.length === 0}
              components={{ Toolbar: CustomToolbarAdminGrid }}
            />
          </div>
        )}
        {showMobile && <SubscriptionTransactionManagerCards items={subscriptionTransactionFilted} />}
      </CardContent>
      
    </Card>
  )
}

SubscriptionTransactionManager.defaultProps = {
  isContent: false
}


export default SubscriptionTransactionManager
