// ** React Imports
import { Fragment, SyntheticEvent, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { IconButton, useMediaQuery } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Badge from '@mui/material/Badge'
// ** MUI Imports
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import jwtDecode from 'jwt-decode'
// ** Icon Imports
import Icon from 'src/@core/components/icon'
// ** Type Imports
import { Settings } from 'src/@core/context/settingsContext'
import { FontWeight } from 'src/layouts/TypographyTheme'
import { emailFormat } from 'src/pages/components/profile/UserProfile'
// ** Context
// ** Redux imports
import appStore, { AppStore } from 'src/zustand/app'
import profileStore, {ProfileStore} from 'src/zustand/profile'

interface Props {
  settings: Settings
}

// ** Styled Components
const BadgeContentSpan = styled('span')(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
}))

const UserDropdown = (props: Props) => {
  const user = profileStore((state:ProfileStore) => state.userDetail)
  const avatarUrl = '/images/avatars/user-lg.png'

  // ** Props
  const { settings } = props

  // ** States
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  // ** Hooks
  const navigate = useNavigate()

  const logout = profileStore((state:ProfileStore) => state.logout)
  const { t } = useTranslation()
  const hideAlert = appStore((state: AppStore) => state.hideAlert)
  // ** Vars
  const { direction } = settings

  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = (url?: string) => {
    if (url) {
      navigate(url)
    }
    setAnchorEl(null)
  }

  const styles = {
    py: 2,
    px: 4,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    color: 'text.primary',
    textDecoration: 'none',
    '& svg': {
      mr: 2,
      fontSize: '1.375rem',
      color: 'text.primary'
    }
  }

  const handleLogout = () => {
    hideAlert()
    logout()
    navigate('/')
  }

  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('lg'))

  const token: string = profileStore((state: ProfileStore) => state.token)
  interface Token {
    id: number
    email: string
    roles: Array<string>
  }
  const isAdmin = useMemo(() => {
    if (token !== '') {
      try {
        const dataToken: Token = jwtDecode(token)

        return dataToken.roles.includes('ADMIN')
      } catch {
        return false
      }
    }
  }, [token])

  return (
    <Fragment>
      {false && (
        <>
          <Badge
            overlap='circular'
            onClick={handleDropdownOpen}
            sx={{ ml: 2, cursor: 'pointer' }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
          >
            <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
              <Avatar
                alt={user.firstName + user.lastName}
                onClick={handleDropdownOpen}
                sx={{ width: 64, height: 64 }}
                src={avatarUrl}
              />
              <Typography sx={{ px: 2 }} fontWeight={FontWeight.Normal} fontSize={16} color='#6C7080'>
                Peter Castell
              </Typography>
              <Icon icon='mdi:chevron-down' color='#9295A6' style={{ fontSize: '24px' }} />
            </Box>
          </Badge>
        </>
      )}
      {!mobile && (
        <Box
          style={{ cursor: 'pointer' }}
          sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', py: 2 }}
          onClick={handleDropdownOpen}
        >
          <Typography sx={{ px: 2 }} fontWeight={FontWeight.Normal} fontSize={16} color='white'>
            {emailFormat(user.email)}
          </Typography>
          <Icon icon='mdi:chevron-down' color='#9295A6' style={{ fontSize: '24px' }} />
        </Box>
      )}

      {mobile && (
        <IconButton onClick={handleDropdownOpen}>
          <Icon icon='iconamoon:menu-burger-horizontal' color='#9295A6' style={{ fontSize: '32px' }}></Icon>
        </IconButton>
      )}

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose()}
        sx={{ '& .MuiMenu-paper': { width: 230, mt: 4 } }}
        anchorOrigin={{ vertical: 'bottom', horizontal: direction === 'ltr' ? 'right' : 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: direction === 'ltr' ? 'right' : 'left' }}
      >
        {false && (
          <>
            <Box sx={{ pt: 2, pb: 3, px: 4 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', ml: 3, alignItems: 'flex-start', flexDirection: 'column' }}>
                  <Typography sx={{ fontWeight: 600 }}>Test</Typography>
                  <Typography variant='body2' sx={{ fontSize: '0.8rem', color: 'text.disabled' }}>
                    {user.status}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Divider sx={{ mt: '0 !important' }} />
          </>
        )}
        <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/')}>
          <Box sx={styles}>
            <Icon icon='material-symbols:dashboard' />
            {t('DASHBOARD')}
          </Box>
        </MenuItem>
        {isAdmin && <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/admin')}>
          <Box sx={styles}>
            <Icon icon='mdi:shield-account-outline' />
            {t('ADMIN')}
          </Box>
        </MenuItem>}
        <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/profile')}>
          <Box sx={styles}>
            <Icon icon='mdi:account-outline' />
            {t('PROFILE')}
          </Box>
        </MenuItem>
        <MenuItem
          onClick={handleLogout}
          sx={{ py: 2, '& svg': { mr: 2, fontSize: '1.375rem', color: 'text.primary' } }}
        >
          <Icon icon='mdi:logout-variant' />
          Logout
        </MenuItem>
      </Menu>
    </Fragment>
  )
}

export default UserDropdown
