import { useEffect, useRef } from 'react'
import G6, { Graph } from '@antv/g6'
import { Box } from '@mui/material'
import { ReferredUser, ReferredUserTree } from 'src/@http/user'

//import { registerNodeG6 } from './backup/G6Node2'
import ConfigReferredGraphic from './ConfigReferredGraphic'
import { registerNodeG6 } from './G6Node'
import { TreeMode } from './types'



interface Props {
  data: ReferredUserTree
  rawData: ReferredUser[]
  treeMode: TreeMode
  container: string
  width: number | string
  height: number | string
}

const RenderRefferedGraphics = ({data, rawData, treeMode, container, width, height}: Props) => {

  const graphRef = useRef<Graph | null>(null)
  const ref = useRef<any>(null)

  // the tree graph data
  //const data2 = treeData2

  useEffect(()=>{
    registerNodeG6({treeMode})
  },[])
  
  useEffect(() => {
    if (!graphRef.current) {
      // Instantiate the Graph
      
      graphRef.current = new G6.TreeGraph(ConfigReferredGraphic({graphRef, treeMode, container}))
    }

    const defaultZoom = {
      zoom: 0.8,
      x: ref.current.offsetWidth / 2,
      y: 600 / 2
    }

    graphRef.current.data(data)
    graphRef.current.render()
    
    // Default zoom
    graphRef.current.zoomTo(defaultZoom.zoom, { x: defaultZoom.x, y: defaultZoom.y })
  }, [graphRef.current, rawData, treeMode])

  return <Box id={container} sx={{ width, height}} ref={ref}></Box>
}

RenderRefferedGraphics.defaultProps = {
  container: 'g6-container',
  width: 1,
  height: 600
}

export default RenderRefferedGraphics
