import { Line } from 'react-chartjs-2'
import { Skeleton } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from 'chart.js'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend)

interface ChartProps {
  data: number[]
  loading: boolean
}

const StatsLineAreaChart = ({ data, loading }: ChartProps) => {
  const theme = useTheme()

  const options = {
    responsive: true,
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: false
      }
    },
    legend: {
      display: false
    },
    elements: {
      point: {
        borderWidth: 0,
        radius: 10,
        backgroundColor: 'rgba(0,0,0,0)'
      }
    },
    scales: {
      // to remove the labels
      x: {
        ticks: {
          display: false
        },
        // to remove the x-axis grid
        grid: {
          drawBorder: false,
          display: false
        }
      },
      // to remove the y-axis labels
      y: {
        ticks: {
          display: false,
          beginAtZero: true
        },
        // to remove the y-axis grid
        grid: {
          drawBorder: false,
          display: false
        }
      }
    }
  }

  function segmentColor(ctx: any, color1: string, color2: string) {
    if (ctx.p0.parsed.y < 0 && ctx.p1.parsed.y < 0) {
      return color2
    } else if (ctx.p0.parsed.y < 0) {
      const gradient = ctx.chart.ctx.createLinearGradient(ctx.p0.x, ctx.p0.y, ctx.p1.x, ctx.p1.y)
      gradient.addColorStop(0.5, color2)
      gradient.addColorStop(1, color1)

      return gradient
    } else if (ctx.p1.parsed.y < 0) {
      const gradient = ctx.chart.ctx.createLinearGradient(ctx.p1.x, ctx.p1.y, ctx.p0.x, ctx.p0.y)
      gradient.addColorStop(0.5, color2)
      gradient.addColorStop(1, color1)

      return gradient
    }

    return color1
  }

  const getBorderColor = (ctx: any, colPositive: string, colNegative: string) => {
    if (ctx.p0.parsed.y * ctx.p1.parsed.y < 0) {
      // if the segment changes sign from p0 to p1
      const x0 = ctx.p0.parsed.x,
        x1 = ctx.p1.parsed.x,
        y0 = ctx.p0.parsed.y,
        y1 = ctx.p1.parsed.y,
        dataset = ctx.chart.data.datasets[ctx.datasetIndex],
        //identify the correct axes used for the dataset
        xAxisId = dataset.xAxisId ?? 'x',
        yAxisId = dataset.yAxisId ?? 'y',
        //transform values to pixels
        x0px = ctx.chart.scales[xAxisId].getPixelForValue(x0),
        x1px = ctx.chart.scales[xAxisId].getPixelForValue(x1),
        y0px = ctx.chart.scales[yAxisId].getPixelForValue(y0),
        y1px = ctx.chart.scales[yAxisId].getPixelForValue(y1)

      // create gradient form p0 to p1
      const gradient = ctx.chart.ctx.createLinearGradient(x0px, y0px, x1px, y1px)
      // calculate frac - the relative length of the portion of the segment
      // from p0 to the point where the segment intersects the x axis
      const frac = Math.abs(y0) / (Math.abs(y0) + Math.abs(y1))
      // set colors at the ends of the segment
      const [col_p0, col_p1] = y0 > 0 ? [colPositive, colNegative] : [colNegative, colPositive]
      gradient.addColorStop(0, col_p0)
      gradient.addColorStop(frac, col_p0)
      gradient.addColorStop(frac, col_p1)
      gradient.addColorStop(1, col_p1)

      return gradient
    }

    return ctx.p0.parsed.y >= 0 ? colPositive : colNegative
  }

  const config = {
    labels: data,
    datasets: [
      {
        fill: {
          target: 'origin',
          above: '#03A66D', // or color if you don't want an gradient
          below: '#DC2626' // or color if you don't want an gradient
        },
        label: '',
        data,
        tension: 0.4,
        //borderWidth: 0,
        borderColor: 'transparent',
        //backgroundColor:'transparent',
        pointRadius: 0,
        pointHoverRadius: 0
        /*
        segment: {
            borderColor: (ctx: any) => {
                return getBorderColor(ctx, '#03A66D', '#DC2626');
            }
        },
        */
      }
    ]
  }

  return (
    <>
      {data.length === 0 && <Skeleton sx={{width: '100%', height:100, mt:-5}} animation={loading?'pulse': false}/>}
      {data.length !== 0 && <Line options={options} data={config} />}
    </>
  )
}

export default StatsLineAreaChart
