import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar, Box, Button, Card, CardContent, Typography, useMediaQuery } from '@mui/material'
import { Theme } from '@mui/material/styles'
import {
  DataGrid
} from '@mui/x-data-grid'
import Icon from 'src/@core/components/icon'
import { ModeForm } from 'src/types'
import newsStore from 'src/zustand/news'

import { DialogGeneric } from '../../../generic/DialogGeneric'
import CustomToolbarAdminGrid from '../../CustomToolbarAdminGrid'

import columns from './ColumnsNewsCategoriesGrid'
import NewsCategoriesForm from './NewsCategoriesForm'
import NewsCategoriesManagerCards from './NewsCategoriesManagerCards'

interface Props {
  isContent: boolean
}


const NewsCategoriesManager = ({isContent} : Props) => {
  const { t } = useTranslation()
  const [show, setShow] = useState<boolean>(false)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { categories, getNewsCategory } = newsStore()

  useEffect(() => {
    const init = async () => {
      setLoading(true)
      await getNewsCategory()
      setLoading(false)
    }
    init()
  }, [])

  const showMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))


  return (
    <Card  sx={{ ...(isContent && { border: 'none', boxShadow: 'none' }) }}>
      <CardContent sx={{}}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {!isContent && <>
              <Avatar sx={{ width: 40, height: 40, bgcolor: 'primary.main' }} color='error' variant='rounded'>
              <Icon icon='mdi:format-list-bulleted-type' style={{ fontSize: 24 }} color='white' />
            </Avatar>
            <Typography sx={{ ml: 2 }} variant='h5'>
              { t('NEWS_CATEGORIES')}
            </Typography>
            </>}
          </Box>
          <Button
            onClick={() => {
              setShow(true)
            }}
            startIcon={<Icon icon='mdi:plus-box-outline' color='#9295A6' />}
          >
            <Typography color='#9295A6' sx={{ textTransform: 'capitalize' }}>
              {t('ADD')}
            </Typography>
          </Button>
        </Box>
        {!showMobile && (
          <div onClick={event => event.stopPropagation()}>
            <DataGrid
              sx={{
                mt: 4,
                width: '100%'
              }}
              autoHeight
              rows={categories}
              getRowId={r => r.id}
              columns={columns}
              pageSize={10}
              disableSelectionOnClick
              rowsPerPageOptions={[5]}
              loading={loading}
              components={{ Toolbar: CustomToolbarAdminGrid }}
            />
          </div>
        )}
        {showMobile && <NewsCategoriesManagerCards />}
      </CardContent>
      <DialogGeneric
        show={show}
        hideActions={true}
        showIconClose={true}
        closeFunc={() => {
          setShow(false)
        }}
        title={t('NEWS_CATEGORIES_DETAILS')}
        leftAline={true}
        
      >
        <Box sx={{ pt: 4 }}>
          <NewsCategoriesForm
            modeForm={ModeForm.create}
            submitLoading={submitLoading}
            closeFunc={() => {setShow(false)}}
          />
        </Box>
      </DialogGeneric>
    </Card>
  )
}

NewsCategoriesManager.defaultProps = {
  isContent: false
}


export default NewsCategoriesManager