import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { createRank, updateRank } from 'src/@http/commission'
import CustomBtn, { CustomLoadingBtn } from 'src/layouts/components/shared-component/CustomBtn'
import { ModeForm } from 'src/types'
import adminStore from 'src/zustand/admin'
import * as yup from 'yup'

interface ValuesDefaultCustomerForm {
  id: string
  email: string
  registerDate: string
  status: boolean
}

interface Props {
  modeForm: ModeForm
  submitLoading: boolean
  valuesDefaultForm?: API.Commission.Rank | null
  closeFunc: () => any
}

interface CommissionRankForm {
  intCommissionRankId: number
  vchCommissionRankName: string
  dcmMinBalance: number
  dcmMaxBalance: number
  dcmCommissionPercentage: number
  dcmCostPerMonth: number
  intAccountTypeId: number
}

const CommissionRankForm = ({ modeForm, closeFunc, valuesDefaultForm }: Props) => {


  const { getAllAccountTypes, accountsTypes, getAllCommissionRank } = adminStore()

  let defaultValuesForm: CommissionRankForm = {
    intCommissionRankId: 0,
    vchCommissionRankName: '',
    dcmMinBalance: 0,
    dcmMaxBalance: 0,
    dcmCommissionPercentage: 0,
    dcmCostPerMonth: 0,
    intAccountTypeId: accountsTypes.length === 0 ? 0 : accountsTypes[0].accountTypeId
  }

  if (modeForm === ModeForm.edit && valuesDefaultForm) {
    defaultValuesForm = {
      intCommissionRankId: valuesDefaultForm.commissionRankId,
      vchCommissionRankName: valuesDefaultForm.commissionRankName,
      dcmMinBalance: parseFloat(valuesDefaultForm.minBalance),
      dcmMaxBalance: parseFloat(valuesDefaultForm.maxBalance),
      dcmCommissionPercentage: parseFloat(valuesDefaultForm.commissionPercentage),
      dcmCostPerMonth: parseFloat(valuesDefaultForm.costPerMonth),
      intAccountTypeId: 0
    }
  }

  const validations = {
    vchCommissionRankName: yup.string(),
    dcmMinBalance: yup.number().positive(),
    dcmMaxBalance: yup.number().positive(),
    dcmCommissionPercentage: yup.number().positive(),
    dcmCostPerMonth: yup.number().positive(),
    intAccountTypeId: yup.number().positive()
  }

  if (modeForm === ModeForm.create) {
    validations.vchCommissionRankName = validations.vchCommissionRankName.required()
    validations.dcmMinBalance = validations.dcmMinBalance.required()
    validations.dcmMaxBalance = validations.dcmMaxBalance.required()
    validations.dcmCommissionPercentage = validations.dcmCommissionPercentage.required()
    validations.dcmCostPerMonth = validations.dcmCostPerMonth.required()
    validations.intAccountTypeId = validations.intAccountTypeId.required()
  }

  const schemaValidations = yup.object().shape(validations)

  const { t } = useTranslation()
  const theme = useTheme()
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CommissionRankForm>({ resolver: yupResolver(schemaValidations), defaultValues: defaultValuesForm })

  

  useEffect(() => {
    if (accountsTypes.length === 0) getAllAccountTypes()
  }, [])

  const success = async () => {
    closeFunc()
    await getAllCommissionRank()
  }

  const onSubmit = async (form: CommissionRankForm) => {
    const {
      dcmCommissionPercentage,
      dcmCostPerMonth,
      dcmMaxBalance,
      dcmMinBalance,
      intAccountTypeId,
      intCommissionRankId,
      vchCommissionRankName
    } = form
    const body = {
      vchCommissionRankName,
      dcmMinBalance,
      dcmMaxBalance,
      dcmCommissionPercentage,
      dcmCostPerMonth,
      intAccountTypeId
    }
    if (modeForm === ModeForm.create) {
      try {
        await createRank(body)
        toast.success(t('CREATE_COMMISSION_RANK_SUCCESS_MESSAGE'), { duration: 4000 })
        await success()
      } catch (error: any) {
        toast.error(error.messagge, { duration: 4000 })
      }
    } else {
      try {
        await updateRank({ ...body, intCommissionRankId })
        toast.success(t('UPDATE_COMMISSION_RANK_SUCCESS_MESSAGE'), { duration: 4000 })
        await success()
      } catch (error: any) {
        toast.error(error.messagge, { duration: 4000 })
      }
    }
  }

  return (
    <>
      <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='vchCommissionRankName'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                disabled={ModeForm.show === modeForm}
                {...field}
                label={t('NAME')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='dcmMinBalance'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                type='number'
                variant='outlined'
                disabled={ModeForm.show === modeForm}
                {...field}
                label={t('MIN_BALANCE')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='dcmMaxBalance'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                type='number'
                variant='outlined'
                disabled={ModeForm.show === modeForm}
                {...field}
                label={t('MAX_BALANCE')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='dcmCommissionPercentage'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                type='number'
                variant='outlined'
                disabled={ModeForm.show === modeForm}
                {...field}
                label={t('COMMISSION_PORCENTAGE')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='dcmCostPerMonth'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                type='number'
                variant='outlined'
                disabled={ModeForm.show === modeForm}
                {...field}
                label={t('COST_PER_MONTH')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>

        <FormControl fullWidth sx={{ my: 2 }}>
          <InputLabel>{t('ACCOUNT_TYPE')}</InputLabel>
          <Controller
            name='intAccountTypeId'
            control={control}
            render={({ field: { name, value, onChange }, fieldState }) => (
              <>
                <Select
                  error={!!fieldState.error}
                  defaultValue={value}
                  label={t('ACCOUNT_TYPE')}
                  fullWidth
                  name={name}
                  value={value}
                  onChange={onChange}
                  disabled={ModeForm.show === modeForm}
                >
                  {accountsTypes.map((accountType: API.Commission.AccountType, key: number) => (
                    <MenuItem value={accountType.accountTypeId} key={key}>
                      {accountType.accountTypeName}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  <Typography color='error' sx={{ fontSize: 12 }}>
                    {fieldState.error?.message}
                  </Typography>{' '}
                </FormHelperText>
              </>
            )}
          />
        </FormControl>

        <Grid container>
          <Grid sx={{ p: 1 }} item xs={6}>
            <CustomLoadingBtn
              loading={isLoading}
              fullWidth
              size='large'
              type='submit'
              variant='contained'
              sx={{ mt: 3 }}
            >
              {valuesDefaultForm ? t('EDIT') : t('PROCEED')}
            </CustomLoadingBtn>
          </Grid>
          <Grid sx={{ p: 1 }} item xs={6}>
            <CustomBtn fullWidth size='large' variant='contained' onClick={() => closeFunc()} sx={{ mt: 3 }}>
              {t('CANCEL')}
            </CustomBtn>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

CommissionRankForm.defaultProps = {
  editForm: ModeForm.create
}

export default CommissionRankForm
