import { deleteAsync, getAsync, postAsync, putAsync } from 'src/@http'

const root = '/global-settings'

export interface GlobalSetting {
  key: string
  value: string
  isPrivate: boolean
}

export interface GlobalSettingRequest {
  vchKey: string
  vchValue: string
  blnIsPrivate: boolean
}

export const getGlobalSettingForKey = (key: string): Promise<GlobalSetting> => {
  return getAsync(root + `/${key}`)
}

export const getAllGlobalSetting = (): Promise<GlobalSetting[]> => {
  return getAsync(root)
}

export const postGlobalSetting = (body: GlobalSettingRequest): Promise<GlobalSetting> => {
  return postAsync(root, body)
}

export const putGlobalSetting = (body: GlobalSettingRequest): Promise<boolean> => {
  return putAsync(root, body)
}

export const deleteGlobalSetting = (key: string) => {
  return deleteAsync(root + `/${key}`)
}
