import { createRef, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import LoadingButton from '@mui/lab/LoadingButton'
import { CircularProgress } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useSettings } from 'src/@core/hooks/useSettings'
import { deleteAccountUserPlatform, getAccountUserPlatforms, saveAccountUserPlatform } from 'src/@http/account'
import { getCountries } from 'src/@http/country'
import { createUser, getById, updateUser } from 'src/@http/user-admin'
import type { UserPlatform } from 'src/pages/components/item-platform'
import { Platform } from 'src/pages/components/item-platform'
import type { CountryModel } from 'src/types/models/countryModel'
import type { PlatformModel } from 'src/types/models/platformModel'
import { UserModel } from 'src/types/models/userModels'
import { CreateUserRequest, UpdateUserRequest } from 'src/types/requests/userAdminRequests'
import { logger } from 'src/utils/Logger'
import profileStore, {ProfileStore} from 'src/zustand/profile'

interface FormData {
  email: string
  firstName: string
  middleName: string
  lastName: string
  country: string
  language: string
  telephone: string
  userType: string
}

function useQuery() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

const AdminUserNewOrEditUser = () => {
  const params = useParams()

  const userId = params.id ?? ''

  const query = useQuery()
  const view: boolean = query.get('view') === 'true' ? true : false

  // ** State
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [userPlatforms, setUserPlatforms] = useState<{ [key: string]: any }>({})

  const [formData, setFormData] = useState<FormData>({} as FormData)
  const [countries, setCountries] = useState<CountryModel[]>([])

  // ** Hooks
  const user = profileStore((state:ProfileStore) => state.userDetail)
  const platforms: PlatformModel[] = [] // 08112023
  const { t } = useTranslation()
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<FormData>()
  const { settings } = useSettings()
  const { skin } = settings

  useEffect(() => {
    if (userId == '') return

    loadUserById(userId)
  }, [userId])
  useEffect(() => {
    const dataFetch = async () => {
      const countries = await getCountries()
      setCountries(countries)
    }
    dataFetch()
  }, [])

  const platformRefs = useMemo(
    () =>
      Array(length)
        .fill(0)
        .map(i => createRef()),
    []
  )

  const loadUserById = (userId: string) => {
    setIsLoading(true)

    getById(userId).then(async user => {
      setFormData({
        lastName: user.last_name,
        firstName: user.first_name,
        middleName: user.middle_name,
        country: user.country._id,
        language: user.preferred_languague,
        email: user.email,
        telephone: user.telephone,
        userType: user.role.name
      })
      if (user.role.name === 'User' || user.role.name === 'Manager') {
        const platforms = await getAccountUserPlatforms(userId)
        const platform: { [key: string]: string } = {}
        platforms.forEach((accountPlatform: any) => {
          platform[`${accountPlatform.platform._id}`] = accountPlatform
        })
        setUserPlatforms(platform)
      }

      setIsLoading(false)
    })
  }

  const handleFormChange = (field: keyof FormData, value: FormData[keyof FormData]) => {
    setFormData({ ...formData, [field]: value })
  }

  const onSubmit = () => {
    setIsSubmitting(true)

    const submitMessage = userId == '' ? t('USER_CREATED_SUCCESS') + '! 👌' : t('USER_UPDATED_SUCCESS') + '! 👌'
    const promiseRequest = userId == '' ? onSubmitNewUser() : onSubmitUpdateUser(userId)

    promiseRequest
      .then(async data => {
        toast.success(submitMessage, { duration: 4000 })

        const user = data as UserModel | undefined
        if (user && (formData.userType == 'User' || formData.userType == 'Manager')) {
          await createAccountPlatform(user._id, userId != '')
          if (!(userId == '')) removeAccountPlatform()
        }

        history.back()
      })
      .catch(e => {
        logger.log('Ocurrio un error', e)
        toast.error(e.message, { duration: 4000 })
      })
      .finally(() => setIsSubmitting(false))
  }

  const onSubmitNewUser = (): Promise<void> => {
    const request: CreateUserRequest = {
      userName: formData.email,
      lastName: formData.lastName,
      firstName: formData.firstName,
      middleName: formData.middleName,
      country_id: formData.country,
      preferredLanguage: formData.language,
      emailAddress: formData.email,
      telephone: formData.telephone,
      userType: formData.userType,
      birthday: new Date().toISOString().split('T')[0]
    }

    return createUser(request)
  }

  const createAccountPlatform = (userId: string, update = false) => {
    platformRefs.forEach(async platformRef => {
      const userPlataform = (
        platformRef.current as {
          getState: () => UserPlatform
        }
      ).getState()
      logger.log('Estado Creando:', userPlataform)
      if (userPlataform.create) {
        try {
          await saveAccountUserPlatform(userId, userPlataform.id, userPlataform.username, userPlataform.password)
          logger.log('Creado:', userId, userPlataform.id, userPlataform.username, userPlataform.password)
        } catch (err: any) {
          if (!update) toast.error(err.message + '🙄', { duration: 4000 })
        }
      }
    })
  }

  const removeAccountPlatform = () => {
    platformRefs.forEach(async platformRef => {
      const userPlataform = (
        platformRef.current as {
          getState: () => UserPlatform
        }
      ).getState()
      logger.log('Estado Borrando:', userPlataform)
      if (userPlataform.delete && userPlatforms[userPlataform.id] !== undefined) {
        logger.log('Borrar: ', userPlatforms[userPlataform.id])
        await deleteAccountUserPlatform(userPlatforms[userPlataform.id]._id)
      }
    })
  }

  const onSubmitUpdateUser = (userId: string): Promise<void> => {
    const request: UpdateUserRequest = {
      id: userId,
      userName: formData.email,
      lastName: formData.lastName,
      firstName: formData.firstName,
      middleName: formData.middleName,
      country: formData.country,
      preferredLanguage: formData.language,
      emailAddress: formData.email,
      telephone: formData.telephone,
      userType: formData.userType,
      birthday: new Date().toISOString().split('T')[0]
    }

    return updateUser(request)
  }


  if (isLoading) {
    return (
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            {userId == '' && <CardHeader title={t('NEW_USER')} />}
            {userId != '' && !view && <CardHeader title={t('EDIT_USER')} subheader={'Id: ' + userId} />}
            {userId != '' && view && <CardHeader title={t('VIEW_USER')} subheader={'Id: ' + userId} />}

            <Divider sx={{ m: '0 !important' }} />

            <CardContent>
              <Grid container>
                <Grid item xs={12}>
                  <Box sx={{ mt: 6, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <CircularProgress sx={{ mb: 4 }} />
                    <Typography>{t('LOADING')}...</Typography>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    )
  }
  const itemPlatform = (plataform: any, i: number) => {
    if (view && userPlatforms[plataform._id] === undefined) return <></>

    return (
      <Grid item xs={12} sx={{ pb: 2 }} key={i}>
        <Platform
          key={`platform-${i}`}
          platform={plataform}
          ref={platformRefs[i]}
          editable={!view}
          username={
            userId == '' ? '' : userPlatforms[plataform._id] !== undefined ? userPlatforms[plataform._id].username : ''
          }
        ></Platform>
      </Grid>
    )
  }

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Card>
          {userId == '' && <CardHeader title={t('NEW_USER')} />}
          {userId != '' && !view && <CardHeader title={t('EDIT_USER')} subheader={'Id: ' + userId} />}
          {userId != '' && view && <CardHeader title={t('VIEW_USER')} subheader={'Id: ' + userId} />}

          <Divider sx={{ m: '0 !important' }} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Typography variant='body2' sx={{ fontWeight: 600 }}>
                    1. {t('ACCOUNT_DETAILS')}
                  </Typography>
                  {userId == '' && (
                    <Typography variant='caption' sx={{ marginLeft: 5 }}>
                      {t('PLEASE_ENTER_THE_USER_EMAIL')}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    type='email'
                    label={t('EMAIL')}
                    value={formData.email}
                    disabled={view}
                    onChange={e => handleFormChange('email', e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>{t('USER_ROLE')}</InputLabel>
                    <Select
                      label={t('USER_ROLE')}
                      value={formData.userType}
                      onChange={e => handleFormChange('userType', e.target.value)}
                      disabled={true}
                    >
                      <MenuItem value='Admin'>Admin</MenuItem>
                      <MenuItem value='Manager'>Manager</MenuItem>
                      <MenuItem value='User'>User</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Divider sx={{ mb: '0 !important' }} />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body2' sx={{ fontWeight: 600 }}>
                    2. {t('PERSONAL_INFO')}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label={t('FIRST_NAME')}
                    value={formData.firstName}
                    onChange={e => handleFormChange('firstName', e.target.value)}
                    disabled={view}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label={t('MIDDLE_NAME')}
                    value={formData.middleName}
                    onChange={e => handleFormChange('middleName', e.target.value)}
                    disabled={view}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label={t('LAST_NAME')}
                    value={formData.lastName}
                    onChange={e => handleFormChange('lastName', e.target.value)}
                    disabled={view}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    type='number'
                    label={t('TELEPHONE')}
                    value={formData.telephone}
                    placeholder='XXX XXX XXXX'
                    onChange={e => handleFormChange('telephone', e.target.value)}
                    InputProps={{ startAdornment: <InputAdornment position='start'>US (+1)</InputAdornment> }}
                    disabled={view}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel>{t('COUNTRY')}</InputLabel>
                    <Select
                      label={t('COUNTRY')}
                      value={formData.country}
                      onChange={e => handleFormChange('country', e.target.value)}
                      disabled={view}
                    >
                      {countries.map((country: CountryModel, i: number) => {
                        return (
                          <MenuItem key={i} value={country._id}>
                            {country.name}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth>
                    <InputLabel>{t('LANGUAGE')}</InputLabel>
                    <Select
                      label={t('LANGUAGE')}
                      value={formData.language}
                      onChange={e => handleFormChange('language', e.target.value)}
                      disabled={view}
                    >
                      <MenuItem value='spanish'>Spanish (Es)</MenuItem>
                      <MenuItem value='english'>English (En)</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {((!view && formData.userType == 'User') || (view && Object.keys(userPlatforms).length !== 0)) && (
                  <>
                    <Grid item xs={12}>
                      <Divider sx={{ mb: '0 !important' }} />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='body2' sx={{ fontWeight: 600 }}>
                        3. {t('PLATFORM_CONNECTION')}
                      </Typography>
                    </Grid>
                    {platforms.map((plataform, i: number) => {
                      return itemPlatform(plataform, i)
                    })}
                  </>
                )}

                <Grid container item xs={12} justifyContent='flex-end'>
                  <Button onClick={() => history.back()} variant='outlined' sx={{ mr: 4 }}>
                    {t(view ? 'GO_BACK' : 'CANCEL')}
                  </Button>
                  {!view && (
                    <LoadingButton loading={isSubmitting} onClick={onSubmit} variant='contained' sx={{ mr: 4 }}>
                      {t('SAVE_CHANGES')}
                    </LoadingButton>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </form>
        </Card>
      </Grid>
    </Grid>
  )
}

export default AdminUserNewOrEditUser
