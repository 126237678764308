import * as React from 'react'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import Icon from 'src/@core/components/icon'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

export interface Tab {
  index: number
  label: string
  component: React.ReactNode
  disabled?: boolean
  icon: string
}
interface Props {
  tabs: Tab[]
  defaultIndex: number
}

function GenericTab({ tabs, defaultIndex }: Props) {
  const [value, setValue] = React.useState(defaultIndex)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderColor: 'divider' }}>
        <Tabs 
        value={value} 
        onChange={handleChange} 
        indicatorColor='primary' 
        scrollButtons
        allowScrollButtonsMobile
        variant="scrollable"
        >
          {tabs.map((tab: Tab, key: number) => (
            <Tab
              label={tab.label}
              key={key}
              {...a11yProps(tab.index)}
              disabled={tab.disabled && tab.disabled}
              icon={<Icon icon={tab.icon} fontSize={20} />}
              iconPosition='start'
              sx={{ py: 0 }}
            />
          ))}
        </Tabs>
      </Box>
      {tabs.map((tab: Tab, key: number) => (
        <CustomTabPanel key={key} value={value} index={tab.index}>
          {tab.component}
        </CustomTabPanel>
      ))}
    </Box>
  )
}

GenericTab.defaultProps = {
  defaultIndex: 0
}

export default GenericTab
