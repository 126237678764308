import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Avatar, Box, Button, Card, CardContent, Typography, useMediaQuery } from '@mui/material'
import { Theme } from '@mui/material/styles'
import {
  DataGrid
} from '@mui/x-data-grid'
import Icon from 'src/@core/components/icon'
import { registerUser } from 'src/@http/auth'
import { apiCredentials, postPlatform } from 'src/@http/platform/'
import type { CustomerForm } from 'src/pages/components/admin/customer-manager/CustomerDetailsForm'
import { ModeForm } from 'src/types'
import adminStore from 'src/zustand/admin'

import { DialogGeneric } from '../../generic/DialogGeneric'
import CustomToolbarAdminGrid from '../CustomToolbarAdminGrid'

import columns from './ColumnsCommissionRankGrid'
import CommissionRankForm from './CommissionRankForm'
import CommissionRankManagerCards from './CommissionRankManagerCards'


const CommissionRankManager = () => {
  const { t } = useTranslation()
  const [show, setShow] = useState<boolean>(false)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)

  const { getAllCommissionRank, commissionRank } = adminStore()

  useEffect(() => {
    getAllCommissionRank()
  }, [])

  const onSubmit = async (form: CustomerForm) => {
    const bodyCredentials: apiCredentials = {
      vchApiKey: form.apiKey,
      vchApiSecret: form.secretKey
    }
    setSubmitLoading(true)
    try {
      const response = await registerUser({
        vchEmail: form.email,
        vchPassword: form.password,
        vchLastName: '',
        vchName: '',
        vchPromoCode: form.promocode
      })

      await postPlatform(response.userId, Number(form.platformId), bodyCredentials)
      setShow(false)

      toast.success(t('USER_CREATED_SUCCESS'), { duration: 4000 })
    } catch (error: any) {
      toast.error(error.message, { duration: 4000 })
    }

    setSubmitLoading(false)
    getAllCommissionRank()
  }

  const showMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))


  return (
    <Card>
      <CardContent sx={{}}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar sx={{ width: 40, height: 40, bgcolor: 'primary.main' }} color='error' variant='rounded'>
              <Icon icon='mdi:cash-multiple' style={{ fontSize: 24 }} color='white' />
            </Avatar>
            <Typography sx={{ ml: 2 }} variant='h5'>
              { t('COMMISSION_RANK')}
            </Typography>
          </Box>
          <Button
            onClick={() => {
              setShow(true)
            }}
            startIcon={<Icon icon='mdi:plus-box-outline' color='#9295A6' />}
          >
            <Typography color='#9295A6' sx={{ textTransform: 'capitalize' }}>
              {t('ADD')}
            </Typography>
          </Button>
        </Box>
        {!showMobile && (
          <div onClick={event => event.stopPropagation()}>
            <DataGrid
              sx={{
                mt: 4,
                width: '100%'
              }} //overflowX: 'scroll'
              autoHeight
              rows={commissionRank}
              getRowId={r => r.commissionRankId}
              columns={columns}
              pageSize={10}
              disableSelectionOnClick
              rowsPerPageOptions={[5]}
              loading={commissionRank.length===0}
              components={{ Toolbar: CustomToolbarAdminGrid }}
            />
          </div>
        )}
        {showMobile && <CommissionRankManagerCards />}
      </CardContent>
      <DialogGeneric
        show={show}
        hideActions={true}
        showIconClose={true}
        closeFunc={() => {
          setShow(false)
        }}
        title={t('COMMISSION_RANK_DETAILS')}
        leftAline={true}
      >
        <Box sx={{ pt: 4 }}>
          <CommissionRankForm
            modeForm={ModeForm.create}
            submitLoading={submitLoading}
            closeFunc={() => {setShow(false)}}
          />
        </Box>
      </DialogGeneric>
    </Card>
  )
}

export default CommissionRankManager