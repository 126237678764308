import { useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Avatar,
  Box,
  FormControl,
  Grid,
  Switch,
  TextField,
  Typography
} from '@mui/material'
import Icon from 'src/@core/components/icon'
import { postNewsCategory, putNewsCategory } from 'src/@http/news'
import CustomBtn, { CustomLoadingBtn } from 'src/layouts/components/shared-component/CustomBtn'
import { ModeForm } from 'src/types'
import newsStore, { Category } from 'src/zustand/news'
import * as yup from 'yup'

interface Props {
  modeForm: ModeForm
  submitLoading: boolean
  valuesDefaultForm?: Category | null
  closeFunc: () => any
}

interface NewsCategoriesForm {
  id: number
  name: string
  status: boolean
}

const NewsCategoriesForm = ({ modeForm, closeFunc, valuesDefaultForm }: Props) => {
  const { t } = useTranslation()
  const { getNewsCategory } = newsStore()

  let defaultValuesForm: NewsCategoriesForm = {
    id: 0,
    name: '',
    status: true
  }

  if (modeForm === ModeForm.edit && valuesDefaultForm) {
    defaultValuesForm = {
      id: valuesDefaultForm.id,
      name: valuesDefaultForm.name,
      status: valuesDefaultForm.statusId === 1
    }
  }

  const validations = {
    name: yup.string(),
    statusId: yup.boolean()
  }

  validations.name = validations.name.required()

  const schemaValidations = yup.object().shape(validations)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<NewsCategoriesForm>({ resolver: yupResolver(schemaValidations), defaultValues: defaultValuesForm })

  const statusWatch = useWatch({ control, name: 'status' })

  const onSubmit = async (form: NewsCategoriesForm) => {
    setIsLoading(true)
    if (modeForm === ModeForm.create) {
      try {
        await postNewsCategory({name: form.name, statusId: form.status?1:0})
        toast.success(t('CREATE_SUCCESS'), { duration: 4000 })
        closeFunc()
        getNewsCategory()
      } catch (error: any) {
        toast.error(error.message, { duration: 4000 })
      }
    } else {
        try {
          await putNewsCategory(form.id, {name: form.name, statusId: form.status?1:0})
          toast.success(t('UPDATE_SUCCESS'), { duration: 4000 })
          closeFunc()
          getNewsCategory()
        } catch (error: any) {
          toast.error(error.message, { duration: 4000 })
        }
    }
    setIsLoading(false)
  }

  return (
    <>
      <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='name'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                disabled={ModeForm.show === modeForm}
                {...field}
                label={t('NAME')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center' }}>
          <Box>
            <Avatar sx={{ bgcolor: 'primary.main', width: 50, height: 50 }} variant='rounded'>
              <Icon icon={statusWatch ? 'mdi:eye-outline' : 'mdi:eye-off-outline'} color='white' fontSize={30} />
            </Avatar>
          </Box>
          <Box>
            <Box>
              <Typography align='center' fontSize={22}>
                {t('STATUS')}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Controller
              name='status'
              control={control}
              render={({ field: { ...field } }) => (
                <Switch defaultChecked={defaultValuesForm.status} {...field} disabled={ModeForm.show === modeForm} />
              )}
            />
          </Box>
        </Box>

        <Grid container>
          <Grid sx={{ p: 1 }} item xs={6}>
            <CustomLoadingBtn
              loading={isLoading}
              fullWidth
              size='large'
              type='submit'
              variant='contained'
              sx={{ mt: 3 }}
            >
              {valuesDefaultForm ? t('EDIT') : t('SAVE')}
            </CustomLoadingBtn>
          </Grid>
          <Grid sx={{ p: 1 }} item xs={6}>
            <CustomBtn fullWidth size='large' variant='contained' onClick={() => closeFunc()} sx={{ mt: 3 }}>
              {t('CANCEL')}
            </CustomBtn>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

NewsCategoriesForm.defaultProps = {
  editForm: ModeForm.create
}

export default NewsCategoriesForm
