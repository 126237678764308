import { GraphOptions, IG6GraphEvent, ModeType } from '@antv/g6'

import { TreeMode } from './types'

interface Props {
  graphRef: any
  treeMode: TreeMode
  container: string
}

const ConfigReferredGraphic = ({ graphRef, treeMode, container }: Props) => {
  
  const collapseExpandMode: ModeType = {
    type: 'collapse-expand',
    shouldBegin: (e: IG6GraphEvent) => {
      if (e.target.get('name') === 'collapse-marker' || e.target.get('name') === 'collapse-marker-text') {
        if (graphRef.current && e.item !== null){
          graphRef.current.setItemState(e.item, 'collapse', e.item.getModel().collapsed ? 'yes' : 'no')
        }

        return true
      }

      return false
    }
  }


 

  const config: GraphOptions = {
    container, // the html container for the graph
    // the size of the graph
    //width: 800,
    //height: 600,
    fitView: true, // fit the graph to the view
    modes: {
      // intraction modes
      default: ['drag-canvas', 'zoom-canvas', collapseExpandMode, ]
    },
    defaultNode: {
      // global node configuration
      type: 'flow-rect'
    },
    defaultEdge: {
      // global edge configuration
      type: treeMode === 'horizontal' ? 'cubic-horizontal' : 'cubic-vertical'
    },
    nodeStateStyles: {
      // node styles in different states
      'collapse:yes': {
        'collapse-marker-text': {
          text: '-'
        }
      },
      'collapse:no': {
        'collapse-marker-text': {
          text: '+'
        }
      }
    },
    linkCenter: treeMode === 'vertical',
    layout: {
      // layout algorithm for the tree graph
      type: 'compactBox',
      direction: treeMode === 'horizontal' ? 'LR': 'TB',
      getVGap: function getVGap() {
        return  treeMode === 'horizontal' ? 80 : 150
      },
      getHGap: function getHGap() {
        return treeMode === 'horizontal' ? 200 : 180
      }
    }
  }

  return config
}

ConfigReferredGraphic.defaultProps = {
  container: 'g6-container'
}

export default ConfigReferredGraphic
