import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Avatar,
  Box,
  FormControl,
  Grid,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import Icon from 'src/@core/components/icon'
import { Platform, putExchangePlatform } from 'src/@http/platform'
import { CustomLoadingBtn } from 'src/layouts/components/shared-component/CustomBtn'
import { ModeForm } from 'src/types'
import profileStore from 'src/zustand/profile'
import * as yup from 'yup'

interface PlatformForm {
  platformId: number
  platformName: string
  vchApiCode: string
  vchImage: string
  intPassPhrase: boolean
  vchServiceDomain: string
}

interface Props {
  modeForm: ModeForm
  valuesDefaultForm?: Platform
  submitSuccess: () => void
}

const PlatformForm = ({ modeForm, valuesDefaultForm, submitSuccess }: Props) => {
  const { setPlatforms } = profileStore()

  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)
  const tomorrow = new Date()
  tomorrow.setDate(new Date().getDate() + 1)

  const defaultValuesForm: PlatformForm = {
    platformId: 0,
    platformName: '',
    vchApiCode: '',
    vchImage: '',
    intPassPhrase: false,
    vchServiceDomain: ''
  }

  if (valuesDefaultForm) {
    defaultValuesForm.platformId = valuesDefaultForm.platformId
    defaultValuesForm.platformName = valuesDefaultForm.platformName
    defaultValuesForm.vchApiCode = valuesDefaultForm.apiCode
    defaultValuesForm.vchImage = valuesDefaultForm.vchImage
    defaultValuesForm.intPassPhrase = valuesDefaultForm.intPassPhrase === 1
    defaultValuesForm.vchServiceDomain = valuesDefaultForm.vchServiceDomain
  }

  const validations = {
    platformName: yup.string(),
    apiCode: yup.string(),
    vchImage: yup.string(),
    vchServiceDomain: yup.string(),
    intPassPhrase: yup.boolean()
  }

  if (modeForm === ModeForm.create) {
    validations.platformName = validations.platformName.required().min(3)
    validations.apiCode = validations.apiCode.required().min(3)
    validations.vchImage = validations.vchImage.required().min(3)
    validations.vchServiceDomain = validations.vchServiceDomain.required().min(3)
    validations.intPassPhrase = validations.intPassPhrase.required()
  }

  const schemaValidations = yup.object().shape(validations)

  const {
    control,
    handleSubmit,
    reset,
    formState,
    watch,
    getValues
  } = useForm<PlatformForm>({ resolver: yupResolver(schemaValidations), defaultValues: defaultValuesForm })

  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const onSubmit = async (form: PlatformForm) => {
    /*
    if (modeForm == ModeForm.create) {
      setSubmitLoading(true)
      try {
        await postPromocode({
          vchCode: form.code,
          dtmStartDate: form.startDate.toISOString(),
          dtmEndDate: form.endDate.toISOString(),
          intNumberOfSubscriptionDays: form.numberOfSubscriptionDays,
          intStatusId: form.statusId ? 1 : 0,
          intNumberOfUsesAvailable: form.numberOfUsesAvailable
        })
        toast.success(t('CUPON_CREATED_SUCCESS'), { duration: 4000 })
        getAllPromocodes()
        submitSuccess()
      } catch (error: any) {
        toast.error(error.message, { duration: 4000 })
      } finally {
        setSubmitLoading(false)
      }
    }
    */

    if (modeForm == ModeForm.edit) {
      setSubmitLoading(true)
      try {
        await putExchangePlatform({
          intPlatformId: form.platformId,
          vchApiCode: form.vchApiCode,
          intPassPhrase: form.intPassPhrase,
          intStatusId: valuesDefaultForm? valuesDefaultForm.intStatusId: 0,
          vchImage: form.vchImage,
          vchName: form.platformName,
          vchServiceDomain: form.vchServiceDomain
        })
        toast.success(t('CUPON_UPDATED_SUCCESS'), { duration: 4000 })
        setPlatforms()
        submitSuccess()
      } catch (error: any) {
        toast.error(error.message, { duration: 4000 })
      } finally {
        setSubmitLoading(false)
      }
    }
  }

  //--------------------------
  const { t } = useTranslation()
  const theme = useTheme()
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
        {ModeForm.create !== modeForm && (
          <FormControl fullWidth sx={{ my: 2 }}>
            <Controller
              name='platformId'
              control={control}
              render={({ field: { ...field }, fieldState }) => (
                <TextField
                  disabled
                  variant='outlined'
                  {...field}
                  label={t('ID')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </FormControl>
        )}

        {
          <FormControl fullWidth sx={{ my: 2 }}>
            <Controller
              name='platformName'
              control={control}
              render={({ field: { ...field }, fieldState }) => (
                <TextField
                  variant='outlined'
                  {...field}
                  label={t('NAME')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </FormControl>
        }

        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='vchImage'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                disabled={ModeForm.show === modeForm}
                {...field}
                label={t('IMAGE_URL')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>

        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='vchApiCode'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                disabled={ModeForm.show === modeForm}
                {...field}
                label={t('API_CODE')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>

        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='vchServiceDomain'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                disabled={ModeForm.show === modeForm}
                {...field}
                label={t('SERVICE_DOMAIN')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>

        <Grid container alignItems='center'>
          {!lessThanSmall && (
            <Grid item xs={1} sm={1.5}>
              <Avatar sx={{ bgcolor: 'primary.main', width: 50, height: 50 }} variant='rounded'>

              <Icon icon={getValues('intPassPhrase') ? 'mdi:eye-outline' : 'mdi:eye-off-outline'} />
              </Avatar>
            </Grid>
          )}

          <Grid item xs={10} sm={8.5}>
            <Box>
              <Typography align='center' fontSize={22}>
              {`${t('PASS_PHRASE')} / ${t('API_PASSWORD')}`}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Controller
              name='intPassPhrase'
              control={control}
              render={({ field: { ...field } }) => (
                <Switch defaultChecked={getValues('intPassPhrase')} {...field} disabled={ModeForm.show === modeForm} />
              )}
            />
          </Grid>
        </Grid>
        {ModeForm.show !== modeForm && (
          <CustomLoadingBtn
            disabled={ModeForm.edit === modeForm ? !formState.isDirty : false}
            size='large'
            variant='contained'
            type='submit'
            style={{ minHeight: 60, width: '100%' }}
            sx={{ mt: { xs: 4, sm: 4 } }}
            loading={submitLoading}
          >
            {t('SAVE')}
          </CustomLoadingBtn>
        )}
      </form>
    </>
  )
}

export default PlatformForm
