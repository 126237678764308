import { useEffect, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Avatar,
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Switch,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material'
import Chip from '@mui/material/Chip'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { Theme, useTheme } from '@mui/material/styles'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import MDEditor from '@uiw/react-md-editor'
import Icon from 'src/@core/components/icon'
import { postNews, putNews } from 'src/@http/news'
import CustomBtn, { CustomLoadingBtn } from 'src/layouts/components/shared-component/CustomBtn'
import { ModeForm } from 'src/types'
import adminStore from 'src/zustand/admin'
import newsStore, { Category, News } from 'src/zustand/news'
import * as yup from 'yup'

interface Props {
  modeForm: ModeForm
  submitLoading: boolean
  valuesDefaultForm?: News | null
  closeFunc: () => any
}

interface NewsForm {
  id: number
  title: string
  thumbnail: string
  status: boolean
  startDate: Date
  endDate: Date
  categoriesIds: number[]
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight: personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium
  }
}

const NewsForm = ({ modeForm, closeFunc, valuesDefaultForm }: Props) => {
  const { t } = useTranslation()
  const { getAllAccountTypes, accountsTypes, getAllCommissionRank } = adminStore()
  const { categories, getNews } = newsStore()

  let defaultValuesForm: NewsForm = {
    id: 0,
    title: '',
    thumbnail: '',
    status: true,
    startDate: new Date(),
    endDate: new Date(),
    categoriesIds: []
  }

  if (modeForm === ModeForm.edit && valuesDefaultForm) {
    const aux1 = valuesDefaultForm.startDate.split('T')[0]
    const aux2 = valuesDefaultForm.endDate.split('T')[0]
    defaultValuesForm = {
      id: valuesDefaultForm.id,
      title: valuesDefaultForm.title,
      thumbnail: valuesDefaultForm.thumbnail,
      status: valuesDefaultForm.statusId === 1,
      startDate: new Date(`${aux1}:00:00:00`),
      endDate: new Date(`${aux2}:00:00:00`),
      categoriesIds: valuesDefaultForm.categories.map((value: Category)=> value.id)
    }
  }
 

  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)
  const tomorrow = new Date()
  tomorrow.setDate(new Date().getDate() + 1)

  const formatDate = (date: any) => {
    return new Date(date).toLocaleDateString()
  }

  const validations = {
    title: yup.string(),
    thumbnail: yup.string(),
    statusId: yup.boolean(),
    categoriesIds: yup.array().min(1, t('CATEGORY_IS_REQUIRED') || 'Category is required'),
    startDate: yup.date().min(yesterday, ({ min }) => `Date needs to be before ${formatDate(min)}.`),
    endDate: yup
      .date()
      .when('startDate', ([startDate], schema) =>
        schema.min(startDate, ({ min }) => `Date needs to be before ${formatDate(min)}.`)
      )
  }

  if (modeForm === ModeForm.create) {
    validations.title = validations.title.required()
  }

  const schemaValidations = yup.object().shape(validations)

  
  const theme = useTheme()
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm<NewsForm>({ resolver: yupResolver(schemaValidations), defaultValues: defaultValuesForm })

  const statusWatch = useWatch({ control, name: 'status' })

  const [text, setText] = useState<string>(
    valuesDefaultForm && valuesDefaultForm !== null && modeForm === ModeForm.edit ? valuesDefaultForm.content : ''
  )

  useEffect(() => {
    if (accountsTypes.length === 0) getAllAccountTypes()
  }, [])

  const disableStartDate = (datee: Date) => {
    return datee <= yesterday
  }

  const disableEndDate = (datee: Date) => {
    return datee <= getValues('startDate')
  }

  const success = async () => {
    closeFunc()
  }

  const onSubmit = async (form: NewsForm) => {
    console.log(form, text)

    setIsLoading(true)
    if (modeForm === ModeForm.create) {
      try {
        await postNews({
          title: form.title,
          statusId: form.status?1:0,
          categories: form.categoriesIds,
          content: text,
          endDate: form.endDate.toISOString(),
          startDate: form.startDate.toISOString(),
          thumbnail: form .thumbnail
        })
        toast.success(t('CREATE_SUCCESS'), { duration: 4000 })
        closeFunc()
        getNews()
      } catch (error: any) {
        toast.error(error.message, { duration: 4000 })
      }
    } else {
        try {
          await putNews(form.id, 
            {
              title: form.title,
              statusId: form.status?1:0,
              categories: form.categoriesIds,
              content: text,
              endDate: form.endDate.toISOString(),
              startDate: form.startDate.toISOString(),
              thumbnail: form .thumbnail
            })
          toast.success(t('UPDATE_SUCCESS'), { duration: 4000 })
          closeFunc()
          getNews()
        } catch (error: any) {
          toast.error(error.message, { duration: 4000 })
        }
    }
    setIsLoading(false)
  }

  const [categoriesIdsSelected, setCategoriesIdsSelected] = useState<string[]>([])

  const handleChange = (event: SelectChangeEvent<typeof categoriesIdsSelected>) => {
    const {
      target: { value }
    } = event
    setCategoriesIdsSelected(typeof value === 'string' ? value.split(',') : value)
  }

  return (
    <>
      <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='title'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                disabled={ModeForm.show === modeForm}
                {...field}
                label={t('TITLE')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>
        <Box sx={{ mb: 4 }}>
          <MDEditor
            value={text}
            onChange={(value: string | undefined) => {
              if (value) setText(value)
            }}
            height={400}
          />
        </Box>
        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='categoriesIds'
            control={control}
            render={({ field, fieldState }: any) => (
              <FormControl error={!!fieldState.error}>
                <InputLabel id='Categories'>{t('CATEGORIES')}</InputLabel>
                <Select
                  {...field}
                  error={!!fieldState.error}
                  multiple
                  value={field.value}
                  input={<OutlinedInput label={t('CATEGORIES')} />}
                  renderValue={(selected: any) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value: any) => {
                        const category = categories.find(c => c.id === parseInt(value))

                        return <Chip key={value} label={category?.name} />
                      })}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                  
                >
                  {categories.map((category: Category, key: number) => (
                    <MenuItem
                      key={key}
                      value={category.id}
                      style={getStyles(`${category.id}`, categoriesIdsSelected, theme)}
                    >
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  <Typography color='error' sx={{ fontSize: 12 }}>
                    {fieldState.error?.message}
                  </Typography>{' '}
                </FormHelperText>
              </FormControl>
              
            )}
          />
        </FormControl>

        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            control={control}
            name='startDate'
            render={({ field: { ref, onBlur, name, ...field }, fieldState }) => (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  {...field}
                  inputRef={ref}
                  label={t('START_DATE')}
                  shouldDisableDate={disableStartDate}
                  renderInput={inputProps => (
                    <TextField
                      {...inputProps}
                      onBlur={onBlur}
                      name={name}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </LocalizationProvider>
            )}
          />
        </FormControl>

        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            control={control}
            name='endDate'
            render={({ field: { ref, onBlur, name, ...field }, fieldState }) => (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  {...field}
                  inputRef={ref}
                  label={t('END_DATE')}
                  shouldDisableDate={disableEndDate}
                  renderInput={inputProps => (
                    <TextField
                      {...inputProps}
                      onBlur={onBlur}
                      name={name}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </LocalizationProvider>
            )}
          />
        </FormControl>

        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='thumbnail'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                disabled={ModeForm.show === modeForm}
                {...field}
                label={t('THUMBNAIL')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center' }}>
          <Box>
            <Avatar sx={{ bgcolor: 'primary.main', width: 50, height: 50 }} variant='rounded'>
              <Icon icon={statusWatch ? 'mdi:eye-outline' : 'mdi:eye-off-outline'} color='white' fontSize={30} />
            </Avatar>
          </Box>
          <Box>
            <Box>
              <Typography align='center' fontSize={22}>
                {t('STATUS')}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Controller
              name='status'
              control={control}
              render={({ field: { ...field } }) => (
                <Switch defaultChecked={defaultValuesForm.status} {...field} disabled={ModeForm.show === modeForm} />
              )}
            />
          </Box>
        </Box>

        <Grid container>
          <Grid sx={{ p: 1 }} item xs={6}>
            <CustomLoadingBtn
              loading={isLoading}
              fullWidth
              size='large'
              type='submit'
              variant='contained'
              sx={{ mt: 3 }}
            >
              {valuesDefaultForm ? t('EDIT') : t('SAVE')}
            </CustomLoadingBtn>
          </Grid>
          <Grid sx={{ p: 1 }} item xs={6}>
            <CustomBtn fullWidth size='large' variant='contained' onClick={() => closeFunc()} sx={{ mt: 3 }}>
              {t('CANCEL')}
            </CustomBtn>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

NewsForm.defaultProps = {
  editForm: ModeForm.create
}

export default NewsForm
