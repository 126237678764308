import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, CardContent, Grid, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import EndDateSubscription from 'src/layouts/components/shared-component/EndDateSubscription'
import { User } from 'src/types/models/userModels'
import profileStore, { ProfileStore } from 'src/zustand/profile'

import Statistics from './Statistics'

const MyPerformance = () => {
  const { t } = useTranslation()

  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('lg'))

  const userDetail: User = profileStore((state: ProfileStore) => state.userDetail)

  const isLogged = profileStore((state: ProfileStore) => state.isLogged)

  const setperformanceDetailInit = profileStore((state: ProfileStore) => state.setperformanceDetailInit)

  useEffect(() => {
    const userId = Number(userDetail.userId)
    if (userId !== 0) {
      const endDate = new Date() // Now
      const startDate = new Date()
      startDate.setDate(startDate.getDate() - 7)
      setperformanceDetailInit(userId, startDate, endDate, 'LAST_7_DAYS')
    }
  }, [JSON.stringify(userDetail)])

  return (
    <Card sx={{ minWidth: 275 }}>
      <Grid container sx={{ alignItems: 'center', justifyContent: 'center' }}>
        <Grid item xs={12} style={{ ...(!isLogged ? { filter: 'blur(3px)' } : {}) }}>
          <CardContent style={{ ...(!isLogged ? { userSelect: 'none' } : {}) }}>
            
            <Typography sx={{ mb: userDetail.activeSubscription ? 2 : 0 }} variant='h5' component='div'>
              {t('MY_PERFORMANCE')}
            </Typography>

            {!mobile && (userDetail.activeSubscription || userDetail.blnPendingSubscription) && (
                <EndDateSubscription></EndDateSubscription>
            )}
            {userDetail.vchUrgentNote !== '' && (
              <Typography sx={{ mb: 1.5, ml: 1, fontWeight: 'bold' }} color='#F61414'>
                {userDetail.vchUrgentNote}
              </Typography>
            )}
            <Statistics></Statistics>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  )
}

export { MyPerformance }
