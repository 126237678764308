import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Theme, useMediaQuery } from '@mui/material'
import { getInfoReferredUsers,InfoReferredUser } from 'src/@http/user'
import { logger } from 'src/utils/Logger'
import profileStore, { ProfileStore } from 'src/zustand/profile'

import RefDefaultCard from './RefDefaultCard'
import SubscriptionCommissionCard from './SubscriptionCommissionCard'

const ReferredCards = () => {
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const { t } = useTranslation()
  const [infoReferredUser, setInfoReferreduser] = useState<InfoReferredUser | null>(null)
  const user = profileStore((state: ProfileStore) => state.userDetail)
  const setInfoReferredUserStore = profileStore((state: ProfileStore) => state.setInfoReferredUser)

  useEffect(()=> {
    const init =  async () => {
      if(Number(user.userId) !== 0) {
        try {
          const response = await getInfoReferredUsers(Number(user.userId))
          setInfoReferredUserStore(response)
          setInfoReferreduser(response)
        } catch (error) {
          logger.log('Error [getInfoReferredUsers]', error)
        }
      }
     
    }
    init()
  }, [user])

  return (
    <Grid
      container
      xs={12}
      spacing={downMd && !downSm ? 2 : 0}
      rowSpacing={2}
      sx={{ display: 'flex', justifyContent: downMd ? 'center' : 'space-between' }}
    >
      <Grid item xs={12} sm={4} md={2.3}>
        <RefDefaultCard
          title={t('NUMBER_OF_DIRECT_REFERRALS')}
          value={infoReferredUser?.numberDirectReferrals || 0}
          imageUrl='/images/icons/LINK-Referidos-01.png'
          showGradient
        />
      </Grid>
      <Grid item xs={12} sm={4} md={2.3}>
        <RefDefaultCard
          title={t('TOTAL_REFERRALS')}
          value={infoReferredUser?.totalReferrals || 0}
          imageUrl='/images/icons/Widget-Referidos-TOTALES-01.png'
        />
      </Grid>
      <Grid item xs={12} sm={4} md={2.3}>
        <SubscriptionCommissionCard
        amountSubscriptionCommission={infoReferredUser?.amountSubscriptionCommission || 0}
        totalSixMonths={infoReferredUser?.totalSixMonths || 0}
        totalTwelveMonths={infoReferredUser?.totalTwelveMonths || 0}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={2.3}>
        <RefDefaultCard
          title={t('TOTAL_REFERRALS_OF_YOUR_NETWORK_WITHOUT_ACTIVE_SUBSCRIPTION')}
          value={infoReferredUser?.totalNoSubscription || 0}
          imageUrl='/images/icons/Total Referidos SIN estrategia-01.png'
        />
      </Grid>
      <Grid item xs={12} sm={4} md={2.3}>
        <RefDefaultCard
          title={t('TOTAL_DIRECT_REFERRALS_WITH_ACTIVE_SUBSCRIPTION')}
          value={infoReferredUser?.totalSubscription || 0}
          imageUrl='/images/icons/Referidos directo SIN estrategia Activa-01.png'
        />
      </Grid>
      
    </Grid>
  )
}

export default ReferredCards
