import { getAsync, putAsync } from 'src/@http'

const root = '/telegram-bot'

export interface TelegramBotSetting {
  telegramBotConfigurationId: number
  apiKey: string
  secret: string
  userId: number
  traderId: number
  symbolPercentageChange: number
  balancePercentageChange: number
  marketOrdersConsumed: number
  balanceFetchMs: number
  balancePercentageFetchMs: number
  liquidationPriceFecthMs: number
  marketOrdersConsumedFetchMs: number
  marketOrdersTimeFetchMs: number
}

export const getTelegramBotSettings = (): Promise<TelegramBotSetting> => {
  return getAsync(root + '/configuration')
}

export const putTelegramBotSettings = (body: TelegramBotSetting): Promise<boolean> => {
  return putAsync(root + '/configuration', body)
}
