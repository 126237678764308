import React from 'react'
import ReactPlayer from 'react-player/youtube'
import { Card, CardContent, CardHeader } from '@mui/material'

interface Props {
  title: string
  subheader: string
  url: string
}

// Only loads the YouTube player
const CardHelpVideo = (props: Props) => {
  const { title, url, subheader } = props

  return (
    <Card sx={{ minHeight: 480 }}>
      <CardHeader title={title} subheader={subheader} />
      <CardContent>
        <ReactPlayer
          url={url}
          controls={true}
          width='100%'
          // height='100%'
          /*width='400px'*/
        />
      </CardContent>
    </Card>
  )
}

export default CardHelpVideo
