import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material'
import Icon from 'src/@core/components/icon'
import CustomAvatar from 'src/@core/components/mui/avatar'
import { DialogGeneric } from 'src/pages/components/generic/DialogGeneric'
import { ModeForm } from 'src/types'
import { /*PercentageTraderPost, */ Trader /*, TraderPercentageStrategy */ } from 'src/types/models/traderModel'
import adminStore from 'src/zustand/admin'

// import adminStore from 'src/zustand/admin'
// import { logger } from 'src/utils/Logger'
import TraderItemCard from './TraderItemCard'
import TraderRegisterForm from './TraderRegisterForm'
// import CustomBtn /*, { ActiveBtn }*/ from 'src/layouts/components/shared-component/CustomBtn'

const TraderCard = () => {
  const [showTraderRegisterDialog, setShowTraderRegisterDialog] = useState<boolean>(false)
  //const [traders, setTraders] = useState<Trader[]>([])
  const [traderSelected, setTraderSelected] = useState<Trader | null>(null)
  const [traderStrategiesSelected, setTraderStrategiesSelected] = useState<any | null>(null)
  const { t } = useTranslation()
  const {getAllTraders, traders} = adminStore()
  const [notFound, setNotFound] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const init = async () => {
      setLoading(true)
      const tradersList = await getAllTraders()
      if(tradersList.length === 0)
        setNotFound(true)
      setLoading(false)
    }
    init()
    
  },[])

  const submitGetTraders = async () => {
    
    setShowTraderRegisterDialog(false)
    await getAllTraders()
    /*
    setTraders([])
    getTraders()
      .then(res => {
        setTraders(res)
        setShowTraderRegisterDialog(false)
      })
      .catch(err => toast.error(err))
      */
  }

  const openDialogEdit = (trader: Trader, percents: any) => {
    setTraderSelected(trader)
    setTraderStrategiesSelected(percents)
    setShowTraderRegisterDialog(true)
  }

  const openDialogCreate = () => {
    setTraderSelected(null)
    setTraderStrategiesSelected(null)
    setShowTraderRegisterDialog(true)
  }

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CustomAvatar sx={{ width: 40, height: 40, bgcolor: 'primary.main' }} variant='rounded'>
              <Icon icon='mdi:account-multiple' style={{ fontSize: 24 }} color='white' />
            </CustomAvatar>
            <Typography sx={{ m: 3 }} color='white' variant='h5' component='div'>
              {t('TRADER')}
            </Typography>
          </Box>
          <Button onClick={() => openDialogCreate()} startIcon={<Icon icon='mdi:plus-box-outline' color='#9295A6' />}>
            <Typography color='#9295A6' sx={{ textTransform: 'capitalize' }}>
              {t('ADD')}
            </Typography>
          </Button>
        </Box>

        {traders.length !== 0 && <Grid sx={{ mt: 3 }} container>
          {traders.map(trader => (
            <Grid key={trader.traderId} item xs={12} sm={6} lg={4} sx={{ p: 1 }}>
              <TraderItemCard
                trader={trader}
                editFunction={(percents: any) => openDialogEdit(trader, percents)}
                deleteFunctionSuccess={() => submitGetTraders()}
              />
            </Grid>
          ))}
        </Grid>}
        {(notFound) && (
          <Typography align='center'>
            {t('DATA_NOT_FOUND')}
          </Typography>
        )}
        {(traders.length === 0 && loading) && (
          <Grid sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Grid>
        )}


      </CardContent>
      <DialogGeneric
        show={showTraderRegisterDialog}
        closeFunc={() => {
          setShowTraderRegisterDialog(false)
        }}
        hideActions={true}
        title={t('TRADER_DETAIL')}
        leftAline={true}
        showIconClose={true}
      >
        <Box sx={{ mt: 4 }}>
          <TraderRegisterForm
            strategies={traderStrategiesSelected}
            trader={traderSelected}
            modeForm={ModeForm.edit}
            submitFinishFunction={() => submitGetTraders()}
            closeFunc={() => {setShowTraderRegisterDialog(false)}}
          />
        </Box>
      </DialogGeneric>
    </Card>
  )
}

export default TraderCard
