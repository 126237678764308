import axios from 'axios';
import { mountStoreDevtool } from 'simple-zustand-devtools'
import { getAllAccountType, getAllRank } from 'src/@http/commission'
import { getCompanies } from 'src/@http/company'
import { GlobalSetting } from 'src/@http/global-settings'
import { getAllByTraderGridbots,GridBot } from 'src/@http/gridbot'
import { getAllPromocodes, Promocode } from 'src/@http/promocode'
import { getSubscriptions } from 'src/@http/subscription'
import { getSubscriptionCommissionWithdrawal,SubscriptionCommissionWithdrawal } from 'src/@http/subscriptionCommissionWithdrawal';
import { getSubscriptionTransaction,SubscriptionTransaction } from 'src/@http/subscriptionTransaction';
import { getPositionsBybitByTraderId, getTraders, putClosePositionByPositionIdAndTraderId } from 'src/@http/trader'
import { changeStrategyById, getAllUser, getUserDetailById } from 'src/@http/user'
import { SymbolResponse } from 'src/pages/components/admin/trader/admin-gridbot/types/bybit'
import { Company } from 'src/types/models/companyModel'
import { Trader } from 'src/types/models/traderModel'
import { logger } from 'src/utils/Logger'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import getPositions from './utils/getPositions'


type State = {
  users: API.User.AllUserResponse[]
  loading: boolean
  loadingUsers: boolean
  loadingClosePosition: boolean
  promocodes: Promocode[]
  traders: Trader[]
  companies: Company[]
  positionsBybit: API.Trader.PositionsBybit[]
  performanceUserDetail: Store.PerformanceDetail
  subscriptions: API.Subscription.SubscriptionResponse[]
  publicGlobalSettings:GlobalSetting[]
  accountsTypes: API.Commission.AccountType[]
  commissionRank: API.Commission.Rank[]
  gridBots: GridBot[],
  bybitSymbols: string[]
  subscriptionTransaction: SubscriptionTransaction[]
  subscriptionCommissionWithdrawal: SubscriptionCommissionWithdrawal[]
}

interface ClosePosition {
  intTraderPositionByBitId: number
  vchSize: string
}

type Actions = {
  getAllUser: () => void
  getAllPromocodes: () => Promise<Promocode[]>
  getAllSubscriptions: () => Promise<API.Subscription.SubscriptionResponse[]>
  resetAdminStore: () => void
  getAllTraders: () => Promise<Trader[]>
  getAllCompanies: () => Promise<Company[]>
  setPublicGlobalSettings: (globalSettings: GlobalSetting[]) => void
  setperformanceUserDetail: (userId: number, startDate: Date, endDate: Date) => void
  getBybitPositionsTradersById: (traderId: string) => void
  closePositionsByTraderIdAndPositionId: (traderId: number, positionId: number, body: ClosePosition) => void
  getAllAccountTypes: () =>  void
  getAllCommissionRank: () =>  void
  getAllByTraderGridbots: (traderId: number) => void
  resetGridbots: () =>  void
  getBybitSymbols: () => void
  getsubscriptionTransaction:() => void
  activateStrategyByUser:(userId:number) => void
  getSubscriptionCommissionWithdrawal:() => void
}

const initialState: State = {
  users: [],
  loading: false,
  loadingClosePosition: false,
  loadingUsers: false,
  promocodes: [],
  traders: [],
  companies: [],
  positionsBybit: [],
  performanceUserDetail: {
    data: [],
    loading: false,
    id: 1,
    title: ''
  },
  
  subscriptions: [],
  publicGlobalSettings: [],
  accountsTypes:[],
  commissionRank: [],
  gridBots: [],
  bybitSymbols: [],
  subscriptionTransaction:[],
  subscriptionCommissionWithdrawal: []
}

const adminStore = create<State & Actions>()(
  persist(
    (set, get) => ({
      ...initialState,
      getAllCommissionRank: async () => {
        const response = await  getAllRank()
        set({ commissionRank: response.filter(rank => rank.statusId === 1) })
      },   
      getAllUser: async () => {
        set({ loadingUsers: true })
        const users = await getAllUser()
        set({ users: users.sort((user1: API.User.AllUserResponse, user2: API.User.AllUserResponse) => new Date(user1.createdDate) >= new Date(user2.createdDate)?-1:1) })
        set({ loadingUsers: false })
      },
      getAllByTraderGridbots: async (traderId: number) => {
        set({ gridBots: await getAllByTraderGridbots(traderId) })
      },
      getsubscriptionTransaction: async () => {
        set({ subscriptionTransaction: await getSubscriptionTransaction() })
      },
      getSubscriptionCommissionWithdrawal: async () => {
        set({ subscriptionCommissionWithdrawal: await getSubscriptionCommissionWithdrawal() })
      },
      activateStrategyByUser: async (userId: number) => {
        try {
          logger.log('Activating strategy....')
          const userDetail = await getUserDetailById(userId.toString())
          await changeStrategyById(`${userId}`, userDetail.currentStrategyId)
        } catch (error) {
          logger.error('Error:', error)
        }
      },
      getBybitSymbols: async () => {
        try {
          const response = await axios(
            'https://api-testnet.bybit.com/v5/market/instruments-info?category=linear'
          );
          const data = response.data as SymbolResponse;
          const usdtList = data.result.list.filter(value => value.symbol.includes('USDT'))
          set({ bybitSymbols: usdtList.map(value=> value.symbol) })
        } catch (error) {
          logger.log(error)
        }
      },
      resetGridbots: () => {
        set({ gridBots: [] })
      },
      getAllPromocodes: async () => {
        const  promocodes = await getAllPromocodes()
        set({ promocodes })

        return promocodes
      },
      getAllSubscriptions: async () => {
        const subscriptions = await getSubscriptions()
        set({ subscriptions })

        return subscriptions
      },
      getAllTraders: async () => {
        const traders = await getTraders()
        set({ traders})

        return traders
      },
      getAllCompanies: async () => {
        const companies = await getCompanies()
        set({ companies })

        return companies
      },
      setPublicGlobalSettings: async (globalSettings: GlobalSetting[]) => {
        const publicGlobalSettings = globalSettings.filter(gg => !gg.isPrivate)
        set({ publicGlobalSettings:  publicGlobalSettings})
      },
      getBybitPositionsTradersById: async (traderId: string) => {
        set({ loading: true })
        set({ positionsBybit: await getPositionsBybitByTraderId(traderId) })
        set({ loading: false })
      },

      closePositionsByTraderIdAndPositionId: async (traderId: number, positionId: number, body: ClosePosition) => {
        set({ loadingClosePosition: true })
        const res = await putClosePositionByPositionIdAndTraderId(traderId, positionId, body)
        logger.log('Res Close Position :', res)
        set({ positionsBybit: await getPositionsBybitByTraderId(traderId.toString()) })
        set({ loadingClosePosition: false })
      },
      setperformanceUserDetail: async (userId: number, startDate: Date, endDate: Date) => {
        set({
          performanceUserDetail: {
            ...get().performanceUserDetail,
            loading: true,
            data: []
          }
        })
        const data = await getPositions(userId, startDate, endDate)
        set({
          performanceUserDetail: {
            ...get().performanceUserDetail,
            loading: false,
            data
          }
        })
      },
      getAllAccountTypes: async () => {
        set({ accountsTypes: await getAllAccountType() })
      },   
      resetAdminStore: () => {
        set(initialState)
      }
    }),
    {
      name: 'admin-store'
    }
  )
)

if (process.env.NODE_ENV === 'development') mountStoreDevtool('admin-store', adminStore)

export default adminStore
