import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Avatar, Box, ButtonGroup, Card, CardContent, Chip, CircularProgress, Grid, IconButton, Typography } from '@mui/material'
import Icon from 'src/@core/components/icon'
import { cancelAllOperationById, deleteGribot, GridBot, putGribot } from 'src/@http/gridbot'
import CustomBtn from 'src/layouts/components/shared-component/CustomBtn'
import { DialogGeneric } from 'src/pages/components/generic/DialogGeneric'
import { logger } from 'src/utils/Logger'
import adminStore from 'src/zustand/admin'
import profileStore from 'src/zustand/profile'

import ChangeStatus from '../../actions-form/ChangeStatus'

import GridBotOperationsDialog from './GridBotOperationsDialog'
import GridBotRSIDialog from './GridBotRSIDialog'

interface Props {
  item: GridBot
  editFunction: (data: any) => any
  deleteFunctionSuccess: () => any
  updateFunction: () => any
  setGlobalLoading: (value: boolean)=> void
}

const GridBotItemCard = ({ item, editFunction, deleteFunctionSuccess, updateFunction, setGlobalLoading }: Props) => {
  const { t } = useTranslation()
  const [show, setShow] = useState<boolean>(false)
  const [loadingCancel, setLoadingCancel] = useState<boolean>(false)
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false)
  const [showDetails, setShowDetails] = useState<boolean>(false)
  const [showRSI, setShowRSI] = useState<boolean>(false)
  const [showStopAllwithOperations, setShowStopAllwithOperations] = useState<boolean>(false)
  const { getAllByTraderGridbots,resetGridbots } = adminStore()

  const deleteItem = async () => {
    try {
      const res = await deleteGribot(item.intGridBotId)
      toast.success(t('DELETE_SUCCESS'))
      deleteFunctionSuccess()
    } catch (err: any) {
      toast.error(err.message)
    }
  }

  const change = async (status: boolean) => {
    const sleep = (ms: any) => new Promise(r => setTimeout(r, ms))
    setLoadingStatus(true)
    try {
      await putGribot({
        intGridBotId: item.intGridBotId,
        intStatusId: status ? 1 : 0,
        intUpdatedUserId: parseInt(profileStore.getState().userDetail.userId)
      })
      if(status)
        await sleep(8000)
      await getAllByTraderGridbots(item.intTraderId)
    } catch (error) {
      logger.log('[putGribot] error: ', error)
    }
    setLoadingStatus(false)
  }

  const stopAllOperations = async () => {
    //setLoadingCancel(true)
    setGlobalLoading(true)
    resetGridbots()
    try {
      await cancelAllOperationById(item.intGridBotId)
      await getAllByTraderGridbots(item.intTraderId)
      toast.success(t('ALL_OPERATIONS_WAS_STOPPED_SUCCESSFULLY_FOR_BOT', {id: item.intGridBotId}))
    } catch (err: any) {
      toast.error(err.message)
    }
    //setLoadingCancel(false)
    setGlobalLoading(false)
  }

  const ActionButtons = () => {
    const buttons = [
      {
        icon: 'carbon:edit',
        action: () => {
          editFunction(item)
        }
      },
      {
        icon: 'ph:trash-light',
        action: () => {
          setShow(true)
        }
      }
    ]

    return (
      <Grid container sx={{ flexDirection: 'row', justifyContent: 'end' }}>
        <ButtonGroup>
          {buttons.map(button => (
            <Grid item key={button.icon}>
              <IconButton onClick={() => button.action()} aria-label='capture screenshot' color='secondary'>
                <Icon icon={button.icon} fontSize={16} />
              </IconButton>
            </Grid>
          ))}
        </ButtonGroup>
      </Grid>
    )
  }

  return (
    <Card sx={{ backgroundColor: 'background.default' }}>
      <CardContent sx={{ pr: 0 }}>
        <Grid container sx={{ flexDirection: 'row', width: '100%' }}>
          <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              src={`https://raw.githubusercontent.com/spothq/cryptocurrency-icons/master/32/color/${item.vchSymbol
                .replace('USDT', '')
                .toLowerCase()}.png`}
              sx={{ width: 30, height: 30 }}
            >
              <Typography sx={{ fontSize: 18 }}>{item.vchSymbol[0]}</Typography>
            </Avatar>
          </Grid>
          <Grid item sx={{ display: 'flex', alignItems: 'center' }} xs={7}>
            <Typography sx={{ fontSize: 18, mr: 2 }}>{item.vchSymbol}</Typography>

            <Chip
              size='small'
              label={item.vchSide === 'Sell' ? t('SELL') : t('BUY')}
              color={item.vchSide === 'Sell' ? 'error' : 'success'}
            />
          </Grid>
          <Grid item xs={3} sx={{ justifyContent: 'end', alignItems: 'center' }}>
            <ActionButtons />
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'end' }}>
        <IconButton
            onClick={() => {
              setShowRSI(true)
            }}
            color='secondary'
          >
            <Icon icon='mdi:chart-line' fontSize={16} />
          </IconButton>
          <IconButton
            onClick={() => {
              setShowDetails(true)
            }}
            color='secondary'
          >
            <Icon icon='mdi:eye-outline' fontSize={16} />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
          <Typography sx={{ mr: 2 }}> {t('STATUS')}:</Typography>
          {loadingStatus && <Box sx={{minHeight:38}}><CircularProgress size={25} sx={{mx:2, mt:2}}></CircularProgress></Box>}
          {!loadingStatus && 
          <ChangeStatus
            change={change}
            status={item.intStatusId}
            keyConfirmMessage={
              item.intStatusId === 1 ? 'CONFIRM_STOP_GRIDBOT_WITHOUT_CLOSING_OPERATIONS' : 'CONFIRM_ENABLING_GRIDBOT'
            }
          ></ChangeStatus>}
          
          
        </Box>

        <Box sx={{ display: 'flex', mt: 2 }}>
          <Typography sx={{ mr: 2 }}>ID:</Typography>
          <Typography sx={{ fontWeight: 600 }}>{item.intGridBotId}</Typography>
        </Box>
        <Box sx={{ display: 'flex', mt: 2 }}>
          <Typography sx={{ mr: 2 }}> {t('AMOUNT')}:</Typography>
          <Typography sx={{ fontWeight: 600 }}>${item.dcmAmount}</Typography>
        </Box>
        <Box sx={{ display: 'flex', mt: 2 }}>
          <Typography sx={{ mr: 2 }}> {t('LEVERAGE')}:</Typography>
          <Typography sx={{ fontWeight: 600 }}>x{item.intLeverage}</Typography>
        </Box>
        <Box sx={{ display: 'flex', mt: 2 }}>
          <Typography sx={{ mr: 2 }}> {t('AMOUNT_MULTIPLIER')}:</Typography>
          <Typography sx={{ fontWeight: 600 }}>{item.dcmAmountMultiplier}</Typography>
        </Box>
        <Box sx={{ display: 'flex', mt: 2 }}>
          <Typography sx={{ mr: 2 }}> {t('PERCENTAGE_STEP')}:</Typography>
          <Typography sx={{ fontWeight: 600 }}>{item.dcmPercentageStep}</Typography>
        </Box>
        {false && (
          <Box sx={{ display: 'flex', mt: 2 }}>
            <Typography sx={{ mr: 2 }}> {t('PERCENTAGE_STEP_MULTIPLIER')}:</Typography>
            <Typography sx={{ fontWeight: 600 }}>{item.dcmPercentageStepMultiplier}</Typography>
          </Box>
        )}
        <Box sx={{ display: 'flex', mt: 2 }}>
          <Typography sx={{ mr: 2 }}> {t('PERCENTAGE_STEP_TP')}:</Typography>
          <Typography sx={{ fontWeight: 600 }}>{item.dcmPercentageStepTPSL}</Typography>
        </Box>

        <Box sx={{ display: 'flex', mt: 2 }}>
          <CustomBtn
            onClick={() => setShowStopAllwithOperations(true)}
            variant='contained'
            color='error'
            fullWidth
            disabled={!item.blnActiveOperations}
            startIcon={!loadingCancel && <Icon icon='mdi:robot-off' />}
            sx={{ mr: 4 }}
          >
            {!loadingCancel && 
            <Typography color='white' sx={{ fontWeight: 600 }}>
            {t('STOP_GRIDBOT_WITH_CLOSING_OPERATIONS')}
          </Typography>
            }
            {loadingCancel &&
                  <CircularProgress color="inherit" sx={{ fontWeight: 500}}/>
            
            }
          </CustomBtn>
        </Box>

        <DialogGeneric
          show={show}
          title={t('DELETE_GRIDBOT')}
          confirmText={t('CONFIRM') || ''}
          confirmFunc={() => deleteItem()}
          closeFunc={() => {
            setShow(false)
          }}
        >
          {t('CONFIRM_DELETE_MESSAGE')}
        </DialogGeneric>
        <DialogGeneric
          show={showStopAllwithOperations}
          title={t('STOP_GRIDBOT')}
          confirmText={t('CONFIRM') || ''}
          confirmFunc={() => {
            stopAllOperations()
          }}
          closeFunc={() => {
            setShowStopAllwithOperations(false)
          }}
        >
          {t('STOP_GRIDBOT_WITH_CLOSING_LIMIT_OPERATIONS')}
        </DialogGeneric>
        <GridBotOperationsDialog
        gridBot={item}
        showProp={showDetails}
        closeFunc={() => {setShowDetails(false)}}
        />
        <GridBotRSIDialog
        gridBot={item}
        showProp={showRSI}
        closeFunc={() => {setShowRSI(false)}}
        />
      </CardContent>
      
    </Card>
  )
}

export default GridBotItemCard
