import { useEffect, useMemo, useState } from 'react'
import { /*Controller, */ Controller, useForm } from 'react-hook-form' //Descomentar en caso de emergencia si no llegamos a integrar la wallet
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Avatar,
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  // FormHelperText, //Descomentar en caso de emergencia
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  // TextField, //Descomentar en caso de emergencia
  Typography
} from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Icon from 'src/@core/components/icon'
import { getSubscriptions } from 'src/@http/subscription'
import { CustomLoadingBtn } from 'src/layouts/components/shared-component/CustomBtn'
import { logger } from 'src/utils/Logger'
// import FileUploader from 'src/layouts/components/shared-component/FileUploader' //Descomentar en caso de emergencia
import * as yup from 'yup'

import CryptoDeposit from './CryptoDeposit'

interface SelectLabel {
  id: number
  label: string
}

interface Subscription extends SelectLabel {
  price: number
}

interface CryptoData {
  transactionHash: string
  network: SelectLabel
  currency: SelectLabel
  externalPayment: boolean
  promocode: string
  originWallet: string
  paymentMethodId: number
}

interface CreditCardData {
  number: string
  name: string
  cvv: number
  dataExpired: string
}

interface PaymentMethod extends SelectLabel {
  paymentLabel: string
}

interface PaymentMethodForm {
  transactionHash?: string
}

interface PaymentsForm {
  promocode: string
  originWallet: string
}

export enum PaymentMethodId {
  PROMOCODE = 1,
  INTERNAL = 2,
  EXTERNAL = 3,
  DIRECT = 4,
  EXCHANGE_AXES = 5
}

interface Props {
  onSave: (subscription: API.Subscription.SubscriptionResponse, paymentData: CryptoData) => void
  isLoading: boolean
}

const PaymentForm = ({ onSave, isLoading }: Props) => {
  const { t } = useTranslation()

  const paymentMethods: PaymentMethod[] = [
    /*
    {
      id: PaymentMethodId.EXCHANGE_AXES,
      label: t('EXCHANGE_AXES'),
      paymentLabel: t('VALIDATE_PAYMENT')
    },
    */
    {
      id: PaymentMethodId.DIRECT,
      label: t('DIRECT_WALLET'),
      paymentLabel: t('SEND_PAYMENT_DATA_FOR_VALIDATION')
    },
    /*
    {
      id: PaymentMethodId.EXTERNAL,
      label: t('AXES_WALLET'),
      paymentLabel: t('VALIDATE_PAYMENT')
    },
    */
    {
      id: PaymentMethodId.PROMOCODE,
      label: t('PROMOCODE'),
      paymentLabel: t('VALIDATE_PROMOCODE')
    }
    /*
    {
      id: '1',
      label: 'Pay Pal'
    },
    {
      id: '2',
      label: 'Credit & Debit Card'
    }
    */
  ]

  const networks: SelectLabel[] = [
    {
      id: 0,
      label: 'TRC20'
    },
    {
      id: 1,
      label: 'Ethereum Network'
    }
  ]

  const currencies: SelectLabel[] = [
    {
      id: 0,
      label: 'USDT'
    },
    {
      id: 1,
      label: 'Bitcoin'
    },
    {
      id: 2,
      label: 'Ethereum'
    }
  ]

  const [subscriptions, setSubscriptions] = useState<API.Subscription.SubscriptionResponse[]>([])

  const [subscriptionSelectedId, setSubscriptionSelectedId] = useState<string>('0')
  const [cryptoMethodSelectedId, setCryptoMethodSelectedId] = useState<string>('1')
  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(false)

  //const [subscriptionSelected, setSubscriptionSelected] = useState<API.Subscription.SubscriptionResponse|null>(null)
  const [paymentMethodIdSelected, setPaymentMethodIdSelected] = useState<number>(paymentMethods[0].id)
  const [networkIdSelected, setNetworkIdSelected] = useState<number>(networks[0].id)
  const [currencyIdSelected, setCurrencyIdSelected] = useState<number>(currencies[0].id)

  const [paymentMethodObject] = useMemo(
    () => [
      paymentMethods.find((paymentMethod: SelectLabel) => paymentMethod.id === paymentMethodIdSelected) ||
        paymentMethods[0]
    ],
    [paymentMethodIdSelected]
  )

  /* Formulario de validación */
  const defaultValuesForm: PaymentsForm = {
    promocode: '',
    originWallet: ''
  }
  
  const getValidations = (paymentMethodId: number) => {
    let validations = {}
    switch (paymentMethodId) {
      case PaymentMethodId.DIRECT:
        validations = {
          originWallet: yup.string().test('TRC20', t('TRC20_STRING_FORMAT') || '', function (value) {
            if (value === undefined) return false
      
            const expresionRegular = /^T[1-9A-HJ-NP-Za-km-z]{33}$/
      
            return expresionRegular.test(value)
          })
        }
        break;
        case PaymentMethodId.PROMOCODE:
          validations = {
            promocode: yup.string().required()
          }
        break;
    }

    return yup.object().shape(validations)
  }
  const schemaValidations = getValidations(paymentMethodObject.id)
  const { control, handleSubmit, getValues} = useForm<PaymentsForm>({
    resolver: yupResolver(schemaValidations),
    defaultValues: defaultValuesForm
  })

  useEffect(() => {
    logger.log(paymentMethodObject)
  }, [paymentMethodObject])

  useEffect(() => {
    getSubscriptions()
      .then(subscriptions => {
        setSubscriptions(subscriptions)
        setSubscriptionSelectedId(subscriptions[subscriptions.length-1].subscriptionId.toString())
      })
      .catch(err => toast.error(err))
  }, [])

  const handleCryptoMethodChange = (event: SelectChangeEvent) => {
    setCryptoMethodSelectedId(event.target.value as string)
  }

  const onSubmit = (data: PaymentsForm) => {
    //const { transactionHash } = data
    let subscription = subscriptions.find(
      (subscription: API.Subscription.SubscriptionResponse) =>
        subscription.subscriptionId === parseInt(subscriptionSelectedId)
    )
    if (!subscription) subscription = subscriptions[0]

    const paymentData: CryptoData = {
      currency: currencies.find((currency: SelectLabel) => currency.id === currencyIdSelected) || currencies[0],
      network: networks.find((network: SelectLabel) => network.id === networkIdSelected) || networks[0],
      transactionHash: '', //transactionHash || '',
      externalPayment: paymentMethodIdSelected === PaymentMethodId.EXTERNAL,
      promocode: paymentMethodIdSelected === PaymentMethodId.PROMOCODE ? data.promocode : '',
      originWallet: data.originWallet,
      paymentMethodId: paymentMethodIdSelected
    }

    onSave(subscription, paymentData)
  }

  const handleSubscriptionChange = (event: SelectChangeEvent) => {
    const data = event.target.value as string
    setSubscriptionSelectedId(data)
  }

  const handlePaymentMethodChange = (event: SelectChangeEvent) => {
    const data = event.target.value as string
    setPaymentMethodIdSelected(Number(data))
  }

  const handleNetworkChange = (event: SelectChangeEvent) => {
    const data = event.target.value as string
    setNetworkIdSelected(Number(data))
  }

  const handleNCurrencyChange = (event: SelectChangeEvent) => {
    const data = event.target.value as string
    setCurrencyIdSelected(Number(data))
  }

  return (
    <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
      {paymentMethodIdSelected !== PaymentMethodId.PROMOCODE && (
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id='demo-simple-select-label'>{t('SUBSCRIPTION')}</InputLabel>
          <Select
            labelId='subscription'
            id='subscription'
            required
            value={subscriptionSelectedId}
            label={t('SUBSCRIPTION')}
            onChange={handleSubscriptionChange}
            MenuProps={{
              PaperProps: {
                sx: {
                  '& .MuiBox-root': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%'
                  }
                }
              }
            }}
          >
            {subscriptions.map((subscription: API.Subscription.SubscriptionResponse, key: number) => (
              <MenuItem key={key} value={subscription.subscriptionId}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography>{subscription.subscriptionName}</Typography>
                  <Typography>USDT {subscription.subscriptionValue}</Typography>
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <FormControl fullWidth sx={{ py: 2, mb: 0, mt: 4 }}>
        <FormLabel sx={{ textAlign: 'left' }}>{t('CHOOSE_PAYMENT_METHOD')}:</FormLabel>
        <RadioGroup value={paymentMethodIdSelected} onChange={handlePaymentMethodChange}>
          {paymentMethods.map((paymentMethod: SelectLabel, key: number) => (
            <Box key={key} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <FormControlLabel key={key} value={paymentMethod.id} control={<Radio />} label={paymentMethod.label} />
              {paymentMethod.id === PaymentMethodId.DIRECT && (
                <Avatar
                  variant='square'
                  alt='crypto'
                  src='/images/payments/crypto-logo-2.png'
                  sx={{ width: 45, height: 45 }}
                />
              )}
              {paymentMethod.id === PaymentMethodId.EXCHANGE_AXES && (
                <Avatar
                  variant='square'
                  alt='crypto'
                  src='/images/apple-touch-icon.png'
                  sx={{ width: 45, height: 45 }}
                />
              )}
              {paymentMethod.id === PaymentMethodId.PROMOCODE && (
                <Icon icon='mdi:ticket' style={{ fontSize: 40, paddingRight: 2 }} color='white' />
              )}
            </Box>
          ))}
        </RadioGroup>
      </FormControl>

      {paymentMethodObject.id === PaymentMethodId.DIRECT && (
        <>
          <CryptoDeposit
            cryptoMethodValueId={PaymentMethodId.DIRECT}
            handleCryptoMethodChange={handleCryptoMethodChange}
          ></CryptoDeposit>
          <FormControl fullWidth sx={{ my: 4 }}>
            <Controller
              name='originWallet'
              control={control}
              render={({ field: { ...field }, fieldState }) => (
                <TextField
                  variant='outlined'
                  {...field}
                  label={t('ORIGIN_WALLET')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </FormControl>
        </>
      )}

      {paymentMethodObject.id === PaymentMethodId.EXCHANGE_AXES && (
        <>
          <CryptoDeposit
            cryptoMethodValueId={PaymentMethodId.EXTERNAL}
            handleCryptoMethodChange={handleCryptoMethodChange}
          ></CryptoDeposit>

          {/* Comentamos para integrar posteriormente con un servicio */}
          {/* <FormControl fullWidth sx={{ mb: 4, mt: 4 }}>
            <Controller
              name='transactionHash'
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange, onBlur } }) => (
                <TextField
                  autoFocus
                  label={t('TRANSACTION_HASH')}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  error={Boolean(errorsSendRecovery.transactionHash)}
                />
              )}
            />
            {errorsSendRecovery.transactionHash && (
              <FormHelperText sx={{ color: 'error.main' }}>{errorsSendRecovery.transactionHash.message}</FormHelperText>
            )}
          </FormControl>
          <FileUploader/> */}
        </>

        /*
        <>
         <FormControl fullWidth sx={{ mt: 4 }}>
            <InputLabel>{t('CURRENCY')}</InputLabel>
            <Select
              labelId='currency'
              id='currency'
              required
              value={currencyIdSelected}
              label={t('CURRENCY')}
              onChange={handleNCurrencyChange}
            >
              {currencies.map((currency: SelectLabel, key: number) => (
                <MenuItem key={key} value={currency.id}>
                  {currency.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 4, mt:4 }}>
            <Controller
              name='wallet'
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange, onBlur } }) => (
                <TextField
                  autoFocus
                  label={t('WALLET')}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  error={Boolean(errorsSendRecovery.wallet)}
                />
              )}
            />
            {errorsSendRecovery.wallet && (
              <FormHelperText sx={{ color: 'error.main' }}>{errorsSendRecovery.wallet.message}</FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth sx={{ mb: 4 }}>
            <InputLabel>{t('NETWORK')}</InputLabel>
            <Select
              labelId='network'
              id='network'
              required
              value={networkIdSelected}
              label={t('NETWORK')}
              onChange={handleNetworkChange}
            >
              {networks.map((network: SelectLabel, key: number) => (
                <MenuItem key={key} value={network.id}>
                  {network.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          
        </>*/
      )}
      {paymentMethodObject.id === PaymentMethodId.PROMOCODE && (
        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='promocode'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                {...field}
                label={t('Promocode')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>
      )}

      <Divider sx={{ mt: 2, mb: 2 }}></Divider>
      <CustomLoadingBtn
        variant='contained'
        loading={isLoading || subscriptions.length === 0}
        style={{ height: 60, width: '100%' }}
        sx={{ mt: 0 }}
        type='submit'
        disabled={disabledSubmit}
        /*type='submit' onClick={() => onSubmit({ transactionHash: 'dsadsadasdsa' })}*/
      >
        {paymentMethodObject.paymentLabel}
      </CustomLoadingBtn>
    </form>
  )
}

export default PaymentForm
export type { CreditCardData, CryptoData, Subscription }
