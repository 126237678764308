// ** React Imports
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
// ** MUI Imports
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { DataGrid } from '@mui/x-data-grid'
// ** Icon Imports
import Icon from 'src/@core/components/icon'
import PageHeader from 'src/@core/components/page-header'
import { getAll } from 'src/@http/user-admin'
import { UserModel } from 'src/types/models/userModels'

import columns from './columns'

interface TableHeaderProps {
  onAddNewUser: () => void
}

const TableHeader = (props: TableHeaderProps) => {
  // ** Props
  const { onAddNewUser } = props
  const { t } = useTranslation()

  return (
    <Box sx={{ p: 5, pb: 3, display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between' }}>
      <Button
        sx={{ mr: 4, mb: 2 }}
        color='secondary'
        variant='outlined'
        startIcon={<Icon icon='mdi:export-variant' fontSize={20} />}
      >
        {t('EXPORT')}
      </Button>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
        <Button sx={{ mb: 2 }} onClick={onAddNewUser} variant='contained'>
          {t('ADD_USER')}
        </Button>
      </Box>
    </Box>
  )
}

function AdminUsersList() {
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [userList, setUserList] = useState<UserModel[]>([])
  const { t } = useTranslation()

  useEffect(() => {
    getAll().then(userList => {
      setUserList(userList)
      setIsLoading(false)
    })
  }, [])

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <PageHeader
          title={<Typography variant='h5'>{t('USER_LIST')}</Typography>}
          subtitle={<Typography variant='body2'>{t('FIND_ALL')}</Typography>}
        />
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader title={t('SEARCH_FILTERS')} />

          <CardContent>
            <Grid container spacing={6}></Grid>
          </CardContent>

          <TableHeader
            onAddNewUser={() => {
              navigate('/admin-users/new')
            }}
          />

          <Divider />

          <DataGrid
            loading={isLoading}
            autoHeight
            rows={userList}
            getRowId={r => r._id}
            columns={columns}
            pageSize={100}
            disableSelectionOnClick
            rowsPerPageOptions={[10, 25, 50]}
          />
        </Card>
      </Grid>
    </Grid>
  )
}

export default AdminUsersList
