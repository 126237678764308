import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)
import { Bar } from 'react-chartjs-2'

import { hex2rgba } from './libs'

interface Props {
  title: string
  lineColor: string
  data: any[]
  keyData: string
  keyLabels: string
}

const BarChartAxes = ({ title, lineColor, data, keyData, keyLabels }: Props) => {
  const plugin = {
    id: 'customCanvasBackgroundColor',
    beforeDraw: (chart: any, args: any, options: any) => {
      const { ctx } = chart
      ctx.save()
      ctx.globalCompositeOperation = 'destination-over'
      ctx.fillStyle = options.color || '#99ffff'
      ctx.fillRect(0, 0, chart.width, chart.height)
      ctx.restore()
    }
  }

  const options = {
    responsive: true,
    scales: {
      x: {
        border: { dash: [4, 4] },
        grid: {
          display: false,
          color: hex2rgba('#FFFFFF', 0.4)
        },
        ticks: {
          color: hex2rgba('#FFFFFF', 0.8),
        }
      },
      y: {
        border: { dash: [4, 4] },
        grid: {
          color: hex2rgba('#FFFFFF', 0.4),
        },
        ticks: {
          color: hex2rgba('#FFFFFF', 0.8),
        }
      }
    },
    plugins: {
      legend: {
        position: 'top' as const,
        display: false
      },
      title: {
        display: false,
        text: title
      },
      customCanvasBackgroundColor: {
        color: 'backgroundColor'
      }
    }
  }

  const dataInfo = {
    labels: data.map((value) => value[keyLabels]),
    datasets: [
      {
        label: title,
        data: data.map((value) => Number(value[keyData])),
        backgroundColor: (context: any) => {
          const value = context.dataset.data[context.dataIndex]

          return value >= 0 ? 'rgba(75, 192, 192, 0.2)' : 'rgba(255, 99, 132, 0.2)'
        },
        borderColor: (context: any) => {
          const value = context.dataset.data[context.dataIndex]

          return value >= 0 ? 'rgba(75, 192, 192, 1)' : 'rgba(255, 99, 132, 1)'
        },
        borderWidth: 1
      }
    ]
  }

  return <Bar style={{ padding: 8, border: 4 }} options={options} data={dataInfo} plugins={[plugin]} />
}

export default BarChartAxes
