import { Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import i18n from 'i18next'
import Icon from 'src/@core/components/icon'
import CustomChip from 'src/@core/components/mui/chip'
import { updateUserDetail } from 'src/@http/user'
import { FontWeight } from 'src/layouts/TypographyTheme'
import adminStore from 'src/zustand/admin'
import profileStore, { StrategyProfile } from 'src/zustand/profile'

import ChangeStatus from '../actions-form/ChangeStatus'
import { ChangeStrategy } from '../actions-form/ChangeStrategy'
import { RowOptions } from '../actions-form/RowOptions'
import { StopStrategy } from '../actions-form/StopStrategy'

const userStatusObj: UserStatusType = {
  active: 'success',
  pending: 'warning',
  inactive: 'secondary'
}

interface CellType {
  row: API.User.AllUserResponse
}

interface PropsCurrentStrategy {
  currentStrategyId: number | null
}
const CurrentStartegy = ({ currentStrategyId }: PropsCurrentStrategy) => {
  const strategiesProfile: StrategyProfile[] = profileStore.getState().strategiesProfile

  const strategy: StrategyProfile | undefined = strategiesProfile.find(value => value.strategyId === currentStrategyId)

  return (
    <CustomChip
      skin='light'
      size='small'
      label={strategy !== undefined ? strategy.strategyName : 'inactive'}
      style={{
        //backgroundColor: strategy !== undefined ? '#03A66D' : 'secondary',
        color: strategy !== undefined ? 'white' : '#9295A6'
      }}
      sx={{ textTransform: 'capitalize' }}
    />
  )
}

const columns: GridColDef[] = [
  {
    width: 180,
    field: 'createdDate',
    headerAlign: 'center',
    align: 'center',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('REGISTRATION_DATE')}</Typography>,
    renderCell: ({ row }: CellType) => {
      const { createdDate } = row

      return (
        <Typography sx={{ px: 4 }} color={'white'} fontWeight={FontWeight.Normal} fontSize={14}>
          {createdDate ? row.createdDate.slice(0, 10) : '-'}
        </Typography>
      )
    }
  },
  {
    width: 250,
    field: 'email',
    headerAlign: 'left',
    align: 'left',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('EMAIL')}</Typography>,
    renderCell: ({ row }: CellType) => {
      return (
        <Typography noWrap variant='body2' color='white'>
          {row.userId} - {row.email}
        </Typography>
      )
    }
  },
  {
    width: 100,
    field: 'status',
    headerAlign: 'center',
    align: 'center',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('STATUS')}</Typography>,
    renderCell: ({ row }: CellType) => {
      const change = async (status: boolean) => {
        await updateUserDetail(row.userId, { intStatusId: status ? 1 : 0 })
        adminStore.getState().getAllUser()
      }

      return <ChangeStatus status={row.status} change={change}></ChangeStatus>
    }
  },
  {
    width: 110,
    field: 'currentStrategyIsActive',
    headerAlign: 'center',
    align: 'center',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('STRATEGY')}</Typography>,
    renderCell: ({ row }: CellType) => {
      return <StopStrategy row={row}></StopStrategy>
    }
  },
  {
    width: 150,
    field: 'currentStrategyId',
    headerAlign: 'center',
    align: 'center',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('T_STRATEGY')}</Typography>,
    renderCell: ({ row }: CellType) => {
      const { currentStrategyId } = row
      
  return (
        <>
          <CurrentStartegy currentStrategyId={currentStrategyId} />

          <ChangeStrategy row={row}></ChangeStrategy>
        </>
      )
    }
  },
  {
    width: 220,
    field: 'activeSubscription',
    headerAlign: 'center',
    align: 'center',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('SUBSCRIPTION_STATUS')}</Typography>,
    renderCell: ({ row }: CellType) => {
      const { activeSubscription } = row
      let active = false
      if (activeSubscription) active = activeSubscription

      return (
        <CustomChip
          skin='light'
          size='small'
          label={active ? i18n.t('ACTIVE') : i18n.t('INACTIVE')}
          color={userStatusObj[active ? 'active' : 'inactive'] || 'inactive'}
          sx={{ textTransform: 'capitalize' }}
        />
      )
    }
  },
  {
    width: 250,
    field: 'endDateSubscription',
    headerAlign: 'center',
    align: 'center',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('SUBSCRIPTION_EXPIRATION')}</Typography>,
    renderCell: ({ row }: CellType) => {
      const { endDateSubscription } = row

      return (
        <Typography sx={{ px: 4 }} color={'white'} fontWeight={FontWeight.Normal} fontSize={14}>
          {endDateSubscription !== null ? endDateSubscription.slice(0, 10) : '-'}
        </Typography>
      )
    }
  },
  {
    width: 250,
    field: 'paymentMethod',
    headerAlign: 'center',
    align: 'center',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('PAYMENT_METHOD')}</Typography>,
    renderCell: ({ row }: CellType) => {
      const { paymentMethod } = row

      return (
        <Typography sx={{ px: 4 }} color={'white'} fontWeight={FontWeight.Normal} fontSize={14}>
          {paymentMethod !== '' ? paymentMethod : '-'}
        </Typography>
      )
    }
  },
  {
    width: 100,
    field: 'isAdmin',
    headerAlign: 'center',
    align: 'center',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('ADMIN')}</Typography>,
    renderCell: ({ row }: CellType) => {
      return <Icon icon='mdi:star' fontSize={20} color={row.isAdmin ? '#FFEA00' : 'default'} />
    }
  },
  {
    width: 100,
    minWidth: 90,
    sortable: false,
    field: 'actions',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('ACTIONS')}</Typography>,
    renderCell: ({ row }: CellType) => <RowOptions row={row} />
  }
]

export default columns
