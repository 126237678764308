import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, CircularProgress, Dialog, Divider, IconButton, Toolbar, Typography } from '@mui/material'
import Icon from 'src/@core/components/icon'
import { ReferredUser, ReferredUserTree } from 'src/@http/user'
import CustomBtn from 'src/layouts/components/shared-component/CustomBtn'

import RenderRefferedGraphics from '../dashboard/ReferredGraphic/RenderRefferedGraphics'
import { TreeMode } from '../dashboard/ReferredGraphic/types'

import ReferredUsersTable from './ReferredUsersTable'

interface Props {
  visualMode: 'tree' | 'table'
  loading: boolean
  referredUsers: ReferredUser[]
  filledReferredUsers: ReferredUser[]
  filledReferredUsersTree: ReferredUserTree[]
  treeMode?: TreeMode
}

const ReferredUsersData = ({
  referredUsers,
  loading,
  filledReferredUsers,
  filledReferredUsersTree,
  visualMode,
  treeMode
}: Props) => {
  const { t } = useTranslation()
  const [fullscreen, setFullscreen] = useState<boolean>(false)

  const changeFullscreen = () => {
    //toggleFullScreen()
    setFullscreen(true)
  }
  

  return (
    <>
      {visualMode === 'table' && (
        <ReferredUsersTable
          allData={referredUsers}
          filteredData={filledReferredUsers}
          level='1'
          parentId={referredUsers.length !== 0 ? referredUsers[0].intUserId : 0}
        ></ReferredUsersTable>
      )}
      {visualMode === 'tree' && filledReferredUsersTree.length !== 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'end', mb:2 }}>
          <CustomBtn
            variant='contained'
            onClick={() => {
              changeFullscreen()
            }}
          >
            {t('FULLSCREEN')}
          </CustomBtn>
          
        </Box>
      )}
      {visualMode === 'tree' && treeMode !== undefined && filledReferredUsersTree.length !== 0 && (
        <RenderRefferedGraphics data={filledReferredUsersTree[0]} rawData={filledReferredUsers} treeMode={treeMode} />
      )}
      {filledReferredUsers.length === 0 && !loading && <Typography sx={{ mt: 4 }}>{t('DATA_NOT_FOUND')}</Typography>}
      {loading && (
        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      <Dialog fullScreen open={fullscreen}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
            {t('FULLSCREEN')}
          </Typography>
          <IconButton
            edge='start'
            color='inherit'
            onClick={() => {
              setFullscreen(false)
            }}
            aria-label='close'
          >
            <Icon icon='mdi:close' fontSize={30} />
          </IconButton>
        </Toolbar>
        <Divider></Divider>
        {visualMode === 'tree' && treeMode !== undefined && filledReferredUsersTree.length !== 0 && (
          <RenderRefferedGraphics
            data={filledReferredUsersTree[0]}
            rawData={filledReferredUsers}
            treeMode={treeMode}
            container='g6-container-fullscreen'
            width={1}
            height={'100vh'}
          />
        )}
      </Dialog>
    </>
  )
}

export default ReferredUsersData
