import { useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import CustomChip from 'src/@core/components/mui/chip'
import { postClosePositions } from 'src/@http/position'
import { logger } from 'src/utils/Logger'

import { DialogGeneric } from '../../../generic/DialogGeneric'

interface Props {
  user: API.User.AllUserResponse
}

const PositionRealTimeCloseDialog = ({ user }: Props) => {
  const { t } = useTranslation()
  const [show, setShow] = useState<boolean>(false)
  

  const confirm = async () => {
    try {
        await postClosePositions(user.userId)
        setShow(false)
        toast.success(t('OPERATIONS_CLOSED_SUCCESSFULLY'))
    } catch (error: any) {
        logger.log('[postClosePositions] Position Close error: ', error)
        toast.error(error.message)
    }
  }
  
  return (
    <>
      <CustomChip
        skin='light'
        size='small'
        label={t('CLOSE')}
        color={'error'}
        sx={{ textTransform: 'capitalize', ml: 2, maxWidth: 100}}
        onClick={() => {
          setShow(true)
        }}
      />
      <DialogGeneric title='CLOSE_ALL_OPERATIONS' show={show} confirmText='CONFIRM' confirmFunc={confirm}>
    {t('USER')+`: ${user.userId}-${user.email}`}
      </DialogGeneric>
    </>
  )
}

export default PositionRealTimeCloseDialog