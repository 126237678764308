import { useTranslation } from 'react-i18next'
import { Avatar, Box, Card, CardContent, Divider, Typography } from '@mui/material'
import Icon from 'src/@core/components/icon'
import CustomBtn, { ActiveBtn } from 'src/layouts/components/shared-component/CustomBtn'

import ChangePasswordForm from './ChangePasswordForm'

interface Authentication {
  title: string
  subtitle: string
  icon: string
  enabled: boolean
  color: string
}

const SecurityCard = () => {
  const { t } = useTranslation()
  const authentications: Authentication[] = [
    {
      title: t('EMAIL_AUTHENTICATION'),
      subtitle: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      icon: 'mdi:email-outline',
      enabled: true,
      color: '#56B2FF'
    },
    {
      title: t('SMS_AUTHENTICATION'),
      subtitle: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      icon: 'mdi:phone-outline',
      enabled: false,
      color: '#6EE7B7'
    },
    {
      title: t('GOOGLE_AUTHENTICATION'),
      subtitle: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      icon: 'mdi:google',
      enabled: false,
      color: '#F87171'
    }
  ]

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
          <Avatar sx={{ bgcolor: 'primary.main', width: 40, height: 40 }} variant='rounded'>
            <Icon icon='mdi:lock' style={{ fontSize: 24 }} color='white' />
          </Avatar>
          <Typography sx={{ m: 3 }} variant='h5' component='div'>
            {t('SECURITY')}
          </Typography>
        </Box>
        {false &&
          authentications.map((auth: Authentication, key: number) => (
            <Box
              key={key}
              sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: { xs: 'center', md: 'space-between' }, py: 3 }}
            >
              <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: { xs: 'center', md: 'space-between' } }}>
                <Avatar sx={{ bgcolor: auth.color, width: 40, height: 40 }} variant='rounded'>
                  <Icon icon={auth.icon} color='#9295A6' />
                </Avatar>
                <Box sx={{ px: 4 }}>
                  <Typography
                    style={{ fontSize: 16 }}
                    sx={{
                      textAlign: {
                        xs: 'center',
                        sm: 'left'
                      },
                      py: {
                        xs: 2,
                        sm: 0
                      }
                    }}
                  >
                    {auth.title}
                  </Typography>
                  <Typography
                    style={{ fontSize: 12 }}
                    sx={{
                      textAlign: {
                        xs: 'center',
                        sm: 'left'
                      },
                      pb: {
                        xs: 2,
                        sm: 0
                      }
                    }}
                    color='#9295A6'
                  >
                    {auth.subtitle}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  mt: {
                    xs: 2,
                    sm: 0
                  }
                }}
              >
                {auth.enabled && <ActiveBtn style={{ minWidth: 130 }}>{t('DISABLE')}</ActiveBtn>}
                {!auth.enabled && <CustomBtn style={{ minWidth: 130 }}>{t('ENABLE')}</CustomBtn>}
              </Box>
            </Box>
          ))}
        {false && <Divider sx={{ py: 4 }} />}
      </CardContent>
      <ChangePasswordForm />
    </Card>
  )
}

export default SecurityCard
