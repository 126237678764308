import { getAsync } from 'src/@http'

const root = '/subscriptionTransaction'

export interface SubscriptionTransaction {
  intSubscriptionTransactionId: number
  intUserId: number
  intSubscriptionId: number
  intTxId: string
  intPlatformId: number
  intStatusId: number
  dtmCreatedDate: string
  vchPaymentType: string
  vchOriginWallet: string | null
  vchDestinationWallet: string | null
  dcmAmountTransaction: string | null
  user: User
}

interface User {
  intUserId: number
  vchEmail: string
}

export const getSubscriptionTransaction = (): Promise<SubscriptionTransaction[]> => {
  return getAsync(root)
}
