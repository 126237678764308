import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormControl, InputLabel, MenuItem, Typography } from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import profileStore, { ProfileStore } from 'src/zustand/profile'

interface Props {
  setPlatformId: (platformId: string) => void
  allOption: boolean
}

interface PlatformFor {
  platformId: number
  platformName: string
  intStatusId: number
}

const PlaformSelector = ({setPlatformId, allOption}: Props) => {
  const { t } = useTranslation()
  const [platformSelected, setPlatformSelected] = useState<string>(allOption?'0':'1')
  const platforms = profileStore((state: ProfileStore) => state.platforms)
  const allPlatforms: PlatformFor = {
    platformName: t('ALL'),
    platformId: 0,
    intStatusId: 1
  }

  const listPlatforms: PlatformFor[] = useMemo(()=> {
    if(allOption)
      return [allPlatforms, ...platforms]

    return platforms
  }, [platforms, allOption])
  

  const handleChange = (event: SelectChangeEvent) => {
    const id = event.target.value as string
    setPlatformId(id);
    setPlatformSelected(id)
  };


  return (
    <FormControl fullWidth>
      <InputLabel id='demo-simple-select-label'>{t('PLATFORM')}</InputLabel>
      <Select
        labelId='platform'
        id='platform'
        required
        value={platformSelected}
        label={t('PLATFORM')}
        onChange={handleChange}
      >
        {listPlatforms.filter(value => value.intStatusId === 1).map((platform: PlatformFor, key: number) => (
          <MenuItem key={key} value={platform.platformId}>
            <Typography component={'div'}>{platform.platformName}</Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

PlaformSelector.defaultProps = {
  allOption: false
}

export default PlaformSelector
