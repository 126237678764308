import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Avatar,
  Box,
  FormControl,
  Switch,
  /*Switch,*/ TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import Icon from 'src/@core/components/icon'
import { validateCode } from 'src/@http/2fa'
// import { postPromocode, putPromocode } from 'src/@http/promocode'
import { GlobalSetting, postGlobalSetting, putGlobalSetting } from 'src/@http/global-settings'
// import { postSubscriptions, putSubscriptions } from 'src/@http/subscription'
import { CustomLoadingBtn } from 'src/layouts/components/shared-component/CustomBtn'
import TwoFactorAuthField from 'src/layouts/components/TwoFactorAuthField'
import { ModeForm } from 'src/types'
import profileStore, { ProfileStore } from 'src/zustand/profile'
import * as yup from 'yup'

interface GlobalSettingForm {
  key: string
  value: string
  isPrivate: boolean
  code: string
}

interface Props {
  modeForm: ModeForm
  valuesDefaultForm?: GlobalSetting
  submitSuccess: () => void
}

const GlobalSettingForm = ({ modeForm, valuesDefaultForm, submitSuccess }: Props) => {
  // --- FORM

  const defaultValuesForm: GlobalSettingForm = {
    key: '',
    value: '',
    isPrivate: false,
    code: ''
  }

  if (valuesDefaultForm) {
    defaultValuesForm.key = valuesDefaultForm.key
    defaultValuesForm.value = valuesDefaultForm.value
    defaultValuesForm.isPrivate = valuesDefaultForm.isPrivate
  }

  const validations = {
    key: yup.string(),
    value: yup.string(),
    isPrivate: yup.boolean(),
    code: defaultValuesForm.isPrivate ? yup.string().required() : yup.string()
  }

  if (modeForm === ModeForm.create) {
    validations.key = validations.key.required().min(1)
    validations.value = validations.value.required().min(1)
  }

  const schemaValidations = yup.object().shape(validations)

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm<GlobalSettingForm>({ resolver: yupResolver(schemaValidations), defaultValues: defaultValuesForm })

  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const userDetail = profileStore((state: ProfileStore) => state.userDetail)
  const onSubmit = async (form: GlobalSettingForm) => {

    if(defaultValuesForm.isPrivate){
      setSubmitLoading(true)
      try {
        await validateCode(userDetail.email, form.code)
        setSubmitLoading(false)
      } catch (error: any) {
        toast.error(error.message, { duration: 4000 })
        setSubmitLoading(false)

        return 0
      }
    }

    if (modeForm == ModeForm.create) {
      setSubmitLoading(true)
      try {
        await postGlobalSetting({
          vchKey: form.key,
          vchValue: form.value,
          blnIsPrivate: form.isPrivate
        })
        toast.success(t('GLOBAL_SETTING_CREATED_SUCCESS'), { duration: 4000 })
        submitSuccess()
      } catch (error: any) {
        toast.error(error.message, { duration: 4000 })
      } finally {
        setSubmitLoading(false)
      }
    }

    if (modeForm == ModeForm.edit) {
      setSubmitLoading(true)
      try {
        await putGlobalSetting({
          vchKey: form.key,
          vchValue: form.value,
          blnIsPrivate: form.isPrivate
        })
        toast.success(t('GLOBAL_SETTING_UPDATED_SUCCESS'), { duration: 4000 })
        // getAllSubscriptions()
        
        submitSuccess()
      } catch (error: any) {
        toast.error(error.message, { duration: 4000 })
      } finally {
        setSubmitLoading(false)
      }
    }
  }

  //--------------------------
  const { t } = useTranslation()
  const theme = useTheme()
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const [disabledProceed, setDisabledProceed] = useState<boolean>(defaultValuesForm.isPrivate)

  return (
    <>
      <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='key'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                disabled={ModeForm.create !== modeForm}
                variant='outlined'
                {...field}
                label={t('KEY')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>

        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='value'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                {...field}
                label={t('VALUE')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>
        {defaultValuesForm.isPrivate && <TwoFactorAuthField successFunc={() => {setDisabledProceed(false)}} control={control} />}
        
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center' }}>
          <Box>
            <Avatar sx={{ bgcolor: 'primary.main', width: 50, height: 50 }} variant='rounded'>
            <Icon icon='mdi:secure' fontSize={20} />
            </Avatar>
          </Box>

          <Box>
            <Box>
              <Typography align='center' fontSize={22}>
                {t('PRIVATE')}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Controller
              name='isPrivate'
              control={control}
              render={({ field: { ...field } }) => (
                <Switch defaultChecked={defaultValuesForm.isPrivate} {...field} disabled={ModeForm.show === modeForm} />
              )}
            />
          </Box>
        </Box>
        {ModeForm.show !== modeForm && (
          <CustomLoadingBtn
            disabled={disabledProceed}
            size='large'
            type='submit'
            variant='contained'
            style={{ minHeight: 60, width: '100%' }}
            sx={{ mt: { xs: 4, sm: 4 } }}
            loading={submitLoading}
          >
            {t('PROCEED')}
          </CustomLoadingBtn>
        )}
      </form>
    </>
  )
}

export default GlobalSettingForm
