import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Switch } from '@mui/material'

import { DialogGeneric } from '../../generic/DialogGeneric'

interface Props {
  status: number
  change: (status: boolean) => void
  sizeSwitch: "small" | "medium",
  keyTitleDialog: string
  keyConfirmMessage: string
}

const ChangeStatus = ({status, change, sizeSwitch, keyTitleDialog, keyConfirmMessage}: Props) => {
  const [checked, setChecked] = useState(status === 1)
  const [event, setEvent] = useState(false)
  const [show, setShow] = useState<boolean>(false)
  const { t } = useTranslation()
  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setShow(true)
    setEvent(event.target.checked)
  }

  const confirmChange = async () => {
    setChecked(event)
    try {
      await change(event)

      toast.success(t('STATUS_CHANGED'), { duration: 4000 })
    } catch (error: any) {
      toast.error(error.message, { duration: 4000 })
    }
    
  }

  useEffect(() => {
    const statusBool = status===1 ? true: false
    if( statusBool !== checked)
      setChecked(statusBool)
  }, [status])



  return (
    <>
      <Switch size={sizeSwitch}  checked={checked} onChange={handleChange} />
      <DialogGeneric
        show={show}
        title={t(keyTitleDialog)}
        confirmText={t('CONFIRM') || ''}
        confirmFunc={() => confirmChange()}
        closeFunc={() => {
          setShow(false)
        }}
      >
        {t(keyConfirmMessage)}
      </DialogGeneric>
    </>
  )
}

ChangeStatus.defaultProps = {
  sizeSwitch: 'medium',
  keyTitleDialog: 'CHANGE_STATUS',
  keyConfirmMessage: 'CONFIRM_CHANGE_STATUS'
}

export default ChangeStatus
