import { postAsync } from 'src/@http'

/* Endpoints definition */
const Login = '/auth/signIn'
// const ForgotPassword = '/forgot-password'
const ValidateCode = '/auth/verifyCode'
// const SendCode = '/auth/sendCode'
const SignUp = '/auth/signUp'
const Users = '/users'
const ForgotPassword = '/auth/forgotPassword'
const sendCodeAndChangePassword = '/auth/changePassword'
interface Auth {
  access_token: string
  token_type: string
}

interface Role {
  sinRoleId: number
  vchName: string
  intStatusId: number
  intCreatedUserId: number
  dtmCreatedDate: string
  intUpdatedUserId: any
  dtmUpdatedDate: any
}

interface SignUpRequest {
  vchName: string
  vchLastName: string
  vchEmail: string
  vchPassword: string
  vchPromoCode: string
  code: string
  intReferredUserId: number
}

interface UserRegisterRequest {
  vchName: string
  vchLastName: string
  vchEmail: string
  vchPassword: string
  vchPromoCode: string
}

interface SignUpResponse {
  userId: number
  email: string
  firstName: string
  lastName: string
  status: number
}

export interface LoginRequest {
  email: string
  password: string
  captchaToken: string
}

export interface ChangePassCode {
  code: string
  password: string
}

// interface LoginResponse {
//   token: string
// }

export const signUp = (body: SignUpRequest): Promise<SignUpResponse> => {
  return postAsync(SignUp, body)
}

export const registerUser = (body: UserRegisterRequest): Promise<SignUpResponse> => {
  return postAsync(Users, body)
}

export const validateCode = (code: string): Promise<Auth> => {
  return postAsync(ValidateCode, {
    code: code
  })
}

export const login = (body: LoginRequest): Promise<string> => {
  return postAsync(Login, body)
}

export const forgotPassword = (email: string): Promise<boolean> => {
  return postAsync(ForgotPassword, { email })
}

export const sendCodeAndPassword = (body: ChangePassCode): Promise<void> => {
  return postAsync(sendCodeAndChangePassword, body)
}
