import { Chip } from '@mui/material'
import Box from '@mui/material/Box'
import { Settings } from 'src/@core/context/settingsContext'
import ModeToggler from 'src/@core/layouts/components/shared-components/ModeToggler'
import UserDropdown from 'src/@core/layouts/components/shared-components/UserDropdown'

import LanguageDropdown from '../../../@core/layouts/components/shared-components/LanguageDropdown'
import NotificationDropdown, {
  NotificationsType
} from '../../../@core/layouts/components/shared-components/NotificationDropdown'

const notifications: NotificationsType[] = [
  {
    meta: 'Hoy',
    title: 'Nuevo mensaje 📥',
    subtitle: 'Tienes un nuevo mensaje asignado en Capital Markets',
    avatarIcon: 'CM',
    avatarColor: 'error'
  },
  {
    meta: 'Ayer',
    title: 'Firma pendiente #58458 ✒',
    subtitle: 'Tienes una firma pendiente de realizar en el mensaje #58458',
    avatarIcon: '✒',
    avatarColor: 'primary'
  },
  {
    meta: '14 Abr',
    title: (
      <>
        Mensaje hace <Chip label='35 días' color='error' size='small' />
      </>
    ),
    subtitle: (
      <>
        El mensaje de referencia Transaccion 2 hace <Chip label='35 días' color='error' size='small' />
        que está en la etapa de Análisis.
      </>
    ),
    avatarIcon: '35',
    avatarColor: 'error'
  }
]

interface Props {
  settings: Settings
  saveSettings: (values: Settings) => void
}
const AppBarContent = (props: Props) => {
  // ** Props
  const { settings, saveSettings } = props

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <ModeToggler settings={settings} saveSettings={saveSettings} />
      <LanguageDropdown settings={settings} saveSettings={saveSettings} />
      <NotificationDropdown settings={settings} notifications={notifications} />
      <UserDropdown settings={settings} />
    </Box>
  )
}

export default AppBarContent
