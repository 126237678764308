import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Card, CardContent, Typography } from '@mui/material'
import { updateUserDetail } from 'src/@http/user'
import adminStore from 'src/zustand/admin'
import profileStore from 'src/zustand/profile'

// import adminStore from 'src/zustand/admin'
import PositionRealTimeCloseDialog from './PositionRealTimeCloseDialog'
import PositionRealTimeDialog from './PositionRealTimeDialog'

interface Props {
  item: API.User.AllUserResponse
}

const userStatusObj: UserStatusType = {
  active: 'success',
  pending: 'warning',
  inactive: 'secondary'
}

const PositionRealTimeItemCard = ({ item }: Props) => {
  // const { bgColor } = adminStore()
  const { t } = useTranslation()
  const [show, setShow] = useState<boolean>(false)
  const user = item

  const { currentStrategyIsActive, currentStrategyId, activeSubscription, endDateSubscription } = user
  let activeCurrentStrategy = false
  if (currentStrategyIsActive) activeCurrentStrategy = currentStrategyIsActive
  let activeSubscriptioLocal = false
  if (activeSubscription) activeSubscriptioLocal = activeSubscription

  const strategies: any = {
    1: {
      color: '#1AF772',
      name: t('LOW')
    },
    2: {
      color: '#FF8E0A',
      name: t('MEDIUM')
    },
    3: {
      color: '#F10707',
      name: t('HIGH')
    }
  }

  const { getAllUser } = adminStore()
  const change = async (status: boolean) => {
    await updateUserDetail(user.userId, { intStatusId: status ? 1 : 0 })
    getAllUser()
  }
  const platformsDic = profileStore.getState().platformsDic

  return (
    <Card variant='outlined' sx={{ backgroundColor: '' }}>
      <CardContent sx={{ px: 2 }}>

        <Box sx={{ display: 'flex', fontSize: 12, mt: 2 }}>
          <Box sx={{ mr: 3 }}> {t('REGISTRATION_DATE')}:</Box>{' '}
          <span> {user.createdDate ? user.createdDate.slice(0, 10) : '-'}</span>
        </Box>

        <Box sx={{ display: 'flex', fontSize: 12, mt: 2 }}>
          <Box sx={{ mr: 3, alignSelf: 'center' }}> {t('EMAIL')}:</Box>
          <Typography sx={{ wordBreak: 'break-all', fontSize: 12 }}>
            {user.userId}-{user.email}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', fontSize: 12, mt: 2 }}>
          <Box sx={{ mr: 3 }}> {t('PLATFORM')}:</Box>{' '}
          <span>{user.platformId !== null ? platformsDic[user.platformId].platformName : '-'}</span>
        </Box>

        <Box sx={{ display: 'flex', fontSize: 12, mt: 2, mb: 2 }}>
          <Box sx={{ mr: 3 }}> {t('ACTIONS')}:</Box>
          <PositionRealTimeDialog user={user} />
        <PositionRealTimeCloseDialog user={user} />
        </Box>

        
      </CardContent>
    </Card>
  )
}

export default PositionRealTimeItemCard
