import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Avatar, Box, Button, Card, CardContent, Typography, useMediaQuery } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { DataGrid } from '@mui/x-data-grid'
import Icon from 'src/@core/components/icon'
import { registerUser } from 'src/@http/auth'
import { apiCredentials, postPlatform } from 'src/@http/platform/'
import PlatformSelector from 'src/layouts/components/shared-component/PlatformSelector'
import type { CustomerForm } from 'src/pages/components/admin/customer-manager/CustomerDetailsForm'
import { ModeForm } from 'src/types'
import adminStore from 'src/zustand/admin'

import { DialogGeneric } from '../../generic/DialogGeneric'
import CustomToolbarAdminGrid from '../CustomToolbarAdminGrid'

import columns from './ColumnsCustomerGrid'
import CustomerDetailsForm from './CustomerDetailsForm'
import UserManagerCards from './UserManagerCards'

interface Customer {
  id: string
  email: string
  registerDate: Date
  ipOrigen: string
  platformId: number
  apiKey: string
  secretKey: string
}

const CustomerManagementCard = () => {
  const { t } = useTranslation()
  const [show, setShow] = useState<boolean>(false)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const { getAllUser, users } = adminStore()
  const [loading, setLoading] = useState<boolean>(false)
  const showMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const [selectedPlatformId, setSelectedPlatformId] = useState<number>(0)

  useEffect(() => {
    const aux = async () => {
      setLoading(true)
      getAllUser()
      setLoading(false)
    }
    aux()
  }, [])

  const userList = useMemo(() => {
    if (selectedPlatformId === 0) return users

    return users.filter(user => (user.platformId ? user.platformId === selectedPlatformId : false))
  }, [users, selectedPlatformId])

  const onSubmit = async (form: CustomerForm) => {
    const bodyCredentials: apiCredentials = {
      vchApiKey: form.apiKey,
      vchApiSecret: form.secretKey
    }
    setSubmitLoading(true)
    try {
      const response = await registerUser({
        vchEmail: form.email,
        vchPassword: form.password,
        vchLastName: '',
        vchName: '',
        vchPromoCode: form.promocode
      })

      await postPlatform(response.userId, Number(form.platformId), bodyCredentials)
      setShow(false)

      toast.success(t('USER_CREATED_SUCCESS'), { duration: 4000 })
    } catch (error: any) {
      toast.error(error.message, { duration: 4000 })
    }

    setSubmitLoading(false)
    getAllUser()
  }

  return (
    <Card>
      <CardContent sx={{}}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', flexWrap:'wrap' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar sx={{ width: 40, height: 40, bgcolor: 'primary.main' }} color='error' variant='rounded'>
              <Icon icon='mdi:account-group' style={{ fontSize: 24 }} color='white' />
            </Avatar>
            <Typography sx={{ ml: 2 }} variant='h5'>
              {' ' + t('CUSTOMER')}
            </Typography>
          </Box>
          <Box sx={{ width: 300, mt:{xs:4, md:0} }}>
            <PlatformSelector
              allOption
              setPlatformId={(id: string) => {
                setSelectedPlatformId(Number(id))
              }}
            />
          </Box>
        </Box>
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2 }}>
            <Button
              onClick={() => {
                setShow(true)
              }}
              startIcon={<Icon icon='mdi:plus-box-outline' color='#9295A6' />}
            >
              <Typography color='#9295A6' sx={{ textTransform: 'capitalize' }}>
                {t('ADD')}
              </Typography>
            </Button>
          </Box>
        </Box>
        {!showMobile && (
          <div onClick={event => event.stopPropagation()}>
            <DataGrid
              sx={{
                mt: 4,
                width: '100%'
              }} //overflowX: 'scroll'
              autoHeight
              rows={userList}
              getRowId={r => r.userId}
              columns={columns}
              pageSize={10}
              disableSelectionOnClick
              rowsPerPageOptions={[5]}
              loading={loading}
              components={{ Toolbar: CustomToolbarAdminGrid }}
            />
          </div>
        )}
        {showMobile && <UserManagerCards list={userList}></UserManagerCards>}
      </CardContent>
      <DialogGeneric
        show={show}
        hideActions={true}
        showIconClose={true}
        closeFunc={() => {
          setShow(false)
        }}
        title={t('CUSTOMER_DETAILS')}
        leftAline={true}
      >
        <Box sx={{ pt: 4 }}>
          <CustomerDetailsForm
            modeForm={ModeForm.create}
            submitFinishFunction={form => {
              onSubmit(form)
            }}
            submitLoading={submitLoading}
          />
        </Box>
      </DialogGeneric>
    </Card>
  )
}

export default CustomerManagementCard
