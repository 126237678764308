import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, CardContent, Grid, Typography } from '@mui/material'
import Icon from 'src/@core/components/icon'
import CustomAvatar from 'src/@core/components/mui/avatar'
import { Category } from 'src/zustand/news'

import { RowNewsCategoriesOptions } from './RowNewsCategoriesOptions'


interface Props {
  item: Category
}

const userStatusObj: UserStatusType = {
  active: 'success',
  pending: 'warning',
  inactive: 'secondary'
}

const CommissionRankItemCard = ({ item }: Props) => {
  // const { bgColor } = adminStore()
  const { t } = useTranslation()
  const [show, setShow] = useState<boolean>(false)


  const { name, statusId } = item



  return (
    <Card variant='outlined' sx={{ backgroundColor: '' }}>
      <CardContent sx={{ px: 2 }}>
        <Grid container sx={{}}>
          <Grid item xs={2}>
            <CustomAvatar color={statusId === 1 ? 'primary' : 'secondary'} variant='rounded'>
              <Icon icon={statusId === 1 ? 'mdi:format-list-bulleted-type' : 'akar-icons:stop'} color='white' />
            </CustomAvatar>
          </Grid>
          <Grid item sx={{ p: 1, pl: 1, fontSize: 18, display:'flex', alignItems:'center' }} xs={8}>
          <Typography sx={{pl:2}}>{name}</Typography>
          </Grid>
          <Grid item xs={2} sx={{ justifyContent: 'end', display: 'flex' }}>
            <RowNewsCategoriesOptions row={item} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default CommissionRankItemCard