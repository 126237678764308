import { useTranslation } from 'react-i18next'
import { Box, Card, CardContent, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import Icon from 'src/@core/components/icon'
import CustomAvatar from 'src/@core/components/mui/avatar'
import { News } from 'src/zustand/news'

import { RowNewsOptions } from './RowNewsOptions'


interface Props {
  item: News
}

const NewsItemCard = ({ item }: Props) => {
  const { t } = useTranslation()


  const { title, statusId, startDate, endDate, createdDate } = item
  const size = 20

  return (
    <Card variant='outlined' sx={{ backgroundColor: '' }}>
      <CardContent sx={{ px: 2 }}>
        <Grid container sx={{}}>
          <Grid item xs={2}>
            <CustomAvatar color={statusId === 1 ? 'primary' : 'secondary'} variant='rounded'>
              <Icon icon={statusId === 1 ? 'mdi:newspaper-variant-outline' : 'akar-icons:stop'} color='white' />
            </CustomAvatar>
          </Grid>
          <Grid item sx={{ p: 1, pl: 1, fontSize: 18, display:'flex', alignItems:'center' }} xs={9}>
          <>
          {title.length > size && (
            <Grid container>
              <Grid item sx={{ display: 'flex', alignItems: 'center' }} xs={11}>
                <Typography sx={{}} color={'white'}>{title.slice(0, size) + '...'}</Typography>
              </Grid>
              <Grid item xs={1} sx={{ justifyContent: 'end', display: 'flex', alignItems: 'center' }}>
                <Tooltip disableFocusListener disableTouchListener title={title}>
                  <IconButton>
                    <Icon icon='mdi:information-outline' />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          )}
          {title.length < size && <Typography sx={{pl:2}} color={'white'}>{title}</Typography>}
        </>
          </Grid>
          <Grid item xs={1} sx={{ justifyContent: 'end', display: 'flex' }}>
            <RowNewsOptions row={item} />
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', fontSize: 12, mt: 2 }}>
          <Box sx={{ mr: 3 }}> {t('START_DATE')}:</Box>{' '}
          <span> {startDate ? startDate.slice(0, 10) : '-'}</span>
        </Box>
        <Box sx={{ display: 'flex', fontSize: 12, mt: 2 }}>
          <Box sx={{ mr: 3 }}> {t('END_DATE')}:</Box>{' '}
          <span> {endDate ? endDate.slice(0, 10) : '-'}</span>
        </Box>
        <Box sx={{ display: 'flex', fontSize: 12, mt: 2 }}>
          <Box sx={{ mr: 3 }}> {t('CREATED_DATE')}:</Box>{' '}
          <span> {createdDate ? createdDate.slice(0, 10) : '-'}</span>
        </Box>
      </CardContent>
    </Card>
  )
}

export default NewsItemCard