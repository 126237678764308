// import toast from 'react-hot-toast'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Carousel } from 'react-responsive-carousel'
import styled from '@emotion/styled'
// import { useNavigate } from 'react-router-dom'
import { Box, Button, Card, Grid, Link, Typography } from '@mui/material'
import { ButtonProps } from '@mui/material'
import { Theme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import {PromotionBanner } from 'src/@http/PromotionBanner'
import appStore, { AppStore } from 'src/zustand/app'

// import Icon from 'src/@core/components/icon'
// import { FontWeight } from 'src/layouts/TypographyTheme'
// import { logger } from 'src/utils/Logger'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader

const BybitBtn = styled(Button)<ButtonProps>(({ theme }) => ({
  color: 'white',
  backgroundColor: '#ff914d',
  fontWeight: 400,
  '&:hover': {
    backgroundColor: '#ff914d'
  },
  textTransform: 'none',
  '&:disabled': {
    backgroundColor: '#424245'
  }
}))

/*
const OkxBtn = styled(Button)<ButtonProps>(({ theme }) => ({
  color: 'black',
  backgroundColor: '#bdfd3a',
  fontWeight: 500,
  '&:hover': {
    backgroundColor: '#bdfd3a'
  },
  textTransform: 'none',
  '&:disabled': {
    backgroundColor: '#424245'
  }
}))
*/

const PromotionCarousel = () => {
  const { t } = useTranslation()

  const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const small = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm') && theme.breakpoints.down('lg'))

  const promotionBanners: PromotionBanner[] = appStore((state: AppStore) => state.promotionBanners)
  const setPromotionBanner = appStore((state: AppStore) => state.setPromotionBanner)

  const [intervalz, setIntervalz] = useState(2000)
  
  const onChange = (index: any, item: any) => {
    setIntervalz(item.props["data-interval"]);
  };

  useEffect(() => {
    setPromotionBanner()
  }, [])

  return (
    <Card sx={{ minWidth: 275 }}>
      <Grid container sx={{ alignItems: 'center', justifyContent: 'center' }}>
        <Carousel 
        autoPlay={true}
         infiniteLoop={true}
          showThumbs={false} 
          showArrows={false} 
          showStatus={false} 
          onChange={onChange}
          interval={intervalz}
          >
          {promotionBanners.map((promotionBanner: PromotionBanner) => {
            return <Link href={promotionBanner.vchPromotionUrl} 
            target='_blank' 
            key={promotionBanner.intPromotionBannerId}
            data-interval={promotionBanner.intDurationMs}
            >
            <Box sx={{ maxHeight: 300, position: 'relative' }}>
              <>
                <Typography
                  sx={{
                    position: 'absolute',
                    top: small ? '55%' : '65%',
                    left: '10%',
                    maxWidth: hidden ? 220 : small ? 380 : 650,
                    textAlign: 'left',
                    fontSize: hidden ? 6 : small ? 8 : 12
                  }}
                >
                  {promotionBanner.vchPromotionText.split('/newLine').map((i, key) => {
                    return <div key={key}>{i}</div>
                  })}
                </Typography>
                <Link href={promotionBanner.vchPromotionUrl} target='_blank'>
                  <BybitBtn
                    sx={{ position: 'absolute', top: small ? '75%' : '80%', left: '10%' }}
                    variant='contained'
                    size={small ? 'small' : 'medium'}
                  >
                    {t('REGISTER_NOW')}
                  </BybitBtn>
                </Link>
              </>

              <img src={hidden ? promotionBanner.vchMobileImageUrl : promotionBanner.vchDesktopImageUrl} />
            </Box>
          </Link>
          })}
        </Carousel>
      </Grid>
    </Card>
  )
}

export { PromotionCarousel }
