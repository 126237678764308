import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import Icon from 'src/@core/components/icon'
import CustomAvatar from 'src/@core/components/mui/avatar'
import { SubscriptionTransaction } from 'src/@http/subscriptionTransaction'
import profileStore from 'src/zustand/profile'

import CopyText from './column/CopyText'
import SubscriptionTransactionStatus from './column/SubscriptionTransactionStatus'

interface Props {
  item: SubscriptionTransaction
}

const userStatusObj: UserStatusType = {
  active: 'success',
  pending: 'warning',
  inactive: 'secondary'
}

const SubscriptionTransactionItemCard = ({ item }: Props) => {
  // const { bgColor } = adminStore()
  const { t } = useTranslation()
  const [show, setShow] = useState<boolean>(false)

  const {
    dtmCreatedDate,
    vchOriginWallet,
    vchDestinationWallet,
    vchPaymentType,
    intStatusId,
    user,
    intPlatformId,
    dcmAmountTransaction
  } = item

  const strategies: any = {
    1: {
      color: '#1AF772',
      name: t('LOW')
    },
    2: {
      color: '#FF8E0A',
      name: t('MEDIUM')
    },
    3: {
      color: '#F10707',
      name: t('HIGH')
    }
  }

  const platform = profileStore.getState().platforms.find(value => value.platformId === intPlatformId)

  return (
    <Card variant='outlined' sx={{ backgroundColor: '' }}>
      <CardContent sx={{ px: 2 }}>
        <Grid container sx={{}}>
          <Grid item xs={2}>
            <CustomAvatar color={intStatusId === 1 ? 'success' : 'warning'} variant='rounded'>
              <Icon icon={intStatusId === 1 ? 'mdi:check-circle-outline' : 'mdi:receipt-text-clock'} color='white' />
            </CustomAvatar>
          </Grid>
          <Grid item sx={{ p: 1, pl: 1, fontSize: 18, display: 'flex', alignItems: 'center' }} xs={8}>
            <Typography sx={{ pl: 2 }}>{vchPaymentType === '' ? '-' : vchPaymentType}</Typography>
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', fontSize: 12, mt: 2 }}>
          <Box sx={{ mr: 3 }}> {t('DATE')}:</Box> <span> {dtmCreatedDate ? dtmCreatedDate.slice(0, 10) : '-'}</span>
        </Box>
        <Box sx={{ display: 'flex', fontSize: 12, mt: 2 }}>
          <Box sx={{ mr: 3 }}> {t('STATUS')}:</Box>{' '}
          <SubscriptionTransactionStatus item={item}></SubscriptionTransactionStatus>
        </Box>
        <Box sx={{ display: 'flex', fontSize: 12, mt: 2 }}>
          <Box sx={{ mr: 3 }}> {t('USER')}:</Box>{' '}
          <Typography sx={{ wordBreak: 'break-all', fontSize: 12 }}>
            {user.intUserId}-{user.vchEmail}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', fontSize: 12, mt: 2 }}>
          <Box sx={{ mr: 3 }}> {t('AMOUNT_TRANSACTION')}:</Box>{' '}
          <span>{dcmAmountTransaction !== null ? `$${dcmAmountTransaction}` : '-'}</span>
        </Box>
        <Box sx={{ fontSize: 12, mt: 2 }}>
          <Box sx={{ mr: 3, mb: 1 }}>
            {' '}
            {t('ORIGIN_WALLET')}: {vchOriginWallet === null && '-'}
          </Box>
          {vchOriginWallet !== null && (
            <Box sx={{ mr: 2 }}>
              <CopyText text={vchOriginWallet}></CopyText>
            </Box>
          )}
        </Box>
        <Box sx={{ fontSize: 12, mt: 2 }}>
          <Box sx={{ mr: 3, mb: 1 }}>
            {' '}
            {t('DESTINATION_WALLET')}: {vchDestinationWallet === null && '-'}
          </Box>
          {vchDestinationWallet !== null && (
            <Box sx={{ mr: 2 }}>
              <CopyText text={vchDestinationWallet}></CopyText>
            </Box>
          )}
        </Box>
        <Box sx={{ display: 'flex', fontSize: 12, mt: 2 }}>
          <Box sx={{ mr: 3 }}> {t('PLATFORM')}:</Box> <span> {platform?.platformName}</span>
        </Box>
      </CardContent>
    </Card>
  )
}

export default SubscriptionTransactionItemCard
