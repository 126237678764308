import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Card, CardContent, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import Icon from 'src/@core/components/icon'
import CustomAvatar from 'src/@core/components/mui/avatar'
import CustomChip from 'src/@core/components/mui/chip'
import { updateUserDetail } from 'src/@http/user'
import adminStore from 'src/zustand/admin'

// import adminStore from 'src/zustand/admin'
import ChangeStatus from '../actions-form/ChangeStatus'
import { ChangeStrategy } from '../actions-form/ChangeStrategy'
import { RowOptions } from '../actions-form/RowOptions'
import { StopStrategy } from '../actions-form/StopStrategy'

interface Props {
  item: API.User.AllUserResponse
}

const userStatusObj: UserStatusType = {
  active: 'success',
  pending: 'warning',
  inactive: 'secondary'
}

const UserItemCard = ({ item }: Props) => {
  // const { bgColor } = adminStore()
  const { t } = useTranslation()
  const [show, setShow] = useState<boolean>(false)
  const user = item

  const { currentStrategyIsActive, currentStrategyId, activeSubscription, endDateSubscription } = user
  let activeCurrentStrategy = false
  if (currentStrategyIsActive) activeCurrentStrategy = currentStrategyIsActive
  let activeSubscriptioLocal = false
  if (activeSubscription) activeSubscriptioLocal = activeSubscription

  const strategies: any = {
    1: {
      color: '#1AF772',
      name: t('LOW')
    },
    2: {
      color: '#FF8E0A',
      name: t('MEDIUM')
    },
    3: {
      color: '#F10707',
      name: t('HIGH')
    }
  }

  const { getAllUser } = adminStore()
  const change = async (status: boolean) => {
    await updateUserDetail(user.userId, { intStatusId: status ? 1 : 0 })
    getAllUser()
  }

  return (
    <Card variant='outlined' sx={{ backgroundColor: '' }}>
      <CardContent sx={{ px: 2 }}>
        <Grid container sx={{}}>
          <Grid item xs={2}>
            <CustomAvatar color={user.activeSubscription ? 'primary' : 'secondary'} variant='rounded'>
              <Icon icon={user.activeSubscription ? 'mdi:user' : 'akar-icons:stop'} color='white' />
            </CustomAvatar>
          </Grid>
          <Grid item sx={{ p: 1, pl: 1, fontSize: 18, display: 'flex', alignItems: 'center' }} xs={8}>
            <Icon icon='mdi:star' fontSize={20} color={user.isAdmin ? '#FFEA00' : 'default'} />
            <Typography>{user.email.split('@')[0]}</Typography>
          </Grid>
          <Grid item xs={2} sx={{ justifyContent: 'end', display: 'flex' }}>
            <Tooltip disableFocusListener disableTouchListener title={user.email}>
              <IconButton>
                <Icon icon='mdi:information-outline' />
              </IconButton>
            </Tooltip>
            <RowOptions row={user} />
          </Grid>
        </Grid>

        <Box sx={{ display: 'flex', fontSize: 12, mt: 2 }}>
          <Box sx={{ mr: 3 }}> {t('REGISTRATION_DATE')}:</Box>{' '}
          <span> {user.createdDate ? user.createdDate.slice(0, 10) : '-'}</span>
        </Box>

        <Box sx={{ display: 'flex', fontSize: 12, mt: 2 }}>
          <Box sx={{ mr: 3, alignSelf: 'center' }}> {t('SUBSCRIPTION_STATUS')}:</Box>
          <ChangeStatus change={change} status={user.status}></ChangeStatus>
        </Box>

        <Box sx={{ display: 'flex', fontSize: 12, mt: 2 }}>
          <Box sx={{ mr: 3, alignSelf: 'center' }}> {t('STRATEGY_STATUS')}:</Box>
          <StopStrategy row={user}></StopStrategy>
        </Box>

        <Box sx={{ display: 'flex', fontSize: 12, mt: 2 }}>
          <Box sx={{ mr: 3, alignSelf: 'center' }}> {t('STRATEGY')}:</Box>
          <CustomChip
            skin='light'
            size='small'
            label={currentStrategyId !== null ? strategies[currentStrategyId].name : 'inactive'}
            style={{
              backgroundColor:
                currentStrategyId !== null ? strategies[currentStrategyId].color : userStatusObj['inactive'],
              color: currentStrategyId !== null ? 'black' : '#9295A6'
            }}
            sx={{ textTransform: 'capitalize', alignSelf: 'center' }}
          />

          <ChangeStrategy row={user}></ChangeStrategy>
        </Box>

        <Box sx={{ display: 'flex', fontSize: 12, mt: 2 }}>
          <Box sx={{ mr: 3 }}> {t('PAYMENT_METHOD')}:</Box>{' '}
          <span> {user.paymentMethod ? user.paymentMethod.slice(0, 10) : '-'}</span>
        </Box>

        <Box sx={{ display: 'flex', fontSize: 12, mt: 2 }}>
          <Box sx={{ mr: 3 }}> {t('SUBSCRIPTION_EXPIRATION')}:</Box>{' '}
          <span> {endDateSubscription !== null ? endDateSubscription.slice(0, 10) : '-'}</span>
        </Box>
      </CardContent>
    </Card>
  )
}

export default UserItemCard
