import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getGlobalSettingForKey } from 'src/@http/global-settings'
import profileStore, { ProfileStore } from 'src/zustand/profile'

// import GenericTab from 'src/layouts/components/GenericTab'
import CryptoDashboard from '../components/home/CryptoDashboard'
import Mt4Dashboard from '../components/home/Mt4Dashboard'

const Home = () => {
  const [disabledMt4Tab, setDisabledMt4Tab] = useState<boolean>(true)
  const setPlatforms = profileStore((state:ProfileStore) => state.setPlatforms)
  const setShowPaymentFlowDialog = profileStore((state: ProfileStore) => state.setShowPaymentFlowDialog)
  
  const useQuery = () => {
    const { search } = useLocation()
  
    return useMemo(() => new URLSearchParams(search), [search])
  }
  const token: string = profileStore((state:ProfileStore) => state.token)
  const query = useQuery()
  const renewSubscription: string | null = query.get('renewSubscription')

  if (renewSubscription && renewSubscription === 'true' && token !=='' ) setShowPaymentFlowDialog(true)

  useEffect(() =>{ setPlatforms() }, [])

  useEffect(() => {
    getGlobalSettingForKey('disabledTabMt4')
      .then(disabled => {
        setDisabledMt4Tab(disabled.value === 'true')
      })
      .catch(err => setDisabledMt4Tab(true))
  }, [])

  const tab1 = () => {
    return <CryptoDashboard></CryptoDashboard>
  }
  const tab2 = () => {
    return <Mt4Dashboard></Mt4Dashboard>
  }

  const tabs = [
    {
      index: 0,
      label: 'Crypto',
      component: tab1(),
      icon: 'mdi:bitcoin'
    },
    {
      index: 1,
      label: 'Mt4/Bybit',
      component: tab2(),
      icon: 'mdi:account-group',
      disabled: disabledMt4Tab
    }
  ]

  return (
    <>
      <CryptoDashboard />
      {/* <GenericTab tabs={tabs}></GenericTab> */}
    </>
  )
}

export default Home
