import { forwardRef, useImperativeHandle, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Icon from 'src/@core/components/icon'
import { verifyUserPlatform } from 'src/@http/account'
import { DialogGeneric } from 'src/pages/components/generic/DialogGeneric'
import { PlatformName } from 'src/types/enums/platforms'
import type { PlatformModel } from 'src/types/models/platformModel'
import { logger } from 'src/utils/Logger'

interface UserPlatformState {
  username: string
  password: string
}

interface UserPlatform {
  id: string
  username: string
  password: string
  create: boolean
  delete: boolean
}

interface saveCredential {
  username: string
  password: string
}

interface Props {
  platform: PlatformModel
  username?: string
  editable?: boolean
}

const Platform = forwardRef((props: Props, ref) => {
  const defaultFormPlataform: UserPlatformState = { username: '', password: '' }
  const defaultCredential: saveCredential = { username: '', password: '' }
  if (props.username) {
    defaultFormPlataform.username = props.username
    defaultFormPlataform.password = '********'
    defaultCredential.username = props.username
  }
  const [formPlatform, setFormPlatform] = useState<UserPlatformState>(defaultFormPlataform)
  const [saveCredential, setSaveCredential] = useState<saveCredential>(defaultCredential)
  const [accountName, setAccountName] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isVerify, setIsVerify] = useState<boolean>(false)
  const { t } = useTranslation()
  const [showDialog, setShowDialog] = useState<boolean>(false)

  let labels: saveCredential = { username: 'Username', password: 'Password' }

  switch (props.platform.name) {
    case PlatformName.bybit:
      labels = { username: 'Api Key', password: 'Secret Key' }
      break
  }

  const closeFunc = () => {
    setShowDialog(false)
  }

  useImperativeHandle(ref, () => ({
    getState: (): UserPlatform => {
      return {
        id: props.platform._id,
        username: saveCredential.username,
        password: saveCredential.password,
        create: saveCredential.password !== '',
        delete: saveCredential.password === '' && saveCredential.username === ''
      }
    }
  }))

  const handleFormChange = (field: keyof UserPlatformState, value: UserPlatformState[keyof UserPlatformState]) => {
    setFormPlatform({ ...formPlatform, [field]: value })
    setIsVerify(false)
  }

  const confirmFunc = () => {
    logger.log('Data Previa:', formPlatform)
    setSaveCredential({
      username: formPlatform.username,
      password: formPlatform.password
    })
    logger.log('Establecido: ', saveCredential)
  }

  const deleteCredentials = () => {
    setSaveCredential({
      username: '',
      password: ''
    })
    setFormPlatform({
      username: '',
      password: ''
    })
  }

  const verify = async () => {
    setIsLoading(true)
    logger.log('Verify:', formPlatform)
    try {
      const response: any = await verifyUserPlatform(props.platform._id, formPlatform.username, formPlatform.password)
      toast.success('Correct credentials! 👌', { duration: 4000 })
      setIsVerify(true)
      setAccountName(response.account_name)
    } catch (err: any) {
      toast.error(err.message + '🙄', { duration: 4000 })
      setIsVerify(false)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Card variant='outlined' elevation={0}>
      <CardContent sx={{ pa: 0, ma: 0 }}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={7}
            sx={{
              textAlign: { xs: 'center', sm: 'left' },
              display: 'flex',
              flexDirection: 'column',
              justifyContent: { xs: 'center', md: 'flex-start' },
              alignSelf: 'center'
            }}
          >
            <Typography sx={{ fontWeight: 'bold', pb: { xs: 2 } }}>{props.platform.name}</Typography>
            {saveCredential.username !== '' && (
              <Typography variant='body2' sx={{ pb: 2 }}>
                {saveCredential.username}
              </Typography>
            )}
          </Grid>
          {props.editable && (
            <Grid item xs={12} sm={5}>
              <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-end' } }}>
                <Button
                  onClick={() => {
                    setShowDialog(true)
                  }}
                  variant='outlined'
                  sx={{ mr: 2 }}
                  startIcon={<Icon icon={saveCredential.username === '' ? 'mdi:add' : 'mdi:pencil-circle'} />}
                >
                  {saveCredential.username === '' ? t('ADD') : t('EDIT')}
                </Button>
                <Button variant='outlined' disabled={saveCredential.username === ''} onClick={deleteCredentials}>
                  {t('DELETE')}
                </Button>
              </Box>
              {saveCredential.username !== '' && (
                <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-end' } }}>
                  <Typography variant='caption' sx={{ pt: 4 }}>
                    {accountName}
                  </Typography>
                </Box>
              )}
            </Grid>
          )}
        </Grid>
      </CardContent>
      <DialogGeneric
        title={props.platform.name}
        show={showDialog}
        confirmText='SAVE'
        confirmDisabled={!isVerify}
        confirmFunc={confirmFunc}
        closeFunc={closeFunc}
      >
        <Grid container sx={{ pb: 4 }}>
          <Grid item xs={12} sm={12} sx={{ pb: 2 }}>
            <TextField
              sx={{ mt: 2 }}
              required
              fullWidth
              label={labels.username}
              value={formPlatform.username}
              onChange={e => handleFormChange('username', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              fullWidth
              type='password'
              label={labels.password}
              value={formPlatform.password}
              onChange={e => handleFormChange('password', e.target.value)}
            />
          </Grid>
        </Grid>
        <LoadingButton
          loading={isLoading}
          disabled={!(formPlatform.username !== '' && formPlatform.password !== '')}
          onClick={verify}
          size='small'
          variant='outlined'
          {...(isVerify && { color: 'success' })}
          {...(isVerify && { endIcon: <Icon icon='mdi:check-circle' fontSize={20} /> })}
        >
          {t('VERIFY')}
        </LoadingButton>
      </DialogGeneric>
    </Card>
  )
})

Platform.defaultProps = {
  editable: true
}

export { Platform }
export type { UserPlatform }
