import { Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import i18n from 'i18next'
import { FontWeight } from 'src/layouts/TypographyTheme'
import profileStore from 'src/zustand/profile'

import PositionRealTimeCloseDialog from './PositionRealTimeCloseDialog'
import PositionRealTimeDialog from './PositionRealTimeDialog'


const userStatusObj: UserStatusType = {
  active: 'success',
  pending: 'warning',
  inactive: 'secondary'
}

interface CellType {
  row: API.User.AllUserResponse
}

const columns: GridColDef[] = [
  {
    flex: 3,
    field: 'createdDate',
    headerAlign: 'center',
    align: 'center',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('REGISTRATION_DATE')}</Typography>,
    renderCell: ({ row }: CellType) => {
      const { createdDate } = row

      return (
        <Typography sx={{ px: 4 }} color={'white'} fontWeight={FontWeight.Normal} fontSize={14}>
          {createdDate ? row.createdDate.slice(0, 10) : '-'}
        </Typography>
      )
    }
  },
  {
    flex: 4,
    field: 'email',
    headerAlign: 'left',
    align: 'left',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('EMAIL')}</Typography>,
    renderCell: ({ row }: CellType) => {
      return (
        <Typography noWrap variant='body2' color='white'>
          {row.userId} - {row.email}
        </Typography>
      )
    }
  },
  {
    flex: 2,
    field: 'platformId',
    headerAlign: 'left',
    align: 'left',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('PLATFORM')}</Typography>,
    renderCell: ({ row }: CellType) => {

      const platformsDic = profileStore.getState().platformsDic

      return (
        <Typography noWrap variant='body2' color='white'>
          {row.platformId !== null ? platformsDic[row.platformId].platformName: '-'}
        </Typography>
      )
    }
  },
  {
    flex: 3,
    sortable: false,
    field: 'actions',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('ACTIONS')}</Typography>,
    renderCell: ({ row }: CellType) => <>
    <PositionRealTimeDialog  user={row}/>
    <PositionRealTimeCloseDialog user={row}/>
    </>
  }

  /*
  {
    width: 150,
    field: 'currentStrategyId',
    headerAlign: 'center',
    align: 'center',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('T_STRATEGY')}</Typography>,
    renderCell: ({ row }: CellType) => {
      const strategies: any = {
        1: {
          color: '#1AF772',
          name: i18n.t('LOW')
        },
        2: {
          color: '#FF8E0A',
          name: i18n.t('MEDIUM')
        },
        3: {
          color: '#F10707',
          name: i18n.t('HIGH')
        }
      }

      const { currentStrategyId } = row

return (
        <>
        <CustomChip
            skin='light'
            size='small'
            label={currentStrategyId !== null && strategies[currentStrategyId]!== undefined ? strategies[currentStrategyId].name : 'inactive'}
            style={{
              backgroundColor:
                currentStrategyId !== null && strategies[currentStrategyId]!== undefined ? strategies[currentStrategyId].color : userStatusObj['inactive'],
              color: currentStrategyId !== null ? 'black' : '#9295A6'
            }}
            sx={{ textTransform: 'capitalize' }}
          />
          
          <ChangeStrategy row={row}></ChangeStrategy>
        </>
      )
    }
  },
  */
]

export default columns
