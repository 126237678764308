import { mountStoreDevtool } from 'simple-zustand-devtools'
import { getCurrentNews, getNews, getNewsCategory } from 'src/@http/news'
import { logger } from 'src/utils/Logger'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export interface News {
  id: number
  title: string
  thumbnail: string
  categories: Category[]
  content: string
  statusId: number
  createdDate: string
  startDate: string
  endDate: string
}

export interface CurrentNews {
  id: number
  title: string
  thumbnail: string
  categories: Category[]
  content: string
  startDate: string
}

const defaultContent = `

  [<img src="https://www.freecodecamp.org/news/content/images/size/w2000/2022/08/pexels-mateusz-dach-2811648.jpg" width="450"/>](image.png)
  <br>
  Bybit se complace en anunciar la próxima inclusión de ECOX en nuestra plataforma de Trading Spot

  <br>
Para celebrarlo, te ofrecemos un **fondo de premios de 62,500 ECOX**.

<br>
**🗓 Periodo del evento: 7 de noviembre de 2022, 3PM UTC - 21 de noviembre de 2022, 3PM UTC**
<br>
<br>
**Durante este periodo, simplemente haz un depósito de 150 ECOX y recibirás 50 ECOX, así de sencillo.**
<br>
<br>
¡Date prisa! Las recompensas se distribuirán por orden de llegada.
  
  
  `

export interface Category {
  name: string
  statusId: number
  id: number
}

type State = {
  newsInit: CurrentNews[]
  newsAdmin: News[]
  categories: Category[]
}

type Actions = {
  resetAdminStore: () => void
  getNewsCategory: () => void
  getNews: () => void
  getCurrentNews: () => void
}

const initialState: State = {
  newsInit: [],
  newsAdmin: [],
  categories: []
}

const newsStore = create<State & Actions>()(
  persist(
    (set, get) => ({
      ...initialState,
      getNewsCategory: async () => {
        try {
          const categories = await getNewsCategory()
          set({ categories })
          logger.log('[Get] categories:', categories)
        } catch (error) {
          logger.log('[Get] categories:', error)
        }
      },
      getNews: async () => {
        try {
          const newsAdmin = await getNews()
          set({ newsAdmin })
          logger.log('[Get] newsAdmin:', newsAdmin)
        } catch (error) {
          logger.log('[Get] newsAdmin:', error)
        }
      },
      getCurrentNews: async () => {
        try {
          const newsInit = await getCurrentNews()
          set({ newsInit })
          logger.log('[Get] getCurrentNews:', newsInit)
        } catch (error) {
          logger.log('[Get] getCurrentNews:', error)
        }
      },
      resetAdminStore: () => {
        set(initialState)
      }
    }),
    {
      name: 'news-store'
    }
  )
)

if (process.env.NODE_ENV === 'development') mountStoreDevtool('news-store', newsStore)

export default newsStore

export interface NewsStore extends State, Actions {}