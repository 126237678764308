import { getAsync, postAsync } from 'src/@http'

export interface CryptoCurrency {
  intCryptoCurrencyId: number
  vchSymbol: string
  vchBaseCoin: string
  vchQuoteCoin: string
  intStatusId: number
}

const rootPath = '/cryptocurrency'

export const getAllCryptoCurrency = (): Promise<CryptoCurrency[]> => {
  return getAsync(rootPath)
}

export const postCryptoCurrency = (body: { vchSymbol: string }): Promise<CryptoCurrency> => {
  return postAsync(rootPath, body)
}
