import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
// import { Link} from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Avatar,
  Box,
  FormControl,
  // FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import Icon from 'src/@core/components/icon'
import { createAccountType, updateAccountType } from 'src/@http/commission'
// import { styled } from '@mui/material/styles'
import { CustomBtn, CustomLoadingBtn } from 'src/layouts/components/shared-component/CustomBtn'
import { ModeForm } from 'src/types'
// import { PercentageTraderPost, Trader } from 'src/types/models/traderModel'
import { logger } from 'src/utils/Logger'
import * as yup from 'yup'

interface Props {
  accountType?: API.Commission.AccountType | null
  modeForm: ModeForm
  submitFinishFunction: () => any
  closeFunc: () => any
}
interface AccountTypeForm {
  name: string
  commissionFrecuencyInDaysDefault: number
  status: boolean
}

const AccountTypeForm = (props: Props) => {
  const { closeFunc, submitFinishFunction, modeForm, accountType } = props

  const defaultValuesForm: AccountTypeForm = {
    name: accountType ? accountType.accountTypeName : '',
    commissionFrecuencyInDaysDefault: accountType ? accountType.commissionFrecuencyInDaysDefault : 0,
    status: accountType ? accountType.statusId === 1 : true
  }

  const validations = {
    name: yup.string(),
    commissionFrecuencyInDaysDefault: yup.number().positive(),
    status: yup.boolean()
  }

  if (modeForm === ModeForm.create) {
    validations.name = validations.name.required()
    validations.commissionFrecuencyInDaysDefault = validations.commissionFrecuencyInDaysDefault.required()
  }

  const schemaValidations = yup.object().shape(validations)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { t } = useTranslation()
  const theme = useTheme()
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const [checked, setChecked] = useState<boolean>(defaultValuesForm.status)
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm<AccountTypeForm>({ resolver: yupResolver(schemaValidations), defaultValues: defaultValuesForm })

  const onSubmit = async (data: AccountTypeForm) => {
    setIsLoading(true)
    const { name, commissionFrecuencyInDaysDefault } = data
    try {
      const items = await createAccountType({
        vchAccountTypeName: name,
        intCommissionFrecuencyInDaysDefault: commissionFrecuencyInDaysDefault
      })
      logger.log(items)

      toast.success(t('CREATE_ACCOUNT_TYPE_SUCCESS_MESSAGE'), { duration: 4000 })
      submitFinishFunction()
    } catch (error: any) {
      toast.error(error.message, { duration: 4000 })
    }
    setIsLoading(false)
  }

  const onSubmitEdit = async (data: AccountTypeForm) => {
    if (!accountType) return logger.error('You dont have Account type')
    setIsLoading(true)
    const { name, commissionFrecuencyInDaysDefault, status } = data

    try {
      const responseUpdate = await updateAccountType({
        intAccountTypeId: accountType.accountTypeId,
        intCommissionFrecuencyInDaysDefault: commissionFrecuencyInDaysDefault,
        vchAccountTypeName: name
      })
      logger.log('Response update', responseUpdate)
      toast.success(t('UPDATE_ACCOUNT_TYPE_SUCCESS_MESSAGE'), { duration: 4000 })
      submitFinishFunction()
    } catch (error: any) {
      toast.error(error.message)
    }
    setIsLoading(false)
  }

  return (
    <form noValidate autoComplete='off' onSubmit={accountType ? handleSubmit(onSubmitEdit) : handleSubmit(onSubmit)}>
      <FormControl fullWidth sx={{ my: 2 }}>
        <Controller
          name='name'
          control={control}
          render={({ field: { ...field }, fieldState }) => (
            <TextField
              variant='outlined'
              disabled={ModeForm.show === modeForm}
              {...field}
              label={t('ACCOUNT_TYPE_NAME')}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
            />
          )}
        />
      </FormControl>
      <FormControl fullWidth sx={{ my: 2 }}>
        <Controller
          name='commissionFrecuencyInDaysDefault'
          control={control}
          render={({ field: { ...field }, fieldState }) => (
            <TextField
              type='number'
              variant='outlined'
              disabled={ModeForm.show === modeForm}
              {...field}
              label={t('COMMISSION_FRECUENCY_ID_DAYS_DEFAULT')}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
            />
          )}
        />
      </FormControl>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center' }}>
        <Box>
          <Avatar sx={{ bgcolor: 'primary.main', width: 50, height: 50 }} variant='rounded'>
            <Icon icon='mdi:account' fontSize={20} />
          </Avatar>
        </Box>

        <Box>
          <Box>
            <Typography align='center' fontSize={22}>
              {t('STATUS')}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Controller
            name='status'
            control={control}
            render={({ field: { ...field } }) => (
              <Switch defaultChecked={defaultValuesForm.status} {...field} disabled={ModeForm.show === modeForm} />
            )}
          />
        </Box>
      </Box>

      <Grid container>
        <Grid sx={{ p: 1 }} item xs={6}>
          <CustomLoadingBtn loading={isLoading} fullWidth size='large' type='submit' variant='contained' sx={{ mt: 3 }}>
            {accountType ? t('EDIT') : t('PROCEED')}
          </CustomLoadingBtn>
        </Grid>
        <Grid sx={{ p: 1 }} item xs={6}>
          <CustomBtn fullWidth size='large' variant='contained' onClick={() => closeFunc()} sx={{ mt: 3 }}>
            {t('CANCEL')}
          </CustomBtn>
        </Grid>
      </Grid>
    </form>
  )
}

export default AccountTypeForm
