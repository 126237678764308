import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { Avatar, Box, FormControl, Grid, Switch, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
// import { postPromocode, putPromocode } from 'src/@http/promocode'
import { postSubscriptions, putSubscriptions } from 'src/@http/subscription'
import { CustomLoadingBtn } from 'src/layouts/components/shared-component/CustomBtn'
import { ModeForm } from 'src/types'
import * as yup from 'yup'

interface SubscriptionForm {
  subscriptionId: number
  subscriptionName: string
  subscriptionValue: number
  availableDays: number
  statusId: boolean
}

interface Props {
  modeForm: ModeForm
  valuesDefaultForm?: API.Subscription.SubscriptionResponse
  submitSuccess: () => void
}

const SubscriptionForm = ({ modeForm, valuesDefaultForm, submitSuccess }: Props) => {
  // --- FORM

  const defaultValuesForm: SubscriptionForm = {
    subscriptionId: 0,
    availableDays: 0,
    subscriptionName: '',
    subscriptionValue: 0.0,
    statusId: true
  }

  if (valuesDefaultForm) {
    defaultValuesForm.subscriptionId = valuesDefaultForm.subscriptionId
    defaultValuesForm.availableDays = valuesDefaultForm.availableDays
    defaultValuesForm.subscriptionName = valuesDefaultForm.subscriptionName
    defaultValuesForm.subscriptionValue = valuesDefaultForm.subscriptionValue
    defaultValuesForm.statusId = valuesDefaultForm.statusId === 1
  }

  const validations = {
    subscriptionId: yup.number().typeError('Invalid id format'),
    subscriptionName: yup.string(),
    subscriptionValue: yup.number(),
    statusId: yup.boolean(),
    availableDays: yup.number()
  }

  if (modeForm === ModeForm.create) {
    validations.subscriptionName = validations.subscriptionName.required().min(4)
    validations.subscriptionValue = validations.subscriptionValue.required().min(1)
    validations.availableDays = validations.availableDays.required().min(1)
    validations.statusId = validations.statusId.required()
  }

  const schemaValidations = yup.object().shape(validations)

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues
  } = useForm<SubscriptionForm>({ resolver: yupResolver(schemaValidations), defaultValues: defaultValuesForm })

  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const onSubmit = async (form: SubscriptionForm) => {
    if (modeForm == ModeForm.create) {
      setSubmitLoading(true)
      try {
        await postSubscriptions({
          vchSubscriptionName: form.subscriptionName,
          dcmSubscriptionValue: form.subscriptionValue,
          intAvailableDays: form.availableDays
        })
        toast.success(t('SUBSCRIPTION_CREATED_SUCCESS'), { duration: 4000 })
        submitSuccess()
      } catch (error: any) {
        toast.error(error.message, { duration: 4000 })
      } finally {
        setSubmitLoading(false)
      }
    }

    if (modeForm == ModeForm.edit) {
      setSubmitLoading(true)
      try {
        const res = await putSubscriptions({
          intSubscriptionId: Number(form.subscriptionId),
          vchSubscriptionName: form.subscriptionName,
          dcmSubscriptionValue: form.subscriptionValue,
          intAvailableDays: form.availableDays
        })
        toast.success(t('SUBSCRIPTION_UPDATED_SUCCESS'), { duration: 4000 })
        // getAllSubscriptions()
        submitSuccess()
      } catch (error: any) {
        toast.error(error.message, { duration: 4000 })
      } finally {
        setSubmitLoading(false)
      }
    }
  }

  //--------------------------
  const { t } = useTranslation()
  const theme = useTheme()
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const [disabledProceed, setDisabledProceed] = useState<boolean>(false)

  return (
    <>
      <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
        {ModeForm.create !== modeForm && (
          <FormControl fullWidth sx={{ my: 2 }}>
            <Controller
              name='subscriptionId'
              control={control}
              render={({ field: { ...field }, fieldState }) => (
                <TextField
                  disabled
                  variant='outlined'
                  {...field}
                  label={t('ID')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </FormControl>
        )}

        {
          <FormControl fullWidth sx={{ my: 2 }}>
            <Controller
              name='subscriptionName'
              control={control}
              render={({ field: { ...field }, fieldState }) => (
                <TextField
                  variant='outlined'
                  {...field}
                  label={t('NAME')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </FormControl>
        }
        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            control={control}
            name='availableDays'
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                {...field}
                label={t('AVAILABLE_DAYS')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            control={control}
            name='subscriptionValue'
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                {...field}
                label={t('VALUE')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>

        <Grid container alignItems='center'>
          {!lessThanSmall && (
            <Grid item xs={1} sm={1.5}>
              <Avatar sx={{ bgcolor: '#FCA5A5', width: 50, height: 50 }} variant='rounded' />
            </Grid>
          )}

          <Grid item xs={10} sm={8.5}>
            <Box>
              <Typography align='center' fontSize={22}>
                {t('STATUS')}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Controller
              name='statusId'
              control={control}
              render={({ field: { ...field } }) => (
                <Switch defaultChecked={getValues('statusId')} {...field} disabled={ModeForm.show === modeForm} />
              )}
            />
          </Grid>
        </Grid>
        {ModeForm.show !== modeForm && (
          <CustomLoadingBtn
            disabled={disabledProceed}
            size='large'
            type='submit'
            variant='contained'
            style={{ minHeight: 60, width: '100%' }}
            sx={{ mt: { xs: 4, sm: 4 } }}
            loading={submitLoading}
          >
            {t('PROCEED')}
          </CustomLoadingBtn>
        )}
      </form>
    </>
  )
}

export default SubscriptionForm
