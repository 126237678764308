import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { DialogGeneric } from 'src/pages/components/generic/DialogGeneric'
import LoginForm from 'src/pages/components/home/LoginForm'

interface Props {
  show: boolean
  closeFunc: () => void
  onSuccess?: () => void
  showIconClose?: boolean
}
const LoginDialog = ({ show, closeFunc, onSuccess, showIconClose }: Props) => {
  const { t } = useTranslation()

  return (
    <DialogGeneric
      show={show}
      hideActions={true}
      showIconClose={showIconClose}
      closeFunc={closeFunc}
      title={t('LOGIN')}
    >
      <Box sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center', pt: 4 }}>
        <LoginForm onSuccess={onSuccess ? onSuccess : () => undefined} closeFunc={closeFunc} />
      </Box>
    </DialogGeneric>
  )
}

LoginDialog.defaultProps = {
  showIconClose: false
}

export default LoginDialog
