import styled from '@emotion/styled'
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton/LoadingButton'
import { Button, ButtonProps } from '@mui/material'

import { FontWeight } from '../../TypographyTheme'

const CustomBtn = styled(Button)<ButtonProps>(({ theme }) => ({
  color: 'white',
  backgroundColor: 'primary.main',
  fontWeight: FontWeight.Normal,
  '&:hover': {
    backgroundColor: 'primary.main'
  },
  textTransform: 'none',
  '&:disabled': {
    backgroundColor: '#424245'
  }
}))

const CustomLoadingBtn = styled(LoadingButton)<LoadingButtonProps>(({ theme }) => ({
  color: 'white',
  backgroundColor: 'bakground',
  fontWeight: FontWeight.Normal,
  '&:hover': {
    backgroundColor: 'primary.main'
  },
  textTransform: 'none'
}))

const ActiveBtn = styled(Button)<ButtonProps>(({ theme }) => {
  return {
    color: 'primary.main',
    backgroundColor: 'background.default',
    fontWeight: FontWeight.Normal,
    '&:hover': {
      color: 'white',
      backgroundColor: 'primary.main'
    },
    textTransform: 'none'
  }
})

export default CustomBtn
export { ActiveBtn, CustomBtn, CustomLoadingBtn }
