import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar, Box, Button, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material'
import Icon from 'src/@core/components/icon'
import { Platform } from 'src/@http/platform'
import { DialogGeneric } from 'src/pages/components/generic/DialogGeneric'
import { ModeForm } from 'src/types'
import profileStore from 'src/zustand/profile'

import PlatformForm from './PlatformForm'
import PlatformItemCard from './PlatformItemCard'

interface Props {
  isContent: boolean
}


const PlatformManager = ({isContent}: Props) => {
  const [showCuponRegisterDialog, setShowCuponRegisterDialog] = useState<boolean>(false)
  const [platformSelected, setPlatformSelected] = useState<Platform | null>(null)
  const { t } = useTranslation()

  const openDialogEdit = (platform: Platform) => {
    setPlatformSelected(platform)
    setShowCuponRegisterDialog(true)
  }

  const openDialogCreate = () => {
    setPlatformSelected(null)
    setShowCuponRegisterDialog(true)
  }

  const { setPlatforms, platforms } = profileStore()
  const [notFound, setNotFound] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const init = async () => {
      setLoading(true)
      const plaftformList = await setPlatforms()
      if (plaftformList.length === 0) setNotFound(true)
      setLoading(false)
    }
    init()
  }, [])

  return (
    <Card  sx={{ ...(isContent && { border: 'none', boxShadow: 'none' }) }}>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {!isContent && <>
              <Avatar sx={{ bgcolor: 'primary.main', width: 40, height: 40 }} variant='rounded'>
              <Icon icon='mdi:layers' style={{ fontSize: 24 }} color='white' />
            </Avatar>
            <Typography sx={{ m: 3 }} color='white' variant='h5' component='div'>
              {t('PLATFORMS')}
            </Typography>
            </>}
          </Box>
          {false && (
            <Button onClick={() => openDialogCreate()} startIcon={<Icon icon='mdi:plus-box-outline' color='#9295A6' />}>
              <Typography color='#9295A6' sx={{ textTransform: 'capitalize' }}>
                {t('ADD')}
              </Typography>
            </Button>
          )}
        </Box>

        {platforms.length !== 0 && (
          <Grid sx={{ mt: 3 }} container>
            {platforms.map((platform: Platform, key: number) => (
              <Grid key={key} item sx={{ p: 1 }} xs={12} sm={6} lg={4}>
                <PlatformItemCard
                  platform={platform}
                  editFunction={() => openDialogEdit(platform)}
                  deleteFunctionSuccess={() => setPlatforms()}
                />
              </Grid>
            ))}
          </Grid>
        )}
        {notFound && <Typography align='center'>{t('DATA_NOT_FOUND')}</Typography>}
        {platforms.length === 0 && loading && (
          <Grid sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Grid>
        )}
      </CardContent>
      <DialogGeneric
        show={showCuponRegisterDialog}
        closeFunc={() => {
          setShowCuponRegisterDialog(false)
        }}
        hideActions={true}
        title={t('PLATFORM_DETAIL')}
        leftAline={true}
        showIconClose={true}
      >
         <Box sx={{ mt: 4 }}>
        <PlatformForm
          submitSuccess={() => setShowCuponRegisterDialog(false)}
          modeForm={platformSelected ? ModeForm.edit : ModeForm.create}
          {...(platformSelected ? { valuesDefaultForm: platformSelected } : {})}
        ></PlatformForm>
      </Box>
      </DialogGeneric>
    </Card>
  )
}

PlatformManager.defaultProps = {
  isContent: false
}

export default PlatformManager
