import React, { Fragment, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import Icon from 'src/@core/components/icon'
import CustomChip from 'src/@core/components/mui/chip'
import { ReferredUser } from 'src/@http/user'
import profileStore from 'src/zustand/profile'

import StrategyColum from './StrategyColum'

const userStatusObj: UserStatusType = {
  active: 'success',
  pending: 'warning',
  inactive: 'secondary'
}

interface RowProps {
  row: ReferredUser
  allData: ReferredUser[]
  filteredData: ReferredUser[]
}

function Row({ row, allData, filteredData }: RowProps) {
  const [open, setOpen] = React.useState(false)
  const { t } = useTranslation()
  const nextLevel = (Number(row.level) + 1).toString()
  const existData =
    allData.filter(value => value.intReferredUserId === row.intUserId && value.level === nextLevel).length !== 0
  const emailReferredUser = allData.find(value => value.intUserId === row.intReferredUserId)?.vchEmail || ''

  const emailFormated = (email: string) => {
    const aux = email.split('@')
    const aux2 = aux[1].split('.')

    if (aux2.length !== 2) return '***ff@***ff.com'

    return `***${aux[0].slice(aux[0].length - 2, aux[0].length)}@***${aux2[1].slice(
      aux2[1].length - 2,
      aux2[1].length
    )}.${aux2[1]}`
  }

  const emailMaxLengthFormated = (email: string, intReferredUserId: number) => {
    const max = 25
    const label = `${intReferredUserId}-${email}`
    if (label.length > max) {
      const aux = label.slice(`${intReferredUserId}-`.length, max-3)
  
      return aux + '...'
    }
  
    return email
  }

  return (
    <Fragment key={row.intUserId}>
      <TableRow>
        <TableCell width='20'>
          {existData && (
            <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
              {open ? <Icon icon='mdi:chevron-up' fontSize={20} /> : <Icon icon='mdi:chevron-down' fontSize={20} />}
            </IconButton>
          )}
          {!existData && <Typography> </Typography>}
        </TableCell>
        <TableCell component='th' scope='row' align='center'>
          <Typography color={'white'}>{row.dtmCreatedDate ? row.dtmCreatedDate.slice(0, 10) : '-'}</Typography>
        </TableCell>
        <TableCell align='left'>
          <Typography color={'white'}>
            {row.intUserId} - {profileStore.getState().isAdmin() ? row.vchEmail : emailFormated(row.vchEmail)}
          </Typography>
        </TableCell>
        <TableCell align='center'>
          {' '}
          <Typography color={'white'}>{row.level}</Typography>
        </TableCell>
        <TableCell align='center'>
          <Typography align='center' sx={{}}>
            {row.vchSubscriptionName}
          </Typography>
          {row.blnActiveSubscription !== 'Active' && (
            <CustomChip
              skin='light'
              size='small'
              label={row.blnActiveSubscription === 'Active' ? t('ACTIVE') : t('INACTIVE')}
              color={userStatusObj[row.blnActiveSubscription === 'Active' ? 'active' : 'inactive'] || 'inactive'}
              sx={{ textTransform: 'capitalize' }}
            />
          )}
        </TableCell>
        <TableCell align='center'>
          {false && <StrategyColum name={row.vchStrategyName} />}
          <CustomChip
            skin='light'
            size='small'
            label={row.blnCurrentStrategyIsActive === 'Active' ? t('ACTIVE') : t('INACTIVE')}
            color={userStatusObj[row.blnCurrentStrategyIsActive === 'Active' ? 'active' : 'inactive'] || 'inactive'}
            sx={{ textTransform: 'capitalize' }}
          />
        </TableCell>
        <TableCell align='center'>
          <Typography color={'white'}>{row.paymentMethod}</Typography>
        </TableCell>
        {row.balance !== null && (
          <TableCell align='center'>
            <Typography color={'white'}>${Number(row.balance).toFixed(2)}</Typography>
          </TableCell>
        )}
        <TableCell align='left'>
          <Typography color={'white'}>
            {row.intReferredUserId} - {profileStore.getState().isAdmin() ? emailMaxLengthFormated(emailReferredUser, row.intReferredUserId) : emailFormated(emailReferredUser)}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box margin={1} sx={{ ml: 5 * Number(row.level) }}>
              <ReferredUsersTable
                allData={allData}
                level={nextLevel}
                filteredData={filteredData}
                parentId={row.intUserId}
              ></ReferredUsersTable>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

interface Props {
  allData: ReferredUser[]
  level: string
  filteredData: ReferredUser[]
  parentId: number
}

const ReferredUsersTable = ({ allData, level, filteredData, parentId }: Props) => {
  const { t } = useTranslation()

  const showBalance = useMemo(() => {
    if (allData.length !== 0 && allData[0].balance !== null) return true

    return false
  }, [allData])

  return (
    <TableContainer component={Paper}>
      <Table aria-label='collapsible table'>
        {level === '1' && (
          <TableHead>
            <TableRow>
              <TableCell width='20'> </TableCell>
              <TableCell align='center' width='180'>
                <Typography sx={{ textTransform: 'capitalize' }} color='#9295A6'>
                  {t('REGISTRATION_DATE')}
                </Typography>
              </TableCell>
              <TableCell align='left' width='250'>
                <Typography sx={{ textTransform: 'capitalize' }} color='#9295A6'>
                  {t('EMAIL')}
                </Typography>
              </TableCell>
              <TableCell align='center' width='80'>
                <Typography sx={{ textTransform: 'capitalize' }} color='#9295A6'>
                  {t('LEVEL')}
                </Typography>
              </TableCell>
              <TableCell align='center' width='100'>
                <Typography sx={{ textTransform: 'capitalize' }} color='#9295A6'>
                  {t('SUBSCRIPTION')}
                </Typography>
              </TableCell>
              <TableCell align='center' width='100'>
                <Typography sx={{ textTransform: 'capitalize' }} color='#9295A6'>
                  {t('STRATEGY')}
                </Typography>
              </TableCell>
              <TableCell align='center' width='180'>
                <Typography sx={{ textTransform: 'capitalize' }} color='#9295A6'>
                  {t('PAYMENT_METHOD')}
                </Typography>
              </TableCell>
              {showBalance && (
                <TableCell align='center' width='100'>
                  <Typography sx={{ textTransform: 'capitalize' }} color='#9295A6'>
                    {t('BALANCE')}
                  </Typography>
                </TableCell>
              )}
              <TableCell align='left'>
                <Typography sx={{ textTransform: 'capitalize' }} color='#9295A6'>
                  {t('REFERRED_USER')}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
        )}

        <TableBody>
          {filteredData
            .filter(value => value.level === level && value.intReferredUserId === parentId)
            .reverse()
            .map(row => (
              <Row key={row.intUserId} row={row} allData={allData} filteredData={filteredData} />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ReferredUsersTable
