import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
// import { Link} from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Alert,
  Avatar,
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Typography
} from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'
// import { styled } from '@mui/material/styles'
import Icon from 'src/@core/components/icon'
import { apiCredentials, Platform, postPlatform, putPlatform } from 'src/@http/platform/'
import { getPlatformByUserId } from 'src/@http/user'
import { CustomLoadingBtn } from 'src/layouts/components/shared-component/CustomBtn'
import { Platform as PlatformStatus } from 'src/types/models/platformModel'
import { logger } from 'src/utils/Logger'
import appStore, { AppStore } from 'src/zustand/app'
import profileStore, { ProfileStore } from 'src/zustand/profile'
import * as yup from 'yup'

interface Props {
  submitFinishFunction: (apiData: FormDataApiUser) => any
  platformIdSelected?: string | undefined
  editForm?: FormDataApiUser | undefined
  confirmText?: string
}
interface FormDataApiUser {
  apiKey: string
  apiSecret: string
  passPhrase: string
}

const schema = yup.object().shape({
  apiKey: yup.string().min(5).required(),
  apiSecret: yup.string().min(5).required(),
  passPhrase: yup.string()
})

const CredentialsForm = (props: Props) => {
  const userDetail = profileStore((state: ProfileStore) => state.userDetail)
  const setUserPlatform = profileStore((state: ProfileStore) => state.setUserPlatform)
  const hideAlert = appStore((state: AppStore) => state.hideAlert)
  const platforms: Platform[] = profileStore((state: ProfileStore) => state.platforms)
  const getPlatform = profileStore((state: ProfileStore) => state.getPlatform)

  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()

  interface Dictionary {
    [Key: string]: Platform
  }

  const [showApiKey, setShowApiKey] = useState<boolean>(false)
  const [showApiSecret, setShowApiSecret] = useState<boolean>(false)
  const [showPassPhrase, setShowPassPhrase] = useState<boolean>(false)
  
  const [platformSeleted, setPlatformSeleted] = useState<string>(
    props.platformIdSelected ? props.platformIdSelected : "1"
  )

  const platformObj = getPlatform(Number(platformSeleted))


  const onSubmit = async (data: FormDataApiUser) => {
    setIsLoading(true)
    const body: apiCredentials = {
      vchApiKey: data.apiKey,
      vchApiSecret: data.apiSecret,
      vchPassPhrase: data.passPhrase
    }

    try {
      props.editForm === undefined
        ? await postPlatform(Number(userDetail.userId), Number(platformSeleted), body)
        : await putPlatform(Number(userDetail.userId), Number(platformSeleted), body)

      hideAlert()

      let platforms: PlatformStatus[] = []
      platforms = await getPlatformByUserId(userDetail.userId.toString())
      logger.log('User Platforms: ', platforms)

      if (platforms.length !== 0) {
        setUserPlatform({...platforms[0], platformId: Number(platformSeleted)})
      }

      toast.success(t('SUCCESS_CREDENTIALS'), { duration: 4000 })

      props.submitFinishFunction(data)
    } catch (error: any) {
      toast.error(error.message, { duration: 4000 })
    } finally {
      setIsLoading(false)
    }
  }

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FormDataApiUser>({
    resolver: yupResolver(schema),
    defaultValues: {
      apiKey: props.editForm ? props.editForm.apiKey : '',
      apiSecret: props.editForm ? props.editForm.apiSecret : '',
      passPhrase: props.editForm ? props.editForm.passPhrase : ''
    }
  })

  const handlePlatformIdChange = (event: SelectChangeEvent) => {
    setPlatformSeleted(event.target.value as string)
  }

  return (
    <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
      {props.platformIdSelected === undefined && (
        <FormControl fullWidth>
          <InputLabel id='demo-simple-select-label'>{t('PLATFORM')}</InputLabel>
          <Select
            labelId='platform'
            id='platform'
            required
            value={platformSeleted}
            label={t('PLATFORM')}
            onChange={handlePlatformIdChange}
          >
            {platforms
              .filter((value: Platform) => value.intStatusId === 1)
              .map((platform: Platform, key: number) => (
                <MenuItem key={key} value={platform.platformId}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {false && <Avatar variant='square' src={platform.vchImage} sx={{ width: 90, height: 40 }} />}
                    <Typography sx={{ ml: 2 }}>
                      {platform.platformName.charAt(0).toUpperCase() + platform.platformName.slice(1)}
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}

      <FormControl fullWidth sx={{ mt: 4 }}>
        <InputLabel htmlFor='auth-login-v2-password' error={Boolean(errors.apiKey)}>
          {t('API_KEY')}
        </InputLabel>
        <Controller
          name='apiKey'
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange, onBlur } }) => (
            <OutlinedInput
              value={value}
              onBlur={onBlur}
              label={t('API_SECRET_KEY')}
              onChange={onChange}
              id='auth-login-v2-password'
              error={Boolean(errors.apiKey)}
              type={showApiKey ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    edge='end'
                    onMouseDown={e => e.preventDefault()}
                    onClick={() => setShowApiKey(!showApiKey)}
                  >
                    <Icon icon={showApiKey ? 'mdi:eye-outline' : 'mdi:eye-off-outline'} fontSize={20} />
                  </IconButton>
                </InputAdornment>
              }
            />
          )}
        />
        {errors.apiKey && <FormHelperText sx={{ color: 'error.main' }}>{errors.apiKey.message}</FormHelperText>}
      </FormControl>

      <FormControl fullWidth sx={{ mt: 4 }}>
        <InputLabel htmlFor='auth-login-v2-password' error={Boolean(errors.apiSecret)}>
          {t('API_SECRET_KEY')}
        </InputLabel>
        <Controller
          name='apiSecret'
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange, onBlur } }) => (
            <OutlinedInput
              value={value}
              onBlur={onBlur}
              label={t('API_SECRET_KEY')}
              onChange={onChange}
              id='auth-login-v2-password'
              error={Boolean(errors.apiSecret)}
              type={showApiSecret ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    edge='end'
                    onMouseDown={e => e.preventDefault()}
                    onClick={() => setShowApiSecret(!showApiSecret)}
                  >
                    <Icon icon={showApiSecret ? 'mdi:eye-outline' : 'mdi:eye-off-outline'} fontSize={20} />
                  </IconButton>
                </InputAdornment>
              }
            />
          )}
        />
        {errors.apiKey && <FormHelperText sx={{ color: 'error.main' }}>{errors.apiSecret?.message}</FormHelperText>}
      </FormControl>

      {
        /* Solo aplica para Okx */
        platformObj !== null && platformObj.intPassPhrase === 1 && (
          <FormControl fullWidth sx={{ mt: 4 }}>
            <InputLabel htmlFor='passPhrase' error={Boolean(errors.apiKey)}>
              {t('PASS_PHRASE')} / {t('API_PASSWORD')}
            </InputLabel>
            <Controller
              name='passPhrase'
              control={control}
              render={({ field: { value, onChange, onBlur } }) => (
                <OutlinedInput
                  value={value}
                  onBlur={onBlur}
                  label={`${t('PASS_PHRASE')} / ${t('API_PASSWORD')}`}
                  onChange={onChange}
                  id='passPhrase'
                  error={Boolean(errors.passPhrase)}
                  type={showPassPhrase ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        edge='end'
                        onMouseDown={e => e.preventDefault()}
                        onClick={() => setShowPassPhrase(!showPassPhrase)}
                      >
                        <Icon icon={showPassPhrase ? 'mdi:eye-outline' : 'mdi:eye-off-outline'} fontSize={20} />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              )}
            />
            {errors.passPhrase && (
              <FormHelperText sx={{ color: 'error.main' }}>{errors.passPhrase.message}</FormHelperText>
            )}
          </FormControl>
        )
      }

      {props.editForm && <Alert severity='warning'>{t('EDIT_ACTIVE_CREDENTIALS_ACTIVE_POSITION_LOST')}!</Alert>}

      <CustomLoadingBtn loading={isLoading} fullWidth size='large' type='submit' variant='contained' sx={{ mt: 8 }}>
        {props.confirmText ? t(props.confirmText) : t('CONFIRM')}
      </CustomLoadingBtn>
    </form>
  )
}

export { CredentialsForm }
export type { FormDataApiUser }
