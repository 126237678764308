// ** Type import
import { useTranslation } from 'react-i18next'
import { VerticalNavItemsType } from 'src/@core/layouts/types'

const Navigation = (): VerticalNavItemsType => {
  const { t } = useTranslation()

  const menu = [
    {
      title: 'Home',
      path: '/',
      icon: 'mdi:home-outline'
    },
    {
      title: t('ADMIN_USERS'),
      path: '/admin-users',
      icon: 'mdi:account-group-outline'
    },
    {
      title: t('TRANSACTIONS'),
      path: '/transaction',
      icon: 'ic:outline-local-fire-department'
    },
    {
      title: 'Logout',
      path: '/login',
      action: 'logout',
      icon: 'mdi:logout'
    }
  ]

  return menu
}

export default Navigation
