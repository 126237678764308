import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, FormControl, Grid, InputLabel, MenuItem } from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import CardHelpVideo from '../components/help/CardHelpVideo'

import '@react-pdf-viewer/core/lib/styles/index.css'
// import { t } from 'i18next';

interface VideoHelp {
  title: string
  subheader: string
  url: string
  platform: string
  id: number
}

const Help = () => {
  const { t } = useTranslation()

  const videoList: VideoHelp[] = [
    {
      id:3,
      title: t('HOW_TO_REIGSTER_BYBIT'),
      subheader: t('HOW_TO_REIGSTER_BYBIT_SUBTITLE'),
      url: 'https://www.youtube.com/embed/c0XUM3zDDxQ?si=JZ2G8PGxPg-GA7Eg',
      platform: 'Bybit'
    },
    {
      id:2,
      title: t('HOW_TO_CREATE_API_KEYS'),
      subheader: t('HOW_TO_CREATE_API_KEYS_SUBTITLE'),
      url: 'https://www.youtube.com/embed/7ay2kFtKduY?si=I9HijTIMjJwQvThH',
      platform: 'Bybit'
    },
    {
      id:1,
      title: t('HOW_TO_UNIFY_BYBIT'),
      subheader: t('HOW_TO_UNIFY_BYBIT_SUBTITLE'),
      url: 'https://www.youtube.com/embed/a0UxHtjbcBU?si=yNSZgnvnmK0DI_so',
      platform: 'Bybit'
    },
    {
      id:5,
      title: t('ACCOUNT_ISOLATED_MARGIN_BYBIT'),
      subheader: t('ACCOUNT_ISOLATED_MARGIN_BYBIT_SUBTITLE'),
      url: 'https://www.youtube.com/embed/7ay2kFtKduY?si=I9HijTIMjJwQvThH',
      platform: 'Bybit'
    },
    {
      id:4,
      title: t('HOW_TO_CREATE_AN_ACCOUNT_AXES'),
      subheader: t('HOW_TO_CREATE_AN_ACCOUNT_AXES_SUBTITLE'),
      url: 'https://www.youtube.com/embed/VPKczGHaEn8?si=7MfjGn08JyDgWUF4',
      platform: 'Bybit'
    },
    

    {
      id:6,
      title: t('HOW_TO_CREATE_ACCOUNT_IN', { platform: 'KuCoin' }),
      subheader: '',
      url: 'https://www.youtube.com/embed/ZXChQdn2QBw',
      platform: 'KuCoin'
    },
    {
      id:7,
      title: t('HOW_TO_OBTAIN_CREDENTIALS'),
      subheader: '',
      url: 'https://www.youtube.com/embed/GYn5XH38xXw',
      platform: 'KuCoin'
    },
    {
      id:8,
      title: t('HOW_TO_REGISTER_API_KEY_AXES_KAPITAL'),
      subheader: '',
      url: 'https://www.youtube.com/embed/YBUg_msg9yw',
      platform: 'KuCoin'
    }
  ]

  const [platformNameSelected, setPlatformNameSelected] = useState<string>('KuCoin')
  const [videoListFilted, setVideoListFilted] = useState<VideoHelp[]>(videoList.filter((value => value.platform === 'KuCoin')))

  const handleChange = (event: SelectChangeEvent) => {
    const data = event.target.value as string
    setPlatformNameSelected(data)
    const list = videoList.filter((value => value.platform === data))
    setVideoListFilted(list)
  }

  return (
    <Grid container>
      <Grid item xs={12} sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
        <Box sx={{ minWidth: 200 }}>
          <FormControl fullWidth>
            <InputLabel>{t('PLATFORM')}</InputLabel>
            <Select value={platformNameSelected} label={t('PLATFORM')} onChange={handleChange}>
              <MenuItem value='KuCoin'>KuCoin</MenuItem>
              <MenuItem value='Bybit'>Bybit</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Grid>
      {videoListFilted.map((video) => (
          <Grid item xs={12} md={6} sx={{ p: 1 }} key={video.id}>
            <CardHelpVideo url={video.url} title={video.title} subheader={video.subheader} />
          </Grid>
        ))}
    </Grid>
  )
}

export default Help
