import { deleteAsync, getAsync, postAsync, putAsync } from 'src/@http'

export interface GridBotRSI {
    intGridBotRSIId: number
    RSIValue: number
    RSIPercentageIncrement: string
    intGridBotId: number
}


interface PostGridBotRSI {
    RSIValue: number
    RSIPercentageIncrement: string
    intGridBotId: number
}

interface PutGridBotRSI {
    intGridBotRSIId: number
    RSIValue?: number
    RSIPercentageIncrement?: string
}

const rootPath = '/gridBotRSI'

export const getAllGridbotsRSI = (): Promise<GridBotRSI[]> => {
  return getAsync(rootPath)
}

export const getAllGridBotRSIByGridbotId = (gridBotId: number): Promise<GridBotRSI[]> => {
  return getAsync(rootPath + '/getAllByGridBotId/' + gridBotId)
}

export const postGribotRSI = (body: PostGridBotRSI): Promise<GridBotRSI> => {
  return postAsync(rootPath, body)
}

export const putGribotRSI = (body: PutGridBotRSI): Promise<GridBotRSI> => {
  return putAsync(rootPath, body)
}

export const deleteGribotRSI = (gridbitRSIId: number): Promise<any> => {
  return deleteAsync(rootPath + '/' + gridbitRSIId)
}