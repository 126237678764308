import { Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import i18n from 'i18next'
import CustomChip from 'src/@core/components/mui/chip'
import { ThemeColor } from 'src/@core/layouts/types'
import { SubscriptionCommissionWithdrawal } from 'src/@http/subscriptionCommissionWithdrawal'

import CopyText from './column/CopyText'
import SubscriptionCommissionWithdrawalStatus from './column/SubscriptionTransactionStatus'

interface CellType {
  row: SubscriptionCommissionWithdrawal
}

const statusObj: { [key: number]: ThemeColor } = {
  1: 'success',
  0: 'warning',
  2: 'error'
}

const columns: GridColDef[] = [
  {
    width: 100,
    field: 'dtmCreatedDate',
    headerAlign: 'center',
    align: 'center',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('DATE')}</Typography>,
    renderCell: ({ row }: CellType) => {
      return (
        <Typography sx={{ px: 4 }} color={'white'} fontSize={14}>
          {row.dtmCreatedDate ? row.dtmCreatedDate.slice(0, 10) : '-'}
        </Typography>
      )
    }
  },
  {
    width: 320,
    field: 'intUserId',
    headerAlign: 'center',
    align: 'center',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('USER')}</Typography>,
    renderCell: ({ row }: CellType) => {
      return (
        <Typography color={'white'}>
          {row.user.intUserId}-{row.user.vchEmail}
        </Typography>
      )
    }
  },
  {
    width: 120,
    field: 'dcmAmount',
    headerAlign: 'center',
    align: 'center',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('AMOUNT')}</Typography>,
    renderCell: ({ row }: CellType) => {
      return (
        <Typography color={'white'}>
          {row.dcmAmount !== null ? `$${Number(row.dcmAmount).toFixed(2)}` : '-'}
        </Typography>
      )
    }
  },
  {
    width: 200,
    field: 'intStatusId',
    headerAlign: 'center',
    align: 'center',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('STATUS')}</Typography>,
    renderCell: ({ row }: CellType) => {
      return (
        <>
          {false && (
            <CustomChip
              skin='light'
              size='small'
              label={row.intStatusId === 1 ? i18n.t('COMPLETED') : i18n.t('PENDING')}
              color={statusObj[row.intStatusId] || 'success'}
              sx={{ textTransform: 'capitalize' }}
            />
          )}
          <SubscriptionCommissionWithdrawalStatus item={row} />
        </>
      )
    }
  },
  {
    width: 350,
    field: 'vchDestinationWallet',
    headerAlign: 'center',
    align: 'center',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('DESTINATION_WALLET')}</Typography>,
    renderCell: ({ row }: CellType) => {
      return (
        <>
          {row.vchDestinationWallet !== null && <CopyText text={row.vchDestinationWallet}></CopyText>}
          {row.vchDestinationWallet === null && (
            <Typography color={'white'}>
              {row.vchDestinationWallet !== null ? `${row.vchDestinationWallet}` : '-'}
            </Typography>
          )}
        </>
      )
    }
  },
  {
    width: 100,
    field: 'vchNetwork',
    headerAlign: 'center',
    align: 'center',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('NETWORK')}</Typography>,
    renderCell: ({ row }: CellType) => {

      return <Typography color={'white'}>{row.vchNetwork}</Typography>
    }
  },
  {
    width: 350,
    field: 'vchTxId',
    headerAlign: 'center',
    align: 'center',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('TX_ID')}</Typography>,
    renderCell: ({ row }: CellType) => {

     return <>
          {(row.vchTxId !== null && row.vchTxId !== '') && <CopyText text={row.vchTxId}></CopyText>}
          {(row.vchTxId === null || row.vchTxId === '')  && (
            <Typography color={'white'}>
              -
            </Typography>
          )}
        </>
    }
  },
  {
    width: 400,
    field: 'vchOriginWallet',
    headerAlign: 'center',
    align: 'center',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('ORIGIN_WALLET')}</Typography>,
    renderCell: ({ row }: CellType) => {
      return (
        <>
          {row.vchOriginWallet !== null && <CopyText text={row.vchOriginWallet}></CopyText>}
          {row.vchOriginWallet === null && (
            <Typography color={'white'}>-</Typography>
          )}
        </>
      )
    }
  },
  {
    width: 150,
    field: 'vchIp',
    headerAlign: 'center',
    align: 'center',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('IP')}</Typography>,
    renderCell: ({ row }: CellType) => {

      return <Typography color={'white'}>{row.vchIp}</Typography>
    }
  },
]

export default columns
