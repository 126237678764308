import { Card, CardContent } from '@mui/material'
import { SubscriptionTransaction } from 'src/@http/subscriptionTransaction'
import ItemsScroller from 'src/layouts/components/ItemScroller'

import SubscriptionTransactionItemCard from './SubscriptionTransactionItemCard'



interface Props {
  items: SubscriptionTransaction[]
}

const SubscriptionTransactionManagerCards = ({items}: Props) => {

  return (
    <Card>
      <CardContent sx={{ px: 0 }}>
        <ItemsScroller list={items} itemCard={SubscriptionTransactionItemCard} increment={6}></ItemsScroller>
      </CardContent>
    </Card>
  )
}

export default SubscriptionTransactionManagerCards