import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, TextField } from '@mui/material'
import { useTheme } from '@mui/material/styles'
// ** MUI Imports
import { DatePicker } from '@mui/x-date-pickers'

// ** Types
import { IDate } from './ButtonsFilter'

interface DateFilterProps {
  onData: (date: IDate) => void
  initDate?:  IDate
  width?: number | string
  maxDate: Date
}

export type IIcon = {
  width: number
  height: number
}

// ** Initialization
import { initialDate } from './ButtonsFilter'

const DateFilter = ({ onData, width, initDate, maxDate}: DateFilterProps) => {
  const { t } = useTranslation()
  const [dataForm, setDataForm] = useState<IDate>(initDate?initDate:initialDate)
  const theme = useTheme()

  function handleDateFilter(newValue: Date | null, isTo: boolean): void {
    if(newValue) {
      let aux = dataForm
      if(isTo)
        aux = { ...dataForm, dateTo: newValue }
      else
        aux = { ...dataForm, dateFrom: newValue }
      
      setDataForm(aux)
      onData(aux)
    }
  }

 
  return (
    <Grid width={width} container display='flex' justifyContent='flex-start' spacing={2}>
      <Grid item xs={12} md={6} sx={{}}>
        <DatePicker
          label={t('FROM')}
          value={dataForm.dateFrom}
          onChange={newValue => handleDateFilter(newValue, false)}
          renderInput={params => <TextField {...params}  fullWidth={true} />}
          maxDate={new Date()}
        />
      </Grid>
      <Grid item xs={12} md={6} sx={{ mt:{xs: 4, md: 0} }}>
        <DatePicker
          label={t('TO')}
          value={dataForm.dateTo}
          minDate={dataForm.dateFrom}
          onChange={newValue => handleDateFilter(newValue, true)}
          renderInput={params => <TextField {...params} fullWidth={true} />}
          maxDate={maxDate}
        />
      </Grid>
    </Grid>
  )
}

DateFilter.defaultProps = {
  maxDate: new Date()
}

export default DateFilter
