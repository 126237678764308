import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Box, CircularProgress, Typography } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import Icon from 'src/@core/components/icon'
import CustomChip from 'src/@core/components/mui/chip'
import { getAllProvider } from 'src/@http/position'
import { logger } from 'src/utils/Logger'

import { DialogGeneric } from '../../../generic/DialogGeneric'

interface Props {
  user: API.User.AllUserResponse
}

const PositionRealTimeDialog = ({ user }: Props) => {
  const { t } = useTranslation()
  const [show, setShow] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [transactions, setTransactions] = useState<{ [Key: string]: any }[]>([])
  const [columns, setColumns] = useState<GridColDef[]>([])

  useEffect(() => {
    const init = async () => {
      setLoading(true)
      try {
        const data = await getAllProvider(user.userId)
        if (!Array.isArray(data)) {
          setIsError(true)
        }
        if (Array.isArray(data) && data.length !== 0) {
          let columns: GridColDef[] = []
          setTransactions(
            data.map((transaction: { [Key: string]: any }, index: number) => {
              return {
                id: index,
                ...transaction
              }
            })
          )

          columns = Object.keys(data[0]).map((field: string) => {
            return { field, width: 150 }
          })
          setColumns(columns)
        }
      } catch (error: any) {
        logger.log('[getAllProvider] Position error: ', error)
        toast.error(error.message)
      }
      setLoading(false)
    }
    if (show) init()
    else {
      setTransactions([])
      setColumns([])
      setIsError(false)
    }
  }, [show])

  return (
    <>
      <CustomChip
        skin='light'
        size='small'
        label={t('SHOW')}
        color={'success'}
        sx={{ textTransform: 'capitalize', ml: 2 }}
        onClick={() => {
          setShow(true)
        }}
      />
      <DialogGeneric
        show={show}
        hideActions={true}
        title={t('OPEN_OPERATIONS') + ` (${user.email})`}
        closeFunc={() => {
          setShow(false)
        }}
        showIconClose
        maxWidth={1200}
      >
        <div onClick={event => event.stopPropagation()}>
          {columns.length !== 0 && (
            <DataGrid
              autoHeight
              rows={transactions}
              getRowId={r => r.id}
              columns={columns}
              // disableSelectionOnClick
              pageSize={10}
              //rowsPerPageOptions={[5]}
              loading={false}
            />
          )}
          {(columns.length === 0 && !loading) && (
            <Box sx={{ mt: 3 }}>
              <Icon icon='iconoir:info-empty' fontSize={60} color='red' />
              <Typography fontSize={16} sx={{ mt: 4 }}>
                {t('OPEN_OPERATIONS_NOT_FOUND')}
              </Typography>
            </Box>
          )}
          {loading && <CircularProgress />}
          {isError && <Typography> {t('SERVICE_ERROR')}</Typography>}
        </div>
      </DialogGeneric>
    </>
  )
}

export default PositionRealTimeDialog
