import { getAsync } from 'src/@http'


export interface PromotionBanner {
    intPromotionBannerId: number
    vchPromotionUrl: string
    vchPromotionText: string
    intStatusId: number
    intSort: number
    intDurationMs: number
    vchDesktopImageUrl: string
    vchMobileImageUrl: string
    dtmCreatedDate: string
}


const rootPath = '/promotion-banner'


export const getPromotionBanner= (): Promise<PromotionBanner[]> => {
  return getAsync(rootPath)
}
