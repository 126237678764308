import { useEffect } from 'react'
import { toast, Toaster } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Box } from '@mui/material'
import { Worker } from '@react-pdf-viewer/core'
import { AxiosError } from 'axios'
import { axiosInstance } from 'src/@http/'
import DialogsApp from 'src/layouts/components/DialogsApp'

import { SettingsConsumer, SettingsProvider } from './@core/context/settingsContext'
import ReactHotToast from './@core/styles/libs/react-hot-toast'
import ThemeComponent from './@core/theme/ThemeComponent'
import AdminRoute from './layouts/components/AdminRoute'
import PrivateRoute from './layouts/components/PrivateRoute'
import UserLayout from './layouts/UserLayout'
import Admin from './pages/admin'
import AdminUsersList from './pages/admin-users/list'
import AdminUserNewOrEditUser from './pages/admin-users/newOrEdit'
import Help from './pages/help'
import Home from './pages/home'
import NewsPage from './pages/news'
import Portfolio from './pages/portfolio'
import Profile from './pages/profile'
import { logger } from './utils/Logger'
import appStore, { AppStore } from './zustand/app'
import profileStore from './zustand/profile'

function App() {
  useEffect(() => {
    const interceptor = axiosInstance.interceptors.response.use(undefined, handleErrorResponse)

    return () => {
      axiosInstance.interceptors.response.eject(interceptor)
    }
  }, [])
  const isLogged = profileStore((state) => state.isLogged)
  const logout = profileStore((state) => state.logout)
  const setShowDialog = appStore((state: AppStore) => state.setShowDialog)

  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name)
      })
    })
    logger.log('Complete Cache Cleared')
  }

  useEffect(() => clearCacheData(), [])
  
  const { t } = useTranslation()
  const handleErrorResponse = (error: AxiosError) => {
    if (error.response) {
      if (error.response.status === 401) {
        if(isLogged){
          setShowDialog('login',true)
          logout()
        }
      }
    } else if (error.request) {
      toast.error(t('ERROR_GET_RESPONSE'))
      logger.log('No se recibió respuesta del servidor:', error.request)
    } else {
      toast.error(t('ERROR_CONFIG_REQUEST'))
      logger.log('Error al configurar la solicitud:', error.message)
    }

    return Promise.reject(error)
  }

  // const [open, setOpen] = useState(true)
  // const handleClose = () => {
  //   setOpen(false)
  // }
  // const handleOpen = () => {
  //   setOpen(true)
  // }

  return (
    <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js'>
      <SettingsProvider>
        <SettingsConsumer>
          {({ settings }) => {
            return (
              <ThemeComponent settings={settings}>
                {false && (
                  <Box
                    sx={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'rgba(0,0,0,0.9)',
                      zIndex: 2
                    }}
                  />
                )}
                <Router>
                  <AppRouteHome />
                  <AppRouterAuth />
                  <AppRouterAdmin />
                  <AppRouteAdmin />
                  <DialogsApp />
                </Router>
                <ReactHotToast>
                  <Toaster position={settings.toastPosition} toastOptions={{ className: 'react-hot-toast' }} />
                </ReactHotToast>
              </ThemeComponent>
            )
          }}
        </SettingsConsumer>
      </SettingsProvider>
    </Worker>
  )
}

function AppRouteHome() {
  return (
    <Routes>
      <Route
        element={
          <PrivateRoute>
            <UserLayout />
          </PrivateRoute>
        }
      >
        <Route path={'/profile'} element={<Profile />} />
        <Route path={'/portfolio'} element={<Portfolio />} />
        <Route path={'/help'} element={<Help />} />
        
      </Route>
    </Routes>
  )
}

function AppRouteAdmin() {
  return (
    <Routes>
      <Route
        element={
          <AdminRoute>
            <UserLayout />
          </AdminRoute>
        }
      >
        <Route path={'/admin'} element={<Admin />} />
      </Route>
    </Routes>
  )
}

function AppRouterAuth() {
  return (
    <Routes>
      <Route element={<UserLayout />}>
        <Route path={'/'} element={<Home />} />
        <Route path={'/news'} element={<NewsPage />} />
      </Route>
    </Routes>
  )
}

function AppRouterAdmin() {
  return (
    <Routes>
      <Route
        element={
          <PrivateRoute>
            <UserLayout />
          </PrivateRoute>
        }
      >
        <Route path={'/admin-users'} element={<AdminUsersList />} />
        <Route path={'/admin-users/new'} element={<AdminUserNewOrEditUser />} />
        <Route path={'/admin-users/:id'} element={<AdminUserNewOrEditUser />} />
      </Route>
    </Routes>
  )
}

export default App
