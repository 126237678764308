import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  /*IconButton, useMediaQuery, useTheme*/ Avatar,
  Box,
  Chip,
  CircularProgress,
  Grid,
  Typography
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { GridColDef } from '@mui/x-data-grid'
// import {  /*DataGrid,*/ GridColDef } from '@mui/x-data-grid'
import Icon from 'src/@core/components/icon'
import { GridBot } from 'src/@http/gridbot'
import { getAllByTraderGridbots, TraderGridBot } from 'src/@http/trader-grid-bot'
import { DialogGeneric } from 'src/pages/components/generic/DialogGeneric'
import { logger } from 'src/utils/Logger'
// import { logger } from 'src/utils/Logger'

interface Props {
  showProp: boolean
  closeFunc: () => void
  gridBot: GridBot
}

interface CellType {
  row: TraderGridBot
}

const GridBotOperationsDialog = ({ showProp, closeFunc, gridBot }: Props) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const [traderGridBotList, setTraderGridBotList] = useState<TraderGridBot[]>([])

  useEffect(() => {
    const init = async () => {
      setLoading(true)
      try {
        const response = await getAllByTraderGridbots(gridBot.intGridBotId)
        setTraderGridBotList(response)
      } catch (error) {
        logger.log('[getAllByTraderGridbots] error:', error)
      }
      setLoading(false)
    }

    if(showProp)
      init()
  }, [showProp])

  const headers: GridColDef[] = [
    {
      field: 'vchSymbol',
      renderHeader: () => <Typography color='#9295A6'>{t('SYMBOL')}</Typography>,
      headerAlign: 'center',
      renderCell: ({ row }: CellType) => {
        return (
          <Box sx={{ display: 'flex', alignItems:'center' }}>
            <Avatar
              variant='square'
              src={`https://raw.githubusercontent.com/spothq/cryptocurrency-icons/master/32/color/${row.vchSymbol
                .replace('USDT', '')
                .toLowerCase()}.png`}
              sx={{ width: 25, height: 25, mr:2 }}
            />
            <Typography fontSize={14}>{row.vchSymbol}</Typography>
          </Box>
        )
      },
      width: 130
    },
    {
      field: 'dcmAmount',
      renderHeader: () => <Typography color='#9295A6'>{t('AMOUNT')}</Typography>,
      width: 100
    },
    {
      field: 'intLeverage',
      renderHeader: () => <Typography color='#9295A6'>{t('LEVERAGE')}</Typography>,
      renderCell: ({ row }: CellType) => {
        return <Typography fontSize={14}>x{row.intLeverage}</Typography>
      },
      width: 100,
      align: 'center'
    },
    {
      field: 'dcmQty',
      renderHeader: () => <Typography color='#9295A6'>{t('QUANTITY')}</Typography>,
      width: 100
    },
    {
      field: 'vchSide',
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => <Typography color='#9295A6'>{t('SIDE')}</Typography>,
      renderCell: ({ row }: CellType) => {
        return (
          <Chip
            size='small'
            label={row.vchSide === 'Sell' ? t('SELL') : t('BUY')}
            color={row.vchSide === 'Sell' ? 'error' : 'success'}
          />
        )
      },
      width: 100
    },

    {
      field: 'vchOrderType',
      renderHeader: () => <Typography color='#9295A6'>{t('ORDER_TYPE')}</Typography>,
      width: 120
    },
    {
      field: 'dcmLimitPrice',
      renderHeader: () => <Typography color='#9295A6'>{t('LIMIT_PRICE')}</Typography>,
      width: 130
    },
    {
      field: 'dcmTakeProfit',
      renderHeader: () => <Typography color='#9295A6'>{t('TAKE_PROFIT')}</Typography>,
      width: 130
    },
    {
      field: 'dcmPercentageStep',
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => <Typography color='#9295A6'>{t('PERCENTAGE_STEP')}</Typography>,
      width: 120
    },
    {
      field: 'vchOrderId',
      renderHeader: () => <Typography color='#9295A6'>{t('ORDER_ID')}</Typography>,
      width: 350
    },
    {
      field: 'vchOrderLinkId',
      renderHeader: () => <Typography color='#9295A6'>{t('ORDER_LINK_ID')}</Typography>,
      width: 350
    }
  ]

  return (
    <DialogGeneric
      show={showProp}
      hideActions={true}
      title={`${t('OPEN_OPERATIONS')} (Trader ID: ${gridBot.intTraderId})`}
      closeFunc={() => {
        closeFunc()
      }}
      showIconClose
      maxWidth={1200}
    >
      <Grid container sx={{ justifyContent: 'center' }}>
        {loading ? (
          <CircularProgress />
        ) : traderGridBotList.length !== 0 ? (
          <DataGrid
            autoHeight
            rows={traderGridBotList}
            getRowId={r => r.intTraderGridBotId}
            columns={headers}
            pageSize={10}
            disableSelectionOnClick
            hideFooterPagination={false}
          />
        ) : (
          <Grid item sx={{ mt: 3 }}>
            <Icon icon='iconoir:info-empty' fontSize={60} color='red' />
            <Typography fontSize={16} sx={{ mt: 4 }}>
              {t('DONT_HAVE_OPEN_OPERATIONS_MESSAGE')}
            </Typography>
          </Grid>
        )}
      </Grid>
    </DialogGeneric>
  )
}

GridBotOperationsDialog.defaultProps = {
  showProp: false
}

export default GridBotOperationsDialog
