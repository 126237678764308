import { useState } from "react"
import { useTranslation } from "react-i18next"
import Icon from 'src/@core/components/icon'
import { CustomLoadingBtn } from "src/layouts/components/shared-component/CustomBtn"
import PaymentFlow from "src/layouts/components/shared-component/PaymentFlow"
import { DialogGeneric } from "src/pages/components/generic/DialogGeneric"
import appStore, { AppStore } from "src/zustand/app"
import profileStore, { ProfileStore, StrategyProfile } from "src/zustand/profile"

interface Props {
  strategyProfile: StrategyProfile
}

const InvestStrategyBtn = ({strategyProfile}:Props) => {
  
    const user = profileStore((state:ProfileStore) => state.userDetail)
    const [show, setShow] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const { t } = useTranslation()
    const activateStrategy = profileStore((state:ProfileStore) => state.activateStrategy)
    const isLogged = profileStore((state: ProfileStore) => state.isLogged)
    const setShowDialog = appStore((state: AppStore) => state.setShowDialog)
    const setShowPaymentFlowDialog = profileStore((state: ProfileStore) => state.setShowPaymentFlowDialog)
    const showPaymentFlowDialog = profileStore((state: ProfileStore) => state.showPaymentFlowDialog)
    const setActiveStrategy = profileStore((state: ProfileStore) => state.setActiveStrategy)

    const handleLoading = async () => {
      setLoading(true)
      await activateStrategy(strategyProfile.strategyId)
      setLoading(false)
    }
  
    return (
      <>
        <CustomLoadingBtn
          loading={loading}
          size='large'
          variant='contained'
          startIcon={<Icon icon={'mdi:play-circle-outline'} />}
          onClick={() => {
            setActiveStrategy(strategyProfile.strategyId)
            if(!isLogged){
              setShowDialog('login',true)
            } else {
              if (user.currentStrategyIsActive) setShow(true)
              else handleLoading()
            }
            
          }}
        >
          {user.activeSubscription?t('ACTIVATE_STRATEGY'):t('ACTIVATE_SUBSCRIPTION')}
        </CustomLoadingBtn>
        <DialogGeneric
          show={show}
          title={t('CHANGE_STRATEGY')}
          confirmText={t('CONFIRM') || ''}
          confirmFunc={() => handleLoading()}
          closeFunc={() => {
            setShow(false)
          }}
        >
          {t('CONFIRM_CHANGE_CURRENT_STRATEGY')}
        </DialogGeneric>
        {showPaymentFlowDialog && (
        <PaymentFlow
          show={showPaymentFlowDialog}
          closeFunc={() => {
            setShowPaymentFlowDialog(false)
          }}
        ></PaymentFlow>
      )}
      </>
    )
  }

  export default InvestStrategyBtn