import { getAsync } from 'src/@http'

export interface TraderGridBot {
    intTraderGridBotId: number;
    intTraderId: number;
    vchSymbol: string;
    dcmQty: string;
    dcmLimitPrice: string;
    dcmTakeProfit: string;
    dcmAmount: string;
    dcmPercentageStep: string;
    dcmPercentageStepTPSL: string;
    vchOrderId: string;
    vchOrderLinkId: string;
    vchSide: string;
    vchOrderType: string;
    intLeverage: number;
    dtmCreatedDate: string;
    dtmUpdatedDate: string;
    intExecuted: number;
    intCanceled: number;
  }

const rootPath = '/traderGridBot'

export const getAllByTraderGridbots = (gridBotId: number): Promise<TraderGridBot[]> => {
  return getAsync(rootPath + '/getAllActiveByGridBotId/' + gridBotId)
}