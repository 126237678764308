import { useTranslation } from 'react-i18next'
import adminStore from 'src/zustand/admin'

import { DialogGeneric } from '../../generic/DialogGeneric'
import DetailPerformance from '../../home/DetailPerformance'

interface Props {
  showProp: boolean
  closeFunc: () => void
}

const TransactionsDialog = ({ showProp, closeFunc }: Props) => {
  const { t } = useTranslation()
  const { performanceUserDetail } = adminStore()

  return (
    <DialogGeneric
      show={showProp}
      hideActions={true}
      title={t('TRANSACTIONS')}
      closeFunc={() => {
        closeFunc()
      }}
      showIconClose
      maxWidth={1200}
    >
      <div onClick={event => event.stopPropagation()}>
        <DetailPerformance performanceDetail={performanceUserDetail} />
      </div>
    </DialogGeneric>
  )
}

TransactionsDialog.defaultProps = {
  showProp: false
}

export default TransactionsDialog
