import { deleteAsync, getAsync, postAsync, putAsync } from 'src/@http'

const rootPath = '/commission'

export const getAllAccountType = (): Promise<API.Commission.AccountType[]> => {
  return getAsync(rootPath + '/accountType')
}

export const getAccountType = (id: number): Promise<API.Commission.AccountType> => {
  return getAsync(rootPath + '/accountType/' + id)
}

export const createAccountType = (
  payload: API.Commission.CreateAccountType
): Promise<API.Commission.AccountType> => {
  return postAsync(rootPath + '/accountType', payload)
}

export const updateAccountType = (
  payload:API.Commission.UpdateAccountType
): Promise<API.Commission.UpdateAccountType> => {
  return putAsync(rootPath + '/accountType', payload)
}

export const deleteAccountType = (id: number): Promise<boolean> => {
  return deleteAsync(rootPath + '/accountType/' + id)
}


export const getAllRank = (): Promise<API.Commission.Rank[]> => {
    return getAsync(rootPath + '/commission/rank')
  }
  
  export const getRank = (id: number): Promise<API.Commission.Rank> => {
    return getAsync(rootPath + '/commission/rank/' + id)
  }
  
  export const createRank = (
    payload: API.Commission.CreateRank
  ): Promise<API.Commission.Rank> => {
    return postAsync(rootPath + '/commission/rank', payload)
  }
  
  export const updateRank = (
    payload: API.Commission.UpdateRank
  ): Promise<API.Commission.UpdateRank> => {
    return putAsync(rootPath + '/commission/rank', payload)
  }
  
  export const deleteRank = (id: number): Promise<boolean> => {
    return deleteAsync(rootPath + '/commission/rank/' + id)
  }
  
