import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography
} from '@mui/material'
import Icon from 'src/@core/components/icon'
import { changePassword } from 'src/@http/user'
import { CustomBtn } from 'src/layouts/components/shared-component/CustomBtn'
import profileStore, {ProfileStore} from 'src/zustand/profile'
import * as yup from 'yup'

interface FormData {
  oldPassword: string
  newPassword: string
  reEnterPassword: string
}

const resolver = yup.object().shape({
  oldPassword: yup.string().required(),
  newPassword: yup.string().min(5).required(),
  reEnterPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), undefined], 'Passwords must match')
    .required()
})

const ChangePasswordForm = () => {
  const user = profileStore((state:ProfileStore) => state.userDetail)
  const initForm: FormData = {
    oldPassword: '',
    newPassword: '',
    reEnterPassword: ''
  }

  const [formData, setFormData] = useState<FormData>(initForm)
  const { t } = useTranslation()
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<FormData>({ resolver: yupResolver(resolver) })
  const [showPassword, setShowPassword] = useState<boolean[]>([false, false, false])

  const updateShowPassword = (index: number, show: boolean) => {
    const newState = showPassword.slice()
    newState[index] = show
    setShowPassword(newState)
  }

  const onSubmit = async (data: FormData) => {
    const { oldPassword, newPassword, reEnterPassword } = data
    if (newPassword === reEnterPassword) {
      try {
        const response = await changePassword(Number(user.userId), oldPassword, newPassword)
        toast.success(t('UPDATED_PASSWORD_CORRECTLY'), { duration: 4000 })
        reset(initForm)
      } catch (err: any) {
        toast.error(err.message, { duration: 4000 })
      }
    }
  }

  return (
    <Box>
      <Box sx={{ px: 4 }}>
        <Typography style={{ fontSize: 16 }}>{t('PASSWORD')}</Typography>
        <Typography style={{ fontSize: 14 }} sx={{ pt: 2 }} color='#9295A6'>
          {t('SET_A_UNIQUE_PASSWORD')}.
        </Typography>
      </Box>
      <Box sx={{ px: 4, pb: 5 }}>
        <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth sx={{ my: 5 }}>
            <InputLabel htmlFor='auth-login-v2-password' error={Boolean(errors.oldPassword)}>
              {t('OLD_PASSWORD')}
            </InputLabel>
            <Controller
              name='oldPassword'
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange, onBlur } }) => (
                <OutlinedInput
                  value={value}
                  onBlur={onBlur}
                  label={t('OLD_PASSWORD')}
                  onChange={onChange}
                  id='auth-login-v2-password'
                  error={Boolean(errors.oldPassword)}
                  type={showPassword[0] ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        edge='end'
                        onMouseDown={e => e.preventDefault()}
                        onClick={() => updateShowPassword(0, !showPassword[0])}
                      >
                        <Icon icon={showPassword[0] ? 'mdi:eye-outline' : 'mdi:eye-off-outline'} fontSize={20} />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              )}
            />
            {errors.oldPassword && (
              <FormHelperText sx={{ color: 'error.main' }} id=''>
                {errors.oldPassword.message}
              </FormHelperText>
            )}
          </FormControl>
          <Grid container spacing={6} sx={{ py: 0 }}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth sx={{ mb: { xs: 0, md: 4 } }}>
                <InputLabel htmlFor='auth-login-v2-password' error={Boolean(errors.newPassword)}>
                  {t('NEW_PASSWORD')}
                </InputLabel>
                <Controller
                  name='newPassword'
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <OutlinedInput
                      value={value}
                      onBlur={onBlur}
                      label={t('NEW_PASSWORD')}
                      onChange={onChange}
                      id='auth-login-v2-password'
                      error={Boolean(errors.newPassword)}
                      type={showPassword[1] ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            edge='end'
                            onMouseDown={e => e.preventDefault()}
                            onClick={() => updateShowPassword(1, !showPassword[1])}
                          >
                            <Icon icon={showPassword[1] ? 'mdi:eye-outline' : 'mdi:eye-off-outline'} fontSize={20} />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  )}
                />
                {errors.newPassword && (
                  <FormHelperText sx={{ color: 'error.main' }} id=''>
                    {errors.newPassword.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth sx={{}}>
                <InputLabel htmlFor='reEnterPassword' error={Boolean(errors.reEnterPassword)}>
                  {t('RE_ENTER_PASSWORD')}
                </InputLabel>
                <Controller
                  name='reEnterPassword'
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <OutlinedInput
                      value={value}
                      onBlur={onBlur}
                      label={t('RE_ENTER_PASSWORD')}
                      onChange={onChange}
                      id='reEnterPassword'
                      error={Boolean(errors.reEnterPassword)}
                      type={showPassword[2] ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            edge='end'
                            onMouseDown={e => e.preventDefault()}
                            onClick={() => updateShowPassword(2, !showPassword[2])}
                          >
                            <Icon icon={showPassword[2] ? 'mdi:eye-outline' : 'mdi:eye-off-outline'} fontSize={20} />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  )}
                />
                {errors.reEnterPassword && (
                  <FormHelperText sx={{ color: 'error.main' }}>{errors.reEnterPassword.message}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: { xs: 'center', sm: 'space-between' }
            }}
          >
            <Typography
              style={{ fontSize: 12, maxWidth: '70%' }}
              sx={{ pt: 2, textAlign: { xs: 'center', sm: 'left' } }}
              color='#9295A6'
            >
              {t('TO_ENSURE_YOUR_ACCOUNT_IS_WELL_PROTECTED')}.
            </Typography>
            <CustomBtn size='large' type='submit' style={{ minHeight: 60 }} sx={{ mt: { xs: 4, sm: 0 }, backgroundColor: 'primary.main'}}>
              {t('SAVE_PASSWORD')}
            </CustomBtn>
          </Box>
        </form>
      </Box>
    </Box>
  )
}

export default ChangePasswordForm
