import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar, Box, CircularProgress, IconButton, Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import i18n from 'i18next'
import Icon from 'src/@core/components/icon'
import { postStopStrategyByUserId } from 'src/@http/user'
import { CustomLoadingBtn } from 'src/layouts/components/shared-component/CustomBtn'
import { FontWeight } from 'src/layouts/TypographyTheme'
import { logger } from 'src/utils/Logger'
import appStore, { AppStore } from 'src/zustand/app'
import profileStore, {ProfileStore} from 'src/zustand/profile'

import { DialogGeneric } from '../generic/DialogGeneric'

import Percentage from './Percentage'

interface Strategy {
  id: number
  color: string
  name: string
  monthPNL: number
  show: boolean
  invest?: boolean
  percentageLastDay: number
  percentageLastMotn: number
  percentageLastSixMonths: number
}

interface CellType {
  row: Strategy
}

const PauseStrategy = ({ id }: { id: number }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const getUserDetail = profileStore((state:ProfileStore) => state.getUserDetail)
  const user = profileStore((state:ProfileStore) => state.userDetail)
  const [show, setShow] = useState<boolean>(false)
  const { t } = useTranslation()
  

  const stopStrategy = async () => {
    setLoading(true)
    await postStopStrategyByUserId(user.userId)
    logger.log(`[v] Stop Stretegy ${id}`)
    await getUserDetail(user.userId)
    setLoading(false)
  }

  return (
    <>
      <IconButton
        aria-label='pause'
        size='large'
        onClick={() => {
          setShow(true)
        }}
      >
        {loading ? (
          <CircularProgress size={20} />
        ) : (
          <Icon color='red' icon='mdi:pause-circle' style={{ background: 'white', borderRadius: '50%' }} />
        )}
      </IconButton>
      <DialogGeneric
        show={show}
        title={t('STOP_STRATEGY')}
        confirmText={t('CONFIRM') || ''}
        confirmFunc={() => stopStrategy()}
        closeFunc={() => {
          setShow(false)
        }}
      >
        {t('CONFIRM_STOP_CURRENT_STRATEGY')}
      </DialogGeneric>
    </>
  )
}

const InvestStrategy = ({ row }: CellType) => {
  const user = profileStore((state:ProfileStore) => state.userDetail)
  const setActiveStrategy = profileStore((state:ProfileStore) => state.setActiveStrategy)
  const [show, setShow] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { t } = useTranslation()
  const activateStrategy = profileStore((state:ProfileStore) => state.activateStrategy)
  const isLogged = profileStore((state: ProfileStore) => state.isLogged)
  const setShowDialog = appStore((state: AppStore) => state.setShowDialog)

  const handleLoading = async () => {
    setLoading(true)
    await activateStrategy(row.id)
    setLoading(false)
  }

  return (
    <>
      <CustomLoadingBtn
        loading={loading}
        variant='contained'
        onClick={() => {
          setActiveStrategy(row.id)
          if(!isLogged){
            setShowDialog('login',true)
          } else {
            if (user.currentStrategyIsActive) setShow(true)
            else handleLoading()
          }
          
        }}
      >
        {i18n.t('ACTIVATE_SUBSCRIPTION')}
      </CustomLoadingBtn>
      <DialogGeneric
        show={show}
        title={t('CHANGE_STRATEGY')}
        confirmText={t('CONFIRM') || ''}
        confirmFunc={() => handleLoading()}
        closeFunc={() => {
          setShow(false)
        }}
      >
        {t('CONFIRM_CHANGE_CURRENT_STRATEGY')}
      </DialogGeneric>
    </>
  )
}

const columns: GridColDef[] = [
  {
    flex: 0.15,
    field: 'name',
    headerName: 'Strategy',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('STRATEGY')}</Typography>,
    renderCell: ({ row }: CellType) => {
      const { name, color } = row

      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar sx={{ bgcolor: color, width: 30, height: 30 }}> </Avatar>
          <Typography sx={{ px: 4 }} color={'white'} fontWeight={FontWeight.Normal} fontSize={18}>
            {name}
          </Typography>
        </Box>
      )
    }
  },

  {
    flex: 0.1,
    field: 'percentageLastDay',
    headerName: `${i18n.t('LAST')} 7 ${i18n.t('DAY')}`,
    headerAlign: 'center',
    align: 'center',
    renderHeader: () => (
      <Typography color='#9295A6'>{`${i18n.t('LAST')} 7 ${i18n.t('DAYS').toLowerCase()}`}</Typography>
    ),
    renderCell: ({ row }: CellType) => {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Percentage percentage={row.percentageLastDay}></Percentage>
        </Box>
      )
    }
  },

  {
    flex: 0.1,
    field: 'percentageLastMotn',
    headerName: `percentageLastMotn`,
    headerAlign: 'center',
    align: 'center',
    renderHeader: () => (
      <Typography color='#9295A6'>{`${i18n.t('LAST')} 14 ${i18n.t('DAYS').toLowerCase()}`}</Typography>
    ),
    renderCell: ({ row }: CellType) => {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Percentage percentage={row.percentageLastMotn}></Percentage>
        </Box>
      )
    }
  },

  {
    flex: 0.1,
    field: 'percentageLastSixMonths',
    headerName: `percentageLastSixMonths`,
    headerAlign: 'center',
    align: 'center',
    renderHeader: () => <Typography color='#9295A6'>{`${i18n.t('LAST')} ${i18n.t('MONTH')}`}</Typography>,
    renderCell: ({ row }: CellType) => {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Percentage percentage={row.percentageLastSixMonths}></Percentage>
        </Box>
      )
    }
  },

  // {
  //   flex: 0.1,
  //   minWidth: 250,
  //   field: 'monthPNL',
  //   headerName: 'by Month PNL',
  //   align: 'center',
  //   headerAlign: 'center',
  //   renderHeader: () => (
  //     <Typography color='#9295A6' align='center'>
  //       {i18n.t('BY_MONTH')} PNL
  //     </Typography>
  //   ),
  //   renderCell: ({ row }: CellType) => {
  //     return (
  //       <Typography
  //         noWrap
  //         variant='body2'
  //         color={row.monthPNL < 0 ? '#DC2626' : '#03A66D'}
  //         fontWeight={FontWeight.Normal}
  //         fontSize={16}
  //       >
  //         ${row.monthPNL < 0 ? row.monthPNL * -1 : row.monthPNL}
  //       </Typography>
  //     )
  //   }
  // },

  {
    flex: 0.1,
    minWidth: 110,
    field: 'active',
    headerName: '',
    headerAlign: 'center',
    align: 'center',
    renderCell: ({ row }: CellType) => {
      const userDetail = profileStore((state:ProfileStore) => state.userDetail)

      return userDetail.currentStrategyIsActive && userDetail.currentStrategyId === row.id ? <Typography color='#08FE4D'>{i18n.t('ACTIVE')}</Typography> : <></>
    }
  },

  {
    flex: 0.1,
    minWidth: 110,
    field: 'invest',
    headerName: '',
    align: 'center',
    headerAlign: 'center',
    renderCell: ({ row }: CellType) => {
      const userDetail = profileStore((state:ProfileStore) => state.userDetail)
  
      return userDetail.currentStrategyIsActive && userDetail.currentStrategyId === row.id ? <PauseStrategy id={row.id}></PauseStrategy> : <InvestStrategy row={row}></InvestStrategy>
    }
  }
  /*
  {
    flex: 0.1,
    minWidth: 130,
    field: 'actions',
    headerName: 'Chart',
    align: 'center',
    headerAlign: 'center',
    renderHeader: () => <Typography color='#9295A6'>{i18n.t('CHART')}</Typography>,
    renderCell: ({ row }: CellType) => {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Avatar alt='curve' src='/images/curve.png' sx={{ width: 120, height: 60 }} />
        </Box>
      )
    }
  }
  */
]

export default columns
export type { Strategy }
