import { deleteAsync, getAsync, postAsync } from 'src/@http'

// import { UserModel } from 'src/types/models/userModels'

/* Endpoints definition */
const GetPlatformAccount = (id: string) => `/account/user/${id}/platform`
const GetHistoryPlatformById = (id: string) => `/account/user/platform/${id}/history` // /account/user/platform/647750c2b0777190fc5f6d0a/history
const SaveAccountUserPlatform = (userId: string, plataformId: string) =>
  `/account/user/${userId}/platform/${plataformId}`
const VerifyUserPlatform = (plataformId: string) => `/account/verify/platform/${plataformId}`
const GetAccountUserPlatforms = (id: string) => `/account/user/${id}/platform `
const DeleteAccountUserPlatform = (id: string) => `/account/${id}`
const GetAccountByUserId = (userId: string) => `/account/account/user/${userId}`

const GetOrdersByAccountIdMetatrader = (accountId: string) => `/metatrader/order/${accountId}`
const GetOrdersByAccountIdBybit = (accountId: string) => `/bybit/orders/${accountId}/`

export const getPlatformAccount = (id: string): Promise<any> => {
  return getAsync(GetPlatformAccount(id))
}

export const getAccountByUserId = (id: string): Promise<any> => {
  return getAsync(GetAccountByUserId(id))
}

export const getOrdersByAccountId = (accountId: string): Promise<any> => {
  return getAsync(GetOrdersByAccountIdMetatrader(accountId))
}

export const getOrdersByAccountIdBybit = (accountId: string): Promise<any> => {
  return getAsync(GetOrdersByAccountIdBybit(accountId))
}

export const getHistoryPlatformById = (id: string): Promise<any> => {
  return getAsync(GetHistoryPlatformById(id))
}

export const getAccountUserPlatforms = (id: string): Promise<any> => {
  return getAsync(GetAccountUserPlatforms(id))
}

export const saveAccountUserPlatform = (
  userId: string,
  plataformId: string,
  username: string,
  password: string
): Promise<void> => {
  return postAsync(SaveAccountUserPlatform(userId, plataformId), {
    username,
    password
  })
}

export const verifyUserPlatform = (plataformId: string, username: string, password: string): Promise<void> => {
  return postAsync(VerifyUserPlatform(plataformId), {
    username,
    password
  })
}

export const deleteAccountUserPlatform = (id: string): Promise<any> => {
  return deleteAsync(DeleteAccountUserPlatform(id))
}
