// ** Type import
import { HorizontalNavItemsType } from 'src/@core/layouts/types'

const navigation = (): HorizontalNavItemsType => [
  {
    title: 'Home',
    path: '/',
    icon: 'mdi:home-outline'
  },
  {
    title: 'Reportes',
    path: '/reports',
    icon: 'mdi:chart-bar'
  },
  {
    title: 'Notificaciones',
    path: '/notifications',
    icon: 'mdi:bell-outline'
  },
  {
    title: 'Seguridad',
    path: '/security',
    icon: 'mdi:shield-outline'
  },
  {
    title: 'Administración',
    path: '/admin',
    icon: 'mdi:account-group-outline'
  }
]

export default navigation
