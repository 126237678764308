import { createContext } from "react";

interface AdminContext {
    path: string
    setPath: (path: string) => void
    params: any
    setParams: (params: any) => void
}

export const AdminContext = createContext<AdminContext>({
    path: 'customer-manager',
    setPath: (path: string) => undefined,
    params: {},
    setParams: (params: any) => undefined
  });