import { useState } from 'react'
import { useTheme } from '@emotion/react'
import { Avatar, Box, Slider, Typography } from '@mui/material'

interface Props {
  actualRank: number
  setRankSelected: (rankid: number) => void
}

const RankSlider = ({ actualRank, setRankSelected }: Props) => {
  const triangles = [
    {
      id: 1,
      value: 9,
      circle: 15
    },
    {
      id: 2,
      value: 26,
      circle: 31
    },
    {
      id: 3,
      value: 42,
      circle: 47
    },
    {
      id: 4,
      value: 58,
      circle: 64
    },
    {
      id: 5,
      value: 75,
      circle: 80
    },
    {
      id: 6,
      value: 91,
      circle: 97
    }
  ]

  const [triangleValue, setTriangleValue] = useState(triangles[actualRank - 1])
  const theme: any = useTheme()

  const changeInfoRank = (rank: any) => {
    const value = triangles.find(value => value.id === rank.id)
    if (value && value.id >= actualRank) {
      setTriangleValue(value || triangles[actualRank - 1])
      setRankSelected(rank.id)
    }
  }

  const ranks = [
    {
      id: 1,
      label: 'AXES 1',
      value: 3
    },
    {
      id: 2,
      label: 'AXES 2',
      value: 22
    },
    {
      id: 3,
      label: 'AXES 3',
      value: 41
    },
    {
      id: 4,
      label: 'AXES 4',
      value: 59
    },
    {
      id: 5,
      label: 'AXES 5',
      value: 78
    },
    {
      id: 6,
      label: 'AXES 6',
      value: 97
    }
  ]

  const porcentage = (actualRank * 100) / 6 - 1.5

  return (
    <Box id='rank-slider' sx={{ width: '100%', position: 'relative' }}>
      <Box
        sx={{ border: 3, borderColor: 'primary.main', borderRadius: '40px', py: 1, my: 0, backgroundColor: '#000000' }}
      >
        <Box
          sx={{
            background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.primary.light} ${porcentage}%, #000000 ${porcentage}%)`,
            py: 3,
            px: 20,
            mx: 1,
            borderRadius: '40px'
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {ranks.map((rank: any, key: number) => (
              <Box key={key} sx={{ display: 'flex', position: 'relative' }}>
                <Typography
                  fontSize={18}
                  fontWeight={600}
                  key={key}
                  color={actualRank > key ? 'black' : 'white'}
                  onClick={() => {
                    changeInfoRank(rank)
                  }}
                  sx={{ cursor: rank.id > actualRank ? 'pointer' : '' }}
                >
                  {rank.label}
                </Typography>
                {actualRank === rank.id && (
                  <Avatar
                    key={key}
                    onClick={() => {
                      changeInfoRank(rank)
                    }}
                    sx={{ cursor:'pointer',position: 'absolute', height: 70, width: 70, left: actualRank === 6 ? 85 : 70, top: -22 }}
                    src='/images/icons/Level indicador-02.png'
                    variant='circular'
                  />
                )}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      {actualRank !== 6 && (
        <Slider
          valueLabelDisplay='off'
          value={actualRank >= triangleValue.id ? triangleValue.circle : triangleValue.value}
          defaultValue={actualRank >= triangleValue.id ? triangleValue.circle : triangleValue.value}
          step={null}
          marks={triangles}
          sx={{
            zIndex: 2,
            position: 'absolute',
            top: 60,
            '& .MuiSlider-root': {
              cursor: 'none'
            },
            '& .MuiSlider-thumb': {
              // color: "transparent"
              marginTop: 4,
              width: 60,
              height: 50,
              borderRadius: 0,
              background: `linear-gradient(to right, ${theme.palette.primary.main} 60%, ${theme.palette.primary.light} )`,
              ///clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)' /* Triángulo hacia arriba */
              clipPath: 'polygon(0% 0%, 100% 0%, 50% 100%)',
              boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.5)'
            },
            '& .MuiSlider-track': {
              color: 'transparent'
            },
            '& .MuiSlider-rail': {
              color: 'transparent'
            },
            '& .MuiSlider-active': {
              color: 'transparent'
            },
            '& .MuiSlider-mark': {
              color: 'transparent'
            }
          }}
        />
      )}
    </Box>
  )
}

RankSlider.defaultProps = {
  actualRank: 3
}

export default RankSlider
