import { deleteAsync, getAsync, postAsync, putAsync } from 'src/@http'
import { Company } from 'src/types/models/companyModel'

/* Endpoints definition */

const GetCompanies = '/company'
const GetCompanysByName = (name: string) => `company/${name}`

const UpdateCompany = '/company'
const PostCompany = '/company'
const DeleteCompanysById = (companyId: string) => `company/${companyId}`

export const getCompanysByName = (name: string): Promise<any> => {
  return getAsync(GetCompanysByName(name))
}

export const deleteCompanysById = (companyId: string): Promise<any> => {
  return deleteAsync(DeleteCompanysById(companyId))
}

export const getCompanies = (): Promise<Company[]> => {
  return getAsync(GetCompanies)
}

export const updateCompany = (companyData: any): Promise<any> => {
  return putAsync(UpdateCompany, companyData)
}

export const postCompany = (companyData: any): Promise<any> => {
  return postAsync(PostCompany, companyData)
}
