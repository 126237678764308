import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar, Box, Button, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material'
import Icon from 'src/@core/components/icon'
import { DialogGeneric } from 'src/pages/components/generic/DialogGeneric'
import { ModeForm } from 'src/types'
import adminStore from 'src/zustand/admin'

import AccountTypeForm from './AccountTypeForm'
import AccountTypeItemCard from './AccountTypeItemCard'

const AccountTypeManager = () => {
  const [showItemRegisterDialog, setShowItemRegisterDialog] = useState<boolean>(false)
  const [itemSelected, setItemSelected] = useState<API.Commission.AccountType | null>(null)
  const { t } = useTranslation()

  const openDialogEdit = (item: API.Commission.AccountType) => {
    setItemSelected(item)
    setShowItemRegisterDialog(true)
  }

  const openDialogCreate = () => {
    setItemSelected(null)
    setShowItemRegisterDialog(true)
  }

  const { getAllAccountTypes , accountsTypes } = adminStore()

  useEffect(() => {
    getAllAccountTypes()
  }, [])

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar sx={{ bgcolor: 'primary.main', width: 40, height: 40 }} variant='rounded'>
              <Icon icon='mdi:account-cog-outline' style={{ fontSize: 24 }} color='white' />
            </Avatar>
            <Typography sx={{ m: 3 }} color='white' variant='h5' component='div'>
              {t('ACCOUNTS_TYPES')}
            </Typography>
          </Box>
          <Button onClick={() => openDialogCreate()} startIcon={<Icon icon='mdi:plus-box-outline' color='#9295A6' />}>
            <Typography color='#9295A6' sx={{ textTransform: 'capitalize' }}>
              {t('ADD')}
            </Typography>
          </Button>
        </Box>

        {accountsTypes.length !== 0 && <Grid sx={{ mt: 3 }} container>
        {accountsTypes.map((accountType: API.Commission.AccountType, key: number) => (
            <Grid key={key} item sx={{ p: 1 }} xs={12} sm={6} lg={4}>
              <AccountTypeItemCard
                accountType={accountType}
                editFunction={() => openDialogEdit(accountType)}
                deleteFunctionSuccess={() => getAllAccountTypes()}
              />
            </Grid>
          ))}
        </Grid>}
        {accountsTypes.length === 0 && <Grid sx={{ mt: 3 ,display:'flex', justifyContent:'center'}} >
        <CircularProgress />
        </Grid>}


      </CardContent>
      <DialogGeneric
        show={showItemRegisterDialog}
        closeFunc={() => {
            setShowItemRegisterDialog(false)
        }}
        hideActions={true}
        title={t('ACCOUNT_TYPE_DETAIL')}
        leftAline={true}
        showIconClose={true}
      >
        <Box sx={{ mt: 4 }}>
          <AccountTypeForm
            submitFinishFunction={() => {setShowItemRegisterDialog(false); getAllAccountTypes()}}
            closeFunc={() => setShowItemRegisterDialog(false)}
            modeForm={itemSelected ? ModeForm.edit : ModeForm.create}
            {...(itemSelected ? { accountType: itemSelected } : {})}
          ></AccountTypeForm>
        </Box>
      </DialogGeneric>
    </Card>
  )
}

export default AccountTypeManager