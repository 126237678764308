import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { IconButton, Snackbar } from '@mui/material'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import Icon from 'src/@core/components/icon'
import appStore, { AppStore, IAlert } from 'src/zustand/app'


const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} {...props} />
})



const AlertGlobal = () => {
  const { t } = useTranslation()
  const alert: IAlert = appStore((state: AppStore) => state.alert)
  const dynamicProps: any = {}
  const navigate = useNavigate()
  const hideAlert = appStore((state: AppStore) => state.hideAlert)

  if (!alert.persistent) {
    dynamicProps['autoHideDuration'] = 4000
    dynamicProps['onClose'] = () => hideAlert()
  }

  const toNavigate = () => {
    hideAlert()
    navigate(alert.navigation)
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={alert.visible}
      sx={{ mt: 20 }}
      {...dynamicProps}
    >
      <Alert
        severity={alert.severity}
        sx={{ width: '100%' }}
        onClose={() => hideAlert()}
        action={
          <>
            {alert.navigation !== '' && (
              <IconButton aria-label='close' color='inherit' sx={{}} onClick={() => toNavigate()}>
                <Icon icon='mdi:link-variant' fontSize={20} />
              </IconButton>
            )}

            <IconButton aria-label='close' color='inherit' sx={{}} onClick={() => hideAlert()}>
              <Icon icon='mdi:close' fontSize={20} />
            </IconButton>
          </>
        }
      >
        {t(`${alert.message}`)}
      </Alert>
    </Snackbar>
  )
}

export default AlertGlobal
