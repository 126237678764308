import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Chip } from '@mui/material'
import Icon from 'src/@core/components/icon'
import { getGlobalSettingForKey } from 'src/@http/global-settings'
import { logger } from 'src/utils/Logger'
import profileStore, { ProfileStore } from 'src/zustand/profile'

const ReferredUserLink = () => {
  const { t } = useTranslation()
  const userEdit = profileStore((state: ProfileStore) => state.userDetail)
  const [urlSignUpWithReferredId, setUrlSignUpWithReferredId] = useState<string>('')

  const getAll = () =>
  getGlobalSettingForKey('UrlSignUpWithReferredId').then(res => {
    setUrlSignUpWithReferredId(res.value)
    })

  useEffect(() => {
    getAll()
  }, [])

  const copyAddress = async () => {
    try {
      await navigator.clipboard.writeText(urlSignUpWithReferredId.replace('{0}', userEdit.userId))
      toast.success(t('COPIED_SUCCESSFULLY'), { duration: 4000 })
    } catch (error) {
      logger.log('Error', error)
    }
  }

  return (
    <>
      {urlSignUpWithReferredId !== '' && (
        <Chip
          label={urlSignUpWithReferredId.replace('{0}', userEdit.userId)}
          variant='outlined'
          deleteIcon={<Icon icon='mdi:content-copy' />}
          onClick={copyAddress}
          onDelete={copyAddress}
        />
      )}
    </>
  )
}

export default ReferredUserLink
