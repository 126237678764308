import { useTranslation } from 'react-i18next'
import { /*IconButton, useMediaQuery, useTheme*/ CircularProgress, Grid, Typography } from '@mui/material'
// import {  /*DataGrid,*/ GridColDef } from '@mui/x-data-grid'
import Icon from 'src/@core/components/icon'
import { DialogGeneric } from 'src/pages/components/generic/DialogGeneric'
// import { logger } from 'src/utils/Logger'
import adminStore from 'src/zustand/admin'

import TraderPositionItemCard from './TraderPositionItemCard'

interface Props {
  showProp: boolean
  closeFunc: () => void
  traderName: string
}

interface Header {
  attr: string
  label: string
  width: number
}

const TraderPositionsDialog = ({ showProp, closeFunc, traderName }: Props) => {
  const { t } = useTranslation()
  const { positionsBybit, loading } = adminStore()

  const headers: Header[] = [
    {
      attr: 'symbol',
      label: t('SYMBOL'),
      width: 1
    },
    {
      attr: 'avgPrice',
      label: t('AVG_PRICE'),
      width: 1
    },
    {
      attr: 'size',
      label: t('QUANTITY'),
      width: 1
    },
    {
      attr: 'side',
      label: t('TYPE'),
      width: 1
    },
    {
      attr: 'createdDate',
      label: t('DATE'),
      width: 1.5
    },
    {
      attr: 'positionValue',
      label: t('POSITION_VALUE'),
      width: 1
    },
    {
      attr: 'riskLimitValue',
      label: t('RISK_LIMIT_VALUE'),
      width: 1
    },
    {
      attr: 'takeProfit',
      label: t('TAKE_PROFIT'),
      width: 1
    },
    {
      attr: 'stopLoss',
      label: t('STOP_LOSS'),
      width: 1
    },
    {
      attr: 'tpslMode',
      label: t('TP/SL'),
      width: 1
    },
    {
      attr: 'action',
      label: t('CLOSE'),
      width: 1
    }
  ]

  return (
    <DialogGeneric
      show={showProp}
      hideActions={true}
      title={t('POSITIONS_TRADER_NAME', { traderName })}
      closeFunc={() => {
        closeFunc()
      }}
      showIconClose
      maxWidth={1200}
    >
      <Grid container sx={{ justifyContent: 'center' }}>
        {loading ? (
          <CircularProgress />
        ) : positionsBybit.length !== 0 ? (
          positionsBybit.map((position: API.Trader.PositionsBybit) => (
            <Grid key={position.traderPositionByBitId} item xs={12} sm={12} md={6} lg={4} sx={{ p: 1 }}>
              <TraderPositionItemCard positionBybit={position} />
            </Grid>
          ))
        ) : (
          <Grid item sx={{ mt: 3 }}>
            <Icon icon='iconoir:info-empty' fontSize={60} color='red' />
            <Typography fontSize={16}>{t('DONT_HAVE_OPEN_POSITIONS_MESSAGE')}</Typography>
          </Grid>
        )}
      </Grid>
    </DialogGeneric>
  )
}

TraderPositionsDialog.defaultProps = {
  showProp: false
}

export default TraderPositionsDialog
