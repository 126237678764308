import { useEffect, useState } from 'react'
import Marquee from 'react-fast-marquee'
import { useNavigate } from 'react-router-dom'
import { Box, Card, IconButton, Typography } from '@mui/material'
import Icon from 'src/@core/components/icon'
import newsStore, { CurrentNews } from 'src/zustand/news'

const NewsMarquee = () => {
  const navigate = useNavigate()
  const { newsInit, getCurrentNews, getNewsCategory } = newsStore()
  const list = newsInit.slice(0, 5)
  const [isPlay, setIsPlay] = useState<boolean>(true)
  const [selectedNews, setSelectedNews] = useState<CurrentNews | null>(null)


  useEffect(() => {
    getCurrentNews()
    getNewsCategory()
  }, [])


  const selectNews = (news: CurrentNews) => {
    setIsPlay(false)
    setSelectedNews(news)
  }

  const deselectNews = (news: CurrentNews) => {
    setIsPlay(true)
    setSelectedNews(null)
  }

  return (
    <Card sx={{ backgroundColor: 'paperCardColor.main' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', py: 2, px: 4 }}>
        <Icon icon='mdi:bullhorn-outline' color='white' fontSize={40} />
        <Box sx={{ width: '92%' }}>
          <Marquee play={isPlay}>
            {list.map((news, key) => (
              <Typography
                key={key}
                sx={{ mx: 4,cursor:'pointer', textDecoration: selectedNews?.id === news.id ? 'underline': '' }}
                onMouseEnter={() => {
                  selectNews(news)
                }}
                onMouseLeave={() => {
                  deselectNews(news)
                }}
                onClick={() => {navigate(`/news?id=` + news.id)}}
              >
                {news.title}
              </Typography>
            ))}
          </Marquee>
        </Box>

        <IconButton
          onClick={() => {
            navigate('/news')
          }}
        >
          <Icon icon='mdi:arrow-right-thin' color='white' fontSize={40} />
        </IconButton>
      </Box>
    </Card>
  )
}

export default NewsMarquee
