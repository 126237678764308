import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Box, ButtonGroup, Card, CardContent, Grid, IconButton } from '@mui/material'
import Icon from 'src/@core/components/icon'
import CustomAvatar from 'src/@core/components/mui/avatar'
import { deleteCompanysById } from 'src/@http/company/'
import { DialogGeneric } from 'src/pages/components/generic/DialogGeneric'
import { Company } from 'src/types/models/companyModel'

interface Props {
  company: Company
  editFunction: () => any
  deleteFunctionSuccess: () => any
}

const CompanyItemCard = (props: Props) => {
  const { t } = useTranslation()
  const { company, editFunction, deleteFunctionSuccess } = props
  const [show, setShow] = useState<boolean>(false)

  const deleteCompany = async () => {
    try {
      const res = await deleteCompanysById(company.companyId.toString())
      toast.success(t('COMPANY_DELETE_MESSAGE_SUCCESS'))
      deleteFunctionSuccess()
    } catch (err: any) {
      toast.error(err)
    }
  }

  const ActionButtons = () => {
    const buttons = [
      {
        icon: 'carbon:edit',
        action: () => {
          editFunction()
        }
      },
      {
        icon: 'ph:trash-light',
        action: () => {
          setShow(true)
        }
      }
    ]

    return (
      <Grid container sx={{ flexDirection: 'row', justifyContent: 'end' }}>
        <ButtonGroup>
          {buttons.map(button => (
            <Grid item key={button.icon}>
              <IconButton onClick={() => button.action()} aria-label='capture screenshot' color='secondary'>
                <Icon icon={button.icon} fontSize={16} />
              </IconButton>
            </Grid>
          ))}
        </ButtonGroup>
      </Grid>
    )
  }

  return (
    <Card sx={{ backgroundColor: 'background.default' }}>
      <CardContent sx={{ pr: 0 }}>
        <Grid container sx={{ flexDirection: 'row', width: '100%' }}>
          <Grid item xs={2}>
            <CustomAvatar color={company.statusId === 1 ? 'success' : 'secondary'} variant='rounded'>
              <Icon icon={company.statusId === 1 ? 'carbon:enterprise' : 'akar-icons:stop'} />
            </CustomAvatar>
          </Grid>
          <Grid item sx={{ p: 1, pl: 1, fontSize: 18 }} xs={8}>
            {company.companyName}
          </Grid>
          <Grid item xs={2} sx={{ justifyContent: 'end' }}>
            <ActionButtons />
          </Grid>
        </Grid>

        <Grid container sx={{ mt: 6, flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', fontSize: 12 }}>
            <Box sx={{ pr: 2 }}> {t('COMPANY_NAME')} :</Box> <span>{company.companyName}</span>
            <Box></Box>
          </Box>
          <Box sx={{ display: 'flex', fontSize: 12 }}>
            <Box sx={{ pr: 2 }}> {t('COMPANY_KEY')} : </Box> <span>{company.companyKey.slice(0, 15)}...</span>
            <Box></Box>
          </Box>
          <Box sx={{ display: 'flex', fontSize: 12 }}>
            <Box sx={{ pr: 2 }}> {t('REFER_KEY')} :</Box> <span>{company.refererKey}</span>
            <Box></Box>
          </Box>
        </Grid>

        <DialogGeneric
          show={show}
          title={t('DELETE_COMPANY_DIALOG')}
          confirmText={t('CONFIRM') || ''}
          confirmFunc={() => deleteCompany()}
          closeFunc={() => {
            setShow(false)
          }}
        >
          {t('DELETE_COMPANY_MESSAGE_DIALOG')}
        </DialogGeneric>
      </CardContent>
    </Card>
  )
}

export default CompanyItemCard
