import { useEffect, useMemo } from 'react'
import { Grid, Theme, useMediaQuery } from '@mui/material'
import { getStrategies } from 'src/@http/strategy'
import { getUserProfitDemo } from 'src/@http/user'
import profileStore, { ProfileStore, StrategyProfile } from 'src/zustand/profile'

import StrategyCard from './StrategyCard'

const StrategyCardContainer = () => {
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
 
  const user = profileStore((state: ProfileStore) => state.userDetail)
  const setStrategiesProfile = profileStore((state: ProfileStore) => state.setStrategiesProfile)
  const strategiesProfile = profileStore((state: ProfileStore) => state.strategiesProfile).filter(strategy => strategy.blnIsVisible === true)

  useEffect(() => {
    const init = async () => {
        const strategies =  await getStrategies()
        const profitDemo = await getUserProfitDemo()

        const saveStrategies = strategies.map(strategy => {
            const profitDemoStrategy = profitDemo.find(demo => demo.strategyId === strategy.strategyId)
            const isActive = user.currentStrategyId === strategy.strategyId && user.currentStrategyIsActive
            
            return {
                ...strategy,
                isActive,
                profitDemo: profitDemoStrategy
            }
        })
        setStrategiesProfile(saveStrategies)
    }
    
    init()
  }, [user])

  useEffect(() => {console.log('strategiesProfile', strategiesProfile)},[strategiesProfile])
  

  const sizeMd = useMemo(() => {
    if (strategiesProfile.length === 1) return 12
    if (strategiesProfile.length > 1 && strategiesProfile.length < 4) return 12 / strategiesProfile.length - 0.2
    if (strategiesProfile.length >= 4) return 3.8
  }, [strategiesProfile])

  const sizeSm = useMemo(() => {
    if (strategiesProfile.length === 1) return 12
    if (strategiesProfile.length > 1 && strategiesProfile.length < 4) return 5.8
    if (strategiesProfile.length >= 4) return 6
  }, [strategiesProfile])

  return (
    <Grid
      container
      xs={12}
      spacing={downMd && !downSm ? 2 : 0}
      rowSpacing={2}
      sx={{ display: 'flex', justifyContent: downMd ? 'center' : 'space-between', alignItems: 'end' }}
    >
      {strategiesProfile.map((strategy: StrategyProfile, key: any) => {
        let percentages: number[] = []
        if (strategy.profitDemo)
            percentages = [strategy.profitDemo.TpSlPercentageWeek,strategy.profitDemo.TpSlPercentageTwoWeek, strategy.profitDemo.TpSlPercentageMonth]

        return <Grid key={key} item xs={12} sm={sizeSm} md={sizeMd}>
            <StrategyCard key={key} strategyProfile={strategy}  isActive={strategy.isActive} percentages={percentages} isCenter={strategiesProfile.length === 1}></StrategyCard>
          </Grid>
        }
      )}
    </Grid>
  )
}

export default StrategyCardContainer
