import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar, Box, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material'
import Icon from 'src/@core/components/icon'
import { getTelegramBotSettings, TelegramBotSetting } from 'src/@http/telegram-bot'
import { ModeForm } from 'src/types'

import TelegramBotForm from './TelegramBotForm'

interface Props {
  isContent: boolean
}

const TelegramBotManager = ({ isContent }: Props) => {
  const { t } = useTranslation()
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [configuration, setConfiguration] = useState<TelegramBotSetting | null>(null)

  useEffect(() => {
    const init = async () => {
      setLoading(true)
      const response = await getTelegramBotSettings()
      setConfiguration(response)
      setLoading(false)
    }
    init()
  }, [])

  const [modeForm] = useState<ModeForm>(ModeForm.edit)

  return (
    <Card sx={{ ...(isContent && { border: 'none', boxShadow: 'none' }) }}>
      <CardContent sx={{}}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {!isContent && (
              <>
                <Avatar sx={{ width: 40, height: 40, bgcolor: 'primary.main' }} color='error' variant='rounded'>
                  <Icon icon='streamline:telegram' style={{ fontSize: 24 }} color='white' />
                </Avatar>
                <Typography sx={{ ml: 2 }} variant='h5'>
                  {t('TELEGRAM_BOT_SETTINGS')}
                </Typography>
              </>
            )}
          </Box>
        </Box>
        {configuration !== null && loading && (
          <Grid sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Grid>
        )}
        {configuration !== null && (
          <Box sx={{ mt: 4 }}>
            <TelegramBotForm
              valuesDefaultForm={configuration}
              modeForm={modeForm}
              submitLoading={submitLoading}
              closeFunc={() => {
                return
              }}
            ></TelegramBotForm>
          </Box>
        )}
      </CardContent>
    </Card>
  )
}

TelegramBotManager.defaultProps = {
  isContent: false
}

export default TelegramBotManager
