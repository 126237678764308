import { getAsync, postAsync, putAsync } from 'src/@http'
import { UserModel } from 'src/types/models/userModels'
import { CreateUserRequest, UpdateUserRequest } from 'src/types/requests/userAdminRequests'

/* Endpoints definition */
const BasePath = `users`

export const getAll = (): Promise<UserModel[]> => {
  return getAsync(BasePath)
}

export const getById = (userId: string): Promise<UserModel> => {
  return getAsync(`${BasePath}/${userId}`)
}

export const createUser = (request: CreateUserRequest): Promise<void> => {
  return postAsync(BasePath, {
    username: request.userName ?? '',
    last_name: request.lastName ?? '',
    first_name: request.firstName ?? '',
    middle_name: request.middleName ?? '',
    my_picture: '',
    country_id: request.country_id ?? '',
    preferred_languague: request.preferredLanguage ?? '',
    email_address: request.emailAddress ?? '',
    telephone: request.telephone ?? '',
    birthday: request.birthday ?? ''
  })
}

export const updateUser = (request: UpdateUserRequest): Promise<void> => {
  return putAsync(BasePath, {
    id: request.id,
    username: request.userName ?? '',
    last_name: request.lastName ?? '',
    first_name: request.firstName ?? '',
    middle_name: request.middleName ?? '',
    my_picture: '',
    country: request.country ?? '',
    preferred_languague: request.preferredLanguage ?? '',
    email_address: request.emailAddress ?? '',
    telephone: request.telephone ?? '',
    birthday: request.birthday ?? ''
  })
}
