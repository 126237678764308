import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  TextField
} from '@mui/material'
import Icon from 'src/@core/components/icon'
// import { toast } from 'react-hot-toast'
import { CustomBtn, CustomLoadingBtn } from 'src/layouts/components/shared-component/CustomBtn'
import adminStore from 'src/zustand/admin'
import * as yup from 'yup'
// import CustomAvatar from 'src/@core/components/mui/avatar'
// import { logger } from 'src/utils/Logger'

interface Props {
  positionBybit: API.Trader.PositionsBybit
}

interface CloseFormPosition {
  qty: number
}

interface ClosePosition {
  intTraderPositionByBitId: number
  vchSize: string
}

const TraderPositionItemCard = ({ positionBybit }: Props) => {
  const { t } = useTranslation()
  const [showClose, setShowClose] = useState<boolean>(false)
  const { closePositionsByTraderIdAndPositionId, loadingClosePosition } = adminStore()

  const onSubmit = (data: CloseFormPosition) => {
    const { qty } = data
    const body: ClosePosition = {
      intTraderPositionByBitId: positionBybit.traderPositionByBitId,
      vchSize: qty.toString()
    }
    closePositionsByTraderIdAndPositionId(positionBybit.traderId, positionBybit.traderPositionByBitId, body)
  }

  const schema = yup.object().shape({
    qty: yup.number().min(0).max(Number(positionBybit.size)).required()
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm<CloseFormPosition>({
    resolver: yupResolver(schema)
  })

  const ActionButtons = () => {
    const buttons = [
      {
        icon: 'gg:close-o',
        action: () => {
          setShowClose(true)
        }
      }
    ]

    return (
      <Grid container sx={{ flexDirection: 'row', justifyContent: 'center' }}>
        <ButtonGroup>
          {buttons.map(button => (
            <Grid item key={button.icon}>
              <IconButton onClick={() => button.action()} aria-label='capture screenshot' color='error'>
                <Icon icon={button.icon} fontSize={16} />
              </IconButton>
            </Grid>
          ))}
        </ButtonGroup>
      </Grid>
    )
  }

  return (
    <Card sx={{ backgroundColor: 'background.default' }}>
      <CardContent sx={{ p: { xs: 2, sm: 4 } }}>
        <Grid container sx={{ flexDirection: 'row', alignItems: 'center' }}>
          <Grid item sx={{ p: 1, pl: 1, fontSize: 15, justifyContent: 'center' }} xs={1.5}>
            <Avatar
              variant='square'
              src={`https://raw.githubusercontent.com/spothq/cryptocurrency-icons/master/32/color/${positionBybit.symbol
                .replace('USDT', '')
                .toLowerCase()}.png`}
              sx={{ width: 30, height: 30 }}
            />
          </Grid>
          <Grid item xs={2.5} sx={{ fontSize: 15 }}>
            <span> {positionBybit.symbol} </span>
          </Grid>
          <Grid item xs={7} sx={{ fontSize: 12, justifyContent: 'center' }}>
            <span>{new Date(positionBybit.createdDate).toString().slice(0, 24)}</span>
          </Grid>
          <Grid item xs={1} sx={{ alignItems: 'start', justifyContent: 'end', pl: 1, pb: 2 }}>
            {!showClose && <ActionButtons />}
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', fontSize: 13, mt: 4 }}>
          <Box sx={{ mr: 3 }}> {t('QUANTITY')}:</Box> <span>{positionBybit.size}</span>
        </Box>
        <Box sx={{ display: 'flex', fontSize: 12 }}>
          <Box sx={{ mr: 3 }}> {t('AVG_PRICE')}:</Box> <span>{positionBybit.avgPrice}</span>
        </Box>
        <Box sx={{ display: 'flex', fontSize: 12 }}>
          <Box sx={{ mr: 3 }}> {t('POSITION_VALUE')}:</Box> <span>{positionBybit.positionValue}</span>
        </Box>
        <Box sx={{ display: 'flex', fontSize: 12 }}>
          <Box sx={{ mr: 3 }}> {t('RISK_LIMIT_VALUE')}:</Box> <span>{positionBybit.riskLimitValue}</span>
        </Box>
        <Box sx={{ display: 'flex', fontSize: 12 }}>
          <Box sx={{ mr: 3 }}> {t('TAKE_PROFIT')}:</Box> <span>{positionBybit.takeProfit}</span>
        </Box>
        <Grid container sx={{ flexDirection: 'row', alignItems: 'center' }}>
          <Grid item xs={4}>
            <Box sx={{ display: 'flex', fontSize: 12 }}>
              <Box sx={{ mr: 3 }}> {t('STOP_LOSS')}:</Box> <span>{positionBybit.stopLoss}</span>
            </Box>
            <Box sx={{ display: 'flex', fontSize: 12 }}>
              <Box sx={{ mr: 3 }}> {t('TP/SL')}:</Box> <span>{positionBybit.tpslMode}</span>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box sx={{ display: 'flex', fontSize: 12 }}>
              <Chip
                label={t('USERS_FOLLOWING_POSITION') + ': ' + positionBybit.usersFollowingPosition}
                color='success'
                variant='outlined'
              />
            </Box>
          </Grid>
        </Grid>

        {showClose && (
          <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
            <Grid container sx={{ flexDirection: 'row', width: '100%', mt: 2 }}>
              <Grid item sx={{ p: 1 }} xs={12}>
                <FormControl fullWidth sx={{ my: 2 }}>
                  <Controller
                    name='qty'
                    control={control}
                    render={({ field: { ...field }, fieldState }) => (
                      <TextField
                        variant='outlined'
                        {...field}
                        placeholder={'0.00'}
                        error={!!fieldState.error}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <Button variant='text' onClick={() => setValue('qty', Number(positionBybit.size))}>
                                {t('ALL')}
                              </Button>
                            </InputAdornment>
                          )
                        }}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item sx={{ p: 1 }} xs={6}>
                <CustomLoadingBtn type='submit' fullWidth variant='contained' loading={loadingClosePosition}>
                  {t('CLOSE_POSITION')}
                </CustomLoadingBtn>
              </Grid>
              <Grid item sx={{ p: 1 }} xs={6}>
                <CustomBtn
                  fullWidth
                  variant='contained'
                  onClick={() => {
                    setShowClose(false)
                  }}
                >
                  {t('CANCEL')}
                </CustomBtn>
              </Grid>
            </Grid>
          </form>
        )}
      </CardContent>
    </Card>
  )
}

export default TraderPositionItemCard
