import toast from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { Chip } from "@mui/material"
import Icon from 'src/@core/components/icon'
import { logger } from "src/utils/Logger"

interface Props {
    text: string
}

const CopyText = ({text}: Props) => {

    const { t } = useTranslation()

    const copyAddress = async (address: string) => {
        try {
          await navigator.clipboard.writeText(address)
          toast.success(t('COPIED_SUCCESSFULLY'), { duration: 4000 })
        } catch (error) {
          logger.log('Error', error)
        }
      }


    return <Chip
    label={text}
    variant='outlined'
    deleteIcon={<Icon icon='mdi:content-copy' />}
    onClick={() => {copyAddress(text)}}
    onDelete={() => {copyAddress(text)}}
    sx={{ml:2}}
  />
}

export default CopyText