import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import QRCode from 'react-qr-code'
import { Alert, Box, Divider, TextField, Typography } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
import { getGlobalSettingForKey } from 'src/@http/global-settings'
import { getWalletAddressUser } from 'src/@http/user'
import CustomBtn from 'src/layouts/components/shared-component/CustomBtn'
import { FontWeight } from 'src/layouts/TypographyTheme'
import { logger } from 'src/utils/Logger'

import { PaymentMethodId } from './PaymentForm'

interface Props {
  cryptoMethodValueId: number
  handleCryptoMethodChange: (event: SelectChangeEvent) => void
}

interface CryptoMethods {
  id: number
  label: string
}

const CryptoDeposit = ({ cryptoMethodValueId, handleCryptoMethodChange }: Props) => {
  const cryptoMethodsInit: CryptoMethods[] = [
    {
      id: 1,
      label: 'Personal Wallet'
    },
    {
      id: 2,
      label: 'Bybit Wallet'
    }
  ]

  const { t } = useTranslation()
  const [cryptoMethods] = useState<CryptoMethods[]>(cryptoMethodsInit)

  /* Dirección a mostrar */
  const [addressWallet, setAddressWallet] = useState<string>('')

  /* Wallets cache de los metodos de pago */
  const [userWallet, setUserWallet] = useState<string>('')
  const [bybitWallet, setBybitWallet] = useState<string>('')
  const [masterWallet, setMasterWallet] = useState<string>('')

  useEffect(() => {
    getWalletAddressUser()
      .then(address => {
        setUserWallet(address)
        if (cryptoMethodValueId === PaymentMethodId.EXTERNAL) setAddressWallet(address)
      })
      .catch(err => {
        toast.error(t('PERSONAL_WALLET_NOT_FOUND'), { duration: 4000 })
      })
  }, [])

  useEffect(() => {
    getGlobalSettingForKey('MasterWalletBybit')
      .then(masterWalletId => {
        setBybitWallet(masterWalletId.value)
        if (cryptoMethodValueId === PaymentMethodId.INTERNAL) setAddressWallet(masterWalletId.value)
      })
      .catch(err => toast.error(err))
  }, [])

  useEffect(() => {
    getGlobalSettingForKey('MasterWallet')
      .then(masterWalletId => {
        setMasterWallet(masterWalletId.value)
        if (cryptoMethodValueId === PaymentMethodId.DIRECT) setAddressWallet(masterWalletId.value)
      })
      .catch(err => toast.error(err))
  }, [])

  const copyAddress = async () => {
    try {
      await navigator.clipboard.writeText(addressWallet)
      toast.success(t('COPIED_SUCCESSFULLY'), { duration: 4000 })
    } catch (error) {
      logger.log('Error', error)
    }
  }

  useEffect(() => {
    switch (cryptoMethodValueId) {
      case PaymentMethodId.INTERNAL:
        setAddressWallet(bybitWallet)
        break
      case PaymentMethodId.EXTERNAL:
        setAddressWallet(userWallet)
        break
      case PaymentMethodId.DIRECT:
        setAddressWallet(masterWallet)
        break
    }
  }, [cryptoMethodValueId])

  return (
    <>
      {/**
     * 
     * <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id='demo-simple-select-label'>{t('WALLETS')}</InputLabel>
        <Select
          id='wallets'
          required
          value={cryptoMethodValueId}
          label={t('WALLETS')}
          onChange={handleCryptoMethodChange}
          MenuProps={{
            PaperProps: {
              sx: {
                '& .MuiBox-root': {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%'
                }
              }
            }
          }}
        >
          {cryptoMethods.map((cryptoMethod: CryptoMethods, key: number) => (
            <MenuItem key={key} value={cryptoMethod.id}>
              <Typography>{cryptoMethod.label}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
     * 
     */}

      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Typography color='white' fontWeight={FontWeight.Bold} fontSize={23}>
            {t('USDT_DEPOSIT_ADDRESS')}
          </Typography>
        </Box>

        <Box>
          <Typography color='white' fontWeight={FontWeight.Normal} fontSize={18} sx={{ mb: 4 }}>
            {t('SCAN_QR_CODE_TO_DEPOSIT')}
          </Typography>

          <Box sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
            {addressWallet !== '' && (
              <Box
                sx={{
                  pt: 2,
                  pb: 1,
                  px: 2,
                  borderWidth: '3px',
                  borderColor: '#28a07c',
                  borderStyle: 'solid',
                  borderRadius: 2,
                  backgroundColor: 'white'
                }}
              >
                <QRCode value={addressWallet} size={140} />
              </Box>
            )}
          </Box>

          <Typography color='white' fontWeight={FontWeight.Normal} fontSize={14} sx={{ mt: 8, mb: 4 }}>
            {t('DISCLAIMER')}
          </Typography>
          <Divider />
          <Typography color='#6C7080' fontWeight={FontWeight.Normal} fontSize={12} sx={{ mt: 4, mb: 8 }}>
            {t('DEPOSIT_ONLY_USDT')}.
          </Typography>
          <Typography color='#6C7080' fontWeight={FontWeight.Normal} fontSize={14}>
            {t('DESTINATION')}
          </Typography>
          <Box sx={{ display: 'flex', mt: 2 }}>
            <TextField
              fullWidth
              id='address'
              label={addressWallet}
              variant='outlined'
              size='small'
              disabled
              sx={{
                '& .MuiInputBase-root': {
                  height: 50
                },
                '& .Mui-disabled': { color: 'white' }
              }}
            />
            <CustomBtn style={{ height: 50, width: '20%' }} variant='contained' sx={{}} onClick={copyAddress}>
              {t('COPY')}
            </CustomBtn>
          </Box>
          <Box sx={{ mt: 4 }}>
            {/**
             * <FormControl fullWidth sx={{ textAlign: 'left' }}>
              <InputLabel id='crypto-network'>{t('NETWORK')}</InputLabel>
              <Select
                label='Network'
                defaultValue='Tron'
                MenuProps={{
                  PaperProps: {
                    sx: {
                      '& .MuiBox-root': {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%'
                      }
                    }
                  }
                }}
              >
                <MenuItem value={'Tron'}>TRC20</MenuItem>
              </Select>
            </FormControl>
             * 
             * 
             */}
          </Box>
          <Box sx={{ mt: 2 }}>
            <Alert severity='warning'>
              {t('WARNING_MESSAGE_NETWORK_CURRENCY', { network: 'TRON', currency: 'USDT' })}
            </Alert>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default CryptoDeposit
