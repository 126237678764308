import { useEffect, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Slider,
  Switch,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
import { Theme, useTheme } from '@mui/material/styles'
import Icon from 'src/@core/components/icon'
import { getAllCryptoCurrency, postCryptoCurrency } from 'src/@http/cryptocurrency'
import { GridBot, postGribot, putGribot } from 'src/@http/gridbot'
import CustomBtn, { CustomLoadingBtn } from 'src/layouts/components/shared-component/CustomBtn'
import { ModeForm } from 'src/types'
import { logger } from 'src/utils/Logger'
import adminStore from 'src/zustand/admin'
import profileStore from 'src/zustand/profile'
import * as yup from 'yup'

interface Props {
  modeForm: ModeForm
  valuesDefaultForm?: GridBot | null
  closeFunc: () => any
  traderId: number
}

interface GridBotForm {
  intGridBotId: number
  vchSymbol: string
  dcmAmount: number
  dcmAmountMultiplier: number
  dcmPercentageStep: number
  dcmPercentageStepMultiplier: number
  dcmPercentageStepTPSL: number
  vchSide: string
  dcmActivateRSIValue: number
  intLimitFetchCandle: number
  intPeriodFetchCandle: number
  intLeverage: number
  intNumberOfOrdersLimit: number
  intNumberOfOrdersMarket: number
  dtmCreatedDate: string
  dtmUpdatedDate: string
  status: boolean
  blnManualActivate: boolean
  blnActivateRSI: boolean
  side: boolean
  intMarketOrderQuantityToIncreaseStep: number | null
  intStepIncreaseDueToLiquidation: number | null
  intMaxOrdersTP: number | null
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight: personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium
  }
}

const GridBotForm = ({ modeForm, closeFunc, valuesDefaultForm, traderId }: Props) => {
  const { t } = useTranslation()
  const { getAllByTraderGridbots, bybitSymbols } = adminStore()
  const { userDetail } = profileStore()

  let defaultValuesForm: GridBotForm = {
    intGridBotId: 0,
    vchSymbol: 'BTCUSDT',
    dcmAmount: 0,
    dcmAmountMultiplier: 1,
    dcmPercentageStep: 0.3,
    dcmPercentageStepMultiplier: 1,
    dcmPercentageStepTPSL: 0.01,
    vchSide: 'Buy',
    dcmActivateRSIValue: 35,
    intLimitFetchCandle: 50,
    intPeriodFetchCandle: 30,
    intLeverage: 1,
    intNumberOfOrdersLimit: 1,
    intNumberOfOrdersMarket: 3,
    dtmCreatedDate: '',
    dtmUpdatedDate: '',
    status: true,
    blnManualActivate: true,
    blnActivateRSI: false,
    side: false,
    intMarketOrderQuantityToIncreaseStep: null,
    intStepIncreaseDueToLiquidation: null,
    intMaxOrdersTP: null
  }

  if (modeForm === ModeForm.edit && valuesDefaultForm) {
    console.log('valuesDefaultForm', valuesDefaultForm)
    defaultValuesForm = {
      ...valuesDefaultForm,
      dcmPercentageStepTPSL: parseFloat(valuesDefaultForm.dcmPercentageStepTPSL),
      dcmActivateRSIValue: parseInt(valuesDefaultForm.dcmActivateRSIValue),
      dcmAmountMultiplier: parseFloat(valuesDefaultForm.dcmAmountMultiplier),
      dcmPercentageStepMultiplier: parseFloat(valuesDefaultForm.dcmPercentageStepMultiplier),
      dcmPercentageStep: parseFloat(valuesDefaultForm.dcmPercentageStep),
      status: valuesDefaultForm.intStatusId === 1,
      side: valuesDefaultForm.vchSide === 'Sell',
      dtmCreatedDate: valuesDefaultForm.dtmCreatedDate ? valuesDefaultForm.dtmCreatedDate.slice(0, 10) : '-',
      dtmUpdatedDate: valuesDefaultForm.dtmUpdatedDate ? valuesDefaultForm.dtmUpdatedDate.slice(0, 10) : '-',
      dcmAmount: parseFloat(valuesDefaultForm.dcmAmount),
      intMarketOrderQuantityToIncreaseStep: valuesDefaultForm.intMarketOrderQuantityToIncreaseStep,
      intStepIncreaseDueToLiquidation:
        valuesDefaultForm.intStepIncreaseDueToLiquidation !== null
          ? parseFloat(valuesDefaultForm.intStepIncreaseDueToLiquidation)
          : null
    }
  }

  const validations = {
    vchSymbol: yup.string(),
    vchSide: yup.string(),
    dcmAmount: yup.number().positive(),
    dcmAmountMultiplier: yup.number().positive(),
    dcmPercentageStep: yup.number().positive(),
    dcmPercentageStepMultiplier: yup.number().positive(),
    dcmPercentageStepTPSL: yup.number().positive(),
    dcmActivateRSIValue: yup.number().positive(),
    intLimitFetchCandle: yup.number().positive(),
    intPeriodFetchCandle: yup.number().positive(),
    intLeverage: yup.number().positive(),
    intNumberOfOrdersLimit: yup.number().positive(),
    intNumberOfOrdersMarket: yup.number().positive(),
    dtmCreatedDate: yup.string(),
    dtmUpdatedDate: yup.string(),
    blnManualActivate: yup.boolean(),
    blnActivateRSI: yup.boolean(),
    status: yup.boolean(),
    intMarketOrderQuantityToIncreaseStep: yup.number().nullable().positive().transform((_, val) => (val !== "" ? Number(val) : null)),
    intStepIncreaseDueToLiquidation: yup.number().nullable().positive().min(1).max(10).transform((_, val) => (val !== "" ? Number(val) : null)),
    intMaxOrdersTP: yup.number().nullable().positive().min(1).transform((_, val) => (val !== "" ? Number(val) : null))
  }

  if (modeForm === ModeForm.create) {
    validations.vchSymbol = validations.vchSymbol.required()
  }

  const schemaValidations = yup.object().shape(validations)

  const theme = useTheme()
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { control, handleSubmit, formState, getValues } = useForm<GridBotForm>({
    resolver: yupResolver(schemaValidations),
    defaultValues: defaultValuesForm
  })

  const statusWatch = useWatch({ control, name: 'status' })
  const activateRSIWatch = useWatch({ control, name: 'blnActivateRSI' })
  const [symbols, setSymbols] = useState<string[]>(['BTCUSDT'])
  const [showAddSymbols, setShowAddSymbols] = useState<boolean>(false)
  const [crytoLoading, setCrytoLoading] = useState<boolean>(false)
  const [inputSymbol, setInputSymbol] = useState<string>('')

  const success = async () => {
    closeFunc()
  }

  const refreshAllGridBots = async () => {
    const sleep = (ms: any) => new Promise(r => setTimeout(r, ms))
    await getAllByTraderGridbots(traderId)
    await sleep(8000)
    getAllByTraderGridbots(traderId)
  }

  const onSubmit = async (form: GridBotForm) => {
    logger.log(form)

    setIsLoading(true)
    if (modeForm === ModeForm.create) {
      try {
        await postGribot({
          vchSymbol: form.vchSymbol,
          vchSide: form.side ? 'Sell' : 'Buy',
          dcmAmount: form.dcmAmount.toString(),
          intLeverage: form.intLeverage,
          blnActivateRSI: form.blnActivateRSI,
          blnManualActivate: form.blnManualActivate,
          intTraderId: traderId,
          intCreatedUserId: parseInt(userDetail.userId),
          dcmActivateRSIValue: form.dcmActivateRSIValue.toString(),
          dcmAmountMultiplier: form.dcmAmountMultiplier.toString(),
          dcmPercentageStep: form.dcmPercentageStep.toString(),
          dcmPercentageStepMultiplier: form.dcmPercentageStepMultiplier.toString(),
          dcmPercentageStepTPSL: `${form.dcmPercentageStepTPSL}`,
          intLimitFetchCandle: form.intLimitFetchCandle,
          intNumberOfOrdersLimit: form.intNumberOfOrdersLimit,
          intNumberOfOrdersMarket: form.intNumberOfOrdersMarket,
          intPeriodFetchCandle: form.intPeriodFetchCandle,
          intStatusId: form.status ? 1 : 0,
          intMarketOrderQuantityToIncreaseStep: form.intMarketOrderQuantityToIncreaseStep,
          intStepIncreaseDueToLiquidation:
            form.intStepIncreaseDueToLiquidation != null ? form.intStepIncreaseDueToLiquidation.toString() : null,
          intMaxOrdersTP: form.intMaxOrdersTP
        })
        toast.success(t('CREATE_SUCCESS'), { duration: 4000 })
        closeFunc()
        refreshAllGridBots()
      } catch (error: any) {
        toast.error(error.message, { duration: 4000 })
      }
    } else {
      try {
        await putGribot({
          vchSymbol: form.vchSymbol,
          vchSide: form.side ? 'Sell' : 'Buy',
          dcmAmount: form.dcmAmount.toString(),
          intLeverage: form.intLeverage,
          blnActivateRSI: form.blnActivateRSI,
          blnManualActivate: form.blnManualActivate,
          intTraderId: traderId,
          dcmActivateRSIValue: form.dcmActivateRSIValue.toString(),
          dcmAmountMultiplier: form.dcmAmountMultiplier.toString(),
          dcmPercentageStep: form.dcmPercentageStep.toString(),
          dcmPercentageStepMultiplier: form.dcmPercentageStepMultiplier.toString(),
          dcmPercentageStepTPSL: `${form.dcmPercentageStepTPSL}`,
          intLimitFetchCandle: form.intLimitFetchCandle,
          intNumberOfOrdersLimit: form.intNumberOfOrdersLimit,
          intNumberOfOrdersMarket: form.intNumberOfOrdersMarket,
          intPeriodFetchCandle: form.intPeriodFetchCandle,
          intGridBotId: form.intGridBotId,
          intUpdatedUserId: parseInt(userDetail.userId),
          intStatusId: form.status ? 1 : 0,
          intMarketOrderQuantityToIncreaseStep: form.intMarketOrderQuantityToIncreaseStep,
          intStepIncreaseDueToLiquidation:
            form.intStepIncreaseDueToLiquidation != null ? form.intStepIncreaseDueToLiquidation.toString() : null,
          intMaxOrdersTP: form.intMaxOrdersTP
        })
        toast.success(t('UPDATE_SUCCESS'), { duration: 4000 })
        closeFunc()
        refreshAllGridBots()
      } catch (error: any) {
        toast.error(error.message, { duration: 4000 })
      }
    }
    setIsLoading(false)
  }

  const [categoriesIdsSelected, setCategoriesIdsSelected] = useState<string[]>([])

  const handleChange = (event: SelectChangeEvent<typeof categoriesIdsSelected>) => {
    const {
      target: { value }
    } = event
    setCategoriesIdsSelected(typeof value === 'string' ? value.split(',') : value)
  }

  const getLeverages = () => {
    const values = [1, 5, 10, 15, 20]

    return values.map((value: number) => {
      return {
        value: value,
        label: 'x' + value.toString()
      }
    })
  }

  const getOrderMarket = () => {
    const values = [1, 2, 3, 10]

    return values.map((value: number) => {
      return {
        value: value,
        label: value.toString()
      }
    })
  }

  const getPeriodFetchCandle = () => {
    return [1, 3, 5, 15, 30, 60, 120, 240, 360, 720]
  }

  const genericMarks = (min: number, max: number, digits: number) => {
    const mid = (max - min) / 2
    const midDiv = (max - min) * 0.25

    return [
      {
        value: min,
        label: min.toFixed(digits)
      },
      {
        value: midDiv,
        label: midDiv.toFixed(digits)
      },
      {
        value: mid,
        label: mid.toFixed(digits)
      },
      {
        value: max - midDiv,
        label: (max - midDiv).toFixed(digits)
      },
      {
        value: max,
        label: max.toFixed(digits)
      }
    ]
  }

  useEffect(() => {
    fecthGetAllCrytoCurrency()
  }, [])

  const addCrypto = async () => {
    setCrytoLoading(true)
    try {
      await postCryptoCurrency({ vchSymbol: inputSymbol })
      await fecthGetAllCrytoCurrency()
      setInputSymbol('')
      toast.success(t('THE_CRYPTOCURRENCY_WAS_ADDED_SUCCESSFULLY'), { duration: 4000 })
    } catch (error: any) {
      toast.error(error.message, { duration: 4000 })
    }
    setCrytoLoading(false)
  }

  const fecthGetAllCrytoCurrency = async () => {
    try {
      const response = await getAllCryptoCurrency()
      setSymbols(response.map(value => value.vchSymbol))
    } catch (error) {
      logger.log(error)
    }
  }

  return (
    <>
      <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
        {false && (
          <FormControl fullWidth sx={{ my: 2 }}>
            <InputLabel>{t('PAIR')}</InputLabel>
            <Controller
              name='vchSymbol'
              control={control}
              render={({ field: { name, value, onChange }, fieldState }) => (
                <>
                  <Select
                    error={!!fieldState.error}
                    defaultValue={value}
                    label={t('PAIR')}
                    fullWidth
                    name={name}
                    value={value}
                    onChange={onChange}
                    disabled={ModeForm.show === modeForm}
                  >
                    {symbols.map((symbol: any, key: number) => (
                      <MenuItem value={symbol.value} key={key}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Avatar
                            variant='square'
                            src={`https://raw.githubusercontent.com/spothq/cryptocurrency-icons/master/32/color/${symbol.label
                              .replace('USDT', '')
                              .toLowerCase()}.png`}
                            sx={{ width: 30, height: 30 }}
                          />
                          <Typography sx={{ ml: 2 }}>{symbol.label}</Typography>
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    <Typography color='error' sx={{ fontSize: 12 }}>
                      {fieldState.error?.message}
                    </Typography>{' '}
                  </FormHelperText>
                </>
              )}
            />
          </FormControl>
        )}

        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='vchSymbol'
            control={control}
            render={({ field: { name, value, onChange }, fieldState }) => (
              <Autocomplete
                defaultValue={value}
                disableClearable
                options={symbols}
                getOptionLabel={option => option}
                onChange={(event: any, newValue: string) => {
                  onChange(newValue)
                }}
                renderOption={(props, option) => (
                  <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <Avatar
                      src={`https://raw.githubusercontent.com/spothq/cryptocurrency-icons/master/32/color/${option
                        .replace('USDT', '')
                        .toLowerCase()}.png`}
                      sx={{ width: 30, height: 30 }}
                    >
                      <Typography sx={{ fontSize: 18 }}>{option[0]}</Typography>
                    </Avatar>
                    <Typography sx={{ ml: 2 }}>{option}</Typography>
                  </Box>
                )}
                renderInput={params => {
                  return <TextField {...params} label={t('PAIR')} />
                }}
              />
            )}
          />
        </FormControl>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={() => {
              setShowAddSymbols(!showAddSymbols)
            }}
            startIcon={<Icon icon={showAddSymbols ? 'mdi:chevron-up' : 'mdi:chevron-down'} color='#9295A6' />}
          >
            <Typography sx={{ fontSize: 10 }}>{showAddSymbols ? t('HIDE') : t('ADD_CRYPTO')}</Typography>
          </Button>
        </Box>
        {showAddSymbols && (
          <Grid container sx={{ mt: 2, mb: 2 }}>
            <Grid item xs={11}>
              <Autocomplete
                value={inputSymbol}
                disableClearable
                options={bybitSymbols}
                getOptionLabel={option => option}
                onChange={(event: any, newValue: string) => {
                  setInputSymbol(newValue)
                }}
                renderOption={(props, option) => (
                  <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <Avatar
                      src={`https://raw.githubusercontent.com/spothq/cryptocurrency-icons/master/32/color/${option
                        .replace('USDT', '')
                        .toLowerCase()}.png`}
                      sx={{ width: 30, height: 30 }}
                    >
                      <Typography sx={{ fontSize: 18 }}>{option[0]}</Typography>
                    </Avatar>
                    <Typography sx={{ ml: 2 }}>{option}</Typography>
                  </Box>
                )}
                renderInput={params => {
                  return <TextField {...params} label={t('SYMBOL')} />
                }}
              />
            </Grid>
            <Grid item xs={1} sx={{}}>
              <IconButton
                sx={{ mt: 2 }}
                disabled={crytoLoading || inputSymbol.length === 0}
                onClick={() => {
                  addCrypto()
                }}
              >
                <Icon icon='mdi:plus-box-outline' fontSize={30} />
              </IconButton>
            </Grid>
          </Grid>
        )}

        <Box sx={{ display: 'flex' }}>
          <Typography align='center' fontSize={20}>
            {t('BUY')}
          </Typography>
          <Controller
            name='side'
            control={control}
            render={({ field: { ...field } }) => (
              <Switch
                sx={{
                  '&.MuiSwitch-root .MuiSwitch-switchBase': { color: '#56CA00' },
                  '&.MuiSwitch-root .Mui-checked': {
                    color: '#DC2626'
                  },
                  '.MuiSwitch-track': {
                    backgroundColor: '#56CA00'
                  },
                  '.Mui-checked+.MuiSwitch-track': {
                    backgroundColor: '#DC2626'
                  }
                }}
                defaultChecked={field.value}
                {...field}
                disabled={ModeForm.show === modeForm}
              />
            )}
          />
          <Typography align='center' fontSize={20}>
            {t('SELL')}
          </Typography>
        </Box>

        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='dcmAmount'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                disabled={ModeForm.show === modeForm}
                {...field}
                type='number'
                label={t('AMOUNT')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>

        <FormControl fullWidth sx={{ mt: 2, mb: 4 }}>
          <Typography align='left' sx={{}}>
            {t('LEVERAGE')}
          </Typography>
          <Controller
            name='intLeverage'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <Slider
                {...field}
                defaultValue={1}
                getAriaValueText={(value: number) => {
                  return `x${value}`
                }}
                step={1}
                valueLabelDisplay='auto'
                marks={getLeverages()}
                min={1}
                max={20}
                disabled={ModeForm.show === modeForm}
              />
            )}
          />
        </FormControl>

        <FormControl fullWidth sx={{ my: 2 }}>
          <Typography align='left' sx={{}}>
            {t('AMOUNT_MULTIPLIER')}
          </Typography>
          <Controller
            name='dcmAmountMultiplier'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <Slider
                {...field}
                step={0.01}
                valueLabelDisplay='auto'
                min={1}
                max={1.9}
                marks={genericMarks(1, 1.9, 2)}
                disabled={ModeForm.show === modeForm}
              />
            )}
          />
        </FormControl>

        <FormControl fullWidth sx={{ my: 2 }}>
          <Typography align='left' sx={{}}>
            {t('PERCENTAGE_STEP')}
          </Typography>
          <Controller
            name='dcmPercentageStep'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <Slider
                {...field}
                step={0.1}
                valueLabelDisplay='auto'
                min={0.1}
                max={5}
                marks={genericMarks(0.1, 5, 1)}
                disabled={ModeForm.show === modeForm}
              />
            )}
          />
        </FormControl>

        {false && (
          <FormControl fullWidth sx={{ my: 2 }}>
            <Typography align='left' sx={{}}>
              {t('PERCENTAGE_STEP_MULTIPLIER')}
            </Typography>
            <Controller
              name='dcmPercentageStepMultiplier'
              control={control}
              render={({ field: { ...field }, fieldState }) => (
                <Slider
                  {...field}
                  step={0.01}
                  valueLabelDisplay='auto'
                  min={1}
                  max={1.9}
                  marks={genericMarks(1, 1.9, 2)}
                  disabled={ModeForm.show === modeForm}
                />
              )}
            />
          </FormControl>
        )}

        <FormControl fullWidth sx={{ my: 2 }}>
          <Typography align='left' sx={{}}>
            {t('PERCENTAGE_STEP_TP')}
          </Typography>
          <Controller
            name='dcmPercentageStepTPSL'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <Slider
                {...field}
                step={0.01}
                valueLabelDisplay='auto'
                min={0.01}
                max={0.99}
                marks={genericMarks(0.01, 0.99, 2)}
                disabled={ModeForm.show === modeForm}
              />
            )}
          />
        </FormControl>

        {false && (
          <FormControl fullWidth sx={{ my: 2 }}>
            <Typography align='left' sx={{}}>
              {t('LIMIT_FETCH_CANDLE')}
            </Typography>
            <Controller
              name='intLimitFetchCandle'
              control={control}
              render={({ field: { ...field }, fieldState }) => (
                <Slider
                  {...field}
                  step={1}
                  valueLabelDisplay='auto'
                  min={1}
                  max={1000}
                  marks={genericMarks(1, 1000, 0)}
                  disabled={ModeForm.show === modeForm}
                />
              )}
            />
          </FormControl>
        )}

        <FormControl fullWidth sx={{ my: 2 }}>
          <Typography align='left' sx={{}}>
            {t('NUMBER_OF_ORDERS_LIMIT')}
          </Typography>
          <Controller
            name='intNumberOfOrdersLimit'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <Slider
                {...field}
                step={1}
                valueLabelDisplay='auto'
                min={1}
                max={100}
                marks={genericMarks(1, 100, 0)}
                disabled={ModeForm.show === modeForm}
              />
            )}
          />
        </FormControl>

        <FormControl fullWidth sx={{ my: 2 }}>
          <Typography align='left' sx={{}}>
            {t('NUMBER_OF_ORDERS_MARKET')}
          </Typography>
          <Controller
            name='intNumberOfOrdersMarket'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <Slider
                {...field}
                step={null}
                valueLabelDisplay='auto'
                min={1}
                max={10}
                marks={getOrderMarket()}
                disabled={ModeForm.show === modeForm}
              />
            )}
          />
        </FormControl>

        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='intPeriodFetchCandle'
            control={control}
            render={({ field, fieldState }: any) => (
              <FormControl error={!!fieldState.error}>
                <InputLabel>{t('PERIOD_FETCH_CANDLE')}</InputLabel>
                <Select
                  {...field}
                  error={!!fieldState.error}
                  value={field.value}
                  input={<OutlinedInput label={t('PERIOD_FETCH_CANDLE')} />}
                >
                  {getPeriodFetchCandle().map((item: number, key: number) => (
                    <MenuItem key={key} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  <Typography color='error' sx={{ fontSize: 12 }}>
                    {fieldState.error?.message}
                  </Typography>{' '}
                </FormHelperText>
              </FormControl>
            )}
          />
        </FormControl>

        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='intStepIncreaseDueToLiquidation'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                disabled={ModeForm.show === modeForm}
                {...field}
                type='number'
                label={t('STEP_INCREASE_DUE_TO_LIQUIDATION')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>

        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='intMarketOrderQuantityToIncreaseStep'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                disabled={ModeForm.show === modeForm}
                {...field}
                type='number'
                label={t('MARKET_ORDER_QUANTITY_TO_INCREASE_STEP')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>

        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='intMaxOrdersTP'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                disabled={ModeForm.show === modeForm}
                {...field}
                type='number'
                label={t('MAX_ORDERS_TP')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>

        {ModeForm.create !== modeForm && (
          <Grid container sx={{ my: 2 }} spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Controller
                  name='dtmCreatedDate'
                  control={control}
                  render={({ field: { ...field }, fieldState }) => (
                    <TextField
                      variant='outlined'
                      disabled={true}
                      {...field}
                      label={t('CREATED_DATE')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Controller
                  name='dtmUpdatedDate'
                  control={control}
                  render={({ field: { ...field }, fieldState }) => (
                    <TextField
                      variant='outlined'
                      disabled={true}
                      {...field}
                      label={t('UPDATE_DATE')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        )}
        <Box sx={{ border: 1, borderRadius: '16px', borderColor: '#234848' }}>
          <Box sx={{ display: 'flex', my: 1, mx: 2 }}>
            <Typography align='center' fontSize={22}>
              {t('ACTIVATE_RSI')}
            </Typography>
            <Controller
              name='blnActivateRSI'
              control={control}
              render={({ field: { ...field } }) => (
                <Switch
                  defaultChecked={defaultValuesForm.blnActivateRSI}
                  {...field}
                  disabled={ModeForm.show === modeForm}
                />
              )}
            />
          </Box>
          <FormControl fullWidth sx={{ my: 2, mx: 2 }}>
            <Typography align='left' sx={{}}>
              {t('ACTIVATE_RSI_VALUE')}
            </Typography>
            <Controller
              name='dcmActivateRSIValue'
              control={control}
              render={({ field: { ...field }, fieldState }) => (
                <Slider
                  {...field}
                  sx={{ width: 480 }}
                  step={1}
                  valueLabelDisplay='auto'
                  min={1}
                  max={100}
                  marks={genericMarks(1, 100, 0)}
                  disabled={ModeForm.show === modeForm || !activateRSIWatch}
                />
              )}
            />
          </FormControl>
        </Box>

        <Box sx={{ display: 'flex', mt: 2 }}>
          <Typography align='center' fontSize={22}>
            {t('MANUAL_ACTIVATE')}
          </Typography>
          <Controller
            name='blnManualActivate'
            control={control}
            render={({ field: { ...field } }) => (
              <Switch
                defaultChecked={defaultValuesForm.blnManualActivate}
                {...field}
                disabled={ModeForm.show === modeForm}
              />
            )}
          />
        </Box>

        {true && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center' }}>
            <Box>
              <Avatar sx={{ bgcolor: 'primary.main', width: 50, height: 50 }} variant='rounded'>
                <Icon icon={statusWatch ? 'mdi:robot-outline' : 'mdi:robot-off-outline'} color='white' fontSize={30} />
              </Avatar>
            </Box>
            <Box>
              <Box>
                <Typography align='center' fontSize={22}>
                  {t('STATUS')}
                </Typography>
                <Typography align='center' fontSize={12} sx={{ fontStyle: 'italic' }}>
                  ({t('OPEN_OPERATIONS_ARE_NOT_CLOSED')})
                </Typography>
              </Box>
            </Box>
            <Box>
              <Controller
                name='status'
                control={control}
                render={({ field: { ...field } }) => (
                  <Switch defaultChecked={defaultValuesForm.status} {...field} disabled={ModeForm.show === modeForm} />
                )}
              />
            </Box>
          </Box>
        )}

        <Grid container>
          <Grid sx={{ p: 1 }} item xs={6}>
            <CustomLoadingBtn
              loading={isLoading}
              fullWidth
              size='large'
              type='submit'
              variant='contained'
              sx={{ mt: 3 }}
              disabled={ModeForm.edit === modeForm ? !formState.isDirty : false}
            >
              {t('SAVE')}
            </CustomLoadingBtn>
          </Grid>
          <Grid sx={{ p: 1 }} item xs={6}>
            <CustomBtn fullWidth size='large' variant='contained' onClick={() => closeFunc()} sx={{ mt: 3 }}>
              {t('CANCEL')}
            </CustomBtn>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

GridBotForm.defaultProps = {
  editForm: ModeForm.create
}

export default GridBotForm
