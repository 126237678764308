// ** React imports

// ** Icon Imports
// import Icon from 'src/@core/components/icon'
// import Icon from 'src/@core/components/icon'

// ** MUI Imports
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import adminStore from 'src/zustand/admin'

import AccountTypeManager from '../components/admin/admin-account-type/AccountTypeManager'
import CommissionRankManager from '../components/admin/admin-commission-rank/CommissionRankManager'
import TraderManager from '../components/admin/admin-trader/TraderManager'
import { AdminContext } from '../components/admin/adminContext'
import CustomerManagementCard from '../components/admin/customer-manager/CustomerManagementCard'
import FinanceManager from '../components/admin/finance/FinanceManager'
import GlobalManager from '../components/admin/global-settings/GlobalManager'
import MarketingManager from '../components/admin/marketing/MarketingManager'
import ReportsManager from '../components/admin/reports/ReportsManager'
import GridBotManager from '../components/admin/trader/admin-gridbot/GridBotManager'
import ManualTraderManager from '../components/admin/trader/manual-trader/ManualTraderManager'
import UserProfileAdmin from '../components/profile/UserProfileAdmin'


export interface Menu {
  label: string
  path: string
  icon: string
}

function useQuery() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}
const Admin = () => {
  const { t } = useTranslation()
  const menus: Menu[] = [
    {
      label: t('TRADER'),
      path: 'trader-manager',
      icon: 'mdi:account-multiple'
    },
    {
      label: t('CUSTOMER'),
      path: 'customer-manager',
      icon: 'mdi:account-group'
    },
    {
      label: t('REPORTS'),
      path: 'reports-manager',
      icon: 'mdi:list-box-outline'
    },
    {
      label: t('FINANCE'),
      path: 'finance-manager',
      icon: 'mdi:finance'
    },
    {
      label: t('COMMUNICATION_AND_MARKETING'),
      path: 'marketing-manager',
      icon: 'mdi:newspaper-variant'
    },
    {
      label: t('GLOBAL_SETTINGS'),
      path: 'global-settings-manager',
      icon: 'material-symbols:settings-outline'
    },
    /*
    {
      label: t('SECURITY'),
      path: 'security',
      icon: 'mdi:lock'
    },
    */
  ]

  
    /*
    {
      label: t('ACCOUNTS_TYPES'),
      path: 'accounts-types-manager',
      icon: 'mdi:account-cog-outline'
    },
    {
      label: t('COMMISSION_RANK'),
      path: 'commission-rank-manager',
      icon: 'mdi:cash-multiple'
    }
    */
  const query = useQuery()

  const menu: Menu = query.get('menu')
    ? menus.find((item: Menu) => item.path === query.get('menu')) || menus[0]
    : {
      path: 'trader-manager',
      icon:'',
      label:''
    }

  const [path, setPath] = useState<string>(menu.path.toLowerCase())
  const [params, setParams] = useState<any>({})
  const { getAllSubscriptions } = adminStore()

  useEffect(()=> {
    getAllSubscriptions()
  }, [])

  return (
    <AdminContext.Provider value={{path, setPath, params, setParams}}>
       <Grid container spacing={10}>
      <Grid item sx={{}} xs={12} lg={4}>
        <UserProfileAdmin
          menus={menus}
          isAdmin={true}
        />
      </Grid>
      <Grid item sx={{}} xs={12} lg={8}>
      {path === 'trader-manager' && <TraderManager />}
      {path === 'gridbot-manager' && < GridBotManager/>}
      {path === 'customer-manager' && <CustomerManagementCard />}
      {path === 'reports-manager' && <ReportsManager />}
      {path === 'finance-manager' && <FinanceManager />}
      {path === 'marketing-manager' && <MarketingManager />}
      {path === 'global-settings-manager' && <GlobalManager />}
      {path === 'manual-trader-manager' && <ManualTraderManager />}
    
        {path === 'accounts-types-manager' && <AccountTypeManager />}
        {path === 'commission-rank-manager' && <CommissionRankManager />}
        
      </Grid>
    </Grid>
  </AdminContext.Provider> 
   
  )
}

export default Admin
//export type { Menu }
