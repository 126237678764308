import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
// import { Link} from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Avatar,
  Box,
  FormControl,
  // FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { postCompany, updateCompany } from 'src/@http/company'
// import { styled } from '@mui/material/styles'
import { CustomBtn, CustomLoadingBtn } from 'src/layouts/components/shared-component/CustomBtn'
import { ModeForm } from 'src/types'
import { Company } from 'src/types/models/companyModel'
// import { PercentageTraderPost, Trader } from 'src/types/models/traderModel'
import { logger } from 'src/utils/Logger'
import * as yup from 'yup'

interface Props {
  company?: Company | null
  modeForm: ModeForm
  submitFinishFunction: () => any
  closeFunc: () => any
}
interface FormDataRegisterCompany {
  name: string
  companyKey: string
  refererKey: string
}

const CompanyRegisterForm = (props: Props) => {
  const { closeFunc, submitFinishFunction, modeForm, company } = props

  const defaultValuesForm: FormDataRegisterCompany = {
    name: company ? company.companyName : '',
    refererKey: company ? company.refererKey : '',
    companyKey: company ? company.companyKey : ''
  }

  const initStatus = company ? company.statusId === 1 : false

  const schema = yup.object().shape({
    name: yup.string().required(),
    refererKey: yup.string().min(5).required(),
    companyKey: yup.string().min(5).required()
  })
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { t } = useTranslation()
  const theme = useTheme()
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const [checked, setChecked] = useState<boolean>(initStatus)

  const onSubmit = async (data: FormDataRegisterCompany) => {
    setIsLoading(true)
    const { name, refererKey, companyKey } = data
    const body = {
      vchCompanyName: name,
      vchCompanyKey: companyKey,
      vchRefererKey: refererKey
    }
    try {
      const tradeResponse = await postCompany(body)
      logger.log(tradeResponse)

      toast.success(t('CREATE_COMPANY_SUCCESS_MESSAGE'))
      submitFinishFunction()
    } catch (error: any) {
      toast.error(error)
    }
    setIsLoading(false)
  }

  const onSubmitEdit = async (data: FormDataRegisterCompany) => {
    if (!company) return logger.error('You dont have trader')
    setIsLoading(true)
    const { name, refererKey, companyKey } = data
    const body = {
      intCompanyId: company.companyId,
      vchCompanyName: name,
      vchCompanyKey: companyKey,
      vchRefererKey: refererKey,
      intStatusId: checked ? 1 : 0
    }
    try {
      const responseUpdate = await updateCompany(body)
      logger.log('Response update', responseUpdate)
      toast.success(t('UPDATE_COMPANY_SUCCESS_MESSAGE'))
      submitFinishFunction()
    } catch (error: any) {
      toast.error(error.message)
    }
    setIsLoading(false)
  }

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FormDataRegisterCompany>({
    resolver: yupResolver(schema),
    defaultValues: defaultValuesForm
  })

  return (
    <form noValidate autoComplete='off' onSubmit={company ? handleSubmit(onSubmitEdit) : handleSubmit(onSubmit)}>
      <FormControl fullWidth sx={{ my: 2 }}>
        <Controller
          name='name'
          control={control}
          render={({ field: { ...field }, fieldState }) => (
            <TextField
              variant='outlined'
              disabled={ModeForm.show === modeForm}
              {...field}
              label={t('COMPANY_NAME')}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
            />
          )}
        />
      </FormControl>
      <FormControl fullWidth sx={{ my: 2 }}>
        <Controller
          name='companyKey'
          control={control}
          render={({ field: { ...field }, fieldState }) => (
            <TextField
              variant='outlined'
              disabled={ModeForm.show === modeForm}
              {...field}
              label={t('COMPANY_KEY')}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
            />
          )}
        />
      </FormControl>
      <FormControl fullWidth sx={{ my: 2 }}>
        <Controller
          name='refererKey'
          control={control}
          render={({ field: { ...field }, fieldState }) => (
            <TextField
              variant='outlined'
              disabled={ModeForm.show === modeForm}
              {...field}
              label={t('REFER_KEY')}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
            />
          )}
        />
      </FormControl>

      <Grid container alignItems='center'>
        {!lessThanSmall && (
          <Grid item xs={1} sm={1.5}>
            <Avatar sx={{ bgcolor: '#FCA5A5', width: 50, height: 50 }} variant='rounded' />
          </Grid>
        )}

        <Grid item xs={10} sm={8.5}>
          <Box>
            <Typography align='center' fontSize={22}>
              {t('STATUS')}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Switch checked={checked} onChange={() => setChecked(!checked)} color='primary' />
          {/* <FormControlLabel
              sx={{ mr: 4 }}
              control={<Switch checked={checked} onChange={() => setChecked(!checked)} color='success' />}
            /> */}
        </Grid>
      </Grid>

      <Grid container>
        <Grid sx={{ p: 1 }} item xs={6}>
          <CustomLoadingBtn loading={isLoading} fullWidth size='large' type='submit' variant='contained' sx={{ mt: 3 }}>
            {company ? t('EDIT') : t('PROCEED')}
          </CustomLoadingBtn>
        </Grid>
        <Grid sx={{ p: 1 }} item xs={6}>
          <CustomBtn fullWidth size='large' variant='contained' onClick={() => closeFunc()} sx={{ mt: 3 }}>
            {t('CANCEL')}
          </CustomBtn>
        </Grid>
      </Grid>
    </form>
  )
}

export default CompanyRegisterForm
