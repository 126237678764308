import { deleteAsync, getAsync, postAsync, putAsync } from 'src/@http'

export interface GridBot {
  intGridBotId: number
  intTraderId: number
  vchSymbol: string
  dcmAmount: string
  dcmAmountMultiplier: string
  dcmPercentageStep: string
  dcmPercentageStepMultiplier: string
  dcmPercentageStepTPSL: string
  vchSide: string
  blnManualActivate: boolean
  blnActivateRSI: boolean
  dcmActivateRSIValue: string
  intLimitFetchCandle: number
  intPeriodFetchCandle: number
  intLeverage: number
  intNumberOfOrdersLimit: number
  intNumberOfOrdersMarket: number
  dtmCreatedDate: string
  dtmUpdatedDate: string
  intCreatedUserId: number
  intUpdatedUserId: number
  intStatusId: number
  blnActiveOperations: boolean
  intMarketOrderQuantityToIncreaseStep: number | null
  intStepIncreaseDueToLiquidation: string | null
  intMaxOrdersTP: number | null
}


interface PostGridBot {
  intTraderId: number
  intCreatedUserId: number
  vchSymbol: string
  dcmAmount: string
  dcmAmountMultiplier: string
  dcmPercentageStep: string
  dcmPercentageStepMultiplier: string
  dcmPercentageStepTPSL: string
  vchSide: string
  blnManualActivate: boolean
  blnActivateRSI: boolean
  dcmActivateRSIValue: string
  intLimitFetchCandle: number
  intPeriodFetchCandle: number
  intLeverage: number
  intNumberOfOrdersLimit: number
  intNumberOfOrdersMarket: number
  intStatusId: number
  intMarketOrderQuantityToIncreaseStep: number | null
  intStepIncreaseDueToLiquidation: string | null
  intMaxOrdersTP: number | null
}

interface PutGridBot {
  intGridBotId: number
  intTraderId?: number
  intUpdatedUserId: number
  vchSymbol?: string
  dcmAmount?: string
  dcmAmountMultiplier?: string
  dcmPercentageStep?: string
  dcmPercentageStepMultiplier?: string
  dcmPercentageStepTPSL?: string
  vchSide?: string
  blnManualActivate?: boolean
  blnActivateRSI?: boolean
  dcmActivateRSIValue?: string
  intLimitFetchCandle?: number
  intPeriodFetchCandle?: number
  intLeverage?: number
  intNumberOfOrdersLimit?: number
  intNumberOfOrdersMarket?: number
  intStatusId?: number
  intMarketOrderQuantityToIncreaseStep?: number | null
  intStepIncreaseDueToLiquidation?: string | null
  intMaxOrdersTP?: number | null
}

interface StopAllByTraiderPost {
  intTraderId: number
  blnStopOperations: boolean
}

const rootPath = '/gridBot'

export const getAllGridbots = (): Promise<GridBot[]> => {
  return getAsync(rootPath)
}

export const getAllByTraderGridbots = (traderId: number): Promise<GridBot[]> => {
  return getAsync(rootPath + '/getAllByTrader/' + traderId)
}

export const postGribot = (body: PostGridBot): Promise<GridBot> => {
  return postAsync(rootPath, body)
}

export const putGribot = (body: PutGridBot): Promise<GridBot> => {
  return putAsync(rootPath, body)
}

export const deleteGribot = (gridbitId: number): Promise<any> => {
  return deleteAsync(rootPath + '/' + gridbitId)
}

export const stopAllByTraider = (body: StopAllByTraiderPost): Promise<GridBot> => {
  return postAsync(rootPath + '/stopAllByTraider', body)
}

export const cancelAllOperationById = (gridbitId: number): Promise<GridBot> => {
  return postAsync(rootPath + '/cancel-all-operations/' + gridbitId, {})
}
