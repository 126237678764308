import { Navigate } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import profileStore, { ProfileStore } from 'src/zustand/profile'

interface Props {
  children: JSX.Element
}

interface Token {
  id: number
  email: string
  roles: Array<string>
}

const AdminRoute = ({ children }: Props) => {
  const token = profileStore((state:ProfileStore) => state.token)
  if (token !== '') {
    const dataToken: Token = jwtDecode(token)
  }
  const isLogged = token !== ''

  // || !dataToken.roles.includes('ADMIN')
  if (!isLogged) return <Navigate to='/' />

  return children
}

export default AdminRoute
