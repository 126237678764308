import { Navigate } from 'react-router-dom'
import profileStore, { ProfileStore } from 'src/zustand/profile'

interface Props {
  children: JSX.Element
}

const PrivateRoute = ({ children }: Props) => {
  const token: string = profileStore((state:ProfileStore) => state.token)
  const isLogged = token !== ''

  if (!isLogged) return <Navigate to='/' />

  return children
}

export default PrivateRoute
