import { Avatar, Box, Typography } from '@mui/material'

interface Props {
  percentage: number
}

const TotalProfits = ({ percentage }: Props) => {
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 7, position: 'relative' }}>
        {true && (
          <Box
            sx={{
              width: 105,
              height: 105,
              border: '15px solid #0A1F29',
              borderRadius: '50%',
              boxSizing: 'border-box',
              position: 'absolute',
              top: -23
            }}
          ></Box>
        )}

        <Box
          sx={{
            width: 105,
            height: 105,
            borderRadius: '50%',
            boxSizing: 'border-box',
            position: 'absolute',
            top: -23,
            backgroundImage: `conic-gradient(black ${percentage.toFixed(2)}%, transparent ${percentage.toFixed(2)}%)`
          }}
        ></Box>

        <Box
          sx={{
            position: 'absolute',
            top: 45
          }}
        >
          <Typography
            sx={{
              fontSize: 14
            }}
          >
            {percentage.toFixed(0)}%
          </Typography>
        </Box>

        <Avatar sx={{ width: 50, height: 50 }} src='/images/icons/Asset 4.png' variant='square' />
      </Box>
    </>
  )
}

export default TotalProfits
