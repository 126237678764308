// ** React Imports
import { MouseEvent, useRef, useState } from 'react'
import { toast } from 'react-hot-toast'
// ** i18n Imports
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
// ** MUI Imports
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
// ** Icon Imports
import Icon from 'src/@core/components/icon'
import CustomAvatar from 'src/@core/components/mui/avatar'
// ** Custom Components Imports
import CustomChip from 'src/@core/components/mui/chip'
// ** Utils Import
import { getInitials } from 'src/@core/utils/get-initials'
import { deleteUser } from 'src/@http/user'
import type { DialogExpose } from 'src/pages/components/generic/DialogGeneric'
import { DialogGeneric } from 'src/pages/components/generic/DialogGeneric'
// ** Custom Table Components Imports
import type { UserModel } from 'src/types/models/userModels'

interface CellType {
  row: UserModel
}

const userStatusObj: UserStatusType = {
  active: 'success',
  pending: 'warning',
  inactive: 'secondary'
}

const LinkStyled = styled(Link)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '1rem',
  cursor: 'pointer',
  textDecoration: 'none',
  color: theme.palette.text.secondary,
  '&:hover': {
    color: theme.palette.primary.main
  }
}))

const RowOptions = ({ id }: { id: string }) => {
  // ** State
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const dialogRef = useRef<DialogExpose>(null)
  const { t } = useTranslation()
  const navigate = useNavigate()

  const rowOptionsOpen = Boolean(anchorEl)

  const handleRowOptionsClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleRowOptionsClose = () => {
    setAnchorEl(null)
  }

  const handleDelete = () => {
    handleRowOptionsClose()
    dialogRef.current?.open().then(async confirm => {
      if (confirm)
        try {
          await deleteUser(id)
          toast.success(t('USER_DELETED_SUCCESS') + '! 👌', { duration: 4000 })
          window.location.reload()
        } catch (err: any) {
          toast.error(err.message + '🙄', { duration: 4000 })
        }
    })
  }

  return (
    <>
      <IconButton size='small' onClick={handleRowOptionsClick}>
        <Icon icon='mdi:dots-vertical' />
      </IconButton>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={rowOptionsOpen}
        onClose={handleRowOptionsClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        PaperProps={{ style: { minWidth: '8rem' } }}
      >
        <MenuItem sx={{ '& svg': { mr: 2 } }} onClick={() => navigate('/admin-users/' + id + '?view=true')}>
          <Icon icon='mdi:eye-outline' fontSize={20} />
          {t('VIEW')}
        </MenuItem>
        <MenuItem component={Link} onClick={handleRowOptionsClose} sx={{ '& svg': { mr: 2 } }} to={`${id}`}>
          <Icon icon='mdi:pencil-outline' fontSize={20} />
          {t('EDIT')}
        </MenuItem>
        <MenuItem onClick={handleDelete} sx={{ '& svg': { mr: 2 } }}>
          <Icon icon='mdi:delete-outline' fontSize={20} />
          {t('DELETE')}
        </MenuItem>
      </Menu>
      <DialogGeneric title='DELETE_USER' ref={dialogRef} confirmText='CONFIRM'>
        {t('CONFIRM_DELETE_USER')}
      </DialogGeneric>
    </>
  )
}

const renderClient = (row: UserModel) => {
  return (
    <CustomAvatar skin='light' color={'primary'} sx={{ mr: 3, width: 30, height: 30, fontSize: '.875rem' }}>
      {getInitials(row.first_name + ' ' + row.last_name)}
    </CustomAvatar>
  )
}

const columns = [
  {
    flex: 0.2,
    minWidth: 230,
    field: 'fullName',
    headerName: 'User',
    renderCell: ({ row }: CellType) => {
      const { first_name, last_name } = row

      const fullName = `${first_name} ${last_name}`

      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {renderClient(row)}
          <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
            <LinkStyled to='/apps/user/view/overview/'>{fullName}</LinkStyled>
            <Typography noWrap variant='caption'>
              {row.email}
            </Typography>
          </Box>
        </Box>
      )
    }
  },
  {
    flex: 0.2,
    minWidth: 250,
    field: 'email',
    headerName: 'Email',
    renderCell: ({ row }: CellType) => {
      return (
        <Typography noWrap variant='body2'>
          {row.email}
        </Typography>
      )
    }
  },
  {
    flex: 0.1,
    minWidth: 110,
    field: 'role',
    headerName: 'User Role',
    renderCell: ({ row }: CellType) => {
      return <Chip label={row.role.name} />
    }
  },
  {
    flex: 0.1,
    minWidth: 110,
    field: 'status',
    headerName: 'Status',
    renderCell: ({ row }: CellType) => {
      return (
        <CustomChip
          skin='light'
          size='small'
          label={'active'}
          color={userStatusObj['active']}
          sx={{ textTransform: 'capitalize' }}
        />
      )
    }
  },
  {
    flex: 0.1,
    minWidth: 90,
    sortable: false,
    field: 'actions',
    headerName: 'Actions',
    renderCell: ({ row }: CellType) => <RowOptions id={row._id} />
  }
]

export default columns
