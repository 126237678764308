// ** React imports

// ** Icon Imports
// import Icon from 'src/@core/components/icon'
// import Icon from 'src/@core/components/icon'

// ** MUI Imports
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import ApiMangement from 'src/pages/components/profile/ApiManagement'
import SecurityCard from 'src/pages/components/profile/SecurityCard'

import NotificationPreference from '../components/profile/NotificationPreference'
import UserProfile from '../components/profile/UserProfile'

interface Menu {
  label: string
  path: string
  icon: string
}

function useQuery() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

const Profile = () => {
  const { t } = useTranslation()
  const menus: Menu[] = [
    // {
    //   label: t('MY_PROFILE'),
    //   path: 'my_profile',
    //   icon: 'mdi:account-outline'
    // },
    {
      label: t('SECURITY'),
      path: 'security',
      icon: 'mdi:lock'
    },
    // {
    //  label: t('NOTIFICATION_PREFERENCES'),
    //  path: 'notificantion_preferences',
    //  icon: 'mdi:bell-outline'
    // },
    // {
    //   label: t('CURRENCY_PREFERENCES'),
    //   path: 'currency_preferences',
    //   icon: 'mdi:currency-usd'
    // },
    // {
    //   label: t('KYC_VERIFICATION'),
    //   path: 'kyc_verification',
    //   icon: 'mdi:file-document-outline'
    // },
    // {
    //   label: t('PAYMENT_OPTIONS'),
    //   path: 'payment_option',
    //   icon: 'mdi:wallet-outline'
    // },
    {
      label: t('API_MANAGMENT'),
      path: 'api_managment',
      icon: 'mdi:api'
    }
  ]
  const query = useQuery()
  const menu: Menu = query.get('menu')
    ? menus.find((item: Menu) => item.path === query.get('menu')) || menus[0]
    : menus[0]
  const [menuSeleted, setMenuSelected] = useState<Menu>(menu)

  return (
    <Grid container spacing={10}>
      <Grid item sx={{}} xs={12} lg={4}>
        <UserProfile
          menuSelected={menuSeleted}
          menus={menus}
          selectMenu={(menu: Menu) => {
            setMenuSelected(menu)
          }}
        />
      </Grid>
      <Grid item sx={{}} xs={12} lg={8}>
        {menuSeleted.path === 'security' && <SecurityCard />}
        {menuSeleted.path === 'api_managment' && <ApiMangement />}
        {menuSeleted.path === 'notificantion_preferences' && <NotificationPreference />}
      </Grid>
    </Grid>
  )
}

export default Profile
export type { Menu }
