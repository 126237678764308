import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Divider, Grid, Typography } from '@mui/material'
import { monthDiff } from 'src/utils/Dates'
import profileStore, { ProfileStore } from 'src/zustand/profile'

import ReferredDirectActives from './ReferredDirectActives'
import TotalProfits from './TotalProfits'

interface EarningDates {
  currentDayNumber: string
  currentYear: string
  subscriptionMonths: string
  currentMonthsDiff: string
}

interface Rank {
  activeUsers: number
  minInWallet: number
  nextRankName: string
  requirements: string
}

interface Props {
  rank: Rank
}

const RankInfoDetails = ({ rank }: Props) => {
  const { t, i18n } = useTranslation()
  const userDetail = profileStore((state: ProfileStore) => state.userDetail)
  const infoReferredUser = profileStore((state: ProfileStore) => state.infoReferredUser)
  const styles = {
    title: {
      fontSize: 20,
      fontWeight: 600
    },
    subTitle: {
      fontSize: 30,
      fontWeight: 600
    },
    dates: {
      fontWeight: 700,
      color: 'black'
    }
  }

  const earningDates: EarningDates = useMemo(() => {
    const now = new Date()
    let subscriptionMonths = '-'
    let currentMonthsDiff = '-'

    if (userDetail.subscriptionActivedDate && userDetail.endDateSubscription) {
      subscriptionMonths = monthDiff(
        new Date(userDetail.subscriptionActivedDate),
        new Date(userDetail.endDateSubscription)
      ).toString()
      currentMonthsDiff = monthDiff(new Date(userDetail.subscriptionActivedDate), now).toString()
      currentMonthsDiff = currentMonthsDiff === '0' ? '1' : currentMonthsDiff
    }

    return {
      currentDayNumber: now.getDate().toString(),
      currentYear: now.getFullYear().toString(),
      subscriptionMonths,
      currentMonthsDiff,
      monthName: now.toLocaleString(i18n.language === 'es' ? 'es-ES' : 'en-GB', { month: 'short' }).toUpperCase()
    }
  }, [userDetail])

  const percentage: number = useMemo(() => {
    let percentage = 0

    if (userDetail.subscriptionActivedDate && userDetail.endDateSubscription) {
      percentage = (Number(earningDates.currentMonthsDiff) * 100) / Number(earningDates.subscriptionMonths)
    }

    return percentage
  }, [earningDates])

  const referredUserDirect = useMemo(() => {
    const axes = rank.requirements.split(',')
    const text = {
      textTop: '',
      textLeft: '',
      textRight: ''
    }
    if (axes.length < 4) {
      text.textTop = axes[0]
      text.textRight = axes[1]
      text.textLeft = axes[2]
    } 

    return text
  }, [rank])

  return (
    <Box sx={{ pb: 8 }}>
      {false && (
        <Typography
          sx={{
            mt: 5,
            color: 'black',
            px: 4,
            textAlign: 'right',
            textDecoration: 'underline',
            cursor: 'pointer'
          }}
        >
          {t('VIEW_RANGE_DETAILS')}...
        </Typography>
      )}

      <Box display='flex' alignItems='center' sx={{ mt: 14 }}>
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item xs={2.9} textAlign='center'>
            <Box sx={{ mt: 14 }}>
              <TotalProfits percentage={percentage}></TotalProfits>
            </Box>

            {false && <Typography sx={{ ...styles.title }}>{t('TOTAL_EARNINGS')}:</Typography>}
            <Typography sx={{ ...styles.title, fontSize: 16, pt: 4 }}>{t('TOTAL_GAINS_FOR_SUBSCRIPTIONS')}:</Typography>

            <Typography sx={{ ...styles.subTitle }}>{infoReferredUser.amountTotalCommission.toFixed(2)} USDT</Typography>
            <Typography sx={{ ...styles.dates }}>{t('AS_OF_DEC_04_2023', { ...earningDates })}</Typography>
            <Typography sx={{ ...styles.dates }}>{t('MONTH_9_OF_12_MONTHS', { ...earningDates })}</Typography>
          </Grid>
          <Divider color='#6D703B' orientation='vertical' flexItem />
          <Grid item xs={2.9} textAlign='center'>
            <ReferredDirectActives 
            textTop={referredUserDirect.textTop}
            textLeft={referredUserDirect.textLeft}
            textRight={referredUserDirect.textRight} />
            <Typography sx={{ ...styles.title, pt: 9 }}>{t('ACTIVE_DIRECT_REFERRALS')}:</Typography>
            {false && (
              <Typography color='#EBDF8C' sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
                {t('VIEW_DETAILS_OF_MY_AXES')}
              </Typography>
            )}

            <Typography sx={{ ...styles.dates }}>{`${rank.requirements.replace(/,/g, ' +')} = ${
              rank.nextRankName
            }`}</Typography>
          </Grid>
          <Divider color='#6D703B' orientation='vertical' flexItem sx={{}} />
          <Grid item xs={2.9} textAlign='center' justifyItems='center'>
            <Typography color='black' sx={{ fontSize: 60, fontWeight: 800, mb: 11 }}>
              {rank.activeUsers}
            </Typography>
            <Typography sx={{ ...styles.title }}>{t('ACTIVE_USERS')}*</Typography>
            <Typography sx={{ color: 'black', px: 4 }}>
              <strong>*{t('ACTIVE')}:</strong>{' '}
              {t('MUST_HAVE_SUBSCRIPTION_VALIDITY_OF_6_OR_12_MONTHS_WITHOUT_EXPIRATION')}.
            </Typography>
          </Grid>
          <Divider color='#6D703B' orientation='vertical' flexItem sx={{}} />
          <Grid item xs={2.9} textAlign='center' justifyItems='center'>
            <Typography color='black' sx={{ fontSize: 60, fontWeight: 800 }}>
              {rank.minInWallet}
            </Typography>
            <Typography sx={{ fontSize: 30, fontWeight: 700, color: 'black', mb: 10 }}>USDT</Typography>
            <Typography color='white' sx={{ fontSize: 20, fontWeight: 700, mb: 10 }}>
              {t('MINIMUMS_IN_YOUR_WALLET')}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default RankInfoDetails
