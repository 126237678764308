import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar, Box, Card, CardContent, Typography, useMediaQuery } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { DataGrid } from '@mui/x-data-grid'
import Icon from 'src/@core/components/icon'
import PlatformSelector from 'src/layouts/components/shared-component/PlatformSelector'
import adminStore from 'src/zustand/admin'

import { DialogGeneric } from '../../../generic/DialogGeneric'
import CustomToolbarAdminGrid from '../../CustomToolbarAdminGrid'

import columns from './ColumnsPositionRealTime'
import UserManagerCards from './UserManagerCards'

interface Customer {
  id: string
  email: string
  registerDate: Date
  ipOrigen: string
  platformId: number
  apiKey: string
  secretKey: string
}

interface Props {
  isContent: boolean
}

const PostionRealTimeManagement = ({ isContent }: Props) => {
  const { t } = useTranslation()
  const [show, setShow] = useState<boolean>(false)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const { getAllUser, users } = adminStore()
  const [loading, setLoading] = useState<boolean>(false)
  const showMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const [selectedPlatformId, setSelectedPlatformId] = useState<number>(0)

  useEffect(() => {
    const aux = async () => {
      setLoading(true)
      getAllUser()
      setLoading(false)
    }
    aux()
  }, [])

  const userList = useMemo(() => {
    if (selectedPlatformId === 0)
      return users.filter(user => user.currentStrategyIsActive && user.activeSubscription)

    return users.filter(
      user =>
        user.currentStrategyIsActive && user.activeSubscription && (user.platformId ? user.platformId === selectedPlatformId : false))
  }, [users, selectedPlatformId])


  return (
    <Card sx={{ ...(isContent && { border: 'none', boxShadow: 'none' }) }}>
      <CardContent sx={{}}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', flexWrap: 'wrap' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {!isContent && (
              <>
                <Avatar sx={{ width: 40, height: 40, bgcolor: 'primary.main' }} color='error' variant='rounded'>
                  <Icon icon='mdi:timetable' style={{ fontSize: 24 }} color='white' />
                </Avatar>
                <Typography sx={{ ml: 2 }} variant='h5'>
                  {t('POSITIONS_REAL_TIME')}
                </Typography>
              </>
            )}
          </Box>
          <Box sx={{ width: 300, mt: { xs: 4, md: 0 } }}>
            <PlatformSelector
              allOption
              setPlatformId={(id: string) => {
                setSelectedPlatformId(Number(id))
              }}
            />
          </Box>
        </Box>
        {!showMobile && (
          <div onClick={event => event.stopPropagation()}>
            <DataGrid
              sx={{
                mt: 4,
                width: '100%'
              }} //overflowX: 'scroll'
              autoHeight
              rows={userList}
              getRowId={r => r.userId}
              columns={columns}
              pageSize={10}
              disableSelectionOnClick
              rowsPerPageOptions={[5]}
              loading={loading}
              components={{ Toolbar: CustomToolbarAdminGrid }}
            />
          </div>
        )}
        {showMobile && <UserManagerCards items={userList}></UserManagerCards>}
      </CardContent>
      <DialogGeneric
        show={show}
        hideActions={true}
        showIconClose={true}
        closeFunc={() => {
          setShow(false)
        }}
        title={t('CUSTOMER_DETAILS')}
        leftAline={true}
      >
        <Box sx={{ pt: 4 }}>Test</Box>
      </DialogGeneric>
    </Card>
  )
}
PostionRealTimeManagement.defaultProps = {
  isContent: false
}

export default PostionRealTimeManagement
