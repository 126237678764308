import { deleteAsync, getAsync, postAsync, putAsync } from 'src/@http'
import { CryptoData } from 'src/pages/components/home/PaymentForm'
import { User, UserModel } from 'src/types/models/userModels'
import profileStore, { WalletBalance } from 'src/zustand/profile'

const rootPath = '/users'

const GetDetailUserById = (userId: string) => `users/${userId}`
const ChangeUserPassword = (userId: number) => `/users/updatePassword/${userId}`
const UpdateUserDetail = (userId: number) => `${rootPath}/${userId}`
const DeleteUser = (userId: string) => `${rootPath}/${userId}`
const UserProfit = (userId: number) => `${rootPath}/${userId}/Profit`
const UserProfitDemo = () => `${rootPath}/Profit/demo`
const RecoveryPassword = '/users/recoveryPassword'
const ResetPassword = '/users/recoveryPasswordByRecovery'
const PostStrategy = (userId: string, strategyId: number) => `users/${userId}/Strategy/${strategyId}`
const GetPlatformByUserId = (userId: string) => `users/${userId}/Platform`
const StopStrategyByUserId = (userId: string) => `users/${userId}/Strategy`
const GetBalanceByUserId = (userId: string) => `users/${userId}/WalletBalance`
const GetProfitReport = (platformId: string, startDate: string, endDate: string) =>
  `${rootPath}/profit/${platformId}?startDate=${startDate}&endDate=${endDate}`
const GetProfitDetail = (userId: string, startDate: string, endDate: string) =>
  `/users/${userId}/profit/detail?startDate=${startDate}&endDate=${endDate}`
const GetProfitDetailGraph = (userId: number) => `/users/${userId}/Profit/Graphic`

const GetProfitGraphicByDates = (userId: number) => `/${userId}/Profit/GraphicByDates`
const GetPercentageProfitGraphicByDates = (userId: number) => `/${userId}/PercentageProfit/GraphicByDates`
const GetDailyProfitGraphicByDates = (userId: number) => `/${userId}/DailyProfit/GraphicByDates`

interface Response {
  statusCode: number
  message: string
  data: any
}

export interface UpdateUserRequest {
  vchName?: string
  vchLastName?: string
  vchPassword?: string
  intStatusId?: number
  dtmEndDateSubscription?: string
}

export interface UserProfitItem {
  ProfitAmount: string
  CreatedDate: string
}

export interface PercentajeProfitItem {
  PercentajeProfit: string
  CreatedDate: string
}

export interface ReferredUser {
  intUserId: number
  intReferredUserId: number
  level: string
  vchStrategyName: string | null
  dtmCreatedDate: string
  blnCurrentStrategyIsActive: string
  intStatusId: string
  vchEmail: string
  blnActiveSubscription: string
  vchSubscriptionName: null | string
  paymentMethod: string
  balance: number | null
}

export interface ReferredUserTree extends ReferredUser {
  children?: ReferredUserTree[]
  collapsed?: boolean
  id?: string
}

export interface ProfitReport {
  userId: number
  email: string
  companyName: string
  createdDate: string
  closedPnl: string
}

export interface CountReferredUser {
  level: string
  users_count: string
}

interface VerifyDirectPaymentPost {
  intSubscriptionTransactionId: number
  blnValidPayment: boolean
  intTxId: string
}

export interface InfoReferredUser {
  numberDirectReferrals: number
  totalReferrals: number
  amountSubscriptionCommission: number
  totalSixMonths: number
  totalTwelveMonths: number
  totalNoSubscription: number
  totalSubscription: number
  amountTotalCommission: number
}

export const changePassword = (userId: number, oldPassword: string, newPassword: string): Promise<Response> => {
  return putAsync(ChangeUserPassword(userId), {
    vchPassword: oldPassword,
    vchNewPassword: newPassword
  })
}

export const getAllUser = (): Promise<API.User.AllUserResponse[]> => {
  return getAsync(rootPath)
}

export const getReferredUsers = (userID: number): Promise<ReferredUser[]> => {
  return getAsync(rootPath + '/getReferredUsers/' + userID)
}

export const getInfoReferredUsers = (userID: number): Promise<InfoReferredUser> => {
  return getAsync(rootPath + '/getInfoReferredUsers/' + userID)
}

export const getCountReferredUsers = (userID: number): Promise<CountReferredUser[]> => {
  return getAsync(rootPath + '/getCountReferredUsers/' + userID)
}

export const getWalletAddressUser = (): Promise<string> => {
  return getAsync(rootPath + '/' + profileStore.getState().userDetail.userId + '/WalletAddress')
}

export const paySubscription = (subscriptionId: number, paymentData: CryptoData): Promise<boolean> => {
  return postAsync(rootPath + '/Subscription', {
    intUserId: profileStore.getState().userDetail.userId,
    intSubscriptionId: subscriptionId,
    intPlatformId: profileStore.getState().userPlatform.platformId,
    intPaymentMethodId: paymentData.paymentMethodId,
    vchCode: paymentData.promocode,
    vchOriginWallet: paymentData.originWallet
  })
}

export const verifyDirectPayment = (data: VerifyDirectPaymentPost): Promise<boolean> => {
  return postAsync(rootPath + '/verifyDirectPayment', data)
}

export const getUserProfit = (userId: number): Promise<API.User.ProfitResponse[]> => {
  return getAsync(UserProfit(userId))
}

export const getUserProfitDemo = (): Promise<API.User.ProfitDemoResponse[]> => {
  return getAsync(UserProfitDemo())
}

export const getUserDetailById = (userId: string): Promise<User> => {
  return getAsync(GetDetailUserById(userId))
}

export const updateUserDetail = (userId: number, userData: UpdateUserRequest): Promise<UserModel> => {
  return putAsync(UpdateUserDetail(userId), userData)
}

export const deleteUser = (userId: string): Promise<UserModel> => {
  return deleteAsync(DeleteUser(userId))
}

export const recoveryPassword = (username: string, email: string): Promise<void> => {
  return postAsync(RecoveryPassword, {
    username: username,
    email: email
  })
}

export const resetPassword = (username: string, code: string, newPassword: string): Promise<any> => {
  return putAsync(ResetPassword, {
    username: username,
    code: code,
    newPassword: newPassword
  })
}

export const changeStrategyById = (userId: string, strategyId: number): Promise<any> => {
  return postAsync(PostStrategy(userId, strategyId), {})
}

export const getPlatformByUserId = (userId: string): Promise<any> => {
  return getAsync(GetPlatformByUserId(userId))
}

export const postStopStrategyByUserId = (userId: string): Promise<any> => {
  return postAsync(StopStrategyByUserId(userId), {})
}

export const getWalletBalance = (userId: string): Promise<WalletBalance> => {
  return getAsync(GetBalanceByUserId(userId))
}

export const getProfitReport = (platformId: string, startDate: string, endDate: string): Promise<any> => {
  return getAsync(GetProfitReport(platformId, startDate, endDate))
}

export const getProfitDetail = (
  userId: string,
  startDate: string,
  endDate: string
): Promise<API.User.ProfitDetailResponse[]> => {
  return getAsync(GetProfitDetail(userId, startDate, endDate))
}

export const getProfitGraph = (userId: number): Promise<any> => {
  return getAsync(GetProfitDetailGraph(userId))
}

export const getProfitGraphicByDates = (userId: number, startDate: Date, endDate: Date): Promise<UserProfitItem[]> => {
  return getAsync(rootPath + GetProfitGraphicByDates(userId), {
    params: {
      startDate: startDate.toISOString().slice(0, -1),
      endDate: endDate.toISOString().slice(0, -1)
    }
  })
}

export const getPercentageProfitGraphicByDates = (
  userId: number,
  startDate: Date,
  endDate: Date
): Promise<PercentajeProfitItem[]> => {
  return getAsync(rootPath + GetPercentageProfitGraphicByDates(userId), {
    params: {
      startDate: startDate.toISOString().slice(0, -1),
      endDate: endDate.toISOString().slice(0, -1)
    }
  })
}

export const getDailyProfitGraphicByDates = (
  userId: number,
  startDate: Date,
  endDate: Date
): Promise<UserProfitItem[]> => {
  return getAsync(rootPath + GetDailyProfitGraphicByDates(userId), {
    params: {
      startDate: startDate.toISOString().slice(0, -1),
      endDate: endDate.toISOString().slice(0, -1)
    }
  })
}
