import { deleteAsync, getAsync, postAsync, putAsync } from 'src/@http'

const root = '/subscription'

const deleteSubs = (subscriptionId: number) => `${root}/${subscriptionId}`

export const getSubscriptions = (): Promise<API.Subscription.SubscriptionResponse[]> => {
  return getAsync(root)
}

export const postSubscriptions = (body: API.Subscription.SubscriptionPost): Promise<any> => {
  return postAsync(root, body)
}

export const putSubscriptions = (body: API.Subscription.SubscriptionPut): Promise<any> => {
  return putAsync(root, body)
}

export const deletedSubscription = (subscriptionId: number): Promise<any> => {
  return deleteAsync(deleteSubs(subscriptionId))
}
