import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import CustomBtn from 'src/layouts/components/shared-component/CustomBtn'
import { IDate } from 'src/views/Filter/ButtonsFilter'
import DateFilter from 'src/views/Filter/DateFilter'

interface Props {
  handleDates: (dates: IDate) => any
  initDate: IDate
  maxDate: Date
  reset: () => any
}

const SimpleDateFilter = ({ handleDates, initDate, maxDate, reset }: Props) => {
  const { t } = useTranslation()

  const [visible, setVisible] = useState<boolean>(false)
  const [isCustom, setIsCustom] = useState<boolean>(false)

  const setDateSelected = (dates: IDate) => {
    handleDates(dates)
  }

  return (
    <Box>
      <CustomBtn
        variant={isCustom ? 'contained' : 'outlined'}
        sx={{ height: 50, mt: { xs: 2, md: 0 }, mb:4 }}
        onClick={() => {
          if (visible) {
            setVisible(false)
            setIsCustom(false)
            reset()
          } else {
            setVisible(true)
            setIsCustom(true)
          }
        }}
      >
        {t('CUSTOM_TIME')}
      </CustomBtn>
      {visible && (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateFilter onData={setDateSelected} width={'100%'} initDate={initDate} maxDate={maxDate} />
        </LocalizationProvider>
      )}
    </Box>
  )
}

export default SimpleDateFilter
