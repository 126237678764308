import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Avatar,
  Box,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Switch,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material'
import Select from '@mui/material/Select'
import { useTheme } from '@mui/material/styles'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import Icon from 'src/@core/components/icon'
import { verifyUserCredentials } from 'src/@http/platform'
import { CustomLoadingBtn } from 'src/layouts/components/shared-component/CustomBtn'
import { ModeForm } from 'src/types'
import * as yup from 'yup'

interface ValuesDefaultCustomerForm {
  id: string
  email: string
  registerDate: string
  status: boolean
  endDateSubscription: string | null
}

interface Props {
  modeForm: ModeForm
  submitFinishFunction: (form: CustomerForm) => void
  submitLoading: boolean
  valuesDefaultForm?: ValuesDefaultCustomerForm
}

interface CustomerForm {
  id: string
  email: string
  password: string
  registerDate: Date
  ipOrigen: string
  platformId: string
  apiKey: string
  secretKey: string
  status: boolean
  promocode: string
  endDateSubscription: Date | null
}

function formatDate(date: any) {
  return new Date(date).toLocaleDateString()
}

declare module 'yup' {
  interface StringSchema {
    ipv4(msg?: string): this
  }
}

yup.addMethod(yup.string, 'ipv4', function (message = 'Invalid IP address') {
  return this.matches(/(^(\d{1,3}\.){3}(\d{1,3})$)/, {
    message,
    excludeEmptyString: true
  }).test('ip', message, value => {
    return value === undefined || value.trim() === ''
      ? true
      : value.split('.').find(i => parseInt(i, 10) > 255) === undefined
  })
})

const CustomerDetailsForm = ({ submitFinishFunction, modeForm, submitLoading, valuesDefaultForm }: Props) => {
  const defaultValuesForm: CustomerForm = {
    id: '0',
    apiKey: '',
    email: '',
    password: '',
    ipOrigen: '',
    platformId: '1',
    registerDate: new Date(),
    secretKey: '',
    status: true,
    promocode: '',
    endDateSubscription: null
  }

  if (valuesDefaultForm) {
    defaultValuesForm.id = valuesDefaultForm.id
    defaultValuesForm.email = valuesDefaultForm.email
    defaultValuesForm.status = valuesDefaultForm.status
    defaultValuesForm.endDateSubscription = valuesDefaultForm.endDateSubscription === null ? null : new Date(valuesDefaultForm.endDateSubscription) 
  }
  const validations = {
    id: yup.number().typeError('Invalid id format'),
    email: yup.string().email('Invalid email format'),
    password: yup.string(),
    registerDate: yup.date(), //.min(new Date(), ({ min }) => `Date needs to be before ${formatDate(min)}.`),
    ipOrigen: yup.string().ipv4(),
    platformId: yup.string(),
    apiKey: yup.string(),
    secretKey: yup.string(),
    status: yup.boolean(),
    promocode: yup.string(),
    endDateSubscription: yup.date().nullable()
  }

  if (modeForm === ModeForm.create) {
    validations.email = validations.email.required()
    validations.password = validations.password.required().min(5)
    validations.apiKey = validations.apiKey.required()
    validations.secretKey = validations.secretKey.required()
    validations.promocode = validations.promocode.required()
  }

  const schemaValidations = yup.object().shape(validations)

  const { t } = useTranslation()
  const theme = useTheme()
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    getValues
  } = useForm<CustomerForm>({ resolver: yupResolver(schemaValidations), defaultValues: defaultValuesForm })

  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showSecretKey, setShowSecretKey] = useState<boolean>(false)
  const [disabledProceed, setDisabledProceed] = useState<boolean>(false)
  const [disabledCheck, setDisabledCheck] = useState<boolean>(true)
  const [isVerified, setIsVerified] = useState<boolean>(false)

  const onSubmit = async (form: CustomerForm) => {
    submitFinishFunction(form)
  }

  const verifyCredentials = async () => {
    try {
      const response = await verifyUserCredentials(1, getValues('apiKey'), getValues('secretKey'))
      setIsVerified(true)
      setDisabledProceed(false)
    } catch (err: any) {
      toast.error(err.message, { duration: 4000 })
    }
  }

  useEffect(() => {
    const subscription = watch(value => {
      setIsVerified(false)
      if (value.apiKey !== '' || value.secretKey !== '') setDisabledProceed(true)
      else setDisabledProceed(false)

      if (value.apiKey !== '' && value.secretKey !== '') setDisabledCheck(false)
      else setDisabledCheck(true)
    })

    return () => subscription.unsubscribe()
  }, [watch(['apiKey', 'secretKey'])])

  return (
    <>
      <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
        {ModeForm.create !== modeForm && (
          <FormControl fullWidth sx={{ my: 2 }}>
            <Controller
              name='id'
              control={control}
              render={({ field: { ...field }, fieldState }) => (
                <TextField
                  disabled
                  variant='outlined'
                  {...field}
                  label={t('ID-Customer')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </FormControl>
        )}

        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='email'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                disabled={ModeForm.show === modeForm}
                {...field}
                label={t('EMAIL')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>
        {ModeForm.show !== modeForm && (
          <FormControl fullWidth sx={{ my: 2 }}>
            <Controller
              name='password'
              control={control}
              render={({ field: { ...field }, fieldState }) => (
                <TextField
                  variant='outlined'
                  {...field}
                  label={`${t('PASSWORD')}`}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <IconButton onMouseDown={e => e.preventDefault()} onClick={() => setShowPassword(!showPassword)}>
                        <Icon icon={showPassword ? 'mdi:eye-outline' : 'mdi:eye-off-outline'} />
                      </IconButton>
                    )
                  }}
                />
              )}
            />
          </FormControl>
        )}

        {ModeForm.create === modeForm && (
          <FormControl fullWidth sx={{ my: 2 }}>
            <Controller
              name='promocode'
              control={control}
              render={({ field: { ...field }, fieldState }) => (
                <TextField
                  variant='outlined'
                  {...field}
                  label={t('PROMOCODE')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </FormControl>
        )}
        {ModeForm.create !== modeForm && (
          <FormControl fullWidth sx={{ my: 2 }}>
            <Controller
              control={control}
              name='registerDate'
              render={({ field: { ref, onBlur, name, ...field }, fieldState }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disabled
                    {...field}
                    inputRef={ref}
                    label={t('REGISTER_DATE')}
                    renderInput={inputProps => (
                      <TextField
                        {...inputProps}
                        onBlur={onBlur}
                        name={name}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </FormControl>
        )}
        {(ModeForm.create !== modeForm && defaultValuesForm.endDateSubscription !== null) && (
          <FormControl fullWidth sx={{ my: 2 }}>
            <Controller
              control={control}
              name='endDateSubscription'
              render={({ field: { ref, onBlur, name, ...field }, fieldState }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disabled={ModeForm.show === modeForm}
                    {...field}
                    inputRef={ref}
                    label={t('END_DATE_SUBSCRIPTION')}
                    renderInput={inputProps => (
                      <TextField
                        {...inputProps}
                        onBlur={onBlur}
                        name={name}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </FormControl>
        )}
        {ModeForm.create !== modeForm && (
          <FormControl fullWidth sx={{ my: 2 }}>
            <Controller
              name='ipOrigen'
              control={control}
              render={({ field: { ...field }, fieldState }) => (
                <TextField
                  disabled
                  variant='outlined'
                  {...field}
                  label={t('IP_ORIGEN')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </FormControl>
        )}

        <FormControl fullWidth sx={{ my: 2 }}>
          <InputLabel>{t('PLATFORM')}</InputLabel>
          <Controller
            name='platformId'
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <Select
                label={t('PLATFORM')}
                defaultValue={'1'}
                required
                fullWidth
                name={name}
                value={value}
                onChange={onChange}
                disabled={ModeForm.show === modeForm}
              >
                <MenuItem value={'1'}>Bybit</MenuItem>
              </Select>
            )}
          />
        </FormControl>
        <FormControl fullWidth sx={{ my: 2 }}>
          <Controller
            name='apiKey'
            control={control}
            render={({ field: { ...field }, fieldState }) => (
              <TextField
                variant='outlined'
                {...field}
                disabled={ModeForm.show === modeForm}
                label={`${t('API_KEY')}`}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                InputProps={{
                  endAdornment:
                    ModeForm.show !== modeForm ? (
                      <IconButton onClick={verifyCredentials} disabled={disabledCheck} style={{ cursor: 'pointer' }}>
                        <Icon color={isVerified ? '#08FC3D' : '#9295A6'} icon='mdi:check-circle-outline' />
                      </IconButton>
                    ) : (
                      <></>
                    )
                }}
              />
            )}
          />
        </FormControl>
        {ModeForm.show !== modeForm && (
          <FormControl fullWidth sx={{ my: 2 }}>
            <Controller
              name='secretKey'
              control={control}
              render={({ field: { ...field }, fieldState }) => (
                <TextField
                  variant='outlined'
                  {...field}
                  label={`${t('SECRET_KEY')}`}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  type={showSecretKey ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onMouseDown={e => e.preventDefault()}
                        onClick={() => setShowPassword(!setShowSecretKey)}
                      >
                        <Icon icon={showSecretKey ? 'mdi:eye-outline' : 'mdi:eye-off-outline'} />
                      </IconButton>
                    )
                  }}
                />
              )}
            />
          </FormControl>
        )}
        <Grid container alignItems='center' justifyContent='space-between'>
          <Avatar sx={{ bgcolor: 'primary.main', width: 50, height: 50 }} color='white' variant='rounded' />

          <Box>
            <Typography align='left' fontSize={22}>
              {t('STATUS')}
            </Typography>
          </Box>
          <Controller
            name='status'
            control={control}
            render={({ field: { ...field } }) => (
              <Switch {...field} defaultChecked={defaultValuesForm.status} disabled={ModeForm.show === modeForm} />
            )}
          />
        </Grid>
        {ModeForm.show !== modeForm && (
          <CustomLoadingBtn
            disabled={disabledProceed}
            variant='contained'
            size='large'
            type='submit'
            style={{ minHeight: 60, width: '100%' }}
            sx={{ mt: { xs: 4, sm: 4 } }}
            loading={submitLoading}
          >
            {t('SAVE')}
          </CustomLoadingBtn>
        )}
      </form>
      {false && (
        <Box sx={{ mt: 4 }}>
          <Typography align='left' fontSize={14}>
            {t('DISCLAIMER')}
          </Typography>
          <Divider sx={{ my: 2 }}></Divider>
          <Typography align='left' fontSize={14} color='#6C7080'>
            {t('PLEASE_CROSS_CHECK_THE_DESTINATION_ADDRESS')}
          </Typography>
        </Box>
      )}
    </>
  )
}

CustomerDetailsForm.defaultProps = {
  editForm: ModeForm.create
}

export default CustomerDetailsForm
export type { CustomerForm, ValuesDefaultCustomerForm }
