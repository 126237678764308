import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
// import { Link} from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography
} from '@mui/material'
// import { styled } from '@mui/material/styles'
import Icon from 'src/@core/components/icon'
import { forgotPassword, sendCodeAndPassword } from 'src/@http/auth'
import { CustomLoadingBtn } from 'src/layouts/components/shared-component/CustomBtn'
import { logger } from 'src/utils/Logger'
import appStore, { AppStore } from 'src/zustand/app'
import * as yup from 'yup'

const schema = yup.object().shape({
  email: yup.string().email().required()
})

const schemaSendNewPassword = yup.object().shape({
  code: yup.string().min(3).required(),
  newPassword: yup.string().min(3).required(),
  newPasswordRepeat: yup
    .string()
    .oneOf([yup.ref('newPassword'), undefined], 'Passwords must match')
    .required()
})

interface FormDataSendRecovery {
  email: string
  // usernameForm: string
}
interface FormDataSendNewPassword {
  code: string
  newPassword: string
  newPasswordRepeat: string
}
interface Props {
  submitFinishFunction: () => any
  closeFunc?: () => any
}

const ForgotPasswordForm = (props: Props) => {
  const { t } = useTranslation()
  // const [username, setUsername] = useState<string>('')
  const [emailSent, setEmailSent] = useState<string>('')
  const [completeFlow, setCompleteFlow] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showPasswordRepeat, setShowPasswordRepeat] = useState<boolean>(false)
  const setShowDialog = appStore((state: AppStore) => state.setShowDialog)

  const {
    control: controlSendRecovery,
    setError: setErrorRecovery,
    handleSubmit: handleSubmitSendRecovery,
    formState: { errors: errorsSendRecovery }
  } = useForm<FormDataSendRecovery>({
    resolver: yupResolver(schema)
  })

  const {
    control: controlSendNewPassword,
    setError: setErrorSendNewPassword,
    handleSubmit: handleSubmitSendNewPassword,

    formState: { errors: errorsNewPasswordRecovery }
  } = useForm<FormDataSendNewPassword>({
    resolver: yupResolver(schemaSendNewPassword)
  })

  const onSubmit = (data: FormDataSendRecovery) => {
    const { email } = data
    setIsLoading(true)
    forgotPassword(email)
      .then(() => {
        setEmailSent(email)
        toast.success(t('SUCCESS_SEND_FORGOT_PASS_MESSAGE'))
      })
      .catch(err => toast.error(err.message + '🙄', { duration: 4000 }))
      .finally(() => setIsLoading(false))
  }

  const onSubmitRecovery = (data: FormDataSendNewPassword) => {
    const { code, newPassword } = data
    setIsLoading(true)
    sendCodeAndPassword({ code, password: newPassword })
      .then(res => {
        logger.log('Response send Code:', res)
        toast.success(t('SUCCESS_CHANGE_PASSWORD'))
        setCompleteFlow(true)
      })
      .catch(err => toast.error(err.message + '🙄', { duration: 4000 }))
      .finally(() => setIsLoading(false))
  }

  const goLogin = () => {
    setShowDialog('forgotPassword', false)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      {emailSent == '' && !completeFlow && (
        <div>
          <form noValidate autoComplete='off' onSubmit={handleSubmitSendRecovery(onSubmit)}>
            <FormControl fullWidth sx={{ mb: 4, mt: 4 }}>
              <Controller
                name='email'
                control={controlSendRecovery}
                rules={{ required: true }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <TextField
                    autoFocus
                    label='Email'
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    error={Boolean(errorsSendRecovery.email)}
                    placeholder='youremail@gmail.com'
                  />
                )}
              />
              {errorsSendRecovery.email && (
                <FormHelperText sx={{ color: 'error.main' }}>{errorsSendRecovery.email.message}</FormHelperText>
              )}
            </FormControl>

            <CustomLoadingBtn
              loading={isLoading}
              fullWidth
              size='large'
              type='submit'
              variant='contained'
              sx={{ mb: 5.25 }}
            >
              {t('RECOVER_PASSWORD')}
            </CustomLoadingBtn>
          </form>
        </div>
      )}

      {emailSent != '' && !completeFlow && (
        <>
          <Box sx={{}}>
            <Typography variant='h5' sx={{ mb: 4 }}>
              {t('VERIFY_YOUR_EMAIL')} ✉️
            </Typography>
            <Typography sx={{ color: 'text.secondary', mb: 6 }}>
              {t('WE_SEND_YOU_AN_EMAIL')} <strong>{emailSent}</strong>, {t('ENTER_THE_CODE_THAT_IS')}.
            </Typography>
            <form noValidate autoComplete='off' onSubmit={handleSubmitSendNewPassword(onSubmitRecovery)}>
              <FormControl fullWidth sx={{ mb: 4 }}>
                <Controller
                  name='code'
                  control={controlSendNewPassword}
                  rules={{ required: true }}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <TextField
                      autoFocus
                      label={t('VERIFICATION_CODE')}
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      error={Boolean(errorsNewPasswordRecovery.code)}
                      placeholder=''
                    />
                  )}
                />
                {errorsNewPasswordRecovery.code && (
                  <FormHelperText sx={{ color: 'error.main' }}>{errorsNewPasswordRecovery.code.message}</FormHelperText>
                )}
              </FormControl>
              <FormControl fullWidth sx={{ mb: 4 }}>
                <InputLabel htmlFor='auth-login-v2-password' error={Boolean(errorsNewPasswordRecovery.newPassword)}>
                  {t('NEW_PASSWORD')}
                </InputLabel>
                <Controller
                  name='newPassword'
                  control={controlSendNewPassword}
                  rules={{ required: true }}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <OutlinedInput
                      value={value}
                      onBlur={onBlur}
                      label={t('NEW_PASSWORD')}
                      onChange={onChange}
                      error={Boolean(errorsNewPasswordRecovery.newPassword)}
                      type={showPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            edge='end'
                            onMouseDown={e => e.preventDefault()}
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            <Icon icon={showPassword ? 'mdi:eye-outline' : 'mdi:eye-off-outline'} fontSize={20} />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  )}
                />
                {errorsNewPasswordRecovery.newPassword && (
                  <FormHelperText sx={{ color: 'error.main' }}>
                    {errorsNewPasswordRecovery.newPassword.message}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl fullWidth sx={{ mb: 4 }}>
                <InputLabel
                  htmlFor='auth-login-v2-password'
                  error={Boolean(errorsNewPasswordRecovery.newPasswordRepeat)}
                >
                  {t('RE_ENTER_PASSWORD')}
                </InputLabel>
                <Controller
                  name='newPasswordRepeat'
                  control={controlSendNewPassword}
                  rules={{ required: true }}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <OutlinedInput
                      value={value}
                      onBlur={onBlur}
                      label={t('RE_ENTER_PASSWORD')}
                      onChange={onChange}
                      error={Boolean(errorsNewPasswordRecovery.newPasswordRepeat)}
                      type={showPasswordRepeat ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            edge='end'
                            onMouseDown={e => e.preventDefault()}
                            onClick={() => setShowPasswordRepeat(!showPasswordRepeat)}
                          >
                            <Icon icon={showPasswordRepeat ? 'mdi:eye-outline' : 'mdi:eye-off-outline'} fontSize={20} />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  )}
                />
                {errorsNewPasswordRecovery.newPasswordRepeat && (
                  <FormHelperText sx={{ color: 'error.main' }}>
                    {errorsNewPasswordRecovery.newPasswordRepeat.message}
                  </FormHelperText>
                )}
              </FormControl>

              <CustomLoadingBtn
                loading={isLoading}
                fullWidth
                size='large'
                type='submit'
                variant='contained'
                sx={{ mb: 5.25 }}
              >
                {t('PASSWORD_RESET')}
              </CustomLoadingBtn>
            </form>
          </Box>
        </>
      )}
      {completeFlow && (
        <Box>
          <Box sx={{ mb: 8 }}>
            <Typography variant='h5' sx={{ mb: 4 }}>
              {t('YOUR_PASSWORD_HAS_BEEN_CORRECTLY_UPDATED')}.✔
            </Typography>
          </Box>
          <CustomLoadingBtn
            onClick={goLogin}
            fullWidth
            size='large'
            type='submit'
            variant='contained'
            sx={{ mb: 5.25 }}
          >
            {t('CLOSE')}
          </CustomLoadingBtn>
        </Box>
      )}
    </Box>
  )
}

export default ForgotPasswordForm
