// ** React Imports
import { useEffect, useMemo, useState } from 'react'
/*
import {
    GoogleReCaptcha,
    GoogleReCaptchaProvider} from 'react-google-recaptcha-v3' */
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
// ** Next Imports
import { Link, useLocation } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
// ** MUI Components
import Box from '@mui/material/Box'
// import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import MuiFormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import { styled, useTheme } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import jwtDecode from 'jwt-decode'
import Icon from 'src/@core/components/icon'
import { useSettings } from 'src/@core/hooks/useSettings'
import { login } from 'src/@http/auth'
import { getPlatformByUserId } from 'src/@http/user'
import { CustomLoadingBtn } from 'src/layouts/components/shared-component/CustomBtn'
import type { Platform } from 'src/types/models/platformModel'
import appStore, { AppStore } from 'src/zustand/app'
import profileStore, { ProfileStore, Token } from 'src/zustand/profile'
import * as yup from 'yup'

const LinkStyled = styled(Link)(({ theme }) => ({
  fontSize: '0.875rem',
  textDecoration: 'none',
  color: theme.palette.primary.main
}))

const FormControlLabel = styled(MuiFormControlLabel)<FormControlLabelProps>(({ theme }) => ({
  '& .MuiFormControlLabel-label': {
    fontSize: '0.875rem',
    color: theme.palette.text.secondary
  }
}))

const schema = yup.object().shape({
  email: yup.string().required(),
  password: yup.string().min(3).required()
})

declare global {
  interface Window {
    grecaptcha: any
  }
}

const captchaSiteKey = process.env.REACT_APP_RECAPTCHA_KEY

interface FormData {
  email: string
  password: string
}

// const codeSended: boolean = false
interface Props {
  onSuccess?: () => void
  closeFunc: () => void
}



function useQuery() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

const LoginForm = ({ onSuccess, closeFunc }: Props) => {
  const setUserDetail = profileStore((state: ProfileStore) => state.setUserDetail)
  const setIsLogged = profileStore((state: ProfileStore) => state.setIsLogged)
  const setToken = profileStore((state: ProfileStore) => state.setToken)
  const { t } = useTranslation()
  const [rememberMe, setRememberMe] = useState<boolean>(true)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const setShowDialog = appStore((state: AppStore) => state.setShowDialog)
  const showAlert = appStore((state: AppStore) => state.showAlert)

  const setWalletBalance = profileStore((state: ProfileStore) => state.setWalletBalance)
  const setUserPlatform = profileStore((state: ProfileStore) => state.setUserPlatform)
  const theme = useTheme()
  const { settings } = useSettings()
  const [isLoading, setIsLoading] = useState(false)

  const query = useQuery()
  const renewSubscription: string | null = query.get('renewSubscription')
  const setShowPaymentFlowDialog = profileStore((state: ProfileStore) => state.setShowPaymentFlowDialog)

  const defaultValuesForm = {
    email: '',
    password: ''
  }

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>({ resolver: yupResolver(schema), defaultValues: defaultValuesForm })

  useEffect(() => {
    // Add reCaptcha
    const script = document.createElement('script')
    script.src = 'https://www.google.com/recaptcha/api.js?render=' + captchaSiteKey
    document.body.appendChild(script)
  }, [])

  const onSubmit = async (data: FormData) => {
    const { email, password } = data

    setIsLoading(true)

    //Get captcha on submit
    const captchaToken: string = await window.grecaptcha.execute(captchaSiteKey, { action: 'login' })

    if (!captchaToken) {
      toast.error(t('MUST_COMPLETE_CAPTCHA'), { duration: 4000 })

      return
    }

    try {
      const response = await login({
        email: email.replace(/\s/g, ''),
        password: password,
        captchaToken: captchaToken
      })
      setIsLogged()
      
      const dataToken: Token = jwtDecode(response)
      
      await setToken(response)

     // const userDetail = await getUserDetailById(dataToken.id.toString())
     // setUserDetail(userDetail)

      const platforms: Platform[] = await getPlatformByUserId(dataToken.id.toString())
      if (platforms.length !== 0) {
        await setUserPlatform(platforms[0])
      }
      if (platforms.length === 0)
        showAlert({
          message: 'API_CREDENTIALS_ARE_MISSING',
          severity: 'warning',
          persistent: true,
          navigation: '/profile?menu=api_managment'
        })

      if (onSuccess !== undefined) onSuccess()

      if (renewSubscription && renewSubscription === 'true') setShowPaymentFlowDialog(true)
    } catch (error: any) {
      setShowDialog('login', true)
      toast.error(error.message, { duration: 4000 })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
      <FormControl fullWidth sx={{ mb: 4 }}>
        <Controller
          name='email'
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange, onBlur } }) => (
            <TextField
              autoFocus
              label='Email'
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              error={Boolean(errors.email)}
              placeholder='youremail@gmail.com'
            />
          )}
        />
        {errors.email && <FormHelperText sx={{ color: 'error.main' }}>{errors.email.message}</FormHelperText>}
      </FormControl>

      <FormControl fullWidth>
        <InputLabel htmlFor='auth-login-v2-password' error={Boolean(errors.password)}>
          {t('PASSWORD')}
        </InputLabel>
        <Controller
          name='password'
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange, onBlur } }: { field: { value: any; onChange: any; onBlur: any } }) => (
            <OutlinedInput
              value={value}
              onBlur={onBlur}
              label={t('PASSWORD')}
              onChange={onChange}
              id='auth-login-v2-password'
              error={Boolean(errors.password)}
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    edge='end'
                    onMouseDown={e => e.preventDefault()}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <Icon icon={showPassword ? 'mdi:eye-outline' : 'mdi:eye-off-outline'} fontSize={20} />
                  </IconButton>
                </InputAdornment>
              }
            />
          )}
        />
        {errors.password && (
          <FormHelperText sx={{ color: 'error.main' }} id=''>
            {errors.password.message}
          </FormHelperText>
        )}
      </FormControl>

      <Box
        sx={{
          mb: 2,
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          justifyContent: 'space-between'
        }}
      >
        <FormControlLabel
          label={t('REMEMBER_ME')}
          control={<Checkbox checked={rememberMe} onChange={e => setRememberMe(e.target.checked)} />}
        />
        <LinkStyled to='/' onClick={() => setShowDialog('forgotPassword', true)}>
          {t('FORGOT_PASSWORD')}
        </LinkStyled>
      </Box>

      {/*
      <Stack direction='column' justifyContent='center' alignItems='center' marginBottom={8}>
          { captchaKey &&
              <GoogleReCaptchaProvider reCaptchaKey={(captchaKey.toString())}>
                  <GoogleReCaptcha onVerify={token => (token ? setCaptchaToken(token) : undefined)} />
              </GoogleReCaptchaProvider> 
          }
      </Stack> */}

      <Box
        sx={{
          mb: 4,
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap'
        }}
      >
        <LinkStyled
          to='/'
          onClick={() => {
            closeFunc()
            setShowDialog('register', true)
          }}
        >
          {t('I_WANT_TO_REGISTER')}
        </LinkStyled>
      </Box>
      <CustomLoadingBtn loading={isLoading} fullWidth size='large' type='submit' variant='contained' sx={{ mb: 7 }}>
        Login
      </CustomLoadingBtn>
    </form>
  )
}

LoginForm.defaultProps = {
  onSuccess: () => undefined
}

export default LoginForm
