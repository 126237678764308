import { Line } from 'react-chartjs-2';

import { hex2rgba } from './libs';

interface Props {
  title: string
  lineColor: string
  data: any[]
  keyData: string
  keyLabels: string
}

const LineChartAxes = ({title, lineColor, data, keyData, keyLabels }:Props) => {
    

    const plugin = {
        id: 'customCanvasBackgroundColor',
        beforeDraw: (chart: any, args: any, options: any) => {
          const {ctx} = chart;
          ctx.save();
          ctx.globalCompositeOperation = 'destination-over';
          ctx.fillStyle = options.color || '#99ffff';
          ctx.fillRect(0, 0, chart.width, chart.height);
          ctx.restore();
        }
      };

    const options = {
        responsive: true,
        scales: {
          x: {
            border:{dash: [4, 4]},
            grid: {
              display: false,
              color: hex2rgba('#FFFFFF', 0.4),
            },
            ticks: {
              color: hex2rgba('#FFFFFF', 0.8), 
            },
          },
          y: {
            border:{dash: [4, 4]},
            grid: {
              
              color: hex2rgba('#FFFFFF', 0.4),
            },
            ticks: {
              color: hex2rgba('#FFFFFF', 0.8), 
            },
          },
        },
        
        plugins: {
          legend: {
            display: false,
            position: 'top' as const,
          },
          title: {
            display: false,
            text: title,
          },
          customCanvasBackgroundColor: {
            color: 'backgroundColor',
          },
        },
        
      };

      const dataInfo = {
        labels: data.map((value) => value[keyLabels]),
        datasets: [
          {
            label: title,
            data: data.map((value) => Number(value[keyData])),
            borderColor: hex2rgba(lineColor, 0.6),
            backgroundColor: hex2rgba(lineColor, 0.6)
          },
        ],
      };

    return  <Line style={{padding: 8, border:4}} options={options} data={dataInfo} plugins={[plugin]} />
}

export default LineChartAxes