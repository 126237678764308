import { useContext, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Box, ButtonGroup, Card, CardContent, Grid, IconButton, Typography } from '@mui/material'
import Icon from 'src/@core/components/icon'
import CustomAvatar from 'src/@core/components/mui/avatar'
import { deleteTradersById, getPercentageStrategiesByTraderId } from 'src/@http/trader/'
import { DialogGeneric } from 'src/pages/components/generic/DialogGeneric'
import { Trader, TraderPercentageStrategy, TraderStrategies } from 'src/types/models/traderModel'
import { logger } from 'src/utils/Logger'
import adminStore from 'src/zustand/admin'
import appStore from 'src/zustand/app'

import { AdminContext } from '../adminContext'

import TraderPositionsDialog from './TraderPositionsDialog'

interface Props {
  trader: Trader
  editFunction: (percents: any) => any
  deleteFunctionSuccess: () => any
}

const TraderItemCard = (props: Props) => {
  const { t } = useTranslation()
  const { trader, editFunction, deleteFunctionSuccess } = props
  const [show, setShow] = useState<boolean>(false)
  const [showTransactions, setShowTransactions] = useState<boolean>(false)
  const { getBybitPositionsTradersById } = adminStore()

  const defaultValues = [
    {
      id: 1,
      strategy: t('STRATEGY_LOW'),
      percentage: 'Empty'
    },
    {
      id: 2,
      strategy: t('STRATEGY_MEDIUM'),
      percentage: 'Empty'
    },
    {
      id: 3,
      strategy: t('STRATEGY_HIGH'),
      percentage: 'Empty'
    }
  ]
  const [strategies, setStrategies] = useState<TraderStrategies[]>(defaultValues)
  const setMenuSeletedAdmin = appStore(state => state.setMenuSeletedAdmin)
  const { setPath, setParams } = useContext(AdminContext)

  useEffect(() => {
    if (strategies[0].percentage === 'Empty')
      getPercentageStrategiesByTraderId(trader.traderId.toString())
        .then((percentsTest: TraderPercentageStrategy[]) =>{
          if (percentsTest.length !== 0)
            setStrategies(
              percentsTest
                .map(p => ({
                  id: p.strategyId,
                  strategy: defaultValues.find(strategyName => p.strategyId === strategyName.id)?.strategy,
                  percentage: p.percent
                }))
                .sort((a, b) => a.id - b.id)
            )
        })
        .catch(err => toast.error(err))
  })

  const deleteTrader = async () => {
    try {
      const res = await deleteTradersById(trader.traderId.toString())
      logger.log('Response :', res)
      toast.success(t('TRADER_DELETE_MESSAGE'))
      deleteFunctionSuccess()
    } catch (err: any) {
      toast.error(err)
    }
  }

  const ActionButtons = () => {
    const buttons = [
      {
        icon: 'carbon:edit',
        action: () => {
          logger.log('Strategies percents:  ', strategies)
          editFunction(strategies)
        }
      },
      {
        icon: 'ph:trash-light',
        action: () => {
          setShow(true)
        }
      },
      {
        icon: 'icon-park-solid:transaction-order',
        action: () => {
          getBybitPositionsTradersById(trader.traderId.toString())
          setShowTransactions(true)
        }
      }
    ]

    return (
      <Grid container sx={{ flexDirection: 'row', justifyContent: 'end' }}>
        <ButtonGroup>
          {buttons.map(button => (
            <Grid item key={button.icon}>
              <IconButton onClick={() => button.action()} aria-label='capture screenshot' color='secondary'>
                <Icon icon={button.icon} fontSize={16} />
              </IconButton>
            </Grid>
          ))}
        </ButtonGroup>
      </Grid>
    )
  }

  return (
    <Card sx={{ backgroundColor: 'background.default' }}>
      <CardContent sx={{ pr: 0 }}>
        <Grid container sx={{ flexDirection: 'row', width: '100%' }}>
          <Grid item xs={2}>
            <CustomAvatar color={trader.statusId === 1 ? 'error' : 'secondary'} variant='rounded'>
              <Icon icon={trader.statusId === 1 ? 'mdi:fire' : 'akar-icons:stop'} />
            </CustomAvatar>
          </Grid>
          <Grid item sx={{ p: 1, pl: 1, fontSize: 18 }} xs={8}>
            {trader.traderName}
          </Grid>
          <Grid item xs={2} sx={{ justifyContent: 'end' }}>
            <ActionButtons />
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end' }}>
          <IconButton
              onClick={() => {setPath('manual-trader-manager'); setParams({trader})}}
              color='secondary'
            >
              <Icon icon='mdi:gesture-tap' fontSize={18} />
            </IconButton>
            <IconButton
              onClick={() => {setPath('gridbot-manager'); setParams({trader})}}
              color='secondary'
            >
              <Icon icon='mdi:robot-outline' fontSize={18} />
            </IconButton>
          </Grid>
        </Grid>

        <Box sx={{ display: 'flex', mt: 2 }}>
          <Typography sx={{ mr: 2,fontSize: 12  }}> {t('ID')}:</Typography>
          <Typography sx={{ fontWeight: 400, fontSize: 12 }}>{trader.traderId}</Typography>
        </Box>
        <Grid container sx={{flexDirection: 'column' }}>
          {strategies.map((strategy: any) => (
            <Box key={strategy.strategy} sx={{ display: 'flex', fontSize: 12, justifyContent: 'space-between' }}>
              <Box minWidth={150}> {strategy.strategy} :</Box> <span>{strategy.percentage}%</span>
              <Box></Box>
            </Box>
          ))}
        </Grid>
        {showTransactions && (
          <TraderPositionsDialog
            traderName={trader.traderName}
            showProp={showTransactions}
            closeFunc={() => setShowTransactions(false)}
          />
        )}

        <DialogGeneric
          show={show}
          title={t('DELETE_TRADER')}
          confirmText={t('CONFIRM') || ''}
          confirmFunc={() => deleteTrader()}
          closeFunc={() => {
            setShow(false)
          }}
        >
          {t('DELETE_TRADER_MESSAGE')}
        </DialogGeneric>
      </CardContent>
    </Card>
  )
}

export default TraderItemCard
