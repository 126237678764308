import { getAsync, postAsync, putAsync } from "..";

export interface ManualTrader {
    intManualTraderId: number;
  vchSymbol: string;
  vchSide: string;
  dcmAmount: number;
  
  intNumberOfOrdersLimit: number | null;
  dcmPercentageStepLimit: number;
  dcmAmountLimit: number;
  dcmMaxPriceLimit: number;
  dcmMinPriceLimit: number;
  intTraderId: number;
  intLeverage: number;
  dtmCreatedDate: string;
  intCreatedUserId: number;
  dtmUpdatedDate: string;
  intUpdatedUserId: number;
  intStatusId: number;
  dcmStopLossPrice: number | null;
  intIsCyclical: number;
  manualTraderTP: {
    dcmPercentageSize: number
    dcmPrice: number
  }[];
}

interface ManualTraderPost{
  vchSymbol: string;
  vchSide: string;
  dcmAmount: number  | null;
  intNumberOfOrdersLimit: number | null;
  dcmPercentageStepLimit: number  | null;
  dcmAmountLimit: number  | null;
  dcmMaxPriceLimit: number  | null;
  dcmMinPriceLimit: number  | null;
  intTraderId: number;
  intLeverage: number;
  intCreatedUserId: number;
  dcmStopLossPrice: number | null;
  intIsCyclical: number;
  manualTraderTP: {
    dcmPercentageSize: number
    dcmPrice: number
  }[];
}

interface PutStatusManualTrader{
  id: number
  intStatusId: number
}

interface ManualTraderPut extends ManualTraderPost{
  intManualTraderId: number
}

const root = '/manual-trader'


export const getManualTraders = (): Promise<ManualTrader[]> => {
  return getAsync(root)
}

export const postCreateManualTrader = (body: ManualTraderPost): Promise<any> => {
  return postAsync(root, body)
}

export const putCreateManualTrader = (body: ManualTraderPut): Promise<any> => {
  return putAsync(root, body)
}

export const putStatusManualTrader = (body: PutStatusManualTrader): Promise<any> => {
  return putAsync(root+`/${body.id}/Status`, {intStatusId: body.intStatusId})
}
