// ** React Imports
import { useEffect, useMemo, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
// import { Link} from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import jwtDecode from 'jwt-decode'
// import { styled } from '@mui/material/styles'
import Icon from 'src/@core/components/icon'
import { generateCode } from 'src/@http/2fa'
import { login, signUp } from 'src/@http/auth'
import { getGlobalSettingForKey } from 'src/@http/global-settings'
import { getPlatformByUserId } from 'src/@http/user'
import { CustomBtn, CustomLoadingBtn } from 'src/layouts/components/shared-component/CustomBtn'
import type { Platform } from 'src/types/models/platformModel'
import { logger } from 'src/utils/Logger'
import appStore, { AppStore } from 'src/zustand/app'
import profileStore, {ProfileStore} from 'src/zustand/profile'
import * as yup from 'yup'

const captchaSiteKey = process.env.REACT_APP_RECAPTCHA_KEY

interface Props {
  submitFinishFunction: () => any
  closeFunc?: () => any
}
interface FormDataRegisterUser {
  email: string
  password: string
  passwordRepeat: string
  code: string
  promocode?: string
}

const schema = yup.object().shape({
  email: yup
    .string()
    .required()
    .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, 'Invalid email Format'),
  password: yup.string().min(5).required(),
  passwordRepeat: yup
    .string()
    .oneOf([yup.ref('password'), undefined], 'Passwords must match')
    .required(),
  code: yup.string().required()
})

function useQuery() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

const RegisterForm = (props: Props) => {
  const initialSec = 300
  const getUserDetail = profileStore((state:ProfileStore) => state.getUserDetail)
  const setIsLogged = profileStore((state:ProfileStore) => state.setIsLogged)
  const setToken = profileStore((state:ProfileStore) => state.setToken)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [checkPromocode, setCheckPromocode] = useState<boolean>(false)
  const setShowDialog = appStore((state: AppStore) => state.setShowDialog)
  const { t } = useTranslation()
  const setUserPlatform = profileStore((state:ProfileStore) => state.setUserPlatform)
  const query = useQuery()
  const referredUserId: string | null = query.get('bienvenido')
  const showAlert = appStore((state: AppStore) => state.showAlert)

  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showPasswordRepeat, setShowPasswordRepeat] = useState<boolean>(false)
  const [isSendEmail, setIsSendEmail] = useState<boolean>(false)
  const [enableCounter, setEnableCounter] = useState<boolean>(false)
  const [counter, setCounter] = useState<number>(initialSec + 1)
  const [referredUserIdDefault, setReferredUserIdDefault] = useState<number>(0)

  const theme = useTheme()
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'))
  

  useEffect(() => {
    if (counter > 0 && enableCounter) {
      const timer = setInterval(() => setCounter(counter - 1), 1000)

      return () => clearInterval(timer)
    } else {
      setEnableCounter(false)
    }
  }, [counter])

  const onSubmit = async (data: FormDataRegisterUser) => {
    const { email, password, passwordRepeat, promocode, code } = data
    
    setIsLoading(true)
    
    //Get captcha on submit
    const captchaToken: string = await window.grecaptcha.execute(captchaSiteKey, {action: "signup"})

    if (!captchaToken) {
      toast.error(t('MUST_COMPLETE_CAPTCHA'), { duration: 4000 })

      return
    }

    try {
      const response = await signUp({
        vchEmail: email,
        vchPassword: password,
        vchLastName: '',
        vchName: '',
        vchPromoCode: promocode !== undefined ? promocode : '',
        code,
        intReferredUserId: (referredUserId !== null && referredUserId.match(/^[0-9]+$/) != null) ? parseInt(referredUserId) : referredUserIdDefault
      })
      
      await loginFunction(email, password, captchaToken)

      toast.success(t('SUCCESS_REGISTER'), { duration: 4000 })
      // const responseLogin = await signIn
      props.submitFinishFunction()
    } catch (error: any) {
      toast.error(error.message, { duration: 4000 })
    } finally {
      setIsLoading(false)
    }
  }

  const loginFunction = async (email: string, password: string, captchaToken: string) => {
    const response = await login({
      email: email,
      password: password,
      captchaToken: captchaToken
    })
    setIsLogged()


    const dataToken: any = jwtDecode(response)
    setToken(response)
    getPlatformByUserId(dataToken.id).then((platforms: Platform[]) => {
      logger.log('Platforms : ', platforms)
      if (platforms.length !== 0) {
        setUserPlatform(platforms[0])
      }
      if (platforms.length === 0)
          showAlert({
            message: 'API_CREDENTIALS_ARE_MISSING',
            severity: 'warning',
            persistent: true,
            navigation: '/profile?menu=api_managment'
          })
    })
    await getUserDetail(dataToken.id)
  }

  const defaultValuesForm = {
    email: '',
    password: '',
    passwordRepeat: '',
    promocode: ''
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    watch
  } = useForm<FormDataRegisterUser>({ resolver: yupResolver(schema), defaultValues: defaultValuesForm })

  useEffect(() => {
    
    const init = async () => {
      try{
        const userIdDefault = await getGlobalSettingForKey('ReferredUserIdDefault')
        setReferredUserIdDefault(parseInt(userIdDefault.value))
      } catch (error: any) {
        logger.error(error)
      }
    }
    
    // Add reCaptcha
    const script = document.createElement("script")
    script.src = "https://www.google.com/recaptcha/api.js?render=" + process.env.REACT_APP_RECAPTCHA_KEY
    document.body.appendChild(script)

    init()    
  }, [])
  
  const handleCheckPromocodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckPromocode(event.target.checked)
  }

  const [isLoadingEmail, setIsLoadingEmail] = useState<boolean>(false)

  const validateEmail = async () => {
    try {
      setIsLoadingEmail(true)
      await generateCode(getValues('email'))
      toast.success(t('VALIDATION_CODE_SENT_EMAIL'), { duration: 4000 })
      setIsSendEmail(true)
    } catch (error) {
    } finally {
      setIsLoadingEmail(false)
      setEnableCounter(true)
      setCounter(initialSec)
    }
  }

  const emailWatch = useWatch({control, name: "email"});

  const isValidEmail = useMemo(() => {
   if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailWatch)) return true

   return false
  }, [emailWatch])

  return (
    <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
      <FormControl fullWidth sx={{ mb: 4, mt: 4 }}>
        <Controller
          name='email'
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange, onBlur }, fieldState }) => (
            <TextField
              autoFocus
              label='Email'
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              error={Boolean(errors.email)}
              placeholder='youremail@gmail.com'
            />
          )}
        />
        {errors.email && <FormHelperText sx={{ color: 'error.main' }}>{errors.email.message}</FormHelperText>}
      </FormControl>

      <FormControl fullWidth sx={{ mb: 4, mt: 4 }}>
        <InputLabel htmlFor='auth-login-v2-password' error={Boolean(errors.password)}>
          {t('PASSWORD')}
        </InputLabel>
        <Controller
          name='password'
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange, onBlur } }) => (
            <OutlinedInput
              value={value}
              onBlur={onBlur}
              label={t('PASSWORD')}
              onChange={onChange}
              id='auth-login-v2-password'
              error={Boolean(errors.password)}
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    edge='end'
                    onMouseDown={e => e.preventDefault()}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <Icon icon={showPassword ? 'mdi:eye-outline' : 'mdi:eye-off-outline'} fontSize={20} />
                  </IconButton>
                </InputAdornment>
              }
            />
          )}
        />
        {errors.password && (
          <FormHelperText sx={{ color: 'error.main' }} id=''>
            {errors.password.message}
          </FormHelperText>
        )}
      </FormControl>

      <FormControl fullWidth sx={{ mb: 4, mt: 4 }}>
        <InputLabel htmlFor='auth-login-v2-password' error={Boolean(errors.passwordRepeat)}>
          {t('REPEAT_PASSWORD')}
        </InputLabel>
        <Controller
          name='passwordRepeat'
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange, onBlur } }) => (
            <OutlinedInput
              value={value}
              onBlur={onBlur}
              label={t('REPEAT_PASSWORD')}
              onChange={onChange}
              id='auth-login-v2-password'
              error={Boolean(errors.passwordRepeat)}
              type={showPasswordRepeat ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    edge='end'
                    onMouseDown={e => e.preventDefault()}
                    onClick={() => setShowPasswordRepeat(!showPasswordRepeat)}
                  >
                    <Icon icon={showPasswordRepeat ? 'mdi:eye-outline' : 'mdi:eye-off-outline'} fontSize={20} />
                  </IconButton>
                </InputAdornment>
              }
            />
          )}
        />
        {errors.passwordRepeat && (
          <FormHelperText sx={{ color: 'error.main' }}>{errors.passwordRepeat.message}</FormHelperText>
        )}
      </FormControl>

      <FormControl fullWidth sx={{ my: 3 }}>
        <Controller
          name='code'
          control={control}
          render={({ field: { ...field }, fieldState }) => (
            <TextField
              variant='outlined'
              {...field}
              label={lessThanSmall? '': t('THE_CODE_WILL_ARRIVE_IN_YOUR_EMAIL')}
              error={!!fieldState.error}
              helperText={lessThanSmall? t('THE_CODE_WILL_ARRIVE_IN_YOUR_EMAIL'): fieldState.error?.message}
              
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      edge='end'
                      style={{ backgroundColor: 'transparent' }}
                      onMouseDown={e => e.preventDefault()}
                      onClick={validateEmail}
                      disabled={!isValidEmail || enableCounter}
                      color={isValidEmail ? 'primary' : 'default'}
                    >
                      {enableCounter && <Typography sx={{ fontSize: 15 }}>{counter} seg</Typography>}
                      {!isLoadingEmail && !enableCounter && (
                        <Typography
                          color={!isValidEmail || enableCounter ? 'default' : 'primary'}
                          style={{ cursor: 'pointer' }}
                          sx={{ fontSize: 15 }}
                        >
                          {t('SEND_VALIDATION')}
                        </Typography>
                      )}
                      {isLoadingEmail && !enableCounter && <CircularProgress size={25} />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          )}
        />
      </FormControl>

      {false && (
          <FormGroup>
            <FormControlLabel
                control={<Checkbox checked={checkPromocode} onChange={handleCheckPromocodeChange} />}
                label={t('PROMO_CODE')}
            />


            <FormControl fullWidth sx={{ mb: 4, mt: 4 }}>
              <Controller
                  name='promocode'
                  control={control}
                  rules={{ required: false }}
                  render={({ field: { value, onChange, onBlur } }) => (
                      <TextField
                          autoFocus
                          label={t('PROMO_CODE')}
                          value={value}
                          onBlur={onBlur}
                          onChange={onChange}
                          placeholder=''
                      />
                  )}
              />
            </FormControl>

          </FormGroup>
      )}


      <Grid container>
        <Grid sx={{ pr: 1 }} item xs={6}>
          <CustomLoadingBtn
            loading={isLoading}
            disabled={!isSendEmail}
            fullWidth
            size='large'
            type='submit'
            variant='contained'
            sx={{ mt: 0 }}
          >
            {t('REGISTER')}
          </CustomLoadingBtn>
        </Grid>
        <Grid sx={{ pl: 1 }} item xs={6}>
          <CustomBtn
            fullWidth
            size='large'
            variant='contained'
            onClick={() => {
              setShowDialog('register',false)
              if (props.closeFunc) props.closeFunc()
            }}
            sx={{ mt: 0 }}
          >
            {t('CANCEL')}
          </CustomBtn>
        </Grid>
      </Grid>
    </form>
  )
}

export { RegisterForm }
