import { /*useEffect,*/ useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Avatar, Box, Card, CardContent, CircularProgress, Grid, IconButton, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import Icon from 'src/@core/components/icon'
import { getProfitDetail, getProfitReport } from 'src/@http/user'
import PlatformSelector from 'src/layouts/components/shared-component/PlatformSelector'
// import { logger } from 'src/utils/Logger'
// import CustomFilterPanel from 'src/views/Filter/CustomFilterPanel'
import ButtonsFilter from 'src/views/Filter/ButtonsFilter'

import CustomToolbarAdminGrid from '../../CustomToolbarAdminGrid'

import ProfitDetailsDialog from './ProfitDetailsDialog'

interface SummaryProfit {
  closedPnl: string
  companyName: string
  createdDate: string
  email: string
  userId: number
}

interface Props {
  isContent: boolean
}

const ProfitSummaryTable = ({isContent}:Props) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const [show, setShow] = useState<boolean>(false)
  const [loadingDetails, setLoadingDetails] = useState<boolean>(false)
  const [totalSummary, setTotalSummary] = useState<number>(0)
  const [userEmail, setUserEmail] = useState<string>('Queso')
  const [detailsByUser, setDetailsByUser] = useState<API.User.ProfitDetailResponse[]>([])
  const [startDate, setStartDate] = useState<string>(new Date(Date.now()).toISOString().slice(0, -5))
  const [endDate, setEndDate] = useState<string>(new Date(Date.now()).toISOString().slice(0, -5))
  const [totalDetail, setTotalDetail] = useState<number>(0)
  const [selectedPlatformId, setSelectedPlatformId] = useState<string>('1')
  const [profitSummaryUsers, setProfitSummaryUsers] = useState<SummaryProfit[]>([])

  const openDetails = (userId: string, userEmail: string) => {
    setShow(true)
    setUserEmail(userEmail)
    setLoading(true)
    setLoadingDetails(true)
    setDetailsByUser([])
    getProfitDetail(userId, startDate, endDate)
      .then(res => {
        setDetailsByUser(res.map((detail: any, index: number) => ({ ...detail, id: index })))
        setTotalDetail(
          res.reduce((accumulator: number, currentItem: any) => {
            const valorNumerico = Number(currentItem.closedPnl)

            return accumulator + valorNumerico
          }, 0)
        )
        setLoading(false)
      })
      .catch(err => {
        setDetailsByUser([])
        setLoading(false)
        toast.error(t(err.message))
      }).finally(() => {setLoadingDetails(false)})
  }

  const dateFilterCallback = async (startDate: Date, endDate: Date) => {
    // Recorto las fechas porque la api no acepta los milisegundos
    setStartDate(startDate.toISOString().slice(0, -5))
    setEndDate(endDate.toISOString().slice(0, -5))
    setLoading(true)
    getProfitReport(selectedPlatformId, startDate.toISOString().slice(0, -5), endDate.toISOString().slice(0, -5))
      .then(res => {
        setProfitSummaryUsers(res)
        setTotalSummary(
          res.reduce((accumulator: number, currentItem: SummaryProfit) => {
            const valorNumerico = Number(currentItem.closedPnl)

            return accumulator + valorNumerico
          }, 0)
        )
      })
      .catch(err => toast.error(err))
      .finally(() => setLoading(false))
  }

  useEffect(()=>{
    profitReportForPlatform(selectedPlatformId)
  }, [selectedPlatformId])

  const profitReportForPlatform = async (id: string) => {
    setLoading(true)
    getProfitReport(id, startDate, endDate)
      .then(res => {
        setProfitSummaryUsers(res)
        setTotalSummary(
          res.reduce((accumulator: number, currentItem: SummaryProfit) => {
            const valorNumerico = Number(currentItem.closedPnl)

            return accumulator + valorNumerico
          }, 0)
        )
      })
      .catch(err => toast.error(err))
      .finally(() => setLoading(false))
  }

  const columns = [
    {
      width: 180,
      field: 'createdDate',
      renderHeader: () => <Typography color='#9295A6'>{t('REGISTRATION_DATE')}</Typography>,
      renderCell: ({ row }: any) => {
  
        return (
          <Typography noWrap variant='body2' fontSize={16}>
            {row.createdDate ? row.createdDate.slice(0, 10) : '-'}
          </Typography>
        )
      }
    },
    {
      width: 400,
      field: 'email',
      headerName: t('EMAIL'),
      renderCell: ({ row }: any) => {
        return (
          <Typography noWrap variant='body2' fontSize={16}>
            {row.userId} - {row.email}
          </Typography>
        )
      },
      editable: false
    },
    {
      width: 180,
      field: 'closedPnl',
      headerName: t('CLOSED_PNL'),
      editable: false,
      type: 'number',
      renderCell: ({ row }: any) => {
        return (
          <Typography noWrap variant='body2' color={Number(row.closedPnl) < 0 ? '#DC2626' : '#03A66D'} fontSize={16}>
            $ {Number(row.closedPnl).toFixed(4)}
          </Typography>
        )
      }
    },
    {
      width: 200,
      field: 'companyName',
      headerName: t('COMPANY_NAME'),
      editable: false
    },
    {
      width: 100,
      field: 'detail',
      headerName: t('DETAILS'),
      editable: false,
      renderCell: ({ row }: any) => {
        return (
          <Box sx={{}}>
            <IconButton
              onClick={() => openDetails(row.userId, row.email)}
              aria-label='capture screenshot'
              color='secondary'
              size='small'
            >
              {loading ? <CircularProgress size={15} /> : <Icon icon='icon-park-outline:view-grid-detail' />}
            </IconButton>
          </Box>
        )
      }
    }
  ]

  

  return (
    <Card sx={{ ...(isContent && { border: 'none', boxShadow: 'none' }) }}>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', mb: 2, flexWrap:'wrap' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {!isContent && <>
              <Avatar sx={{ width: 40, height: 40, bgcolor: 'primary.main' }} color='error' variant='rounded'>
              <Icon icon='tdesign:money' style={{ fontSize: 24 }} color='white' />
            </Avatar>
            <Typography sx={{ ml: 2 }} variant='h5'>
              {t('PERFORMANCE_REPORT')}
            </Typography>
            </>}
          </Box>
          <Box sx={{ width: 300, mt:{xs:4, md:0} }}>
            <PlatformSelector setPlatformId={(id: string) => {setSelectedPlatformId(id)}}/>
          </Box>
        </Box>
        <Grid container direction='row' justifyContent='center'>
          {/* <Grid xs={4} item> */}
          <ButtonsFilter apply={dateFilterCallback} />
          {/* </Grid> */}
        </Grid>
        <Grid container direction='row' justifyContent='center' sx={{ m: 3 }}>
          <Typography sx={{ ml: 2, color: 'secondary.main' }} variant='body2'>
            {t('START_DATE')} :
          </Typography>
          <Typography sx={{ ml: 2, color: 'primary.main' }} variant='body2'>
            {new Date(startDate).toDateString()}
          </Typography>
          <Typography sx={{ ml: 2, color: 'secondary.main' }} variant='body2'>
            {t('END_DATE')} :
          </Typography>
          <Typography sx={{ ml: 2, color: 'primary.main' }} variant='body2'>
            {new Date(endDate).toDateString()}
          </Typography>
          <Typography sx={{ ml: 1, color: 'secondary.main' }} variant='body2'>
            {t('TOTAL')}
          </Typography>
          <Typography sx={{ ml: 1, color: totalSummary > 0 ? '#03A66D': '#DC2626'}} variant='body2'>
            : $ {totalSummary.toFixed(2)}
          </Typography>
        </Grid>

        <DataGrid
          sx={{
            mt: 4,
            width: '100%'
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: 'closedPnl', sort: 'desc' }]
            }
          }}
          autoHeight
          rows={profitSummaryUsers}
          getRowId={r => r.userId}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          // rowsPerPageOptions={[5]}
          loading={loading}
          components={{ Toolbar: CustomToolbarAdminGrid }}
        />
      </CardContent>
      <ProfitDetailsDialog
        showProp={show}
        userEmail={userEmail}
        details={detailsByUser}
        loading={loadingDetails}
        startDate={startDate}
        endDate={endDate}
        total={totalDetail}
        selectedPlatformId={Number(selectedPlatformId)}
        closeFunc={() => setShow(false)}
      />
    </Card>
  )
}

ProfitSummaryTable.defaultProps = {
  isContent: false
}


export default ProfitSummaryTable
