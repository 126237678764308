import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Avatar, Box, Card, CardContent, Chip, Divider, Link, Typography } from '@mui/material'
import Icon from 'src/@core/components/icon'
import { Platform } from 'src/@http/platform'
import CustomBtn from 'src/layouts/components/shared-component/CustomBtn'
// import Icon from 'src/@core/components/icon'
//import type { Platform } from 'src/types/models/platformModel'
import profileStore, { ProfileStore } from 'src/zustand/profile'

import { DialogGeneric } from '../generic/DialogGeneric'
import { CredentialsForm } from '../home/CredentialsForm'

interface ApiKeyCredential {
  platformId: number
  apiKey: string
  apiSecret: string
}



interface PlatformStore {
  platformId: number
  apiKey: string
  apiSecret: string
  passPhrase: string
  status: number
}

const ApiManagementCard = () => {
  
  const cryptoPlatforms: Platform[] = profileStore((state:ProfileStore) => state.platforms)
  const userPlatfom: PlatformStore = profileStore((state:ProfileStore) => state.userPlatform)


  const { t } = useTranslation()
  const [show, setShow] = useState<boolean>(false)
  const [selectedPlatformId, setSelectedPlatformId] = useState<number>(
    userPlatfom.platformId !== 0 ? userPlatfom.platformId : cryptoPlatforms[0].platformId
  )

  const setUserPlatform = profileStore((state: ProfileStore) => state.setUserPlatform)

  const navigate = useNavigate()

  interface PlatformItemProps {
    platforms: Array<Platform>
  }

  const PlatformItem = ({ platforms }: PlatformItemProps) => {
    return (
      <>
        {platforms.map((platform: Platform, key: number) => (
          <Box key={key} sx={{ width: '100%' }}>
            <Card style={{ backgroundColor: 'background.default' }} sx={{ mt: 2 }}>
              <CardContent>
                {platform.intStatusId===1 && userPlatfom.platformId === platform.platformId && (
                  <Chip label={t('ACTIVE')} color='success' variant='outlined' />
                )}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: { xs: 'center', sm: 'space-between' },
                    flexWrap: 'wrap',
                    alignItems: 'center'
                  }}
                >
                  <Avatar
                    src={platform.vchImage}
                    sx={{
                      width: { xs: 200, sm: 150 },
                      height: { xs: 45, sm: 45 },
                      filter: platform.intStatusId===0 ? 'grayscale(1)' : ''
                    }}
                    variant='square'
                  />
                  <CustomBtn
                    sx={{ my: 4, backgroundColor: 'primary.main' }}
                    style={{ minWidth: 115 }}
                    onClick={() => {
                      setShow(true)
                      setSelectedPlatformId(platform.platformId)
                    }}
                    disabled={platform.intStatusId===0}
                  >
                    {t(userPlatfom.platformId === platform.platformId ? 'EDIT' : 'ACTIVATE')}
                  </CustomBtn>
                </Box>
              </CardContent>
            </Card>

            {platforms.length - 1 !== key && <Divider sx={{ my: 2 }} />}
          </Box>
        ))}
      </>
    )
  }

  return (
    <>
      <Card>
        <CardContent>
          <Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
            <Avatar sx={{ bgcolor: 'primary.main', width: 40, height: 40 }} variant='rounded'>
              <Icon icon='mdi:api' style={{ fontSize: 24 }} color='white' />
            </Avatar>
            <Typography sx={{ m: 3 }} variant='h5' component='div'>
              {t('API_MANAGMENT')}
            </Typography>
          </Box>
          <Card sx={{ mt: 2 }} variant='outlined' elevation={0}>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar sx={{ bgcolor: 'primary.main' }} variant='rounded'>
                  <Icon icon='mdi:bitcoin' color='white' />
                </Avatar>
                <Typography sx={{ pl: 2, fontSize: 18, fontWeight: 500 }}>Crypto</Typography>
              </Box>
              <Box>
                <PlatformItem platforms={cryptoPlatforms} />
              </Box>
            </CardContent>
          </Card>
          <Divider sx={{ mt: 6, mb: 8 }} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end', flexWrap: 'wrap' }}>
            <Link href='#' underline='none'>
              <Typography color='#9295A6' fontSize={16} sx={{ mb: { xs: 2, sm: 0 } }}>
                {t('NEED_HELP')}
              </Typography>
            </Link>
            <CustomBtn
              sx={{ backgroundColor: 'primary.main' }}
              style={{ width: 230 }}
              onClick={() => {
                navigate('/')
              }}
            >
              {t('CLOSE')}
            </CustomBtn>
          </Box>
        </CardContent>
        {/* <ChangePasswordForm /> */}
      </Card>

      <DialogGeneric
        show={show}
        hideActions={true}
        showIconClose={true}
        closeFunc={() => {
          setShow(false)
        }}
        title={t(userPlatfom.apiSecret === '' ? 'ADD_API_CREDENTIALS' : 'EDIT_API_CREDENTIALS')}
      >
        <Box sx={{ pt: 4 }}>
          <CredentialsForm
            platformIdSelected={selectedPlatformId.toString()}
            editForm={
              userPlatfom.platformId !== selectedPlatformId
                ? undefined
                : { apiKey: userPlatfom.apiKey, apiSecret: userPlatfom.apiSecret, passPhrase: userPlatfom.passPhrase }
            }
            submitFinishFunction={data => {
              setUserPlatform({
                apiKey: data.apiKey,
                apiSecret: data.apiSecret,
                platformId: userPlatfom.platformId !== 0 ? userPlatfom.platformId : cryptoPlatforms[0].platformId,
                passPhrase: data.passPhrase,
                status: 1
              })
              setShow(false)
            }}
          />
        </Box>
      </DialogGeneric>
    </>
  )
}

export default ApiManagementCard
