import { useTranslation } from "react-i18next"
import { Avatar, Box, Card, CardContent, Typography } from "@mui/material"
import Icon from 'src/@core/components/icon'
import GenericTab, { Tab } from "src/layouts/components/GenericTab"

import ProfitSummaryTable from "./admin-performance-report/ProfitSummaryTable"
import PostionRealTimeManagement from "./admin-position-real-time/PostionRealTimeManagement"

const ReportsManager = () => {

    const { t } = useTranslation()

    const tabs: Tab[] = [
      {
        index: 0,
        label: t('POSITIONS_REAL_TIME'),
        component: <PostionRealTimeManagement isContent/>,
        icon: 'mdi:timetable'
      },
      {
        index: 1,
        label: t('PERFORMANCE_REPORT'),
        component: <ProfitSummaryTable isContent />,
        icon: 'tdesign:money'
      }
    ]


    return <Card>
    <CardContent sx={{}}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center',  flexWrap:'wrap' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar sx={{ width: 40, height: 40, bgcolor: 'primary.main' }} color='error' variant='rounded'>
            <Icon icon='mdi:list-box-outline' style={{ fontSize: 24 }} color='white' />
          </Avatar>
          <Typography sx={{ ml: 2 }} variant='h5'>
            { t('REPORTS')}
          </Typography>
        </Box>
      </Box>
      <GenericTab tabs={tabs}></GenericTab>
    </CardContent>
  </Card>
}

export default ReportsManager